import styled from "styled-components";

export const StyledTenderProductTable = styled.div`
  display: grid;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
  /* max-height: 220px; */
  overflow: auto;

  .tender-product__table .product-table__grid {
    grid-template-columns: unset;
    grid-template-rows: repeat(6, 24px);
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }

  .tender-product__table .product-table__grid > div:nth-child(odd) {
    background-color: #fff;
  }

  .tender-product__table .product-table__grid > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
  }

  .tender-product__table__two .product-table__grid {
    display: block;
  }

  .tender-product__table__two .product-table__grid .product-table__grid-box {
    height: 24px;
  }
`;
