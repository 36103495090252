import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type uiState = {
  drawerIsOpen: boolean;
};

const initialState: uiState = {
  drawerIsOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerIsOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDrawerIsOpen } = uiSlice.actions;

export default uiSlice.reducer;
