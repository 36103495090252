import styled, { keyframes } from "styled-components";

const loaderSpinAnim = keyframes`
  
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }

`;

const checkmarkAnim = keyframes`
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: var(--checkWidth);
      opacity: 1;
    }
    40% {
      height: var(--checkHeight);
      width: var(--checkWidth);
      opacity: 1;
    }
    100% {
      height: var(--checkHeight);
      width: var(--checkWidth);
      opacity: 1;
    }
`;

export const StyledTenderProductBidding = styled.div`
  border-radius: 15px;
  box-shadow: 0 5px 30px 0 rgba(74, 85, 100, 0.14);
  background-color: #fff;
  padding: 1rem;
  column-gap: 1.2rem;
  position: relative;
  overflow: hidden;

  --loaderSize: 40px;
  --brandSuccess: var(--green);
  --checkHeight: calc(var(--loaderSize) / 2);
  --checkWidth: calc(var(--checkHeight) / 2);
  --checkLeft: calc(var(--loaderSize) / 6 + var(--loaderSize) / 17.5);
  --checkThickness: 3px;
  --checkColor: var(--brandSuccess);
  grid-column: 2;
  grid-row: 1;

  .tender-product__bidding__section__title {
    font-family: var(--archivo);
    font-size: 10.5px;
    letter-spacing: 0.53px;
    color: #687c97;
  }

  .tender-product__bidding__fixed {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 27px;
    margin: 1rem 0;
    align-items: center;
  }

  .tender-product__bidding__fixed:last-of-type {
    margin-bottom: 0;
  }

  .tender-product__bidding__fixed__label {
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    color: #687c97;
  }

  .tender-product__bidding__discount {
    font-family: var(--archivo);
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #687c97;
  }

  .tender-product__bidding__fixed__value {
    font-size: 18px;
  }

  .tender-product__bidding__input__wrapper {
    position: relative;
    margin: 1rem 0;
  }

  .tender-product__bidding__input__wrapper input {
    height: 27px;
    border-radius: 6px;
    border: solid 1px rgba(192, 203, 209, 0.25);
    display: block;
    width: 60%;
    outline-style: none;
    text-align: center;
  }

  .tender-product__bidding__submit {
    width: 60%;
    height: 35px;
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.11);
    background-color: #32dea5;
    border-radius: 6px;

    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .tender-product__bidding__cancel {
    width: 60%;
    height: 35px;
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.11);
    background-color: #687c97;
    border-radius: 6px;

    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #fff;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tender-product__bidding__your-bid__title {
    width: 60%;
    text-align: center;
  }

  .tender-product__bidding__submit__icon {
    width: 20px;
    margin-right: 4px;
  }

  .tender-product__bidding__difference {
    font-family: var(--archivo);
    font-size: 14px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .tender-product__bidding__difference.green {
    color: var(--green);
  }

  .tender-product__bidding__difference.red {
    color: var(--red);
  }

  .tender-product__bidding__difference--hidden {
    opacity: 0;
  }

  .tender-product__bidding__detail > span {
    display: none;
  }

  .tender-product__bidding__loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .tender-product__bidding__loading__text {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    color: #687c97;
  }

  .tender-product__bidding__circle-loader {
    margin-bottom: calc(var(--loaderSize) / 5);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: var(--green);
    animation: ${loaderSpinAnim} 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: var(--loaderSize);
    height: var(--loaderSize);
  }

  .tender-product__bidding__circle-loader--complete {
    -webkit-animation: none;
    animation: none;
    border-color: var(--green);
    transition: border 500ms ease-out;
  }

  .tender-product__bidding__checkmark.draw .after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: ${checkmarkAnim};
    transform: scaleX(-1) rotate(135deg);
  }

  .tender-product__bidding__checkmark .after {
    opacity: 1;
    height: var(--checkHeight);
    width: var(--checkWidth);
    transform-origin: left top;
    border-right: var(--checkThickness) solid var(--checkColor);
    border-top: var(--checkThickness) solid var(--checkColor);
    left: var(--checkLeft);
    top: var(--checkHeight);
    position: absolute;
  }

  .tender-product__bidding__timer {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #edf2f6;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .tender-product__bidding__timer .progress {
    height: 3px;
  }

  .tender-product__bidding__timer__title {
    font-family: var(--archivo);
    font-size: 15px;
    font-weight: 600;
    color: #687c97;
    display: flex;
    align-items: center;
  }

  .tender-product__bidding__timer__title svg {
    width: 20px;
    margin-right: 4px;
  }

  .tender-product__bidding__main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 30px;
    column-gap: 1rem;
  }

  .tender-product__bidding__main input {
    border-radius: 6px;
    border: solid 1px rgba(192, 203, 209, 0.25);
    outline-style: none;
    font-family: var(--archivo);
    font-size: 14px;
    text-align: center;
    color: #687c97;
    overflow: hidden;
  }

  .tender-product__bidding__main button {
    width: 100%;
    height: 30px;
  }

  .tender-product__bidding__place-bid {
    font-family: var(--archivo);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.65px;
    color: #687c97;
    fill: #687c97;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .tender-product__bidding__place-bid svg {
    width: 24px;
    margin-right: 2px;
  }

  .tender-product__bidding__place-bid svg > * {
    fill: #687c97;
  }

  .countdown__label {
    display: none;
  }

  .tender-product__bidding__notice {
    font-family: var(--archivo);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #687c97;
    grid-column: span 3;
    margin-top: 10px;
  }

  .tender-product__bidding__reserve__container {
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #edf2f6;
    margin-bottom: 1.25rem;
  }

  .tender-product__bidding__reserve {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cff8ea;
    height: 36px;
    border: 6px;
    overflow: none;
  }

  .tender-product__bidding__reserve > span {
    font-family: var(--archivo);
    font-size: 12px;
    font-weight: 600;
    color: #04895d;
    margin-right: 1rem;
  }

  .tender-product__bidding__reserve .price,
  .tender-product__bidding__reserve .price span {
    font-family: var(--archivo);
    font-size: 21px;
    font-weight: 600;
    color: #04895d;
  }

  .tender-product__bidding__reserve .price .price__postfix {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: bold;
    color: #04895d;
    opacity: 0.5;
  }

  .info-tooltip {
    padding: 12px;
    font-family: var(--archivo);
    font-weight: 600;
    color: #a2a7b4;
    line-height: 1.25;
    border-radius: 6px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eaeef2;
    background-color: #fff;
    font-size: 14px;
  }
`;
