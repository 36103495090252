import { useUserLocation } from "features/users/queries";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";

const initialState = {
  days: "00",
  hours: "00",
  minutes: "00",
  seconds: "00",
  isFinished: true,
};

const addZero = (num: number) => (num < 10 ? `0${num}` : String(num));

const getDate = (
  countDownDate: moment.Moment,
  timezone: string | undefined
) => {
  // Get today's date and time
  const now = timezone ? moment().tz(timezone) : moment();

  // Find the distance between now and the count down date
  const distance = countDownDate.diff(now);

  if (distance < 0) return initialState;

  // Time calculations for days, hours, minutes and seconds
  // days left
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  // hours left
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  // minutes left
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // seconds left
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    days: addZero(days),
    hours: addZero(hours),
    minutes: addZero(minutes),
    seconds: addZero(seconds),
    isFinished: days + hours + minutes + seconds === 0,
  };
};

function useCountdown(date: string, onFinish?: () => void) {
  const { data: location } = useUserLocation();
  const timezone = useMemo(() => location?.timezone, [location?.timezone]);
  const onFinishRef = useRef(onFinish);
  const countDownDate = useMemo(() => moment(date), [date]);
  const [state, setState] = useState(getDate(countDownDate, timezone));

  useEffect(() => {
    const interval = setInterval(() => {
      const newState = getDate(countDownDate, timezone);
      setState(newState);
      if (newState.isFinished && onFinishRef.current) onFinishRef.current();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [countDownDate, timezone]);

  return state;
}

export default useCountdown;
