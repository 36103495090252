import * as RadixTabs from "@radix-ui/react-tabs";
import React from "react";
import styled from "styled-components";

const StyledTabs = styled.div`
  overflow-x: auto;
  white-space: nowrap;

  button {
    padding: 8px 27px;
    border-radius: 30px;
    transition: opacity 0.3s ease-in-out;
    font-family: ${({ theme }) => theme.fonts.archivo};
    opacity: 0.5;
    outline-style: none;
  }
  button[data-state="active"] {
    opacity: 1 !important;
    color: #492cbd;
    background-color: ${({ theme }) => theme.colors.primary[50]};
    font-weight: bold;
  }

  button:hover {
    opacity: 0.75;
  }
`;

const Tabs = ({
  children,
  ...rest
}: RadixTabs.TabsProps &
  React.RefAttributes<HTMLDivElement> & { children?: React.ReactNode }) => {
  return (
    <StyledTabs className="ui-tabs scrollbar--horizontal">
      <RadixTabs.Root {...rest}>{children}</RadixTabs.Root>
    </StyledTabs>
  );
};

Tabs.List = RadixTabs.List;
Tabs.Trigger = RadixTabs.Trigger;
Tabs.Content = RadixTabs.Content;

export default Tabs;
