import styled from "styled-components";

export const StyledPageSummary = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--sectionPadding);
  background-color: #f9fbfd;
  background-image: url(/static/media/register-bg.7a0b87d1.jpg);
  background-size: 100% 6.375rem;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: border-box;
  position: relative;

  .ui-page-summary__title {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[25]};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[700]};
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }

  .ui-page-summary__subtitle {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[16]};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .ui-page-summary__values {
    display: flex;
    align-items: center;
  }

  .ui-page-summary__value {
    display: flex;
    align-items: center;
  }

  .ui-page-summary__value__label {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[14]};
    color: ${({ theme }) => theme.colors.neutral[700]};
    margin-right: 4px;
  }

  .ui-page-summary__value__number {
    border-radius: 30px;
    min-width: 35px;

    p {
      font-family: ${({ theme }) => theme.fonts.archivo};
      font-size: ${({ theme }) => theme.fontSize[14]};
      border-radius: 30px;
      padding: ${({ theme }) => theme.spacing[4]}
        ${({ theme }) => theme.spacing[8]};
      color: #fff;
      font-weight: 600;
      text-align: center;
      background-color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: grid;
    row-gap: 1rem;

    .ui-page-summary__values {
      display: flex;
      align-items: center;
      overflow-x: auto;
      flex-wrap: no-wrap;
    }

    .ui-page-summary__values .ui-divider {
      display: none;
    }

    .ui-page-summary__value {
      width: 102px;
      height: 86px;
      min-width: 102px;
      min-height: 86px;
      overflow: hidden;
      padding: 12px;
      border-radius: 11px;
      box-shadow: 0 20px 30px 0 rgba(187, 181, 198, 0);
      background-color: #fff;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-right: 12px;
    }

    .ui-page-summary__value__label {
      max-width: 10ch;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }
`;
