import { FiltersType } from "types/filters";
import Filters from "components/Filters";
import { ReactComponent as Close } from "assets/icons/x.svg";
import { ReactComponent as Filter } from "assets/icons/filter.svg";
import { useTenderProducts } from "features/products/queries";
import Shimmer from "ui/Shimmer";
import { useEffect, useState } from "react";
import { getProductsDistincts } from "../../util";

const TenderFilters = ({
  filtersList,
  tenderId,
}: {
  filtersList: FiltersType;
  tenderId: string;
}) => {
  const [filters, setFilters] = useState(filtersList);
  const { data, isFetching, isFetched } = useTenderProducts(tenderId);

  useEffect(() => {
    if (data && data.length > 0) {
      const distinctValues = getProductsDistincts(
        [
          { name: "model", type: "select" },
          { name: "carat", type: "range" },
          { name: "color", type: "select" },
          { name: "clarity", type: "select" },
          { name: "lab", type: "select" },
          { name: "cut", type: "select" },
          { name: "polish", type: "select" },
          { name: "symmetry", type: "select" },
        ],
        data
      );
      const newFilters = filtersList.map((filter) => {
        const newFilter = { ...filter };
        const { name } = newFilter;
        const values = distinctValues[name];
        if (values && newFilter.type === "select" && Array.isArray(values)) {
          newFilter.options = newFilter.options.filter((option) => {
            return values
              .filter((item) => item !== undefined)
              .map((item) => item.toUpperCase())
              .includes(option.value.toUpperCase());
          });
        }
        if (newFilter.type === "range" && !Array.isArray(values)) {
          newFilter.min = values?.min || 0;
          newFilter.max = values?.max || 0;
        }
        return newFilter;
      });
      setFilters(newFilters);
    }
  }, [filtersList, data]);

  return (
    <Shimmer shimmer={isFetching && !isFetched}>
      <div className="tender-page__filters">
        <div className="tender-page__filters-top">
          <div className="tender-page__filters__header">
            <Filter className="tender-page__filters__header-icon" />
            <p className="tender-page__filters__header-text">Filter Diamonds</p>
          </div>
          <Filters.Clear className="tender-page__filters__clear">
            <Close className="tender-page__filters__clear-icon" />
            <p className="tender-page__filters__clear-text">Clear Filters</p>
          </Filters.Clear>
        </div>
        <Filters filters={filters} />
      </div>
    </Shimmer>
  );
};

export default TenderFilters;
