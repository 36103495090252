import { ReactComponent as Diamond } from "../../../assets/icons/no-tenders.svg";
interface Props {
  text?: React.ReactNode;
}

const NoTenders = ({ text }: Props) => {
  return (
    <div className="p-overview__notenders">
      <Diamond />
      <div className="p-overview__notenders__text">{text}</div>
    </div>
  );
};

export default NoTenders;
