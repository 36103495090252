import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import Table from "ui/Table";
import Tabs from "ui/Tabs";
import {
  useBuyerOrders,
  useBuyerOrdersOverview,
  useSupplierOrders,
  useSupplierOrdersOverview,
} from "features/orders/queries";
import { useUser } from "features/users/queries";
import { useEffect, useMemo, useState } from "react";
import { Cell, Column, usePagination, useSortBy, useTable } from "react-table";
import { numberWithCommas } from "../../utils";
import moment from "moment";
import Price from "ui/Price";
import useModalActions from "hooks/useModalActions";
import { useAnalytics } from "hooks/useAnalytics";
import { useHistory, useLocation } from "react-router-dom";
import Page from "ui/Page";
import { StyledOrders, StyledOrderStatus } from "./Orders.styled";

const tabs = (type: string) => [
  {
    label: type === "buyer" ? "Total Purchases" : "Total Orders",
    value: "total",
  },
  {
    label: type === "buyer" ? "Active Purchases" : "Active Orders",
    value: "active",
  },
  {
    label: type === "buyer" ? "Completed Purchases" : "Completed Orders",
    value: "completed",
  },
  {
    label: type === "buyer" ? "Rejected Purchases" : "Rejected Orders",
    value: "rejected",
  },
];

const hooks = {
  buyer: {
    orders: useBuyerOrders,
    overview: useBuyerOrdersOverview,
  },
  supplier: {
    orders: useSupplierOrders,
    overview: useSupplierOrdersOverview,
  },
};

const Orders = ({ type }: { type: keyof typeof hooks }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const track = useAnalytics();
  const { openModal } = useModalActions();
  const [tab, setTab] = useState("total");
  const { data: user } = useUser();
  const {
    data: ordersOverview,
    isFetching: isFetchingOrdersOverview,
    isFetched: isFetchedOrdersOverview,
  } = hooks[type].overview();

  const {
    data: orders,
    isFetching: isFetchingOrders,
    isFetched: isFetchedOrders,
  } = hooks[type].orders(tab);

  const columns = useMemo(
    (): Column<Order>[] => [
      {
        Header: "Order ID",
        accessor: "_id",
        Cell: ({ row }: Cell<Order>) => (
          <Shine>
            <p className="p-orders__table__id">
              {"#" +
                String(row.original._id).substr(row.original._id.length - 7)}
            </p>
          </Shine>
        ),
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row }: Cell<Order>) => (
          <Shine>
            <p className="p-orders__table__id">
              {moment(row.original.createdAt).format("LL")}
            </p>
          </Shine>
        ),
      },
      {
        Header: "Event",
        accessor: "tenderOrder.tender.name" as any,
        Cell: ({ row }: Cell<Order>) => {
          // const { openModal } = useModalActions();
          const tender = row.original.tenderOrder?.tender;
          return (
            <Shine>
              <p className="p-orders__table__event">
                {tender?.name || "No tender name"}
              </p>
            </Shine>
          );
        },
      },
      {
        Header: type === "buyer" ? "Purchase Status" : "Order Status",
        accessor: "status",
        Cell: ({ row }: Cell<Order>) => {
          const status = row.original.orderStatus;

          const getStatus = () => {
            if (!status) return "-";
            if (status.includes("invoiced")) return "Invoicing";
            if (status.includes("shipping")) return "Shipping";
            if (status.includes("verification")) return "Verification";
            return status;
          };

          return (
            <Shine>
              <StyledOrderStatus status={getStatus()}>
                {getStatus()}
              </StyledOrderStatus>
            </Shine>
          );
        },
      },
      {
        Header: "Total Value",
        accessor: (originalRow) => originalRow.totalPrice,
        Cell: ({ row }: Cell<Order>) => (
          <Shine>
            <Price
              price={row.original.totalPrice}
              className="p-orders__table__price"
            />
          </Shine>
        ),
      },
      {
        Header: "",
        id: "more details",
        disableSortBy: true,
        Cell: ({ row }: Cell<Order>) => (
          <Shine>
            <p className="p-orders__table__more-details">More Details</p>
          </Shine>
        ),
      },
    ],
    [type]
  );

  const table = useTable(
    {
      columns,
      data: orders || [],
      initialState: { pageSize: 30 },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (tab === "total")
      type === "buyer"
        ? track("My Purchases - Total Tab")
        : track("My Orders - Total Tab");
    if (tab === "active")
      type === "buyer"
        ? track("My Purchases - Active Tab")
        : track("My Orders - Active Tab");
    if (tab === "completed")
      type === "buyer"
        ? track("My Purchases - Completed Tab")
        : track("My Orders - Completed Tab");
    if (tab === "rejected")
      type === "buyer"
        ? track("My Purchases - Rejected Tab")
        : track("My Orders - Rejected Tab");
  }, [tab, type, track]);

  useEffect(() => {
    setTab("total");
  }, [pathname]);

  useEffect(() => {
    if (
      pathname.includes("/supplier/orders") &&
      !user?.company.isTenderSupplier
    ) {
      history.push("/buyer/orders/all");
    }
  }, [type, user, history, pathname]);

  if (!user || !ordersOverview || !orders) return null;

  const values = [
    {
      label: type === "buyer" ? "Total Purchases:" : "Total Orders:",
      value: numberWithCommas(ordersOverview.totalOrders),
    },
    {
      label: type === "buyer" ? "Active Purchases:" : "Active Orders:",
      value: numberWithCommas(ordersOverview.inProcessOrders),
    },
    {
      label: type === "buyer" ? "Completed Purchases:" : "Completed Orders:",
      value: numberWithCommas(ordersOverview.completedOrders),
    },
    {
      label: type === "buyer" ? "Rejected Purchases:" : "Rejected Orders:",
      value: numberWithCommas(ordersOverview.rejectedOrders),
    },
  ];

  return (
    <StyledOrders>
      <Shimmer shimmer={isFetchingOrdersOverview && !isFetchedOrdersOverview}>
        <Page.Summary
          title={`My ${type === "buyer" ? "Purchases" : "Orders"}`}
          subtitle={`You can view the status of your
              ${type === "buyer" ? "purchases" : "orders"} here`}
          values={values}
        />
      </Shimmer>
      <Page.Tabs>
        <Tabs
          orientation="horizontal"
          onValueChange={(newTab) => setTab(newTab)}
          defaultValue={tab}
        >
          <Tabs.List aria-label="choose your orders type">
            {tabs(type).map((tab) => {
              return (
                <Tabs.Trigger key={tab.value} value={tab.value}>
                  {tab.label}
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
        </Tabs>
      </Page.Tabs>
      <Table
        table={table}
        shimmer={isFetchingOrders && !isFetchedOrders}
        switchState={orders}
        className="p-orders__table"
        onRowClick={(tenderOrder) => {
          type === "buyer"
            ? track("My Purchases - Purchase Click")
            : track("My Orders - Order Click");
          openModal({
            modalType: "Order",
            modalProps: { tenderOrder },
          });
        }}
        emptyText={
          <>
            Looks like there are no {type === "buyer" ? "purchases" : "orders"}{" "}
            here. <br />
            When you'll have {type === "buyer" ? "a purchase" : "an order"}, the
            status will appear here.
          </>
        }
      />
    </StyledOrders>
  );
};

export default Orders;
