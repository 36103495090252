import { ReactComponent as CheckCircle } from "assets/icons/checkmark-circle.svg";
import { useAnalytics } from "hooks/useAnalytics";
import { useEffect } from "react";
import Button from "ui/Button";
import { RegisterLastStep } from "../Register.styled";
import { useRegisterContext } from "../Register";
import { useMutateUser } from "features/users/mutations";
import useModalActions from "hooks/useModalActions";
import { useHistory } from "react-router-dom";
import { useUser } from "features/users/queries";

const LastStep = () => {
  const { data: loggedInUser } = useUser();
  const history = useHistory();
  const { closeSpecificModal } = useModalActions();
  const mutateUser = useMutateUser();
  const { user } = useRegisterContext();
  const track = useAnalytics();

  useEffect(() => {
    track("Registration Success");
  }, [track]);

  const handleClick = () => {
    closeSpecificModal("RegisterPopup");
    if (!loggedInUser) mutateUser(user);
    history.push("/");
  };

  useEffect(() => {
    return () => {
      if (!loggedInUser) mutateUser(user);
    };
  }, [user, mutateUser, loggedInUser]);

  return (
    <RegisterLastStep className="register__step__last">
      <CheckCircle />
      <p className="register__step__last__title">
        Thank you for registering to Trade Diamonds
      </p>
      <p className="register__step__last__subtitle">
        Your account is being processed and veryfied by our team.
        <br /> This process may take 7-10 business days and it is a crucial step
        in order to
        <br /> maintain our growing community of legitimate diamond buyer and
        sellers.
        <br /> We will notify you via email as soon as your account is approved.
      </p>
      <Button
        className="register__last__step__submit"
        kind="purple"
        onClick={handleClick}
      >
        Get started!
      </Button>
    </RegisterLastStep>
  );
};

export default LastStep;
