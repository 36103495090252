import styled from "styled-components";

export const StyledForgotPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - var(--headerHeight));
  width: 100%;
  background-color: #f9fbfd;
  background-image: url(/static/media/register-bg.7a0b87d1.jpg);
  background-size: 100% 13.375rem;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: border-box;
  position: relative;

  .forgot__title {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-weight: 600;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.neutral[700]};
    text-align: center;
    margin: 0 auto;
  }

  .forgot__subtitle {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: 1.25rem 0;
    max-width: 50ch;
  }

  .forgot__inner {
    width: 380px;
  }

  .ui-button {
    margin: 1rem auto 0 auto;
    width: 50%;
  }

  .forgot__email__sent {
    margin: 1rem auto 0 auto;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 1.15rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.success[200]};
  }

  .ui-goback {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }

  .forgot__email__sent--mobile {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-rows: minmax(auto, 450px) min-content;
    row-gap: 1rem;
    align-items: unset;
    padding: var(--sectionPadding);
    width: 100%;

    .forgot__inner {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .forgot__title {
      text-align: left;
      font-size: ${({ theme }) => theme.fontSize[25]};
      margin: 0;
    }

    .forgot__subtitle {
      text-align: left;
    }

    .forgot__go-back {
      position: unset;
    }

    input,
    .ui-button {
      height: 45px;
    }

    .ui-button {
      width: 100%;
    }

    .ui-goback {
      position: unset;
      text-align: center;
      justify-content: center;
    }

    form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
    }

    .forgot__email__sent {
      position: absolute;
    }

    .forgot__email__sent {
      display: none;
    }

    .forgot__email__sent--mobile {
      display: block;
    }
  }
`;
