import Shimmer from "ui/Shimmer";
import {
  useFinishedTenders,
  useTendersOverview,
} from "features/tenders/queries";
import Top from "./comps/Top";
import Shine from "ui/Shimmer/Shine";
import { useUser } from "features/users/queries";
import UpcomingTenderCard from "./comps/UpcomingTenderCard";
import TenderCard from "./comps/TenderCard";
import Description from "./comps/Description";
import SectionSeperator from "./comps/SectionSeperator";
import NoTenders from "./comps/NoTenders";
import FinishedTenderCard from "./comps/FinishedTenderCard";
import Accordion from "ui/Accordion";
import { StyledOverview } from "./Overview.styled";
import { capitalizeFirstLetter } from "utils";

const Overview = () => {
  const { data: user } = useUser();
  const { data, isFetching, isFetched } = useTendersOverview();
  const {
    data: finishedTenders,
    isFetching: isFetchingFinishedTenders,
    isFetched: isFetchedFinishedTenders,
  } = useFinishedTenders();
  if (!data || !user) return null;
  const { liveTenders, nextTenders, upcomingTenders } = data;
  return (
    <Shimmer
      shimmer={
        (isFetching && !isFetched) ||
        (isFetchingFinishedTenders && !isFetchedFinishedTenders)
      }
    >
      <StyledOverview className="p-overview">
        <Top
          name={[
            capitalizeFirstLetter(user.firstName),
            capitalizeFirstLetter(user.lastName),
          ].join(" ")}
          liveTenders={liveTenders.length}
          upcomingTenders={upcomingTenders.length}
        />
        <Description />
        {(finishedTenders || []).length > 0 && (
          <div className="p-overview__tenders ">
            <SectionSeperator title="Finished Tenders" />
            {(finishedTenders ?? []).map((tender) => (
              <FinishedTenderCard key={tender._id} tender={tender} />
            ))}
          </div>
        )}
        <div id="live-tenders" className="p-overview__tenders">
          <SectionSeperator title="Live Tenders" />
          {(() => {
            if (liveTenders.length === 0) {
              return (
                <NoTenders
                  text={<p>When a tender is live it will appear here</p>}
                />
              );
            }
            return liveTenders.map((tender) => (
              <TenderCard
                key={tender._id}
                tender={tender}
                status={
                  <Shine>
                    <div className="c-tendercard__live">Closes in</div>
                  </Shine>
                }
              />
            ));
          })()}
        </div>
        {nextTenders.length > 0 && (
          <div className="p-overview__tenders">
            <SectionSeperator title="Next Tenders" />
            {nextTenders.map((tender) => (
              <TenderCard
                isNext
                key={tender._id}
                tender={tender}
                status={
                  <Shine>
                    <div className="c-tendercard__next">Starts in</div>
                  </Shine>
                }
              />
            ))}
          </div>
        )}
        <div id="upcoming-tenders" className="p-overview__tenders">
          <SectionSeperator title="Upcoming Tenders" />
          {(() => {
            if (upcomingTenders.length === 0) {
              return (
                <NoTenders
                  text={
                    <p>
                      There are currently no upcoming tenders,
                      <br /> Future tenders will appear here
                    </p>
                  }
                />
              );
            }
            return upcomingTenders.map((tender) => (
              <UpcomingTenderCard key={tender._id} tender={tender} />
            ));
          })()}
        </div>
        <div className="p-overview__faqs">
          <SectionSeperator title="Frequently Asked Questions" />
          <Accordion type="multiple">
            {[
              {
                header: "How do I sign up for a tender?",
                body:
                  "On the Trade Diamonds left menu, select ‘Overview’ (under ‘Lobby’) - there you can view all the upcoming events. You can choose any event from the list and click ‘Register’.",
              },
              {
                header: "When can I see the diamonds offered in a Tender?",
                body:
                  "The catalog of diamonds for each event are unveiled online 24 hours before the event starts. To see the list of available diamonds, you can click on ‘See Catalog’ for either a live tender or ‘Next Tenders’.",
              },
              {
                header: "How do I get notified about a Tender event?",
                body:
                  "Once a new Tender event is scheduled, you will be notified by email as well as a notification on the platform.",
              },
              {
                header:
                  "What are the measures taken to verify the integrity of the diamonds that Trade Diamonds offers for purchase?",
                body:
                  "Once the tender is complete, the sold diamonds are sent to a Hennig local office for verification. The verification process includes validation of the stone against its GIA certificate, allowing us to guarantee the authenticity of the stone.",
              },
            ].map(({ header, body }, index) => {
              return (
                <Accordion.Item value={`item-${index}`} key={index}>
                  <Accordion.Trigger>
                    {header}
                    <Accordion.Arrrow />
                  </Accordion.Trigger>
                  <Accordion.Content>{body}</Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </StyledOverview>
    </Shimmer>
  );
};

export default Overview;
