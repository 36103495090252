import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  border-radius: ${(props) => props.theme.borderRadius[30]};
  min-width: 34px;
  padding: 0 ${(props) => props.theme.spacing[8]};
  font-family: ${(props) => props.theme.fonts.archivo};
  font-size: ${(props) => props.theme.fontSize.tiny};
  letter-spacing: 0.63px;
  color: ${(props) => props.theme.colors.neutral[600]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
