import React from "react";
import Button from "ui/Button";
import Modal from "..";
import { StyledConfirmModal } from "./ConfirmModal.styled";

type Props = {
  title: React.ReactNode;
  subtitle: React.ReactNode;

  submitText: React.ReactNode;
  cancelText: React.ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
};

const ConfirmModal = ({
  title,
  subtitle,
  submitText,
  cancelText,
  onSubmit,
  onCancel,
}: Props) => {
  return (
    <Modal>
      <StyledConfirmModal>
        <Modal.Close />
        <h3 className="confirm__title">{title}</h3>
        <p className="confirm__subtitle">{subtitle}</p>
        <div className="confirm__actions">
          <Button kind="grey" onClick={onSubmit}>
            {submitText}
          </Button>
          <Button kind="white" onClick={onCancel}>
            {cancelText}
          </Button>
        </div>
      </StyledConfirmModal>
    </Modal>
  );
};

export default ConfirmModal;
