import { useEffect } from "react";

let defaultOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

/**
 *
 * @param {object} props id: target id
 * @returns null
 */
export const useIntersectionObserver = ({
  id = "",
  callback = () => {},
  options = defaultOptions,
}) => {
  useEffect(() => {
    const target = document.getElementById(id);

    let cb = (entries, observer) => {
      entries.forEach((entry) => {
        // Each entry describes an intersection change for one observed
        // target element:
        //   entry.boundingClientRect
        //   entry.intersectionRatio
        //   entry.intersectionRect
        callback(entry);
        //   entry.rootBounds
        //   entry.target
        //   entry.time
      });
    };
    const observer = new IntersectionObserver(cb, options);

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [callback, id, options]);
  return null;
};
