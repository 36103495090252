import React from "react";

interface Props {}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
  }

  render(): React.ReactNode {
    if (this.state?.hasError as boolean) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
