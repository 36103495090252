import { ReactComponent as HelpCenter } from "../../assets/icons/support/help-center.svg";
import { ReactComponent as TalkToUs } from "../../assets/icons/support/talk-to-us.svg";
import { ReactComponent as WhatsApp } from "../../assets/icons/support/whatsapp.svg";
import { ReactComponent as Email } from "../../assets/icons/support/email.svg";
import { Container } from "./Support.styled";
import { useRef } from "react";
import Accordion from "ui/Accordion/Accordion";
const texts = {
  supportPageTitle: "We're here to help.",
  supportPageSubtitle: "Here are all the ways you can get in touch with us",
  supportPageHelpCenterTitle: "Help center",
  supportPageHelpCenterSubtitle:
    "All of our knowledge is just a click away. Search your questions, Read the articles & Find the answers.",
  supportPageHelpCenterLink: "Click here",
  supportPageTalkToUsTitle: "Talk To Us Personally",
  supportPageTalkToUsSubtitle:
    "Looking for human help? Call us on business days from 10am-6pm (Georgia time) to ask any technical questions.",
  supportPageTalkToUsLink: "+1 (917) 456 8007",
  supportPageWhatsappTitle: "WhatsApp Support",
  supportPageWhatsappSubtitle:
    "If you wish to use WhatsApp to receive support on any issue, you can click here to open the conversation.",
  supportPageWhatsappLink: "+1 (917) 456 8007",
  supportPageEmailTitle: "Send Us An Email",
  supportPageEmailSubtitle:
    "You can contact us for AML/KYC and formal company question.",
  supportPageEmailLink: "Support@get-diamonds.com",
  faqPageTitle: "Frequently Asked Questions",
  fqaPageSubtitle: "Here is all the ways to be in touch with us.",
};

const faq = [
  {
    title: "How long does it take to approve my registration?",
    text: (
      <>
        Once your application for registration is complete, your account will be
        processed and verified by our back-office team. This process may take up
        to 48 hours and it is a crucial step in maintaining the integrity of our
        diamond trading community.
      </>
    ),
  },
  {
    title: "Can anyone register for the Trade Diamonds platform?",
    text: "Trade Diamonds is a B2B platform for diamond and jewelry-related companies. In order to be an active member on the platform, you must be a recognised company in the diamond industry and pass successfully through a KYC/AML verification process.",
  },
  {
    title: "How do I sign up for a tender?",
    text: "On the Trade Diamonds left menu, select ‘Overview’ (under ‘Lobby’) - there you can view all the upcoming events. You can choose any event from the list and click ‘Register’.",
  },
  {
    title: "When can I see the diamonds offered in a Tender?",
    text: "The catalog of diamonds for each event are unveiled online 24 hours before the event starts. To see the list of available diamonds, you can click on ‘See Catalog’ for either a live tender or ‘Next Tenders’.",
  },

  {
    title: "How do I get notified about a Tender event?",
    text: "Once a new Tender event is scheduled, you will be notified by email as well as a notification on the platform.",
  },
  {
    title:
      "What are the measures taken to verify the integrity of the diamonds that Trade Diamonds offers for purchase?",
    text: "Once the tender is complete, the sold diamonds are sent to a Hennig local office for verification. The verification process includes validation of the stone against its GIA certificate, allowing us to guarantee the authenticity of the stone.",
  },
  {
    title: "Can I see who owns the diamonds on the tender?",
    text: "In order to guarantee the integrity and confidentiality of the Trade Diamonds Tender process, the identity of neither the buyer or seller is never revealed during the process.",
  },
  {
    title: "Do I have to finish the AML process when signing up?",
    text: "In case you will have the highest bid and win a stone, you will have to complete the AML process in order to receive the goods.",
  },
  {
    title: "How does the payment process work?",
    text: `After the diamond is verified by Hennig as part of the logistics process, Hennig will issue an invoice to the buyer. The buyer will then pay via bank transfer to Hennig Group. Once the transfer is received, Hennig Group will ask the seller to issue an invoice and after receiving the receipt, they will transfer the money (minus commission) to the seller. The payment will be transferred to the seller’s bank account after Hennig receives the money and issues a receipt. Hennig will remit the funds to the seller usually within 24 hrs from receipt of invoice.`,
  },
  {
    title: "How do I receive the diamond after I won the tender?",
    text: "Following the stone verification and receiving the payment, the stone will be shipped from Hennig’s office using a 3rd party courier service. As long as the courier allows the client and destination to use collect charges the shipping will be carried out “Collect charges”. In such cases, the client will be notified and will have to accept to pay the charges before the shipment can leave our office. It is important that the client reacts swiftly to avoid delays.",
  },
  {
    title: "Who pays the shipping costs?",
    text: "The seller pays the shipping costs to Hennig’s local office (in either IND, BE, IL, NY). The buyer pays for the shipping costs from Hennig’s office to their location. If there is no local office where the stone is located, the seller will pay for shipping to a selected Hennig office (either at the country of the Buyer if it is one of the 4 above, or to either IL/BE; whichever is closer).",
  },

  {
    title: "How do I win a diamond on a tender?",
    text: `Each tender event lasts 12 hours. Each buyer can place bids until the close of the event.
The highest bidder equal to or above the Indicative price automatically wins the diamond.
Note: It is possible to place several different bids on different diamonds in each tender.`,
  },

  {
    title: "How do I know if I won?",
    text: `The highest bid on each stone wins. The winner will receive a personal email that announces the winning bid up to 3 hours after the tender is closed.
    If the highest bid of each stone is equal/higher than the reserve price, then the seller is obligated to the sell. If the highest bid is lower than the reserve price, then the seller can refuse to sell the diamond.`,
  },

  {
    title: "Can I amend/cancel my bid after I have placed it?",
    text: "You can amend/cancel any bid as long as the Tender event is still open. To amend/cancel your bid, go to ‘Active Event’ and then click on the diamond you wish to cancel. Then, in the diamond pop-up page, click on ‘Cancel Bid’, after you cancel it you can insert the new bid you wish to suggest.",
  },
  {
    title: "What is the benefit of AI here?",
    text: "An AI-based algorithm knows how to perform complex operations over a large and complex data set and allows us to automatically rank and choose the most attractive stones based on a large set of parameters.",
  },
];

const Support = () => {
  const containerRef = useRef(null as null | HTMLDivElement);
  const contacts = [
    {
      icon: <HelpCenter />,
      title: texts.supportPageHelpCenterTitle,
      subtitle: texts.supportPageHelpCenterSubtitle,
      // link: () => texts.supportPageHelpCenterLink,
      link: (
        <a
          className="contact__link"
          href="https://intercom.help/tradediamonds"
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </a>
      ),
    },
    {
      icon: <TalkToUs />,
      title: texts.supportPageTalkToUsTitle,
      subtitle: texts.supportPageTalkToUsSubtitle,
      link: (
        <p
          className="contact__link"
          style={{ textDecoration: "none", opacity: 0.4 }}
        >
          Coming Soon
        </p>
      ),
    },
    {
      icon: <WhatsApp />,
      title: texts.supportPageWhatsappTitle,
      subtitle: texts.supportPageWhatsappSubtitle,
      link: (
        <p
          className="contact__link"
          style={{ textDecoration: "none", opacity: 0.4 }}
        >
          Coming Soon
        </p>
      ),
    },
    {
      icon: <Email />,
      title: texts.supportPageEmailTitle,
      subtitle: texts.supportPageEmailSubtitle,
      link: (
        <a
          className="contact__link"
          href="mailto:info@tradediamonds.com"
          target="_blank"
          rel="noreferrer"
        >
          info@tradediamonds.com
        </a>
      ),
    },
  ];

  // useEffect(() => {
  //   if (location.pathname === "/faq") {
  //     document.querySelector(".faq")?.scrollIntoView({ behavior: "smooth" });
  //   } else {
  //     containerRef.current?.scrollTo({ top: 0 });
  //   }
  // }, [location]);

  return (
    <Container ref={containerRef}>
      <div className="inner">
        <h1 className="title" style={{ display: "none" }}>
          {texts.supportPageTitle}
        </h1>
        <p className="subtitle" style={{ display: "none" }}>
          {texts.supportPageSubtitle}
        </p>
        <div className="contacts" style={{ display: "none" }}>
          {contacts.map((contact, index) => (
            <div key={index} className="contact">
              <div className="contact__left">{contact.icon}</div>
              <div className="contact__right">
                <p className="contact__title">{contact.title}</p>
                <p className="contact__subtitle">{contact.subtitle}</p>
                {contact.link}
              </div>
            </div>
          ))}
        </div>
        <div className="faq">
          <h1 className="title">{texts.faqPageTitle}</h1>
          {/* <p className="subtitle faq__subtitile">{texts.fqaPageSubtitle}</p> */}
          <Accordion type="multiple">
            {faq.map(({ title, text }, index) => (
              <Accordion.Item value={`item-${index}`} key={index}>
                <Accordion.Trigger>
                  {title}
                  <Accordion.Arrrow />
                </Accordion.Trigger>
                <Accordion.Content>{text}</Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </Container>
  );
};

export default Support;
