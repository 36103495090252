import ProductImage from "components/ProductImage";
import { useState } from "react";
import Modal from "../";
import cn from "classnames";
import { StyledMediaModal } from "./MediaModal.styled";
interface Props {
  product: Product;
}

const initialOptions = [
  { key: "diamondImage", type: "image" } as const,
  { key: "diamondImage2", type: "image" } as const,
  { key: "diamondImage3", type: "image" } as const,
  { key: "diamondImage360", type: "360" } as const,
] as const;

const MediaModal = ({ product }: Props) => {
  const [options] = useState(initialOptions);
  const [selected, setSelected] = useState<typeof initialOptions[number]>(
    initialOptions[0]
  );

  const images = () =>
    options.map((option) => {
      return product[option.key] ? (
        <div
          className={cn("media-popup__left__box", {
            "media-popup__selected": option.key === selected.key,
          })}
          key={option.key}
          onClick={() => setSelected(option)}
        >
          {option.type === "360" ? (
            <img
              alt="product"
              src="/assets/images/diamond360.png"
              className="media-popup__diamond360"
            />
          ) : (
            <ProductImage
              alt="product"
              src={product[option.key]}
              onClick={() => setSelected(option)}
            />
          )}
        </div>
      ) : null;
    });

  return (
    <Modal>
      <StyledMediaModal>
        <Modal.Close />
        {images.length > 1 && <div className="media-popup__left">{images}</div>}
        <div className="media-popup__right">
          <ProductImage
            alt="product"
            src={product[selected.key]}
            is360={selected.type === "360"}
          />
        </div>
      </StyledMediaModal>
    </Modal>
  );
};

export default MediaModal;
