import styled from "styled-components";
import Page from "ui/Page";

export const StyledOrderStatus = styled.p<{ status: string }>`
  border-radius: 30px;
  color: #fff;
  background-color: ${({ status }) => {
    const GREEN = "var(--green)";
    const RED = "var(--red)";
    const ORANGE = "var(--orange)";
    const GRAY = "#c2c4c5";
    status = status.toLowerCase();
    if (status.includes("pending")) return ORANGE;
    if (status.includes("approved") || status.includes("completed"))
      return GREEN;
    if (status.includes("rejected")) return RED;
    return GRAY;
  }};
`;

export const StyledOrders = styled(Page)`
  display: grid;
  grid-template-rows: min-content min-content auto min-content;

  .ui-table__container {
    --columns: repeat(6, minmax(140px, 1fr));
    width: calc(100% - var(--sectionPadding) * 2);
    margin: 0 auto;

    ${StyledOrderStatus} {
      color: #fff;
    }

    .p-orders__table__more-details {
      font-weight: 600;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  @media (max-height: 740px) {
    grid-template-rows: min-content min-content minmax(450px, auto) min-content;
  }
`;
