import styled, { css, keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export interface StyledLoaderProps {
  size?: number;
  color?: string;
  activeColor?: string;
}

export interface StyledLoaderContainerProps {
  centered?: boolean;
}

export const StyledLoaderContainer = styled.div<StyledLoaderContainerProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.centered
      ? css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `
      : undefined}
`;

export const StyledLoader = styled.span<StyledLoaderProps>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid ${({ color }) => color || "white"};
  border-right: 2px solid ${({ color }) => color || "white"};
  border-bottom: 2px solid ${({ color }) => color || "white"};
  border-left: 2px solid ${({ activeColor }) => activeColor || "#d1d7e0"};
  background: transparent;
  width: ${({ size }) => (size || 24) + "px"};
  height: ${({ size }) => (size || 24) + "px"};
  border-radius: 50%;
`;
