import styled from "styled-components";

export const StyledFinishedTender = styled.div`
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  border-radius: 10px;
  padding: 2rem 2rem;
  position: relative;
  max-width: 600px;
  width: 100%;
  max-height: 80%;
  overflow: auto;

  .finished-tender__timesup {
    font-family: var(--archivo);
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: rgba(104, 124, 151, 0.5);
  }

  .finished-tender__title {
    font-family: var(--archivo);
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    color: #f5a000;
    text-transform: uppercase;
    line-height: 2;
  }

  .finished-tender__results {
    font-family: var(--archivo);
    font-size: 15px;
    line-height: 1.53;
    max-width: 55ch;
    margin: 0 auto;
    text-align: center;
  }

  .finished-tender__faq__title {
    font-family: var(--archivo);
    font-size: 18px;
    font-weight: 600;
    color: #687c97;
    margin: 20px 0;
  }

  .finished-tender__faq .accordion {
    border: none;
    margin: 0.75rem 0.5rem 0 0.5rem;
  }

  .finished-tender__faq .accordion-header {
    border: solid 1.5px rgba(192, 203, 209, 0.28);
    background-color: #fff;
    padding: 0.5rem;
    margin: 0 0 0.75rem 0;
  }

  .finished-tender__faq .accordion-header__title {
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 500;
    color: #687c97;
  }

  .finished-tender__faq .accordion-header__arrow {
    color: #687c97;
  }

  .finished-tender__faq .accordion-body {
    border-radius: 6px;
    box-shadow: 0 5px 30px 0 rgba(202, 215, 227, 0.59);
    background-color: #fff;
    /* margin: 0.5rem 0; */
  }

  .finished-tender__accordion__inner__body {
    padding: 1rem;
  }

  .finished-tender__faq__box__title {
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #222b45;
  }

  .finished-tender__gotooverview {
    height: 42px;
    width: 220px;
    border-radius: 6px;
    box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.11);
    background-color: #32dea5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 0 auto;

    font-family: var(--archivo);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: #fff;
    user-select: none;
  }

  .ui-accordion__header .ui-accordion__arrow {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 100%;
    width: calc(100% - var(--sectionPadding) * 2);
    max-height: calc(100% - var(--headerHeight));
    padding: var(--sectionPadding);

    .finished-tender__title {
      font-size: 20px;
    }

    .finished-tender__results {
      font-size: 13px;
    }

    .finished-tender__faq__title {
      margin: 10px 0;
    }

    .ui-accordion__header,
    .ui-accordion__body .ui-accordion__body__text {
      padding: var(--sectionPadding);
      font-size: 13px;
    }

    .ui-accordion__header .ui-accordion__title {
      font-size: 14px;
    }
  }
`;
