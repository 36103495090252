// import { ReactComponent as Copy } from "assets/icons/social/copy.svg";
// import { ReactComponent as Mail } from "assets/icons/social/mail.svg";
// import { ReactComponent as Whatsapp } from "assets/icons/social/whatsapp.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { CSSTransition } from "react-transition-group";
// import useCopyToClipboard from "hooks/useCopyToClipboard";
// import { toast } from "react-toastify";
import { useGiaCertificate } from "features/products/queries";
import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import cn from "classnames";
import { StyledTenderProductCertificate } from "./TenderProductCertificate.styled";
import { useEffect } from "react";
interface Props {
  tenderProduct: TenderProduct;
  certificateIsOpen: boolean;
  setCertificateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  small: boolean;
  certificateIsActive: boolean;
  setCertificateIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenderProductCertificate = ({
  tenderProduct,
  certificateIsOpen,
  setCertificateIsOpen,
  small,
  setCertificateIsActive,
}: Props) => {
  // const { copy } = useCopyToClipboard();
  const { product } = tenderProduct;
  const { data, isFetching, isFetched } = useGiaCertificate({
    mongoId: product._id,
    certificateId: product.certificateId || "",
  });

  // const onCopy = async (certificate: string) => {
  //   const res = await copy(certificate);
  //   res && toast.success("Certificate copied successfully");
  //   !res && toast.error("Could not copy certificate");
  // };

  useEffect(() => {
    if (!data) {
      setCertificateIsOpen(false);
      setCertificateIsActive(false);
    }
  }, [data, setCertificateIsOpen, setCertificateIsActive]);

  if (!data) return null;

  return (
    <CSSTransition
      in={certificateIsOpen}
      timeout={400}
      classNames="my-node"
      unmountOnExit
    >
      <Shimmer shimmer={isFetching && !isFetched}>
        <StyledTenderProductCertificate
          className={cn({
            "tender-product__certificate--small": !!small,
          })}
        >
          <div className="tender-product__certificate__inner">
            <div className="tender-product__certificate__top">
              <img
                className="tender-product__certificate__gia"
                src="/assets/images/gia.png"
                alt="gia"
              />
              <button
                className="tender-product__certificate__close"
                onClick={() => setCertificateIsOpen(false)}
              >
                <ChevronDown color="#687c97" width={22} />
              </button>
            </div>
            <div className="tender-product__certificate__wrapper">
              <div className="tender-product__certificate__left">
                <div>
                  <p className="tender-product__certificate__section__title">
                    GIA NATURAL DIAMOND GRADING REPORT
                  </p>
                  <Table
                    rows={[
                      { label: "October 01, 2021", value: "" },
                      {
                        label: "Shape and cutting style:",
                        value: data.results.shape_and_cutting_style,
                      },
                      {
                        label: "Measurements:",
                        value: data.results.measurements,
                      },
                    ]}
                  />
                </div>
                <div>
                  <p className="tender-product__certificate__section__title">
                    GRADING RESULTS{" "}
                  </p>
                  <Table
                    rows={[
                      {
                        label: "Carat weight:",
                        value: data.results.carat_weight,
                      },
                      {
                        label: "Color grade:",
                        value: data.results.color_grade,
                      },
                      {
                        label: "Clarity grade:",
                        value: data.results.clarity_grade,
                      },
                      { label: "Cut grade:", value: data.results.cut_grade },
                    ]}
                  />
                </div>
                <div>
                  <p className="tender-product__certificate__section__title">
                    ADDITIONAL GRADING INFORMATION
                  </p>
                  <Table
                    rows={[
                      {
                        label: "Polish:",
                        value: data.results.polish,
                      },
                      {
                        label: "Symmetry:",
                        value: data.results.symmetry,
                      },
                      {
                        label: "Fluorescence:",
                        value: data.results.fluorescence,
                      },
                    ]}
                  />
                  <Shine>
                    <p className="tender-product__certificate__comments">
                      <strong>Comments:</strong>{" "}
                      {data.results.report_comments ?? "No comments"}
                    </p>
                  </Shine>
                </div>
              </div>
              <div className="tender-product__certificate__right">
                {!!data.links.plotting_diagram && (
                  <div>
                    <p className="tender-product__certificate__section__title">
                      Proportions
                    </p>
                    <Shine>
                      <div className="tender-product__certificate__proportions">
                        <img src={data.links.plotting_diagram} alt="plotting" />
                      </div>
                    </Shine>
                  </div>
                )}
              </div>
            </div>
          </div>
        </StyledTenderProductCertificate>
      </Shimmer>
    </CSSTransition>
  );
};

const Table = ({ rows }: { rows: { label: string; value: string }[] }) => {
  return (
    <div className="tender-product__certificate__table">
      {rows.map(({ label, value }, index) => {
        return (
          <div className="tender-product__certificate__table__row" key={index}>
            <p className="tender-product__certificate__table__cell__label">
              <Shine>
                <span>{label}</span>
              </Shine>
            </p>
            <p className="tender-product__certificate__table__cell__value">
              <Shine>
                <span>{value}</span>
              </Shine>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default TenderProductCertificate;
