import "./Accordion.css";
import React, { useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
const AccordionContext = React.createContext(null);
const useAccordionContext = () => {
  const context = React.useContext(AccordionContext);
  if (!context) {
    throw new Error("Must be in Accordion component");
  }
  return context;
};
function Accordion({ className = "", children, onClick }) {
  const [open, setOpen] = useState(false);
  const toggle = React.useCallback(() => setOpen((prev) => !prev), [setOpen]);
  return (
    <AccordionContext.Provider value={{ open, toggle }}>
      <div
        className={cn("custom-accordion", className, open ? "open" : "")}
        onClick={onClick}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
}
const arrowVariants = {
  closed: { rotate: 0 },
  open: { rotate: -180 },
};
const AccordionHeader = ({ children, showArrow = true, arrowSrc = "" }) => {
  const { open, toggle } = useAccordionContext();
  return (
    <div
      className="custom-accordion__header"
      onClick={showArrow ? toggle : () => {}}
    >
      {children}
      {showArrow && (
        <motion.img
          src={"/assets/icons/" + arrowSrc}
          alt="accordion arrow"
          className="custom-accordion__arrow"
          animate={open ? "open" : "closed"}
          variants={arrowVariants}
          transition={{ bounce: 0.1, duration: 0.4 }}
          initial={false}
        />
      )}
    </div>
  );
};
const variants = {
  closed: { height: 0, opacity: 0 },
  open: { height: "auto", opacity: 1 },
};
const AccordionBody = ({ children }) => {
  const { open } = useAccordionContext();
  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants}
      transition={{ bounce: 0.1, duration: 0.4 }}
      className={cn("custom-accordion__body")}
      initial={false}
    >
      {children}
    </motion.div>
  );
};
Accordion.useAccordionContext = useAccordionContext;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
export default Accordion;
