import styled from "styled-components";

export const StyledConfirmModal = styled.div`
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 10px;
  min-width: 450px;
  min-height: 160px;
  text-align: center;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 8px;
    font-family: var(--archivo);
    font-size: 20px;
  }

  .confirm__subtitle {
    font-size: 16px;
    font-family: var(--archivo);
  }

  .confirm__actions {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;

    button {
      width: 100%;
      height: 40px;
    }
  }
`;
