import {
  rangeFilter,
  selectFilter,
  clearFilters,
} from "features/filters/slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useAnalytics } from "hooks/useAnalytics";
import React, { useEffect } from "react";
import { OptionsType } from "react-select";
import { FiltersType, RangeNames, SelectNames } from "types/filters";
import ClearFilters from "./ClearFilters";
import RangeFilter from "./RangeFilter";
import SelectFilter from "./SelectFilter";

interface Props {
  filters: FiltersType;
  className?: string;
}

const Filters = ({ filters, className = "" }: Props) => {
  const track = useAnalytics();
  const dispatch = useAppDispatch();
  const filtersState = useAppSelector((state) => state.filters);
  const handleOnSelect = React.useCallback(
    (
      filterName: SelectNames,
      value: OptionsType<{ value: string; label: string }>
    ) => {
      dispatch(selectFilter({ filterName, value }));
    },
    [dispatch]
  );

  const handleOnRange = React.useCallback(
    (filterName: RangeNames, type: "from" | "to", value: number) => {
      dispatch(rangeFilter({ filterName, type, value }));
    },
    [dispatch]
  );

  useEffect(() => {
    track("Active Tender - Filter Change", filtersState);
  }, [filtersState, track]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, [dispatch]);

  return (
    <div className={`filters ${className}`}>
      {filters.map((filter) => {
        switch (filter.type) {
          case "select":
            return (
              <Filters.Select
                key={filter.name}
                filter={filter}
                handleOnSelect={handleOnSelect}
                values={filtersState.select[filter.name] ?? []}
              />
            );
          case "range":
            return (
              <Filters.Range
                key={filter.name}
                filter={filter}
                handleOnRange={handleOnRange}
                from={filtersState.range[filter.name]?.from ?? ""}
                to={filtersState.range[filter.name]?.to ?? ""}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

Filters.Select = SelectFilter;
Filters.Range = RangeFilter;
Filters.Clear = ClearFilters;

export default Filters;
