type Props = {
  product: Product;
};

const useBgmType = ({ product }: Props) => {
  if (
    ["brown", "green"].includes(product?.shade?.toLowerCase() || "") ||
    ["light", "medium", "heavy"].includes(
      product?.milkyInclusions?.toLowerCase() || ""
    )
  ) {
    return "No BGM";
  }

  return "BGM";
};

export default useBgmType;
