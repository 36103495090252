import { useUser, useUserLocation } from "features/users/queries";
import { useEffect } from "react";
import useModalActions from "./useModalActions";

function useTerms() {
  const { isLoading: isLoadingUserLocation } = useUserLocation();
  const { data: user, isLoading } = useUser();
  const { openModal } = useModalActions();
  useEffect(() => {
    if (!isLoadingUserLocation && !isLoading && user && !user.approvedTerms) {
      openModal({
        modalType: "Terms",
        modalProps: undefined,
        withoutClose: true,
      });
    }
  }, [isLoadingUserLocation, isLoading, openModal, user]);

  return null;
}

export default useTerms;
