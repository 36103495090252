import { MIXPANEL_TOKEN } from "config";
import { useUser, useUserLocation } from "features/users/queries";
import mixpanel from "mixpanel-browser";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

const isLocal = window.location.origin.includes("localhost") && false;

mixpanel.init(MIXPANEL_TOKEN);

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

type AnalyticsEvent =
  | "Page View"
  | "Sign In Press"
  | "Sign In Success"
  | "Get Login Press"
  | "Get Login Success"
  | "Register Now Button"
  | "Registration Step - Account Type"
  | "Registration Step - Create An Account"
  | "Registration Step - AML/KYC"
  | "Registration Step - Director/Admin Details"
  | "Registration Step - References"
  | "Registration Step - Upload Files & Terms"
  | "Registration Success"
  | "Live Tender - Enter Button"
  | "Next Tender - Register Button"
  | "Live Tender - Catalog Button"
  | "Next Tender - Catalog Button"
  // | "Next Tender - Add to Calendar Button"
  | "Upcoming Tender - Register Button"
  // | "Upcoming Tender - Catalog Button"
  | "Upcoming Tender - Add to Calendar Button"
  | "Catalog Popup - Enter Button"
  | "Catalog Popup - Register Button"
  | "Event Popup - Catalog Button"
  | "Event Popup - Register Button"
  | "Event Popup - Add to calendar Button"
  | "Overview - FAQ"
  | "My Diamonds - Approve Button"
  | "My Diamonds - Approved Tab"
  | "My Diamonds - Pending Tab"
  | "My Diamonds - Rejected Tab"
  | "Approve Diamond Popup - Approve Button"
  | "Approve Diamond Popup - Decline Button"
  | "Notifications - Bar Press"
  | "Notifications - Message Press"
  | "Active Tender - Export Table"
  | "Active Tender - Diamond View"
  | "Active Tender - Add to Wishlist"
  | "Active Tender - Remove from Wishlist"
  | "Active Tender - Filter Change"
  | "Diamond View - Create Bid"
  | "Diamond View - Cancel Bid"
  | "Diamond View - Add to Wishlist"
  | "Diamond View - Remove from Wishlist"
  | "Diamond View - View Certificate"
  | "My Bids - Bar Press Open"
  | "My Bids - Bar Press Close"
  | "Menu - Overview Page Press"
  | "Menu - Active Tender Page Press"
  | "Menu - My Purchases Page Press"
  | "Menu - My Diamonds Page Press"
  | "Menu - My Orders Page Press"
  | "Menu - About Page Press"
  | "Menu - FAQ Page Press"
  | "Menu - Support Page Press"
  | "Menu - Back To Get"
  | "My Purchases - Purchase Click"
  | "My Purchases - Total Tab"
  | "My Purchases - Active Tab"
  | "My Purchases - Completed Tab"
  | "My Purchases - Rejected Tab"
  | "My Orders - Order Click"
  | "My Orders - Total Tab"
  | "My Orders - Active Tab"
  | "My Orders - Completed Tab"
  | "My Orders - Rejected Tab"
  | "Login - Reset Password";

export const useAnalytics = () => {
  const { data: user } = useUser();
  const { data: userLocation } = useUserLocation();
  const track = useCallback(
    (eventName: AnalyticsEvent, values?: any) => {
      let bodyToSend = {
        ...(values || {}),
        ...(userLocation
          ? {
              userCity: userLocation.city,
              userCountry: userLocation.country,
              userIp: userLocation.ip,
              userLocation: userLocation.loc,
              userOrg: userLocation.org,
              userRegion: userLocation.region,
              userTimezone: userLocation.timezone,
            }
          : {}),
      };
      if (!user) {
        bodyToSend = { ...bodyToSend, isGuest: true };
        if (!isLocal) mixpanel.track(eventName, bodyToSend);
        return;
      }
      try {
        bodyToSend = {
          ...bodyToSend,
          userId: user._id,
          userEmail: user.email,
          userName: [
            capitalizeFirstLetter(user.firstName),
            capitalizeFirstLetter(user.lastName),
          ].join(" "),
          userCompanyName: user.company.name,
        };
        if (!isLocal) mixpanel.track(eventName, bodyToSend);
      } catch (error) {}
    },
    [user, userLocation]
  );
  return track;
};

export const PageViewAnalytics = () => {
  const track = useAnalytics();
  const location = useLocation();
  useEffect(() => {
    const page = !location.pathname
      ? "/" + location.pathname
      : location.pathname;
    track("Page View", {
      page,
    });
  }, [location.pathname, track]);
  return null;
};
