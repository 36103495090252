import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Styles, headerLinksSupplier, headerLinksBuyer } from "./Consts";
import "./styles/_header-user.css";
import { MemoSubHeaderUser } from "./SubHeaderUser";
import Accordion from "./Accordion";
import useClickOutside from "./hooks/useClickOutside";
// Icons

import Logo from "./assets/get-diamonds.png";
import LogoBlue from "./assets/get-diamonds-blue.png";
// import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { ReactComponent as Hamburger } from "./assets/hamburger.svg";
// import { ReactComponent as User } from './assets/svg/user.svg';

// import { ReactComponent as Hammer } from './assets/icons/header/hammer.svg';
import { ReactComponent as Question } from "./assets/question.svg";
import { ReactComponent as Diamond } from "./assets/diamond.svg";
import { ReactComponent as Message } from "./assets/message.svg";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Logout } from "./assets/log-out.svg";

import SwitchAnimator from "./SwitchAnimator";
import { useTendersOverview } from "features/tenders/queries";
import { useLogout } from "features/users/mutations";
import { GD_CLIENT_URL } from "config";
import useModalActions from "hooks/useModalActions";
import Notifications from "components/Notifications";
import { useIsUserSupplier, useUser } from "features/users/queries";
import { useWishlist } from "features/wishlist/queries";
import { useGDWishlist } from "features/gd/queries";
import Counter from "ui/Counter";
import Loader from "ui/Loader";
// const languagesIcons = [
//   { label: 'English', src: EN },
//   { label: 'Arabic', src: AR },
//   { label: 'Russian', src: RU },
//   { label: 'Hindi', src: HD },
//   { label: 'French', src: FR },
//   { label: 'Chinese', src: CN },
// ];

export const HeaderUser = ({ isSupplier = true, user, setShowModal }) => {
  const { openModal } = useModalActions();
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("home");
  const [popupOpen, setPopupOpen] = useState(false);
  const { pathname } = useLocation();
  const { data: tendersWishlist, isLoading: isLoadingTendersWishlist } =
    useWishlist();
  const { data: GDWishlist, isLoading: isLoadingGDWishlist } = useGDWishlist();

  const wishlist = [...(tendersWishlist || []), ...(GDWishlist || [])];

  const navLinkProps = {
    activeclassname: "active",
  };

  const headerLinks = isSupplier ? headerLinksSupplier : headerLinksBuyer;

  useEffect(() => {
    setMenuOpen(false);
    const tabName = pathname.slice(1);
    if (tabName.includes("/") && !tabName.includes("search"))
      setCurrentTab(tabName.split("/")[0]);
    else if (tabName.includes("search")) setCurrentTab("diamonds");
    else setCurrentTab(tabName);
  }, [pathname]);

  const changeSearchTypeWithDispatch = (type) => {};

  return (
    <>
      <div className={Styles.HEADER_CONTAINER_USER}>
        <div className={Styles.LEFT}>
          <button
            onClick={() => {
              setPopupOpen(false);
              setMenuOpen(!menuOpen);
            }}
            className={Styles.HAMBURGER}
          >
            <Hamburger />
          </button>
          <a href={GD_CLIENT_URL}>
            <img src={LogoBlue} alt="" className={Styles.LOGO} id="blue" />
            <img src={Logo} alt="" className={Styles.LOGO} id="white" />
          </a>
          <div className={Styles.NAV_CONTAINER}>
            {headerLinks.map((link, idx) =>
              link.url ? (
                <NavLink {...navLinkProps} to={link.url} key={idx} exact={true}>
                  {link.label}
                  {link.isNew && <span className="new-link">New</span>}
                </NavLink>
              ) : link.isReady ? (
                <a href={link.external} {...link} key={idx}>
                  {link.label}
                  {link.isNew && <span className="new-link">New</span>}
                </a>
              ) : (
                <a
                  key={idx}
                  onClick={() => setShowModal(true)}
                  style={{ cursor: "pointer" }}
                >
                  {link.label}
                  {link.isNew && <span className="new-link">New</span>}
                </a>
              )
            )}
          </div>
        </div>
        <div className={Styles.USER_ACTIONS_CONTAINER}>
          <div className={Styles.LEFT}>
            <a href={GD_CLIENT_URL + "/support"} className="flex">
              <Question className={Styles.FILL} />
            </a>
            <div className={Styles.WISHLIST_CONATINER}>
              <Diamond
                className={Styles.FILL}
                onClick={() =>
                  openModal({ modalType: "WishlistModal", modalProps: {} })
                }
              />
              {!!wishlist.length && (
                <Counter>
                  {isLoadingTendersWishlist || isLoadingGDWishlist ? (
                    <Loader size={8} />
                  ) : (
                    wishlist.length
                  )}
                </Counter>
              )}
            </div>
            {isSupplier && (
              <a
                className="flex"
                href="https://dashboard.get-diamonds.com/pages/messages.php"
              >
                <Message className={Styles.STROKE} />
              </a>
            )}
            <div className={Styles.NOTIFICATIONS_CONTAINER}>
              <Notifications />
            </div>
          </div>
          <div
            className={Styles.RIGHT}
            style={
              popupOpen && window.innerWidth > 768
                ? { pointerEvents: "none" }
                : {}
            }
            onClick={
              popupOpen
                ? () => {
                    setPopupOpen(false);
                  }
                : () => {
                    setPopupOpen(true);
                    setMenuOpen(false);
                  }
            }
          >
            <p>{user.firstName}</p>
            <User id="user" />
            <ChevronDown className={Styles.STROKE} />
          </div>
        </div>
      </div>

      {window.innerWidth > 768 ? (
        <UserActionsPopup
          isSupplier={isSupplier}
          isOpen={popupOpen}
          setPopupOpen={setPopupOpen}
          user={user}
        />
      ) : (
        <UserActionsPopupMobile
          isSupplier={isSupplier}
          isOpen={popupOpen}
          setPopupOpen={setPopupOpen}
          user={user}
        />
      )}
      <MemoSubHeaderUser tab={currentTab} />
      <div
        className={
          menuOpen
            ? Styles.MOBILE_CONTAINER_USER
            : Styles.MOBILE_CONTAINER_USER_OPEN
        }
      >
        <MobileSidebar
          navLinkProps={navLinkProps}
          setMenuOpen={setMenuOpen}
          changeSearchTypeWithDispatch={changeSearchTypeWithDispatch}
          isSupplier={isSupplier}
        />
      </div>
      <div
        className={
          menuOpen ? Styles.CLOSE_MENU_SCREEN_OPEN : Styles.CLOSE_MENU_SCREEN
        }
        onClick={() => setMenuOpen(!menuOpen)}
      ></div>
    </>
  );
};

const UserActionsPopup = ({ isSupplier, isOpen, setPopupOpen, user }) => {
  const { openModal } = useModalActions();
  const { logout } = useLogout();
  const history = useHistory();
  const menuRef = useRef(null);
  const [langOpen, setLangOpen] = useState(false);

  useClickOutside({
    ref: menuRef,
    state: isOpen,
    handler: () => setPopupOpen(false),
  });

  useEffect(() => {
    if (!isOpen) setLangOpen(false);
  }, [isOpen]);

  return (
    <>
      <span className={isOpen ? Styles.TRIANGLE_OPEN : Styles.TRIANGLE}>
        <div className="arrow-up"></div>
        <div className="arrow-down"></div>
        <div className="arrow-left"></div>
        <div className="arrow-right"></div>
      </span>
      <div
        ref={menuRef}
        className={`${
          isOpen
            ? isSupplier
              ? Styles.USER_ACTIONS_POPUP_OPEN
              : Styles.USER_ACTIONS_POPUP_OPEN_BUYER
            : Styles.USER_ACTIONS_POPUP
        } 
          ${langOpen ? Styles.USER_ACTIONS_POPUP_LNG : ""}`}
      >
        <SwitchAnimator state={langOpen}>
          <>
            <div className={Styles.USER}>
              <h4>{user.firstName}</h4>
              <p>{user.companyName}</p>
            </div>
            <div className={Styles.ACCOUNT}>
              <h5>ACCOUNT</h5>
              {!isSupplier && (
                <a href={GD_CLIENT_URL + "/account"}>My Account</a>
              )}
              <button
                onClick={() =>
                  openModal({ modalType: "WishlistModal", modalProps: {} })
                }
              >
                My Wishlist
              </button>
              {
                <button
                  onClick={
                    isSupplier
                      ? () =>
                          window.open(
                            "https://dashboard.get-diamonds.com/pages/profile.php?tab=company"
                          )
                      : () => window.open(GD_CLIENT_URL + "/account")
                  }
                >
                  My Details
                </button>
              }
            </div>
            {isSupplier && (
              <div className={Styles.MANAGE}>
                <h5>MANAGE</h5>
                <button
                  onClick={() =>
                    window.open("https://dashboard.get-diamonds.com/")
                  }
                >
                  Dashboard
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://dashboard.get-diamonds.com/pages/products.php"
                    )
                  }
                >
                  My Stock
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://dashboard.get-diamonds.com/pages/managers.php"
                    )
                  }
                >
                  My Managers
                </button>
              </div>
            )}
            <div className="trade">
              <h5>TRADE</h5>
              <button onClick={() => history.push("/buyer/orders")}>
                My Purchases
              </button>
              {user?.company?.isTenderSupplier && (
                <button onClick={() => history.push(`/supplier/orders/all`)}>
                  My Diamonds
                </button>
              )}
            </div>
            <div className={Styles.SUPPORT}>
              <h5>SUPPORT</h5>
              <button
                onClick={() =>
                  window.open("https://intercom.help/Get-diamonds/en/")
                }
              >
                Help Center
              </button>
              <button
                onClick={() => {
                  setPopupOpen(false);
                  window.open(GD_CLIENT_URL + "/support");
                }}
              >
                Contact Us
              </button>
            </div>
            <div className={Styles.ACTIONS}>
              <span>
                <button onClick={() => logout()}>Sign Out</button>
                <Logout />
              </span>
            </div>
          </>
        </SwitchAnimator>
      </div>
    </>
  );
};

const UserActionsPopupMobile = ({ isSupplier, isOpen, setPopupOpen, user }) => {
  const { openModal } = useModalActions();
  const { logout } = useLogout();
  const history = useHistory();
  const menuRef = useRef(null);
  useClickOutside({
    ref: menuRef,
    state: isOpen,
    handler: () => setPopupOpen(false),
  });
  const openWishlist = () => {
    history.push({
      search: `?wishlist`,
    });
    setPopupOpen(false);
  };
  const openMyBids = () => {};

  useEffect(() => {
    if (isOpen) document.body.style = "overflow: hidden";
    else document.body.style = "overflow: unset";
  }, [isOpen]);

  return (
    <div
      className={
        isOpen
          ? Styles.USER_ACTIONS_POPUP_MOBILE_OPEN
          : Styles.USER_ACTIONS_POPUP_MOBILE
      }
    >
      <div className={Styles.USER_INFO}>
        <div className={Styles.LEFT}>
          <User />
        </div>
        <div className={Styles.RIGHT}>
          <p className={Styles.USERNAME}>{user.firstName}</p>
          <p className={Styles.COMPANY}>{user.companyName}</p>
        </div>
      </div>
      <Accordion className="accordion-drawer">
        <Accordion.Header showArrow arrowSrc="chevron-down.svg">
          Account
        </Accordion.Header>
        <Accordion.Body>
          <button
            onClick={() =>
              openModal({ modalType: "WishlistModal", modalProps: {} })
            }
          >
            My Wishlist
          </button>
          <button
            onClick={() =>
              window.open(
                "https://dashboard.get-diamonds.com/pages/profile.php?tab=company"
              )
            }
          >
            My Details
          </button>
        </Accordion.Body>
      </Accordion>
      {isSupplier && (
        <Accordion className="accordion-drawer">
          <Accordion.Header showArrow arrowSrc="chevron-down.svg">
            Manage
          </Accordion.Header>
          <Accordion.Body>
            <button
              onClick={() => window.open("https://dashboard.get-diamonds.com/")}
            >
              Dashboard
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://dashboard.get-diamonds.com/pages/products.php"
                )
              }
            >
              My Stock
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://dashboard.get-diamonds.com/pages/managers.php"
                )
              }
            >
              My Managers
            </button>
          </Accordion.Body>
        </Accordion>
      )}
      <Accordion className="accordion-drawer">
        <Accordion.Header showArrow arrowSrc="chevron-down.svg">
          Trade
        </Accordion.Header>
        <Accordion.Body>
          <Link to={`/buyer/orders`}>My Purchases</Link>
          {user?.company?.isTenderSupplier && (
            <Link to={`/supplier/mydiamonds/pending`}>My Diamonds</Link>
          )}
        </Accordion.Body>
      </Accordion>
      <Accordion className="accordion-drawer">
        <Accordion.Header showArrow arrowSrc="chevron-down.svg">
          Support
        </Accordion.Header>
        <Accordion.Body>
          <button
            onClick={() =>
              window.open("https://intercom.help/Get-diamonds/en/")
            }
          >
            Help Center
          </button>
          <button
            onClick={() => {
              setPopupOpen(false);
              window.open(GD_CLIENT_URL + "/support", "self");
            }}
          >
            Contact Us
          </button>
        </Accordion.Body>
      </Accordion>
      <Accordion className="accordion-drawer">
        <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
          <button onClick={() => logout()}>
            Log Out <Logout />
          </button>
        </Accordion.Header>
      </Accordion>
    </div>
  );
};

const MobileSidebar = ({
  navLinkProps,
  setMenuOpen,
  changeSearchTypeWithDispatch,
  isSupplier,
}) => {
  const { data: user } = useUser();
  const [isTradeSideBar, setIsTradeSideBar] = useState(false);
  const [isLiveTender, setIsLiveTender] = useState(false);
  const history = useHistory();
  const { isSupplier: isTenderSupplier } = useIsUserSupplier();
  const { data, isError } = useTendersOverview();
  useEffect(() => {
    if (data) checkIfThereIsLiveTender(data);
  }, [data]);

  const checkIfThereIsLiveTender = (data) => {
    if (isError || data.liveTenders.length === 0) setIsLiveTender(false);
    else setIsLiveTender(true);
  };

  const getActiveTenderLink = () => {
    return `/tender/${data.liveTenders[0]._id}`;
  };

  const closeMenu = () => setMenuOpen(false);

  return (
    <>
      {!isTradeSideBar && (
        <div className={Styles.NAV_CONTAINER_MOBILE}>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <a href={GD_CLIENT_URL} {...navLinkProps} target="_parent">
                Home
              </a>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
              <a>Diamonds</a>
            </Accordion.Header>
            <Accordion.Body>
              <a
                href={GD_CLIENT_URL + "/search"}
                target="_parent"
                onClick={() => {
                  closeMenu();
                }}
              >
                Single
              </a>
              <button
                href={GD_CLIENT_URL + "/search"}
                target="_parent"
                onClick={() => {
                  closeMenu();
                }}
              >
                Pairs
              </button>
              <button
                href={GD_CLIENT_URL + "/search"}
                target="_parent"
                onClick={() => {
                  closeMenu();
                }}
              >
                Parcels
              </button>
              <a href={GD_CLIENT_URL + "/creatediamondneed"} target="_parent">
                Create Call
              </a>
            </Accordion.Body>
          </Accordion>
          <Accordion
            className="accordion-drawer"
            onClick={() => setIsTradeSideBar(true)}
          >
            <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
              <button {...navLinkProps} onClick={() => setIsTradeSideBar(true)}>
                Trade
                <span className="new-link">New</span>
              </button>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
              <a href={GD_CLIENT_URL + "/members/sellers"} target="_parent">
                Members
              </a>
            </Accordion.Header>
            <Accordion.Body>
              <a href={GD_CLIENT_URL + "/members/sellers"} target="_parent">
                Sellers
              </a>
              <a href={GD_CLIENT_URL + "/members/buyers"} target="_parent">
                Buyers
              </a>
              <a href={GD_CLIENT_URL + "/members/favorites"} target="_parent">
                Favorites
              </a>
            </Accordion.Body>
          </Accordion>
          {isSupplier && (
            <Accordion className="accordion-drawer">
              <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
                <a href="https://dashboard.get-diamonds.com/" target="_blank">
                  Manage
                </a>
              </Accordion.Header>
            </Accordion>
          )}
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <a href={GD_CLIENT_URL + "/news"} target="_blank" rel="noopener">
                News
              </a>
            </Accordion.Header>
          </Accordion>
        </div>
      )}
      {isTradeSideBar && (
        <div className={Styles.NAV_CONTAINER_MOBILE_TRADE}>
          <div className="top">
            <button onClick={() => setIsTradeSideBar(false)}>{"<"}</button>
            <div className="header">
              <h3>Trade</h3>
              <span className="new-link">New</span>
            </div>
          </div>
          <div className="bottom">
            <Accordion className="accordion-drawer">
              <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
                <a>Lobby</a>
              </Accordion.Header>
              <Accordion.Body>
                <Link to={`/overview`} target="_self">
                  Overview
                </Link>
                {isLiveTender ? (
                  <Link to={getActiveTenderLink()} onClick={closeMenu}>
                    Active Tender
                  </Link>
                ) : (
                  <button style={{ opacity: "0.6" }} disabled={true}>
                    Active Tender
                  </button>
                )}
              </Accordion.Body>
            </Accordion>
            <Accordion className="accordion-drawer">
              <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
                <a>Buy</a>
              </Accordion.Header>
              <Accordion.Body>
                <Link to={`/buyer/orders`} onClick={closeMenu}>
                  My Purchases
                </Link>
              </Accordion.Body>
            </Accordion>
            {!!user?.company?.isTenderSupplier && (
              <Accordion className="accordion-drawer">
                <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
                  <a>Sell</a>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to={`/supplier/mydiamonds/pending`} onClick={closeMenu}>
                    My Diamonds
                  </Link>
                  <Link to={`/supplier/orders/all`} onClick={closeMenu}>
                    My Orders
                  </Link>
                </Accordion.Body>
              </Accordion>
            )}
            <Accordion className="accordion-drawer">
              <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
                <a>Help</a>
              </Accordion.Header>
              <Accordion.Body>
                <a href={GD_CLIENT_URL + "/about-trade"} target="_parent">
                  About Us
                </a>
                <Link to={`/faq`} onClick={closeMenu}>
                  FAQ
                </Link>
                <a href={GD_CLIENT_URL + "/support"}>Support</a>
              </Accordion.Body>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};
