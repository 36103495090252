import Shimmer from "ui/Shimmer";
import { useTenderProductAnalysis } from "features/products/queries";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { kFormatter } from "../../../util";
import { ReactComponent as BarChart } from "assets/icons/bar-chart.svg";
import Price from "ui/Price";
import Shine from "ui/Shimmer/Shine";
import { useActiveTenderProductBid } from "features/bids/queries";
import { useCallback, useMemo, useRef } from "react";
import { ReactComponent as Diamond } from "assets/icons/no-data.svg";
import { StyledTenderProductAnalysis } from "./TenderProductAnalysis.styled";
interface Props {
  tenderProduct: TenderProduct;
  tenderId: string;
  tenderUserId: string;
}

const TenderProductAnalysis = ({
  tenderProduct,
  tenderUserId,
}: // tenderId,
Props) => {
  const indicativeIndex = useRef(-1);
  const averageIndex = useRef(-1);
  const myBidIndex = useRef(-1);

  const { data, isFetching, isFetched } = useTenderProductAnalysis(
    tenderProduct._id
  );

  const {
    data: activeTenderProductBid,
    // isLoading: isLoadingActiveTenderProductBid,
  } = useActiveTenderProductBid(tenderUserId, tenderProduct._id);

  const prepareDataForCharts = useCallback(
    (payload: typeof data) => {
      const getDotObj = (payload: {
        Price: number;
        amt: number;
        x: number;
      }) => ({
        Price: payload.Price,
        amt: payload.amt,
        x: payload.x,
      });

      const dataForCharts = [] as { x: number; amt: number; Price: number }[];
      if (!payload) return { dataForCharts, xLength: 0 };
      const reservePrice = tenderProduct.tenderProductOffer.reservePrice;
      const averagePrice = Number(data?.average || 0);
      let xLength = payload.x[payload.x.length - 1];

      // check if reserve price is in graph
      const hasReservePrice =
        payload.y.findIndex((item) => item === reservePrice) !== -1;
      if (!hasReservePrice) {
        payload.y.push(reservePrice);
        xLength++;
      }

      // check if average price is in graph
      const hasAveragePrice =
        payload.y.findIndex((item) => item === averagePrice) !== -1;
      if (!hasAveragePrice) {
        payload.y.push(averagePrice);
        xLength++;
      }

      // check if bid price is in graph
      if (activeTenderProductBid) {
        const hasBidPrice =
          payload.y.findIndex(
            (item) => item === activeTenderProductBid.price
          ) !== -1;
        if (!hasBidPrice) {
          payload.y.push(activeTenderProductBid.price);
          xLength++;
        }
      }

      payload.y = payload.y.sort((a, b) => a - b);

      indicativeIndex.current = payload.y.findIndex(
        (val) => val === reservePrice
      );
      averageIndex.current = payload.y.findIndex((val) => val === averagePrice);
      myBidIndex.current = payload.y.findIndex(
        (val) => val === activeTenderProductBid?.price
      );

      payload.y.forEach((item, index) => {
        dataForCharts.push(
          getDotObj({
            Price: item,
            amt: item,
            x: Number(
              Math.ceil(
                (payload.x[payload.x.length - 1] / payload.y.length) * index
              ).toFixed(0)
            ),
          })
        );
      });

      return { dataForCharts, xLength } as const;
    },
    [
      activeTenderProductBid,
      data?.average,
      tenderProduct.tenderProductOffer.reservePrice,
    ]
  );

  const { dataForCharts } = useMemo(() => prepareDataForCharts(data), [
    data,
    prepareDataForCharts,
  ]);

  return (
    <Shimmer shimmer={isFetching && !isFetched}>
      <StyledTenderProductAnalysis className="tender-product__analysis">
        <Shine>
          <div className="tender-product__analysis__top">
            <BarChart className="tender-product__analysis__top__icon" />
            <p className="tender-product__analysis__top__title">
              Price Analysis based on Get Diamonds
            </p>
          </div>
        </Shine>
        <div className="tender-product__analysis__summary">
          {[
            { label: "Total Diamonds", value: data?.total },
            {
              label: "Total Carat",
              value: Number(data?.totalCarat || 0).toFixed(0),
            },
            {
              label: "Total Value",
              value: (
                <>
                  <Price price={data?.totalValue || 0} />
                </>
              ),
            },
            {
              label: (
                <>
                  <span className="dot orange"></span>
                  <p>Online Average Price/PPC</p>
                </>
              ),
              value: (
                <Price
                  price={
                    data?.average === "0"
                      ? tenderProduct.tenderProductOffer.reservePrice
                      : Number(data?.average)
                  }
                />
              ),
            },
            // {
            //   label: (
            //     <>
            //       <span className={styles.greenDot}></span>
            //       <p>Asking Price/PPC</p>
            //     </>
            //   ),
            //   value: (
            //     <Price price={tenderProduct.tenderProductOffer.reservePrice} />
            //   ),
            // },
            {
              label: (
                <>
                  <span className="dot green"></span>
                  <p>Your Bid/PPC</p>
                </>
              ),
              value: <Price price={activeTenderProductBid?.price || 0} />,
              show: !!activeTenderProductBid,
            },
          ].map((item, index) => {
            if (item.show === false) return null;
            return (
              <div
                key={index}
                className="tender-product__analysis__summary__box"
              >
                <Shine>
                  <div className="tender-product__analysis__summary__box__label">
                    {item.label}
                  </div>
                </Shine>
                <Shine>
                  <div className="tender-product__analysis__summary__box__value">
                    {item.value}
                  </div>
                </Shine>
              </div>
            );
          })}
        </div>
        <div className="tender-product__analysis__graph">
          <ResponsiveContainer
            width="100%"
            height={"100%"}
            className="shine relative"
          >
            {(() => {
              if (!!data && data?.y.length > 5) {
                return (
                  <LineChart
                    data={dataForCharts}
                    margin={{ top: 10, left: -57, right: 10, bottom: 0 }}
                    defaultShowTooltip={false}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="x"
                      type="number"
                      // ticks={data?.x.filter((v) => v !== 3) || []}
                      // domain={[0, data?.y.length]}
                      // tick={{ dy: 10 }}
                      // interval={2}
                    />
                    <YAxis
                      tickFormatter={(value) => kFormatter(Number(value)) + ""}
                      tick={{ dx: -10 }}
                      minTickGap={0}
                      axisLine={false}
                    />
                    <Tooltip
                      cursor={false}
                      content={(value) => {
                        return null;
                        //   if (!value?.payload?.[0]) return null;
                        //   if (value.payload[0].name !== "Price") return null;
                        //   const { payload } = value.payload[0];
                        //   let display = { label: "", value: 0 };
                        //   const isAverage =
                        //     payload.Price === Number(data.average);
                        //   const isIndicative =
                        //     payload.Price ===
                        //     tenderProduct.tenderProductOffer.reservePrice;
                        //   const isYourBid =
                        //     payload.Price === activeTenderProductBid?.price;
                        //   if (isAverage) {
                        //     display = {
                        //       label: "Average Price",
                        //       value: payload.Price,
                        //     };
                        //   }

                        //   if (isIndicative) {
                        //     display = {
                        //       label: "Asking Price",
                        //       value: payload.Price,
                        //     };
                        //   }

                        //   if (isYourBid) {
                        //     display = {
                        //       label: "Your Bid",
                        //       value: payload.Price,
                        //     };
                        //   }

                        //   if (!display.value) return null;
                        //   return (
                        //     <div className={styles.tooltip}>
                        //       <p className={styles.tooltipText}>
                        //         {display.label}
                        //       </p>
                        //       <Price price={display.value} postfix="/PPC" />
                        //     </div>
                        //   );
                        // }
                      }}
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Price"
                      stroke="#687c97"
                      activeDot={{
                        r: 3,
                        fill: "#687c97",
                        stroke: "#687c97",
                        strokeWidth: 0,
                        display: "none",
                      }}
                      strokeWidth={3}
                      isAnimationActive={false}
                      dot={(props) => {
                        return (
                          <CustomDot
                            {...props}
                            isAverage={
                              parseInt(data.average) === props.payload.Price
                            }
                            isReversePrice={
                              // tenderProduct.tenderProductOffer.reservePrice ===
                              // props.payload.Price
                              false
                            }
                            isBidPrice={
                              activeTenderProductBid?.price ===
                              props.payload.Price
                            }
                            indicativeIndex={indicativeIndex}
                            averageIndex={averageIndex}
                            myBidIndex={myBidIndex}
                          />
                        );
                      }}
                    />
                  </LineChart>
                );
              }
              return (
                <div className="tender-product__analysis__no-data">
                  <Diamond />
                  <p>Not enough data</p>
                </div>
              );
            })()}
          </ResponsiveContainer>
        </div>
        {/* <p className={cn("shine", styles.title)}>Prices / #Diamonds</p> */}
      </StyledTenderProductAnalysis>
    </Shimmer>
  );
};

const CustomDot = (props: any) => {
  const {
    cx,
    cy,
    isAverage,
    isReversePrice,
    isBidPrice,
    indicativeIndex,
    averageIndex,
    myBidIndex,
    index,
  } = props;

  const getDot = () => {
    if (isAverage && index === averageIndex.current) {
      return {
        fill: "#f5a000",
        r: 6,
        stroke: "#fff",
        position: "relative",
        zIndex: 2,
      };
    }
    if (isReversePrice && index === indicativeIndex.current) {
      return { fill: "#32dea5", r: 6, stroke: "#fff" };
    }
    if (isBidPrice && index === myBidIndex.current) {
      return {
        fill: "#32dea5",
        r: 6,
        stroke: "#fff",
        position: "relative",
        zIndex: 2,
      };
    }
    return {
      fill: "#687c97",
      r: 2,
      stroke: "#687c97",
      className: "basic-dot",
      position: "relative",
      zIndex: 1,
    };
  };

  // if (!isAverage && !isReversePrice && !isBidPrice) return null;

  return <circle cx={cx} cy={cy} width={30} height={30} {...getDot()}></circle>;
};

export default TenderProductAnalysis;
