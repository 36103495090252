import { configureStore } from "@reduxjs/toolkit";
import bids from "features/bids/slice";
import filters from "features/filters/slice";
import modals from "features/modals/slice";
import ui from "features/ui/slice";
export const store = configureStore({
  reducer: { filters, modals, bids, ui },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["modals/openModal"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
