import "./ProductTable.css";
import cn from "classnames";
interface Props {
  title: string;
  product: Product;
  keys: {
    label: string;
    key: ProductKey;
  }[];
  className?: string;
}

const ProductTable = ({ product, keys, title, className = "" }: Props) => {
  return (
    <div className={cn("product-table", className)}>
      <p className="product-table__title">{title}</p>
      <div className="product-table__grid">
        {keys.map(({ key, label }) => (
          <div className="product-table__grid-box" key={key}>
            <p className="product-table__grid-box__label">{label}</p>
            <p className="product-table__grid-box__value">
              {product[key] ?? "-"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductTable;
