import styled from "styled-components";

export const StyledCountdown = styled.div<{ withLabels: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ui-countdown__box {
    text-align: center;
  }

  .ui-countdown__value {
    font-family: var(--archivo);
    font-size: 30px;
    letter-spacing: normal;
    color: #222b45;
  }

  .ui-countdown__label {
    font-family: var(--archivo);
    font-size: 13px;
    color: #687c97;
    display: ${(props) => (props.withLabels ? "block" : "none !important")};
  }

  .ui-countdown__seperator::after {
    content: ":";
    font-family: var(--archivo);
    font-size: 30px;
    color: rgba(34, 43, 69, 0.4);
  }

  .ui-countdown__seperator:last-of-type::after {
    content: "";
  }
`;
