import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useQuery } from "react-query";

export const getNotifications = async () => {
  const { data } = await Axios.get(routeGenerator(`/notifications`));
  return data;
};

export const useNotifications = () => {
  return useQuery<INotification[], ApiError>(
    ["notifications"],
    () => getNotifications(),
    { refetchInterval: 10 * 1000 }
  );
};
