import styled from "styled-components";

export const StyledTenderProductMobileCard = styled.div`
  width: calc(100% - var(--sectionPadding) * 2);
  margin: 0 auto 1rem auto;
  border-radius: 6px;
  box-shadow: 0 4px 30px 0 rgba(209, 215, 224, 0.3);
  background-color: #fff;
  padding: 1rem;

  .tender-product-mobile__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 12px;
    max-height: 90px;
    height: 90px;
    position: relative;
  }

  .tender-product-mobile__top__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 2px solid rgba(104, 124, 151, 0.5);
    padding-bottom: 10px;
  }

  .tender-product-mobile__wishlist {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 38px;
    height: 38px;
    position: absolute;
    left: calc(20px / -2);
    top: calc(20px / -2);
    border-radius: 50%;
    z-index: 2;
  }

  .product-image__default,
  .product-image {
    height: 100%;
    max-height: 90px;
    overflow: hidden;
    object-fit: contain;
  }

  .product-image__container {
    background-color: rgba(234, 238, 242, 0.4);
  }

  .tender-product-mobile__top__details {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    margin-top: -8px;
  }

  .tender-product-mobile__my-bid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    .ui-price {
      color: #222b45;
      font-size: 16px;
    }
  }

  .tender-product-mobile__my-bid__text {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.archivo};
    color: #687c97;
  }

  .tender-product-mobile__top__details > div {
    margin-top: 8px;
    margin-right: 8px;
  }

  .tender-product-mobile__table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  .tender-product-mobile__table__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-auto-rows: 24px;
  }

  .tender-product-mobile__table__label {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 12px;
    color: #687c97;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tender-product-mobile__table__value {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 12px;
    font-weight: 600;
    color: #687c97;
    text-transform: uppercase;
  }
`;
