import { FiltersState } from "features/filters/slice";
import { RangeNames, SelectNames } from "types/filters";

export const filterTenderProducts = (
  tenderProducts: TenderProduct[],
  filters: FiltersState
) => {
  const { select, range } = filters;

  return tenderProducts.filter((tenderProduct) => {
    let result = true;
    const { product } = tenderProduct;
    const selectKeys = Object.keys(select) as SelectNames[];
    for (const key of selectKeys) {
      const filter = select[key];
      if (filter && filter.length > 0)
        result =
          result &&
          filter
            .map((item) => item.value.toLowerCase())
            .includes(String(product[key]).toLowerCase());
    }
    const rangeKeys = Object.keys(range) as RangeNames[];
    for (const key of rangeKeys) {
      const filter = range[key];
      if (filter?.from) {
        result = result && Number(product[key]) >= filter.from;
      }
      if (filter?.to) {
        result = result && Number(product[key]) <= filter.to;
      }
    }
    return result;
  });
};

export const numberWithCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + "k"
    : Math.sign(num) * Math.abs(num);
};

export type DistinctSelects = {
  [K in SelectNames]?: string[] | { min: number; max: number };
};

export const getProductsDistincts = (
  fields: {
    name: SelectNames;
    type: "select" | "range";
  }[],
  products: TenderProduct[]
) => {
  const obj = {} as DistinctSelects;
  fields.forEach((field) => {
    if (field.type === "select")
      obj[field.name] = [
        ...(new Set(products.map((item) => item.product[field.name])) as any),
      ];
    if (field.type === "range") {
      const ranges = products.map(
        (item) => item.product[field.name]
      ) as number[];
      obj[field.name] = {
        min: Math.min.apply(Math, ranges),
        max: Math.max.apply(Math, ranges),
      };
    }
  });
  return obj;
};
