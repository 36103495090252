import Page from "ui/Page";
import { getGreetingTime, scrollToElement } from "utils";

interface Props {
  name: React.ReactNode;
  liveTenders: React.ReactNode;
  upcomingTenders: React.ReactNode;
}

const Top = ({ name, liveTenders, upcomingTenders }: Props) => {
  return (
    <Page.Summary
      title={
        <>
          {getGreetingTime()}, <span className="p-overview__name">{name}</span>
        </>
      }
      subtitle={null}
      values={[
        {
          label: "Live Tenders:",
          value: liveTenders,
          handleClick: () => scrollToElement("live-tenders"),
        },
        {
          label: "Upcoming Tenders:",
          value: upcomingTenders,
          handleClick: () => scrollToElement("upcoming-tenders"),
        },
      ]}
    />
  );
};

export default Top;
