import { useUser } from "features/users/queries";
import Register, { registerInitialValues } from "pages/Register/Register"; // , { registerInitialValues }
import { mergeObjects } from "utils";
// import { mergeObjects } from "utils";
import Modal from "..";
import { StyledRegisterPopup } from "./RegisterPopup.styled";

interface Props {}

function RegisterPopup(props: Props) {
  const { data: user } = useUser();

  const registerValuesFromStorage = JSON.parse(
    (localStorage.getItem("register") as any) || "{}"
  );

  const initialValues: typeof registerInitialValues = {
    ...registerInitialValues,
    aml: mergeObjects(
      mergeObjects(registerInitialValues.aml, user?.company.aml || {}) as any,
      registerValuesFromStorage.aml || ({} as any)
    ) as any,
  };

  return (
    <Modal>
      <StyledRegisterPopup className="register-popup">
        <Modal.Close />
        <div className="register-popup__inner">
          <Register
            initialStep={2}
            requireFullValidation={false}
            initialStepperSteps={[
              {
                label: "AML/ KYC",
                goToStep: 2,
              },
              {
                label: "ADMIN DETAILS",
                goToStep: 3,
              },
              {
                label: "REFERENCE",
                goToStep: 4,
              },
              {
                label: "DOCUMENTS",
                goToStep: 5,
              },
              {
                label: "FINISH PROCESS",
                goToStep: 6,
              },
            ]}
            stepConfig={(step) => {
              if (step === 2) return 0;
              if (step === 3) return 1;
              if (step === 4) return 2;
              if (step === 5) return 3;
              if (step === 5) return 4;
              if (step === 6) return 5;
              return 6;
            }}
            initialValues={initialValues}
            updateAml
          />
        </div>
      </StyledRegisterPopup>
    </Modal>
  );
}

export default RegisterPopup;
