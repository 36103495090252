import React from "react";
interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  fitContent?: boolean;
}
const Shine = ({ children, fitContent = false, ...rest }: Props) => {
  const styledChildren = () =>
    React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        className: `${child.props.className} shine ${
          fitContent ? "shine--fit" : ""
        }`,
        ...rest,
      });
    });
  return styledChildren()[0];
};

export default Shine;
