// import { ReactComponent as Plus } from "assets/icons/plus.svg";
// import CircleButton from "components/ui/CircleButton";
import Price from "ui/Price";
import ProductImage from "components/ProductImage";
import ShortDetails from "components/ShortDetails";
// import useModalActions from "hooks/useModalActions";
import "./TenderProductSmallCard.css";
import { useIsTenderProductSaved } from "features/products/queries";
import { ReactComponent as Wishlist } from "assets/icons/wishlist.svg";
import { ReactComponent as WishlistGreen } from "assets/icons/wishlist-green.svg";
import {
  useSaveTenderProduct,
  useUnsaveTenderProduct,
} from "features/products/mutations";
import Shine from "ui/Shimmer/Shine";
import useModalActions from "hooks/useModalActions";
import SwitchAnimation from "ui/SwitchAnimation";
interface Props {
  tenderProduct: TenderProduct;
  customText?: React.ReactNode;
  customValue?: React.ReactNode;
  withWishlist?: boolean;
  onlyView?: boolean;
  priceToShow?: number;
}

const TenderProductSmallCard = ({
  tenderProduct,
  customText = "",
  customValue,
  withWishlist = true,
  onlyView = true,
  priceToShow = 0,
}: Props) => {
  const { openModal } = useModalActions();
  const isSaved = useIsTenderProductSaved({ tenderProduct });
  const { mutate: save, isLoading: isLoadingSave } = useSaveTenderProduct({
    tenderProduct,
  });
  const { mutate: unsave, isLoading: isLoadingUnsave } = useUnsaveTenderProduct(
    {
      tenderProduct,
    }
  );
  return (
    <div
      className="tender-product__small-card"
      onClick={() =>
        openModal({
          modalType: "TenderProduct",
          modalProps: { onlyView, tenderProduct },
        })
      }
    >
      <Shine>
        <div className="tender-product__small-card__img-container">
          <ProductImage src={tenderProduct.product.diamondImage} />
        </div>
      </Shine>
      <div className="tender-product__small-card__details">
        <ShortDetails
          product={tenderProduct.product}
          values={[
            // "colorType",
            "model",
            "carat",
            "clarity",
            "color",
            "polish",
            "symmetry",
          ]}
        />
        <Shine>
          <div className="tender-product__small-card__details-highest">
            <span>{customText ?? "Reserved Price:"} </span>
            {customValue ?? (
              <Price price={tenderProduct.tenderProductOffer.reservePrice} />
            )}
          </div>
        </Shine>
      </div>
      {withWishlist && (
        <Shine>
          <button
            className="wishlist"
            onClick={(e) => {
              e.stopPropagation();
              if (isLoadingUnsave || isLoadingSave) return;
              if (isSaved) return unsave();
              save();
            }}
          >
            <SwitchAnimation state={isSaved}>
              <div>{isSaved ? <WishlistGreen /> : <Wishlist />}</div>
            </SwitchAnimation>
          </button>
        </Shine>
      )}
      {/* <CircleButton
        className="tender-product__small-card__btn"
        onClick={() =>
          openModal({
            modalType: "TenderProduct",
            modalProps: { tenderProduct },
          })
        }
      >
        <Plus />
      </CircleButton> */}
    </div>
  );
};

export default TenderProductSmallCard;
