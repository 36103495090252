import styled from "styled-components";

export const StyledPageTabs = styled.div`
  margin: var(--sectionPadding);
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .ui-tabs__tab {
    white-space: nowrap;
  }

  .ui-tabs {
    position: relative;
  }

  /* .ui-export {
    position: absolute;
    right: 0;
    background: #fff;
    height: 100%;
  } */

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .ui-tabs__tab {
      margin-right: 8px;
    }

    .ui-export {
      display: none;
    }
  }
`;
