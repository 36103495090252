import styled from "styled-components";

export const StyledNotifications = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .ui-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #ffffff;
    color: #f5a000;
    font-size: rem(12px);
    border-radius: 5px;
    right: 22px;
    top: -6px;
    padding: 2px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .message-icon {
      stroke: #212121 !important;
    }
  }
`;

export const StyledNotificationsMain = styled.div`
  position: absolute;
  top: 0;
  right: 32px;
  width: 350px;
  /* left: -350px; */
  /* top: 0; */
  max-height: 400px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(192, 203, 209, 0.28);
  border: solid 1px rgba(192, 203, 209, 0.28);
  border-radius: 10px;
  z-index: 2;
  .notifications__top {
    font-family: var(--archivo);
    font-size: 15px;
    font-weight: bold;
    color: #222b45;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 12px;
    background-color: #fff;
    top: 0;
    position: sticky;
    z-index: 1;
  }

  .notifications__empty {
    font-family: var(--archivo);
    color: #687c97;
    font-size: 14px;
    padding: 12px;
  }

  .notification {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #eaeef2;
    padding: 12px;
  }

  .notification:last-of-type {
    border-bottom: 0;
  }

  .notification__circle__icon {
    width: 55px;
    height: 55px;
    min-width: 55px;
    min-height: 55px;
    border-radius: 10px;
    background-color: rgba(21, 101, 192, 0.05);
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0;
      stroke: #1565c0;
    }
  }

  .notification__body {
    font-family: var(--roboto);
    color: #687c97;
    font-size: 14px;
    line-height: 1.3;
    color: #687c97;

    span {
      font-family: var(--roboto) !important;
      font-size: 12px !important;
      color: #99a2b0 !important;
      display: block !important;
      margin-top: 4px !important;
      position: relative !important;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    box-shadow: none;
    position: fixed;
    top: ${({ theme }) => theme.headerHeight};
    left: 0;

    .notification__body {
      position: relative;
    }

    .notifications__top {
      z-index: 1;
    }
  }
`;
