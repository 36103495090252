import ElipseDetail from "components/ElipseDetail";
import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import Table from "ui/Table";
import Button from "ui/Button";
import { useTenderProducts } from "features/products/queries";
import { useTenderUser } from "features/users/queries";
import { useMemo } from "react";
// import { CSVLink } from "react-csv";
import { Cell, Column, useRowSelect, useSortBy, useTable } from "react-table";
import Modal from "..";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Wishlist } from "../../../assets/icons/wishlist.svg";
import { ReactComponent as WishlistGreen } from "../../../assets/icons/wishlist-green.svg";
import SwitchAnimator from "components/SwitchAnimator";
import {
  useSaveTenderProduct,
  useUnsaveTenderProduct,
} from "features/products/mutations";
import { useRegisterToTender } from "features/tenders/mutations";
import { asyncCatch, extractId } from "utils";
import { useHistory } from "react-router";
import useModalActions from "hooks/useModalActions";
import ExportCSV from "ui/ExportCSV";
import SwitchAnimation from "ui/SwitchAnimation";
import { useAnalytics } from "hooks/useAnalytics";
import DateTime from "ui/DateTime";
import { StyledCatalog } from "./Catalog.styled";

interface Props {
  tender: Tender;
  withRegister?: boolean;
  withEnter?: boolean;
}

const carats = [
  {
    label: "0-0.29",
    max: 0.29,
  },
  {
    label: "0.30-0.49",
    max: 0.49,
  },
  {
    label: "0.50-0.69",
    max: 0.69,
  },
  {
    label: "0.70-0.89",
    max: 0.89,
  },
  {
    label: "0.90-0.99",
    max: 0.99,
  },
  {
    label: "1.00-1.49",
    max: 1.49,
  },
  {
    label: "1.50-1.99",
    max: 1.99,
  },
  {
    label: "2.00-2.99",
    max: 2.99,
  },
  {
    label: "3.00-3.99",
    max: 3.99,
  },
  {
    label: "4.00-4.99",
    max: 4.99,
  },
  {
    label: "5.00-6.99",
    max: 6.99,
  },
  {
    label: "7.00-9.99",
    max: 9.99,
  },
  {
    label: "10.00-10.99",
    max: 10.99,
  },
  {
    label: "11.00-14.99",
    max: 14.99,
  },
  {
    label: "15.00-29.99",
    max: 29.99,
  },
  {
    label: "30.00+",
    max: 100,
  },
];

const getCaratRange = (carat: number) => {
  return carats.find((caratRange) => caratRange.max > carat)?.label;
};

const Catalog = ({ tender, withRegister = true, withEnter = false }: Props) => {
  const track = useAnalytics();
  const { closeModal } = useModalActions();
  const history = useHistory();
  const tenderId = tender._id;
  const {
    data: tenderUser,
    isFetching: isFetchingTenderUser,
    isFetched: isFetchedTenderUser,
    refetch: refetchTenderUser,
  } = useTenderUser(extractId(tenderId));

  const {
    data: tenderProducts,
    isFetching: isFetchingTenderProducts,
    isFetched: isFetchedTenderProducts,
  } = useTenderProducts(extractId(tenderId));

  const {
    mutateAsync: registerToTender,
    isLoading: isLoadingRegisterToTender,
  } = useRegisterToTender();

  const onEnter = async () => {
    track("Catalog Popup - Enter Button", tender);
    const [err, data] = await asyncCatch(registerToTender(tender._id));
    if (!err && data) history.push(`/tender/${tender._id}`);
    closeModal();
  };

  const register = async () => {
    track("Catalog Popup - Register Button", tender);
    await registerToTender(extractId(tenderId));
    await refetchTenderUser();
  };

  const shimmer =
    (isFetchingTenderUser && !isFetchedTenderUser) ||
    (isFetchingTenderProducts && !isFetchedTenderProducts);

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "Carat Range",
          accessor: "product.carat" as any,
          sortType: (a, b) => {
            if (a.original.product.carat === b.original.product.carat) {
              return a > b ? 1 : -1; // same length condition
            }
            return a.original.product.carat > b.original.product.carat ? 1 : -1; // comparing length of string
          },
          id: "range",
          Cell: ({ row }: Cell<TenderProduct>) => {
            return (
              <Shine>
                <p>{getCaratRange(row.original.product.carat)}</p>
              </Shine>
            );
          },
        },
        {
          Header: "Model",
          accessor: "product.model",
          Cell: ({ row }: Cell<TenderProduct>) => (
            <Shine>
              <p>{row.original.product.model?.toUpperCase()}</p>
            </Shine>
          ),
        },
        {
          id: "Carat",
          Header: "Carat",
          accessor: "product.carat",
          sortType: (a, b) => {
            if (a.original.product.carat === b.original.product.carat) {
              return a > b ? 1 : -1; // same length condition
            }
            return a.original.product.carat > b.original.product.carat ? 1 : -1; // comparing length of string
          },
          Cell: ({ row }: Cell<TenderProduct>) => (
            <Shine>
              <p>{(row.original.product.carat || 0).toFixed(2)}</p>
            </Shine>
          ),
        },
        {
          Header: "Color",
          accessor: "product.color",
          Cell: ({ row }: Cell<TenderProduct>) => (
            <Shine>
              <p>{row.original.product.color?.toUpperCase()}</p>
            </Shine>
          ),
        },
        {
          Header: "Clarity",
          accessor: "product.clarity",
          Cell: ({ row }: Cell<TenderProduct>) => (
            <Shine>
              <p>{row.original.product.clarity?.toUpperCase()}</p>
            </Shine>
          ),
        },
      ] as Column<TenderProduct>[],
    []
  );

  const selectedRowIds = useMemo(() => {
    const selected = {} as any;
    (tenderUser?.savedTenderProducts || [])?.forEach(
      (_id) => (selected[_id] = true)
    );
    return selected;
  }, [tenderUser]);

  const table = useTable(
    {
      columns: tableColumns,
      data: tenderProducts as any,
      getRowId: (row) => row._id,
      initialState: { selectedRowIds, sortBy: [{ id: "Carat" }], pageSize: 30 },
    },
    useSortBy,
    // usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: () => "Wishlist",
          Cell: ({ row }: Cell<TenderProduct>) => {
            const { mutateAsync: saveAsync, isLoading: isLoadingSave } =
              useSaveTenderProduct({ tenderProduct: row.original });

            const { mutateAsync: unsaveAsync, isLoading: isLoadingUnsave } =
              useUnsaveTenderProduct({ tenderProduct: row.original });

            const save = async () => {
              row.toggleRowSelected(true);
              const [err] = await asyncCatch(saveAsync());
              if (err) row.toggleRowSelected(false);
            };

            const unsave = async () => {
              row.toggleRowSelected(false);
              const [err] = await asyncCatch(unsaveAsync());
              if (err) row.toggleRowSelected(true);
            };

            return (
              <Shine>
                <button
                  className="catalog__wishlist"
                  onClick={() => (row.isSelected ? unsave() : save())}
                  disabled={isLoadingSave || isLoadingUnsave}
                >
                  <SwitchAnimator state={row.isSelected}>
                    {row.isSelected && <WishlistGreen />}
                    {!row.isSelected && <Wishlist />}
                  </SwitchAnimator>
                </button>
              </Shine>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  return (
    <Modal>
      <Shimmer shimmer={shimmer}>
        <StyledCatalog id="catalog">
          <Modal.Close parentId="catalog" />
          <div className="catalog__top">
            <Shine>
              <p className="catalog__name">
                <span className="catalog__tender">Tender: </span>
                {tender.name}
              </p>
            </Shine>
            <Shine>
              <p className="catalog__dates">
                <DateTime
                  date={tender.startDate}
                  format={"DD/MM/YYYY"}
                  withTimezone={false}
                />
                | <DateTime date={tender.startDate} format={"HH:mm"} />
              </p>
            </Shine>
          </div>
          <div className="catalog__filters">
            {/* <ElipseDetail>{tender.filters.colorType.join(", ")}</ElipseDetail> */}
            <ElipseDetail>
              {tender.filters.model[0]} -{" "}
              {tender.filters.model[tender.filters.model.length - 1]}
            </ElipseDetail>
            <ElipseDetail>
              {tender.filters.color[0]} -{" "}
              {tender.filters.color[tender.filters.color.length - 1]}
            </ElipseDetail>
            <ElipseDetail>
              {(tender.filters.carat.minCarat * 1).toFixed(2)} -{" "}
              {(tender.filters.carat.maxCarat * 1).toFixed(2)}
            </ElipseDetail>
            <ElipseDetail>
              {tender.filters.clarity[0]} -{" "}
              {tender.filters.clarity[tender.filters.clarity.length - 1]}
            </ElipseDetail>
          </div>
          <div className="catalog__table__container">
            <Table
              table={table}
              className="catalog__table"
              switchState={tenderProducts}
            />
            {!shimmer && !tenderUser && (
              <p className="catalog__must_be_registered">
                You have to be registered to wish list a diamond.
              </p>
            )}
          </div>
          <div className="catalog__bottom">
            <Shine>
              <div className="catalog__total">
                Total {tenderProducts?.length} Diamonds
              </div>
            </Shine>
            <div className="catalog__actions">
              <Shine>
                <ExportCSV
                  className="catalog__export"
                  data={table.rows}
                  headers={[
                    {
                      accessor: "original.product.carat",
                      label: "Carat Range",
                      format: (str) => getCaratRange(Number(str || 0)) || "",
                    },
                    { accessor: "original.product.carat", label: "Carat" },
                    { accessor: "original.product.color", label: "Color" },
                    { accessor: "original.product.clarity", label: "Clarity" },
                  ]}
                  filename={tender.name}
                >
                  Export all
                </ExportCSV>
              </Shine>
              {withRegister && (
                <SwitchAnimation state={!!tenderUser}>
                  <div>
                    {!tenderUser && (
                      <Shine>
                        <Button
                          kind="green"
                          onClick={() => register()}
                          disabled={
                            isLoadingRegisterToTender ||
                            !!tenderUser ||
                            isFetchingTenderUser
                          }
                          loading={
                            isLoadingRegisterToTender || isFetchingTenderUser
                          }
                        >
                          {(() => {
                            if (tenderUser) return "Registered";
                            return "Register";
                          })()}
                        </Button>
                      </Shine>
                    )}
                    {!!tenderUser && (
                      <Button kind="green" disabled>
                        <Check /> Registered
                      </Button>
                    )}
                  </div>
                </SwitchAnimation>
              )}
              {withEnter && (
                <Shine>
                  <Button
                    kind="green"
                    onClick={onEnter}
                    disabled={isLoadingRegisterToTender}
                    loading={isLoadingRegisterToTender}
                  >
                    Enter
                  </Button>
                </Shine>
              )}
            </div>
          </div>
        </StyledCatalog>
      </Shimmer>
    </Modal>
  );
};

export default Catalog;
