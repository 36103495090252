import Loader from "ui/Loader";
import Modal from "components/Modal";
import { useGetLogin, useMutateUser } from "features/users/mutations";
import useModalActions from "hooks/useModalActions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { asyncCatch, mySleep } from "utils";
import storage from "utils/storage";
import { GetLoginButton, GetLoginIframeStyled } from "./GetLogin.styled";
import { useAnalytics } from "hooks/useAnalytics";

const url = "https://login.thediamondpricelist.com/dpl/login#tenders";

const GetLogin = (props: ButtonProps) => {
  const track = useAnalytics();
  const { openModal } = useModalActions();

  const handleClick = () => {
    track("Get Login Press");
    openModal({
      modalType: "GetLogin",
      modalProps: undefined,
    });
  };

  return <Button {...props} onClick={handleClick} />;
};

type ButtonProps = React.HTMLProps<HTMLButtonElement>;

const Button = ({ type, ...rest }: ButtonProps) => {
  return (
    <GetLoginButton
      {...(rest as any)}
      className={["get-login__btn", rest.className].join(" ")}
    >
      <span className="left">GET</span>
      <span className="right">Sign In With Get-Diamonds</span>
    </GetLoginButton>
  );
};

const GetLoginModal = () => {
  const track = useAnalytics();
  const mutateUser = useMutateUser();
  const { closeModal } = useModalActions();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const onLoad = async () => {
    await mySleep(1500);
    setIsLoadingIframe(false);
  };
  const onError = () => {
    toast.error("Could not load Get-Diamonds, please try again later");
  };

  const { mutateAsync, isLoading: isLoadingLogin, isSuccess } = useGetLogin();
  useEffect(() => {
    const handler = (e: MessageEvent<{ user: string }>) => {
      (async () => {
        const { data } = e;
        if (data.user) {
          await mySleep(400);
          const token = JSON.parse(data.user);
          const [err, user] = await asyncCatch(mutateAsync(token));
          closeModal();
          if (err || !user)
            return toast.error("Failed logging you, please try again later");
          storage.setToken(user.token);
          track("Get Login Success", user);
          mutateUser(user);
          // else toast.success("Successfully logged in");
        }
      })();
    };
    window.addEventListener("message", handler, false);
    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, [mutateAsync, closeModal, mutateUser, track]);

  return (
    <Modal onClose={closeModal}>
      <GetLoginIframeStyled>
        <Modal.Close />
        <iframe
          title="get login"
          src={url}
          className="get-login__iframe"
          onLoad={onLoad}
          onError={onError}
          style={{ opacity: isLoadingIframe || isLoadingLogin ? 0 : 1 }}
        />
        {(isLoadingIframe || isLoadingLogin) && (
          <Loader
            centered
            color="#212121"
            size={50}
            text={
              <p className="get-login__iframe__loading">
                {(() => {
                  if (isSuccess) return "Successfully logged in";
                  if (isLoadingLogin) return "Logging you in...";
                  if (isLoadingIframe)
                    return "Establishing secure connection...";
                })()}
              </p>
            }
          />
        )}
      </GetLoginIframeStyled>
    </Modal>
  );
};

GetLogin.Modal = GetLoginModal;

export default GetLogin;
