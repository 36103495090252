import { routeGenerator } from "api";
import { tenderProduct } from "../../faker";
import { createArray } from "../../faker";
import { useTenderUser } from "features/users/queries";
import { axios as Axios } from "lib/axios";
import { useQuery } from "react-query";
import { extractId } from "utils";

export const getTenderProduct = async (id: string) => {
  if (!id) return null;
  const { data } = await Axios.get(routeGenerator(`/tenderProducts/${id}`));
  return data;
};

export const useTenderProduct = (id: string) => {
  return useQuery<TenderProduct, ApiError>(["tenderproduct", id], () =>
    getTenderProduct(id)
  );
};

const getTenderProducts = async (tenderId: string) => {
  if (!tenderId) return null;
  const { data } = await Axios.get(
    routeGenerator(`/tenderProducts/tender/${tenderId}`)
  );
  return data;
};

export type UseTenderProducts = TenderProduct[];

const useTenderProductsPlaceholderData = createArray(
  tenderProduct,
  10
) as TenderProduct[];

export const useTenderProducts = (tenderId: string) => {
  return useQuery<UseTenderProducts, ApiError>(
    ["tenderproducts", tenderId],
    () => getTenderProducts(tenderId),
    {
      staleTime: 60 * 60 * 1000,
      placeholderData: useTenderProductsPlaceholderData,
    }
  );
};

const getTenderProductAnalysis = async (tenderProductId: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderProducts/analysis/${tenderProductId}`)
  );

  // // @ts-ignore
  // data.x = [...new Set(data.x)];
  // // @ts-ignore
  // data.y = [...new Set(data.y)];

  if (data.y.length > 30) {
    // data.y = data.y.slice(2);
    data.y.pop();
  }

  return data;
};

interface UseTenderProductAnalysis {
  x: number[];
  y: number[];
  total: string;
  lowest: string;
  highest: string;
  average: string;
  totalCarat: string;
  totalValue: string;
}

const useTenderProductAnalysisPlaceholder: UseTenderProductAnalysis = {
  average: "1000",
  highest: "1000",
  lowest: "1000",
  total: "1000",
  totalCarat: "1000",
  totalValue: "100000",
  x: [1, 2, 3],
  y: [1, 2, 3],
};

export const useTenderProductAnalysis = (tenderProductId: string) =>
  useQuery<UseTenderProductAnalysis, ApiError>(
    ["tenderproductanalysis", tenderProductId],
    () => getTenderProductAnalysis(tenderProductId),
    { placeholderData: useTenderProductAnalysisPlaceholder }
  );

export const useIsTenderProductSaved = ({
  tenderProduct,
}: {
  tenderProduct: TenderProduct;
}) => {
  const {
    data: tenderUser,
    //  isLoading, isError
  } = useTenderUser(extractId(tenderProduct.tender as any));
  return tenderUser
    ? (tenderUser?.savedTenderProducts || [])?.includes(tenderProduct._id)
    : false;
};

const getGiaCertificate = async (payload: {
  mongoId: string;
  certificateId: string;
}) => {
  const { data } = await Axios.post(
    routeGenerator(`/products/gia/details`),
    payload
  );

  return data.giaDetails;
};

export interface GiaDetails {
  report_date: string;
  report_date_iso: string;
  report_number: string;
  report_type: string;
  report_type_code: string;
  links: Links;
  results: Results;
}
export interface Links {
  pdf: string;
  plotting_diagram: string;
  rough_video?: null;
  rough_image?: null;
}
export interface Results {
  shape_and_cutting_style: string;
  measurements: string;
  carat_weight: string;
  color_grade: string;
  color_origin?: null;
  color_distribution?: null;
  clarity_grade: string;
  cut_grade: string;
  polish: string;
  symmetry: string;
  fluorescence: string;
  clarity_characteristics?: null;
  inscriptions: string;
  report_comments: string;
  proportions: Proportions;
}
export interface Proportions {
  depth_pct: string;
  table_pct: string;
  crown_angle: string;
  crown_height: string;
  pavilion_angle: string;
  pavilion_depth: string;
  star_length: string;
  lower_half: string;
  girdle: string;
  culet: string;
}

const useGiaCertificatePlaceholder = {
  report_date: "August 13, 2019",
  report_date_iso: "2019-08-13",
  report_number: "2338480136",
  report_type: "Diamond Grading Report",
  report_type_code: "DG",
  links: {
    pdf:
      "https://report-check-objects-us-west-2-092721654985.s3.amazonaws.com/pdfs/36/2338480136.pdf?AWSAccessKeyId=ASIARLFVEFTEYDFP7D7C&Signature=KkqzxOcfmg2h%2Fc57Tb6I%2BBZbR1s%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEF4aCXVzLXdlc3QtMiJIMEYCIQDrzBSxoS5tkjctKMcM1CBstqfZdm3zFOoLyZCe7HKk3AIhAIawM50yAIiM7zSin%2F9f%2FpiT3QrMsk%2F9gYhPHMIgifdhKroCCEcQAxoMMDkyNzIxNjU0OTg1IgxzZ%2BprmAGFIlXMMsMqlwKBn6I%2BrvSxo0ua3fYnoZ3jUiBmIZg%2BwVDZhBsjzoV5PKexTGLA86zm1bgU79shb0AswQ3dYltK9zn3TokLc6UwErJhMZkpDyIO3%2FW0PdFzpDsWHeniFY%2BO2r9Y8chdr4TqymFTOKdFL0eh8ZRca8trqTtpA9wZjHIWkzESI24BHdN7uJTemH6Ogoz4LY678ozTbg68RHMkPqdRMJ2O8p1Rt1CmjwPM%2B7isV7JYPi%2Fjgojai%2FX4Vvsp%2BSa1SnEBKzSCNzXP8Nk4aPPy18jbBN07I8d5DNbMWL1edWTzaST9%2F2OaPqSjfETXaIeAqIIehRpggNUpL8TAuIc07f7yCh1DkabK1V1x85hS5IDtSuKAuLoBwTv9N%2BkwkZrdjQY6mQE7TSq6P6mRfuXr1iR%2FiIUMnYD8523KGiEU39CYu3ctOBltMsHDvq59ta2U17dALAhyJudggjTWh57DE0o%2FacjB0PQEmajbLUNI0yHp0lEmBG32m5mtGNyjkmqUas%2FpR1CYmNy0qAOCN0VU7mYxvQSZSwCebsuuwchq94eWEQoUwXJWY13L41O%2FjHFlKCM6LGBm%2Bc7YeC7paAg%3D&Expires=1639406705",
    plotting_diagram:
      "https://report-check-objects-us-west-2-092721654985.s3.amazonaws.com/images/36/2338480136_plot.jpg?AWSAccessKeyId=ASIARLFVEFTEYDFP7D7C&Signature=sFZj3VQ%2BNdhzKCbZfsm%2FnpYQS4I%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEF4aCXVzLXdlc3QtMiJIMEYCIQDrzBSxoS5tkjctKMcM1CBstqfZdm3zFOoLyZCe7HKk3AIhAIawM50yAIiM7zSin%2F9f%2FpiT3QrMsk%2F9gYhPHMIgifdhKroCCEcQAxoMMDkyNzIxNjU0OTg1IgxzZ%2BprmAGFIlXMMsMqlwKBn6I%2BrvSxo0ua3fYnoZ3jUiBmIZg%2BwVDZhBsjzoV5PKexTGLA86zm1bgU79shb0AswQ3dYltK9zn3TokLc6UwErJhMZkpDyIO3%2FW0PdFzpDsWHeniFY%2BO2r9Y8chdr4TqymFTOKdFL0eh8ZRca8trqTtpA9wZjHIWkzESI24BHdN7uJTemH6Ogoz4LY678ozTbg68RHMkPqdRMJ2O8p1Rt1CmjwPM%2B7isV7JYPi%2Fjgojai%2FX4Vvsp%2BSa1SnEBKzSCNzXP8Nk4aPPy18jbBN07I8d5DNbMWL1edWTzaST9%2F2OaPqSjfETXaIeAqIIehRpggNUpL8TAuIc07f7yCh1DkabK1V1x85hS5IDtSuKAuLoBwTv9N%2BkwkZrdjQY6mQE7TSq6P6mRfuXr1iR%2FiIUMnYD8523KGiEU39CYu3ctOBltMsHDvq59ta2U17dALAhyJudggjTWh57DE0o%2FacjB0PQEmajbLUNI0yHp0lEmBG32m5mtGNyjkmqUas%2FpR1CYmNy0qAOCN0VU7mYxvQSZSwCebsuuwchq94eWEQoUwXJWY13L41O%2FjHFlKCM6LGBm%2Bc7YeC7paAg%3D&Expires=1639406705",
    rough_video: null,
    rough_image: null,
  },
  results: {
    shape_and_cutting_style: "Round Brilliant",
    measurements: "8.21 - 8.24 x 4.89 mm",
    carat_weight: "2.00 carat",
    color_grade: "K",
    color_origin: null,
    color_distribution: null,
    clarity_grade: "VVS1",
    cut_grade: "Excellent",
    polish: "Excellent",
    symmetry: "Excellent",
    fluorescence: "Medium Blue",
    clarity_characteristics: null,
    inscriptions: "GIA 2338480136",
    report_comments: "Internal graining is not shown.",
    proportions: {
      depth_pct: "59.5",
      table_pct: "61",
      crown_angle: "34.0",
      crown_height: "13.0",
      pavilion_angle: "40.8",
      pavilion_depth: "43.0",
      star_length: "50",
      lower_half: "80",
      girdle: "Thin to Medium, Faceted, 3.0%",
      culet: "None",
    },
  },
};

export const useGiaCertificate = ({
  mongoId,
  certificateId,
}: {
  mongoId: string;
  certificateId: string;
}) => {
  return useQuery<GiaDetails, ApiError>(
    ["giaDetails", mongoId, certificateId],
    () => getGiaCertificate({ mongoId, certificateId }),
    { placeholderData: useGiaCertificatePlaceholder }
  );
};
