import cn from "classnames";
import Shine from "ui/Shimmer/Shine";
import { ReactNode } from "react";
import { Container } from "./ElipseDetail.styled";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ElipseDetail = ({ children, className = "", ...rest }: Props) => {
  return (
    <Shine>
      <Container className={cn("elipse-detail", className)} {...rest}>
        {children}
      </Container>
    </Shine>
  );
};

export default ElipseDetail;
