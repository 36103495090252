import Modal from "../Modal";
import { ReactComponent as DiamondGray } from "assets/icons/diamond-gray.svg";
import { ReactComponent as Users } from "assets/icons/users-gray.svg";
import ElipseDetail from "components/ElipseDetail";
import useModalActions from "hooks/useModalActions";
import AddToCalendar from "ui/AddToCalendar";
import { useRegisterToTender } from "features/tenders/mutations";
import { asyncCatch } from "utils";
import { useTenderRegister } from "features/tenders/queries";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { useAnalytics } from "hooks/useAnalytics";
import { StyledEvent } from "./Event.styled";
import Button from "ui/Button";
import DateTime from "ui/DateTime";
interface Props {
  tender: Tender;
  withSeeCatalog?: boolean;
  withEnterToEvent?: boolean;
}

const Event = ({ tender, withSeeCatalog = true }: Props) => {
  const track = useAnalytics();
  const { data: registered, isFetching } = useTenderRegister(tender._id);

  const { mutateAsync, isSuccess, isLoading } = useRegisterToTender();
  const { openModal, closeModal } = useModalActions();

  const onRegister = async () => {
    track("Event Popup - Register Button", tender);
    const [err] = await asyncCatch(mutateAsync(tender._id));
    if (!err) closeModal();
  };

  const onCatalog = () => {
    track("Event Popup - Catalog Button", tender);
    closeModal();
    openModal({
      modalType: "Catalog",
      modalProps: { tender },
    });
  };

  return (
    <Modal>
      <StyledEvent className="event">
        <Modal.Close />
        <div className="event__details">
          <p className="event__tender">
            Tender: <span className="event__tender__name">{tender.name}</span>
          </p>
          <p className="event__date">
            <DateTime
              date={tender.startDate}
              format={"DD/MM/YYYY"}
              withTimezone={false}
            />
            | <DateTime date={tender.startDate} format={"HH:mm"} />
          </p>
        </div>
        <div className="event__filters">
          <ElipseDetail>
            {tender.filters.model[0]} -{" "}
            {tender.filters.model[tender.filters.model.length - 1]}
          </ElipseDetail>
          <ElipseDetail>
            {tender.filters.color[0]} -{" "}
            {tender.filters.color[tender.filters.color.length - 1]}
          </ElipseDetail>
          <ElipseDetail>
            {(tender.filters.carat.minCarat * 1).toFixed(2)} -{" "}
            {(tender.filters.carat.maxCarat * 1).toFixed(2)}
          </ElipseDetail>
          <ElipseDetail>
            {tender.filters.clarity[0]} -{" "}
            {tender.filters.clarity[tender.filters.clarity.length - 1]}
          </ElipseDetail>
        </div>
        <div className="event__summary">
          <div className="event__summary__box">
            <div className="event__summary__box__circle">
              <DiamondGray />
            </div>
            <p className="event__summary__box__value">
              {tender.tenderProducts.length}
            </p>
            <p className="event__summary__box__label">Diamonds</p>
          </div>
          <div className="event__summary__box">
            <div className="event__summary__box__circle">
              <Users />
            </div>
            <p className="event__summary__box__value">
              {(tender.tenderUsers || []).length}
            </p>
            <p className="event__summary__box__label">Registered Users</p>
          </div>
        </div>
        <p className="event__desc">{tender.description}</p>
        <div className="event__actions">
          <Button
            kind="green"
            loading={isLoading || isFetching}
            onClick={onRegister}
            disabled={!!registered || isSuccess}
          >
            {(!!registered || isSuccess) && <Check />}
            {!!registered || isSuccess ? "Registered" : "Register"}
          </Button>
          {withSeeCatalog && (
            <Button kind="white" onClick={onCatalog}>
              See Catalog
            </Button>
          )}
          <Button kind="white">
            <AddToCalendar
              text={tender.name}
              details={tender.description}
              startDate={tender.startDate}
              endDate={tender.endDate}
              onClick={() => track("Event Popup - Add to calendar Button")}
            >
              Add to calendar
            </AddToCalendar>
          </Button>
        </div>
      </StyledEvent>
    </Modal>
  );
};

export default Event;
