import React, { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
interface Props {
  state: any;
  classNames?: string;
  containerClassName?: string;
  children: JSX.Element;
}

function SwitchAnimator({ state, classNames = "fade", children }: Props) {
  const nodeRef = useRef(null);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={state}
        classNames={classNames}
        in
        timeout={300}
        nodeRef={nodeRef}
      >
        <ChildrenWithRef ref={nodeRef}>{children}</ChildrenWithRef>
      </CSSTransition>
    </SwitchTransition>
  );
}

const ChildrenWithRef = React.forwardRef<any, { children: JSX.Element }>(
  (props, ref) =>
    React.Children.map(props.children as any, (child) => {
      return React.cloneElement(child, {
        ref,
      });
    })
);

export default SwitchAnimator;
