import { useEffect } from "react";

function useClickOutside({ ref, handler, state }) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    if (state) {
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
    } else {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    }
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, state]);
}

export default useClickOutside;
