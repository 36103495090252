import { useUserLocation } from "features/users/queries";
import moment from "moment-timezone";

interface Props {
  format: string;
  date: string;
  withTimezone?: boolean;
}

const DateTime = ({ date, format, withTimezone = true }: Props) => {
  const { data } = useUserLocation();
  if (!data)
    return (
      <>
        {moment(date).format(format)} {withTimezone && moment().zoneAbbr()}
      </>
    );
  return (
    <>
      {moment(date).tz(data.timezone).format(format)}{" "}
      {withTimezone && moment(date).tz(data.timezone).zoneAbbr()}
    </>
  );
};

export default DateTime;
