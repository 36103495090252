import { CSSProperties } from "styled-components";
import { StyledFlag } from "./Flag.styled";

const Flag = ({
  flag,
  className,
  style,
}: {
  flag?: string;
  className?: string;
  style?: CSSProperties;
}) => {
  if (!flag) return null;

  const getFlagSrc = (flag: string) => {
    switch (flag) {
      case "PS":
        return "IL";
      case null:
        return "USA";
      case undefined:
        return "USA";
      default:
        return flag;
    }
  };

  const flagSrc = getFlagSrc(flag);

  return (
    <StyledFlag
      className={["ui-flag", className ?? ""].join(" ")}
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${flagSrc}.svg`}
      alt={`flag ${flagSrc}`}
      style={style || {}}
    />
  );
};
export default Flag;
