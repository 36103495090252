import styled from "styled-components";

export const RegisterStepOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .register__step__one__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 308px;
    column-gap: 1.5rem;
  }

  .ui-button {
    width: 193px;
    margin: 0 auto;
  }

  .register__step__title {
  }
`;

export const RegisterStepOneOption = styled.div<{ selected?: boolean }>`
  transition: all 0.2s ease-in-out;
  background: ${({ theme }) => theme.colors.white};
  border: 4px solid
    ${({ theme, selected }) =>
      !selected ? "transparent" : theme.colors.primary[200]};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 2rem;
  position: relative;
  cursor: pointer;

  .register__step__one__option__icon {
    fill: rgb(73, 44, 189);
  }

  .register__step__one__option__title {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: ${({ theme }) => theme.spacing[16]};
  }

  .register__step__one__option__subtitle {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 1rem;
    margin-top: ${({ theme }) => theme.spacing[16]};
  }

  .register__step__one__option__check {
    position: absolute;
    right: ${({ theme }) => theme.spacing[16]};
    bottom: ${({ theme }) => theme.spacing[16]};
    transition: all 0.2s ease-in-out;
  }
`;

export const StyledRegister = styled.div`
  --fieldsGap: 2rem;
  height: calc(100% - var(--headerHeight));
  width: 100%;
  background-color: #f9fbfd;
  background-image: url(/static/media/register-bg.7a0b87d1.jpg);
  background-size: 100% 13.375rem;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: border-box;
  position: relative;
  overflow: auto;

  .register__inner {
    row-gap: 1.875rem;
    width: 100%;
    height: 100%;
    max-width: 850px;
    margin: 0 auto;
    min-height: 100%;
    display: grid;
    grid-template-rows: min-content auto min-content;
  }

  .register__inner .ui-stepper {
    padding-top: 2.5rem;
    top: 0;
    position: sticky;
    background: #f9fbfd;
    z-index: 100;
  }

  .register__step__title {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[32]};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutral[700]};
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }

  .register__step__subtitle {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[16]};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .register__step__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .register__step__bottom {
    display: grid;
    grid-template-columns: auto min-content 11.875rem;
    align-items: center;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    margin-top: 2.5rem;

    .ui-button {
      width: 100%;
    }
  }

  .register__step__bottom__label {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[12]};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .register__step__bottom__back {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
  }

  .register__step__bottom__back__icon {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .register__step__bottom__back__text {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .register__bottom_text {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[14]};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[600]};
    line-height: 1.6;
    padding-bottom: 2.5rem;
  }

  .register__step__two__fields {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--fieldsGap);
    grid-auto-flow: column;
    margin-top: 2rem;
  }

  .register__step__three__fields {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--fieldsGap);
    grid-auto-flow: column;
    margin-top: 2rem;
  }
  .register__step__four__fields {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--fieldsGap);
    grid-auto-flow: column;
    margin-top: 2rem;
  }

  .register__step__five__fields {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--fieldsGap);
    grid-auto-flow: column;
    margin-top: 2rem;
  }

  .register__steps {
    display: flex;
    flex-direction: column;
  }

  .i-am-admin {
    margin: 1rem 0;
  }

  .reference-top {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .reference-subtitle {
    font-family: var(--archivo);
    font-size: 0.875rem;
    color: #687c97;
    margin-top: 0.5rem;
    line-height: 1.25;
  }

  .reference-title {
    font-family: var(--archivo);
    font-size: 1.75rem;
    font-weight: bold;
    color: #222b45;
  }

  .add-reference {
    font-family: var(--archivo);
    font-size: 0.9375rem;
    font-weight: 600;
    letter-spacing: 0.0469rem;
    color: #492cbd;
    text-transform: uppercase;
    margin-top: 1.2rem;
    cursor: pointer;
  }

  .remove-reference {
    color: #222b45;
    font-family: var(--archivo);
    background: transparent;
    position: absolute;
    right: 0;
    top: 6px;
  }

  .reference-anim-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
  .reference-anim-enter-active {
    opacity: 1;
    max-height: 18.75rem;
    transition: opacity 300ms ease-in, max-height 300ms ease-in;
  }
  .reference-anim-exit {
    opacity: 1;
    overflow: hidden;
    max-height: 18.75rem;
  }
  .reference-anim-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in, max-height 300ms ease-in;
    max-height: 0;
    overflow: hidden;
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed rgba(104, 124, 151, 0.5);
    padding: 2.8125rem 0;
    border-radius: 0.625rem;
    background-color: #fff;
    margin-top: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0.625rem;
  }

  .upload-file__icon {
    margin-bottom: 1.25rem;
  }

  .upload-file > p {
    width: 60%;
    text-align: center;
  }

  .upload-file__title {
    font-family: var(--archivo);
    font-size: 1rem;
    font-weight: bold;
    color: #222b45;
    text-align: left;
    margin-bottom: 12px;
  }

  .upload-file__support {
    font-weight: normal;
    font-family: var(--archivo);
    font-size: 1rem;
    color: #222b45;
    margin-bottom: 12px;
  }

  .upload-file__info {
    font-family: var(--archivo);
    font-size: 0.875rem;
    color: #222b45;
    line-height: 1.5;
  }

  .approve-terms {
    height: 2.5rem;
  }

  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
    height: 2.8125rem;
  }

  .file__title {
    width: 8.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    font-family: var(--archivo);
    font-size: 14px;
    line-height: 1.5;
    color: #222b45;
  }

  .file__progress {
    flex: 1;
    /* background-color: #e8ebf2; */
    background-color: var(--green);
    height: 0.3125rem;
    border-radius: 30px;
    margin: 0 1.875rem 0 0.625rem;
  }

  .file__remove {
    width: 1.75rem;
    height: 1.75rem;
    background-color: #e8ebf2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .file__remove-icon {
    width: 1.25rem;
  }

  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .item-exit {
    opacity: 1;
    height: 2.8125rem;
    overflow: hidden;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in, height 300ms ease-in;
    height: 0;
    overflow: hidden;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .register__inner {
      row-gap: var(--sectionPadding);
      padding: var(--sectionPadding);
    }

    .register__inner .ui-stepper {
      padding: 0;
    }

    .ui-stepper {
      .ui-stepper__step {
        font-size: 10px;
        text-align: center;
      }
    }

    .register__bottom_text {
      padding: var(--sectionPadding) 0;
      font-size: 12px;
    }

    .register__step__title {
      font-size: 20px;
    }

    ${RegisterStepOne} {
      padding: 0;
      display: grid;
      grid-auto-rows: min-content;
      row-gap: var(--sectionPadding);

      .register__step__one__options {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 140px 140px;
        row-gap: 1rem;
      }

      ${RegisterStepOneOption} {
        padding: var(--sectionPadding);
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        column-gap: var(--sectionPadding);

        .register__step__one__option__check {
          display: none;
        }

        .register__step__one__option__title {
          margin-top: 0;
          font-size: ${({ theme }) => theme.fontSize[20]};
        }

        .register__step__one__option__subtitle {
          margin-top: 0.5rem;
        }
      }
    }

    .register__step__two__fields,
    .register__step__three__fields,
    .register__step__four__fields,
    .register__step__five__fields {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }

    .register__steps {
      padding: 0;
      width: 100%;
    }

    .register__step__bottom__label {
      display: none;
    }

    .register__step__bottom {
      display: flex;
      flex-direction: column-reverse;
      margin-top: var(--sectionPadding);

      .register__step__bottom__back {
        margin-top: var(--sectionPadding);
      }
    }

    .register__step__subtitle {
      font-size: 14px;
    }

    .upload-file {
      margin-top: 0;
      padding: var(--sectionPadding);
    }

    .upload-file > p {
      width: 100%;
      text-align: left;
    }

    .upload-file__title,
    .upload-file__support {
      font-size: 14px;
    }

    .upload-file__info {
      font-size: 12px;
    }

    .approve-terms .ui-label {
      font-size: 12px;

      a {
        display: inline;
      }
    }
  }
`;

export const StyledRegisterStepOneOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const RegisterLastStep = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .register__step__last__title {
    max-width: 50ch;
    font-family: var(--archivo);
    font-size: 1.5625rem;
    font-weight: bold;
    color: #222b45;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .register__step__last__title {
    font-family: var(--archivo);
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: #222b45;
  }

  .register__last__step__submit {
    width: 190px;
    margin-top: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;

    svg {
      transform: scale(0.8);
    }

    display: block;

    .register__step__last__title {
      margin: var(--sectionPadding) 0;
      font-size: 20px;
      text-align: left;
    }
    .register__step__last__subtitle {
      text-align: left;
      font-family: var(--archivo);
      color: rgba(104, 124, 151, 1);
      line-height: 24px;
      br {
        display: none;
      }
    }

    .register__last__step__submit {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
`;
