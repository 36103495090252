import styled from "styled-components";

export const StyledExportCSV = styled.button`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.neutral[600]};
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-weight: 600;

  transition: all 0.3s ease-in-out;

  svg {
    width: 20px;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.neutral[600]};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral[700]};
    svg {
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }
`;
