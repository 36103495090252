import { StyledLabel } from "./Label.styled";

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
}

function Label({ children, ...rest }: Props) {
  return (
    <StyledLabel {...rest} className="ui-label">
      {children}
    </StyledLabel>
  );
}

export default Label;
