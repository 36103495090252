import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { motion } from "framer-motion";
import { memo } from "react";
import { Row, TableInstance } from "react-table";
import cn from "classnames";
import Shine from "ui/Shimmer/Shine";
import SwitchAnimation from "ui/SwitchAnimation";
import { StyledTable } from "./Table.styled";
import { ReactComponent as Diamond } from "../../assets/icons/no-data.svg";
import Shimmer from "ui/Shimmer";
import Select from "ui/Select";
interface Props {
  table: TableInstance<any>;
  className?: string;
  onRowClick?: (original: any) => void;
  emptyText?: React.ReactNode;
  switchState: any;
  shimmer?: boolean;
}

const Table = ({
  shimmer,
  table,
  onRowClick,
  emptyText = "No data to display",
  switchState,
}: Props) => {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
  } = table;

  return (
    <SwitchAnimation state={switchState}>
      <Shimmer shimmer={!!shimmer}>
        <StyledTable className="ui-table__container ">
          {table.rows.length > 0 && (
            <>
              <div className="ui-table  scrollbar--horizontal">
                <div className="ui-table__head">
                  {headerGroups.map((headerGroup) => (
                    <div
                      {...headerGroup.getHeaderGroupProps()}
                      className="ui-table__head__row"
                    >
                      {headerGroup.headers.map((column) => (
                        <div
                          className={cn(
                            "ui-table__head__cell",
                            column.isSorted
                              ? "ui-table__head__cell--sorted"
                              : undefined
                          )}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={
                            column.canSort
                              ? { justifyContent: "space-between" }
                              : {}
                          }
                        >
                          <div className="shine">{column.render("Header")}</div>
                          {column.canSort && (
                            <motion.div
                              variants={{
                                desc: {
                                  rotate: 180,
                                },
                                asc: {
                                  rotate: 0,
                                },
                              }}
                              initial="asc"
                              animate={column.isSortedDesc ? "desc" : "asc"}
                              className={cn(
                                "data-table__head-cell__arrow-container",
                                column.isSorted ? "sorted" : undefined
                              )}
                            >
                              <ChevronDown className="data-table__head-cell__arrow" />
                            </motion.div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="ui-table__body scrollbar">
                  {(page ? page : rows).map((row, i) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        row={row}
                        key={row.id}
                        onRowClick={onRowClick}
                      />
                    );
                  })}
                </div>
              </div>
              {pageOptions && (
                <div className="ui-table__pagination">
                  <Shine>
                    <div className="ui-table__pagiantion__left">
                      <p className="ui-table__pagination__items">
                        Items In Page
                      </p>
                      <Select
                        menuPosition="fixed"
                        options={[
                          { label: "30", value: "30" },
                          { label: "50", value: "50" },
                        ]}
                        value={{
                          label: String(pageSize),
                          value: String(pageSize),
                        }}
                        onChange={(v) => {
                          setPageSize(Number(v?.value));
                        }}
                      />
                    </div>
                  </Shine>
                  <Shine>
                    <div className="ui-table__pagination__right">
                      {pageOptions.length !== 1 && (
                        <button
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          {"<"}
                        </button>
                      )}
                      <span>
                        Page <strong>{pageIndex + 1} </strong>
                        of
                        <strong> {pageOptions.length}</strong>{" "}
                      </span>
                      {pageOptions.length !== 1 && (
                        <button
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          {">"}
                        </button>
                      )}
                    </div>
                  </Shine>
                </div>
              )}
            </>
          )}
          {table.rows.length === 0 && (
            <>
              <TableEmpty text={emptyText} />
              <div />
            </>
          )}
        </StyledTable>
      </Shimmer>
    </SwitchAnimation>
  );
};

const TableRow = memo(
  ({ row, onRowClick }: { row: Row<any>; onRowClick: any }) => {
    return (
      <div className="ui-table__body__row" {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
            <div
              onClick={() => onRowClick && onRowClick(row.original)}
              className="ui-table__body__cell"
              {...cell.getCellProps()}
            >
              {cell.render("Cell")}
            </div>
          );
        })}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row.isSelected === nextProps.row.isSelected &&
      prevProps.row.original === nextProps.row.original
    );
  }
);

const TableEmpty = ({ text }: { text: React.ReactNode }) => {
  return (
    <div className="ui-table__empty">
      <Diamond className="ui-table__empty__icon" />
      <p className="ui-table__empty__text">{text}</p>
    </div>
  );
};

export default Table;
