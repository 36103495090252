import { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import cn from "classnames";
interface Props {
  state: any;
  classNames?: string;
  containerClassName?: string;
  children: React.ReactNode;
}

function SwitchAnimator({
  state,
  classNames = "fade",
  children,
  containerClassName = "",
}: Props) {
  const nodeRef = useRef(null);
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={state}
        classNames={classNames}
        in
        timeout={300}
        nodeRef={nodeRef}
      >
        <div
          className={cn("switch-animator", containerClassName)}
          ref={nodeRef}
        >
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default SwitchAnimator;
