// eslint-disable-next-line
import React from "react";
import get from "lodash.get";
import { StyledExportCSV } from "./ExportCSV.styled";
import { ReactComponent as FilePlus } from "../../assets/icons/file-plus.svg";

interface Header {
  label: string;
  accessor: string;
  format?: (value: string | number) => string | number;
}

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  data: any[];
  headers: Header[];
  filename: string;
}

const ExportCSV = ({
  children,
  data,
  headers,
  filename,
  onClick,
  ...rest
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick && onClick(e);
    const labels = headers.map((header) => header.label);
    const values = [] as any;
    data.forEach((row) => {
      const currValue = [] as any;
      headers.forEach((header) => {
        const value = get(row, header.accessor);
        header.format
          ? currValue.push(header.format(value ?? "-"))
          : currValue.push(value ?? "-");
      });
      values.push(currValue);
    });

    const csvString = [labels, ...values].map((e) => e.join(",")).join("\r\n");
    const a = document.createElement("a");
    a.textContent = "download";
    a.download = `${filename}.csv`;
    a.href = "data:text/csv;charset=utf-8," + escape(csvString);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <StyledExportCSV
      {...rest}
      onClick={(e) => handleClick(e)}
      className={[rest.className, "ui-export"].join(" ")}
    >
      <FilePlus />
      {children}
    </StyledExportCSV>
  );
};

export default ExportCSV;
