import { clearFilters } from "features/filters/slice";
import { useAppDispatch } from "hooks";
import cn from "classnames";
interface Props {
  className: string;
  children: React.ReactNode;
}

const ClearFilters = ({ className = "", children }: Props) => {
  const dispatch = useAppDispatch();
  const clear = () => {
    dispatch(clearFilters());
  };
  return (
    <div className={cn(className, "shine")} onClick={clear}>
      {children}
    </div>
  );
};

export default ClearFilters;
