import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Diamond } from "assets/icons/diamond.svg";
import ElipseDetail from "components/ElipseDetail";
import Modal from "components/Modal";
import TenderProductSmallCard from "components/TenderProductSmallCard";
import { tenderProduct } from "../../faker";
import { useRegisterToTender } from "features/tenders/mutations";
import { useTenderRegister } from "features/tenders/queries";
import useModalActions from "hooks/useModalActions";
import moment from "moment";
import styles from "./TenderRegister.module.css";
import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import { useHistory } from "react-router-dom";
import { asyncCatch } from "utils";
interface Props {
  tenderRegisterId: string;
}

const TenderRegister = ({ tenderRegisterId }: Props) => {
  const history = useHistory();
  const { closeModal } = useModalActions();
  const { data: tenderRegister, isFetching, isFetched } = useTenderRegister(
    tenderRegisterId
  );
  const { mutateAsync } = useRegisterToTender();

  const register = async (id: string) => {
    const [err] = await asyncCatch(mutateAsync(id));
    if (!err) history.push(`/tender/${id}`);
    closeModal();
  };

  if (!tenderRegister) return null;

  return (
    <Modal>
      <Shimmer shimmer={isFetching && !isFetched}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <Modal.Close onClick={() => closeModal()} />
            <div className={styles.top}>
              <Shine>
                <p className={styles.title}>
                  <span>Tender: </span> {tenderRegister.tender.name}
                </p>
              </Shine>
              <Shine>
                <p className={styles.time}>
                  {moment(tenderRegister.tender.endDate).format("LL")}
                </p>
              </Shine>
            </div>
            <div className={styles.details}>
              <ElipseDetail>Polished</ElipseDetail>
              <ElipseDetail>RB</ElipseDetail>
              <ElipseDetail>D-F</ElipseDetail>
              <ElipseDetail>1.00 - 1.50</ElipseDetail>
              <ElipseDetail>VG+</ElipseDetail>
            </div>
            <div className={styles.summary}>
              <Shine>
                <div className={styles.summaryBox}>
                  <div className={styles.iconContainer}>
                    <Diamond className={styles.icon} />
                  </div>
                  <p className={styles.summaryBoxValue}>
                    {tenderRegister.diamonds}
                  </p>
                  <p className={styles.summaryBoxLabel}>Diamonds</p>
                </div>
              </Shine>
              <Shine>
                <div className={styles.summaryBox}>
                  <div className={styles.iconContainer}>
                    <Users className={styles.icon} />
                  </div>
                  <p className={styles.summaryBoxValue}>
                    {tenderRegister.activeUsers}
                  </p>
                  <p className={styles.summaryBoxLabel}>Active Users</p>
                </div>
              </Shine>
            </div>
            <Shine>
              <p className={styles.description}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
                veniam molestiae ipsum ipsa. Nam perspiciatis itaque delectus,
                debitis nobis error illo adipisci facilis aliquam non sint
                inventore. Fugit, debitis ut!
              </p>
            </Shine>
            <div className={styles.actions}>
              <Shine>
                <button
                  className={styles.register}
                  onClick={() => register(tenderRegister.tender._id)}
                >
                  REGISTER
                </button>
              </Shine>
            </div>
            <div className={styles.diamonds}>
              <Shine>
                <p className={styles.diamondsTitle}>
                  Hot diamonds in this tender:
                </p>
              </Shine>
              <div className={styles.diamondsList}>
                <TenderProductSmallCard tenderProduct={tenderProduct} />
                <TenderProductSmallCard tenderProduct={tenderProduct} />
                <TenderProductSmallCard tenderProduct={tenderProduct} />
              </div>
            </div>
          </div>
        </div>
      </Shimmer>
    </Modal>
  );
};

export default TenderRegister;
