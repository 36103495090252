import { StyledSelect } from "./Select.styled";
import Rselect, { Props } from "react-select";
import "react-popper-tooltip/dist/styles.css";
import TooltipError from "ui/TooltipError";

function Select(props: Props & { isError?: boolean }) {
  return (
    <StyledSelect error={!!props.error}>
      <Rselect className="ui-select" classNamePrefix="ui-select" {...props} />
      {props.isError && props.error && <TooltipError error={props.error} />}
    </StyledSelect>
  );
}

export default Select;
