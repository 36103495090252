import styled from "styled-components";

export const StyledPhoneInput = styled.div<{ error?: boolean }>`
  position: relative;
  .special-label {
    display: none;
  }

  .react-tel-input .form-control {
    display: block;
    height: 3.125rem !important;
    width: 100%;
    border-radius: 6px;
    border: ${(props) => `2px solid ${props.error ? "#EF0000" : "#eaeef2"}`};
    background-color: transparent;
    padding-left: 1rem;
    outline-style: none;
    transition: all 0.2s ease-in;
    font-family: var(--archivo);
    font-size: 1rem;
    font-weight: 500;
    color: hsl(0, 0%, 20%);

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows.light};
      background-color: transparent;
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.neutral[600]};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral[200]};
    }
  }

  .react-tel-input {
    display: grid;
    grid-template-columns: auto 70px;
    direction: rtl;
    column-gap: 1rem;

    & > * {
      direction: ltr;
    }
  }

  .react-tel-input .flag-dropdown {
    transition: all 0.2s ease-in;
    border: ${(props) => `2px solid ${props.error ? "#EF0000" : "#eaeef2"}`};
    position: relative;
    background: transparent !important;
    border-radius: 6px;
  }

  .react-tel-input .flag-dropdown.open {
    border: 2px solid ${({ theme }) => theme.colors.neutral[600]};
  }

  .react-tel-input .selected-flag {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .react-tel-input .country-list {
    border-radius: 6px;
  }

  .react-tel-input .country-list .country {
    padding: 12px;
  }

  .react-tel-input .country-list .search {
    padding: 14px 12px;
  }

  .react-tel-input .country-list .search-emoji {
    display: none;
  }

  .react-tel-input .country-list .search-box {
    height: 32px;
    width: 100%;
    margin-left: 0;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: transparent !important;
  }

  .react-tel-input .selected-flag .arrow {
    display: none;
  }
`;
