import { ReactComponent as Close } from "assets/icons/x.svg";
import { useEffect, useRef } from "react";
import useModalActions from "hooks/useModalActions";
import { StyledModal } from "./Modal.styled";
interface Props {
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  overlayClassName?: string;
}

const Modal = ({ children, overlayClassName = "", onClose }: Props) => {
  const { closeModal } = useModalActions();
  const overlayRef = useRef(null);
  return (
    <StyledModal
      className={overlayClassName}
      ref={overlayRef}
      onClick={(e) => {
        if (overlayRef.current === e.target) {
          onClose ? onClose() : closeModal();
        }
      }}
    >
      {children}
    </StyledModal>
  );
};

const CloseModal = ({
  onClick,
  parentId = "",
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  parentId?: string;
}) => {
  const { closeModal } = useModalActions();
  const ref = useRef<null | HTMLButtonElement>(null);

  useEffect(() => {
    if (parentId && ref.current) {
      const setXPosition = (x: number, y: number) => {
        const offset = window.innerWidth <= 640 ? 35 / 4 : 35 / 2;
        if (!ref.current) return;
        ref.current.style.position = "fixed";
        ref.current.style.top = [y - offset, "px"].join("");
        ref.current.style.right = [x - offset, "px"].join("");
      };

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { x, y } = entry.target.getBoundingClientRect();
          setXPosition(x, y);
        }
      });

      const onResize = () => {
        const parent = document.getElementById(parentId) as any;
        const { x, y } = parent.getBoundingClientRect();
        setXPosition(x, y);
      };

      const parent = document.getElementById(parentId) as any;
      window.addEventListener("resize", onResize);
      resizeObserver.observe(parent);
      return () => {
        resizeObserver.observe(parent);
        window.removeEventListener("resize", onResize);
      };
    }
  }, [parentId]);

  return (
    <button
      className={["modal__close", "close-modal"].join(" ")}
      onClick={(e) => (onClick ? onClick(e) : closeModal())}
      ref={ref}
    >
      <Close className="modal__close__icon" />
    </button>
  );
};

Modal.Close = CloseModal;
export default Modal;
