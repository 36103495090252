import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: var(--sectionPadding) var(--sectionPadding) 5rem
    var(--sectionPadding);
  height: 100%;
  overflow: auto;

  * {
    font-size: ${(props) => props.theme.fonts.archivo};
  }

  ol {
    list-style: none;
  }

  .title {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: 20px 0 10px 0;
    display: inline-block;
    font-size: ${(props) => props.theme.fonts.archivo};
  }

  .text {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral[600]};
    line-height: 1.2;
  }

  table {
    .title {
      margin: 0;
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.primary[200]};
    text-decoration: underline;
  }
`;

function Terms(): ReactElement {
  return (
    <Container>
      <div>
        <p>
          <strong className="title">Definitions:</strong>
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong className="title">Bid:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The price offered by a Bidder for a specific Good during the
                  Tender term through an online process in the Website.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Bidder:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  A registered user of the Website who submits an online Bid.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Bidding Instructions:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The instructions published at the Website before each Tender.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Business Day:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  Means Monday through Friday, except for public holidays in
                  England or in the respective jurisdiction in which the
                  Verification Labs operate or other day on which commercial
                  banks in England or Verification Labs in the respective
                  jurisdiction are authorized or required by law to remain
                  closed.
                </p>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Buyer:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  A Bidder that his Bid was declared as the winning Bid for a
                  specific Good in a specific Tender.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Company:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  Lucy Platforms Ltd., through its subcontractor Hennig. &nbsp;
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Hennig:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  I Hennig &amp; Co Limited, including (where applicable) its
                  agents and contractors, directors, officers and employees.
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">GIA Certificate:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  Means the certificate issued by GIA for each Good, as provided
                  by the Seller.
                </p>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Goods:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The polished diamonds offered by the Company to Bidders in the
                  Tender.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Payment Term:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  Two (2) Business Days after the date on which an invoice is
                  issued by the Company.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Premises:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The Verification Labs and respective storage warehouse
                  operated by the Company or by a designee thereof.
                </p>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Qualified Bid:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  A Bid which is equal to or higher than the Seller&rsquo;s
                  Reserve Price.
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Reserve Price:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The price required by the Seller for each Good.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Seller:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The owner of the Goods offered for Bid under a specific
                  Tender.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Seller Consideration:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The amount actually received by the Company from a Buyer for a
                  certain Good less the Company's commission, as indicated in
                  the Website.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Tender:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  An online tender process through the Website according to
                  these Tenders Terms of Use.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Tender Close:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  For each Tender, the moment Bids can no longer be accepted, as
                  published on the Website.
                </p>
              </td>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Verification Labs:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  The verification labs operated by the Company or a by an
                  authorized designee thereof, which are used by the Company for
                  the verification process of the Goods.
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong className="title">Website:</strong>
                </p>
              </td>
              <td>
                <p className="text">
                  <a href="http://www.tradediamonds.com">
                    www.tradediamonds.com
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <strong className="title">Tender Process</strong>
        <p className="text">The Tender process includes three main steps:</p>
        <ol>
          <li className="text">Registration to the Website</li>
          <li className="text">Submitting a Bid for a specific Tender</li>
          <li className="text">
            After Tender logistic processes (verification of Goods, payment and
            Goods delivery)
          </li>
        </ol>
        <strong className="title">
          Sellers' Acceptance to include Goods in a Tender{" "}
        </strong>
        <p className="text">
          Once Goods are selected by the Company via the AI algorithms, the
          respective Sellers will be asked to provide their consent for
          including the Goods in a specific Tender event. Once such consent is
          obtained, these Goods cannot be sold by the Seller on any other
          platform until the Tender is closed (and on the assumption that the
          Goods are not sold during this Tender event). Failure to comply may
          lead to exclusion of a Seller from future Tenders at the Company's
          sole discretion.
        </p>
        <p>
          <strong className="title">Viewing</strong>
        </p>
        <p className="text">
          The specific Goods available for Bidding will be presented at the
          Website 12 hours before the Tender event. Physical viewing of the
          Goods is not possible and Bids may only be made based on the
          information available on the Website for the specific Goods.
        </p>
        <p>
          <strong className="title">Bidding</strong>
        </p>
        <p className="text">
          All Bids submitted by Bidders via the Website are considered to be
          final and non-negotiable after the specified Tender Close. Bids may be
          made, adjusted and/or cancelled at any point until the Tender Close.
          Once the Tender is closed all active Bids will be considered binding
          and may not be cancelled any longer. Any cancellation of a
          winning&nbsp;Bid after the Tender Close will be subject to a ten
          percent (10%) penalty fee based on the total value of the submitted
          winning Bid. All Bids must be submitted online via the Website.
        </p>
        <p>
          <strong className="title">Taxes</strong>
        </p>
        <p className="text">
          Where applicable, all Bidders will provide a copy of their VAT
          registration or Sales Tax Registration and Reseller certificate.
        </p>
        <p>
          <strong className="title">Bids' Review</strong>
        </p>
        <p className="text">
          All Bids will be reviewed by the Company after Tender Close and status
          (won/pending/otherwise) will be published at the user's respective
          account in the Website as soon as possible; normally within three (3)
          hours of Tender Close. It is the responsibility of the Bidders to
          check the status on the Website. The Company may also inform the
          respective Buyers and Sellers on the Tender results via email.
        </p>
        <p>
          <strong className="title">Tender Results</strong>
        </p>
        <p className="text">
          The highest Bid among all Qualified Bids will be the winning Bid and
          the same will be considered as a Sale. If there are no Qualified Bids,
          the highest Bid available will be forwarded to the Seller for
          consideration. Should that Bid is approved by the Seller, it will be
          the winning Bid and the same will be considered as a Sale. If not
          approved, there will be no winning Bid and no Sale. If two or more
          Bidders submitted identical Bids and the same are the highest Bids,
          the Bid submitted earlier will be the winning Bid. The Company
          reserves the right to refuse to sell any particular Good if the
          highest Bid received is deemed by the Company and/or the Seller, at
          their sole discretion, to be insufficient.
        </p>
        <p>
          <strong className="title">Verification and invoicing</strong>
        </p>
        <p className="text">
          Once results of the Tender are published, as aforesaid, the respective
          Seller will be required to deliver the Goods in question, as soon as
          possible and no later than two (2) Business Days following the Tender
          Close, to the Verification Lab indicated by the Company, at its own
          cost and risk.
        </p>
        <p className="text">
          Once accepted by the Company, the Good in question will be verified by
          the Company (or a designee thereof) at the Verification Lab against
          the GIA Certificate provided by the Seller to confirm compliance of
          the Good with the parameters indicated in the GIA Certificate.
          Verification is done at the Company's cost. Upon completion of the
          verification process (normally within two (2) Business Days following
          the acceptance of the Good by the Company), an invoice will be issued
          by the Company to the Buyer.
        </p>
        <p>
          <strong className="title">Payment</strong>
        </p>
        <p className="text">
          Full payment for a Good must be transferred by the Buyer via bank wire
          transfer within the Payment Term. Payment must be made to the bank
          account detailed on the invoice. No Goods will be available for
          collection or shipping unless the Company confirms that full payment
          has been received. All Sales are final and no returns are accepted
          after delivery of the Good under any circumstances.
        </p>
        <p className="text">
          The Company will notify the Seller once payment from the Buyer is
          received and Seller will then issue an invoice to the Company for the
          Seller Consideration due to the Seller for the Good. The Company will
          pay the Seller Consideration within seven (7) Business Days following
          the issuance of Seller's invoice.
        </p>
        <p>
          <strong className="title">Penalty Fee</strong>
        </p>
        <p className="text">
          In the event that a Buyer defaults on a purchase by not making payment
          within the Payment Term, the Buyer will be required to pay a penalty
          fee equal to 10% of the Bid. In such case, the Company may, at its
          sole discretion, cancel the Bid(s) and/or take legal actions against
          the defaulting Buyer and/or offer the relevant Good to the second
          highest Bidder, who is under no obligation to accept the Goods so
          offered.
        </p>
        <p>
          <strong className="title">Dispute or Error</strong>
        </p>
        <p className="text">
          In the event of any dispute, error or any other reason deemed
          necessary by the Company, the Company, at its sole discretion, may
          withdraw any Good from the Tender and/or refuse any Bid or cancel any
          Sale. The Bidders agree to waive any claims and not to institute any
          legal action against the Company and/or against each other. Bidders
          agree that all decisions by the Company regarding any disputes in
          connection with the Tender are final and acceptable to all Bidders.
        </p>
        <p>
          <strong className="title">Delivery, Title and Ownership</strong>
        </p>
        <p className="text">
          Title to and ownership of the Goods will only pass to the Buyer after
          full unrestricted payment is received by the Company (or its
          designated representative) and confirmed by the Company. Upon
          confirmation of receipt of funds, the Goods will be available for
          collection by the Buyer from the relevant Premises or, at the
          instructions of the Buyer and at the cost and risk of the Buyer, may
          be shipped by the Company to the Buyer at Buyer&rsquo; cost
        </p>
        <p>
          <strong className="title">Sales commission</strong>
        </p>
        <p className="text">
          Upon a successful sale the Seller shall pay the company a sales
          commission of 2% of the sale amount. This commission shall be invoiced
          to the Seller once the funds from the buyer are received by the
          Company and shall be paid via bank wire within the Payment Term.
        </p>
        <p>
          <strong className="title">Storage; Insurance</strong>
        </p>
        <p className="text">
          The Company will store the Goods in a safe place and insure the Goods,
          at its own cost, for all risks for as long as the Goods are kept at
          the Company's Premises. Notwithstanding the foregoing, in case Goods
          are not picked-up by the Buyer or instructions for shipping are not
          otherwise provided to the Company, as aforesaid, within 7 Business
          Days of confirmation by the Company of receipt of funds, Buyer will be
          charged reasonable insurance and storage fees.
        </p>
        <p>
          <strong className="title">Shipping Fees</strong>
        </p>
        <p className="text">
          Shipping fees for delivery from Seller to the Verification Lab shall
          be paid by the Seller. If the Buyer requests shipment of purchased
          Goods after the Tender Close and the Company agrees to make that
          shipment, the Buyer shall pay all shipping, insurance and reasonable
          handling fees in advance, unless agreed otherwise between Buyer and
          the Company.
        </p>
        <p>
          <strong className="title">Confidentiality</strong>
        </p>
        <p className="text">
          The identity of all Bidders and the amount of each Bid shall remain
          confidential. Bidders agree not to disclose their Bids to third
          parties or coordinate their Bids with any other Bidders or third
          parties.
        </p>
        <p>
          <strong className="title">No Warranties</strong>
        </p>
        <p className="text">
          The content presented on the Website on all Goods is taken As-Is from
          the respective Sellers. The Company has no obligation to examine or
          monitor such content.
        </p>
        <p className="text">
          All Goods are sold As-Is. The Company provides no representations
          and/or warranties and/or guarantees as to the quality, origin,
          physical characteristics or any other aspect of the Goods. The
          Company's sole liability is to verify, once the Good is accepted at
          the respective Verification Lab, compliance of the Good with the
          parameters indicated in the GIA Certificate. Any descriptions of Goods
          made by the Company's personnel or its agents are merely general and
          not a guarantee or representation of the quality, origin, physical
          characteristics or any other aspect of the Goods. It is the
          responsibility of the Bidder to adequately examine all information
          provided on the Website before making any Bids.
        </p>
        <p>
          <strong className="title">Liability</strong>
        </p>
        <p className="text">
          By participating in the Tender, Bidders, Buyers and Sellers: (a) are
          deemed to agree to these Tenders Terms of Use; and (b) specifically
          hereby agree that: (i) the Company's sole liability and the Bidders',
          Buyers' and Sellers' sole remedy against the Company for any claim
          whatsoever relating to a specific Tender shall be limited to the
          return of the amounts actually paid by such Bidders, Buyers or Sellers
          to the Company under that Tender; (ii) Neither the Company nor any
          third party acting on its behalf shall be liable to any Bidder, Buyer
          or Seller for any direct, secondary or incidental loss or damage
          resulting from the withdrawal of any Good or rejection of any Bid;
          (iii) Neither the Company nor any third party acting on its behalf
          will have any obligation of any nature whatsoever to Buyers, Bidders
          or Sellers other than as explicitly set forth in these Tenders Terms
          of Use .
        </p>
        <p>
          <strong className="title">Conflict of Interest</strong>
        </p>
        <p className="text">
          Neither the Company nor any third party acting on its behalf will
          submit a Bid on any Good offered for sale in any Tender.
        </p>
        <p>
          <strong className="title">Conduct</strong>
        </p>
        <p className="text">
          All Bidders, Buyers and Sellers agree to conduct themselves in a
          business-like manner; to maintain the privacy, security and
          confidentiality of other Bidders, Buyers and Sellers; to follow all
          instructions of the Company regarding the Tenders' process and to
          respect and honor the strong traditions of honesty and integrity in
          the diamond industry.
        </p>
        <p>
          <strong className="title">Anti Money Laundering/Patriot Act</strong>
        </p>
        <p className="text">
          As part of the registration process to the Website and from time to
          time, as reasonably required by the Company, all Sellers and Buyers
          will provide the Company with all documentation reasonably requested
          by the Company in order to enable the Company to fully comply with all
          anti-money laundering laws and regulations under applicable laws of
          all relevant jurisdictions. The documentation will include (but not
          necessarily be limited to) a copy of one of the following forms of
          identification: driving license, passport, identity card, sales tax
          registration or other government issued license or registration
          document and where relevant, corporate constitutional documentation.
          Failure to pass the KYC/AML verification shall cancel any bid even if
          won.
        </p>
        <p>&nbsp;</p>
      </div>
    </Container>
  );
}

export default Terms;
