import React from "react";

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  text: string;
  details: string;
  startDate: string;
  endDate: string;
}

const toGoogleCalendarDate = (date: string) =>
  new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, "");

const AddToCalendar = ({
  text,
  details,
  startDate,
  endDate,
  children,
  ...rest
}: Props) => {
  return (
    <a
      {...rest}
      href={`https://calendar.google.com/calendar/r/eventedit?text=${text}
  &details=${details}
  &dates=${toGoogleCalendarDate(startDate)}/${toGoogleCalendarDate(endDate)}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default AddToCalendar;
