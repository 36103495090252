import { useUser } from "features/users/queries";

const useAmlStatus = () => {
  const { data: user } = useUser();

  const getAmlTender = (): AmlTenderStatus => {
    const aml =
      user?.company?.amlTender?.filter(
        ({ userEmail }) => user.email === userEmail
      ) || [];
    if (!aml || aml.length === 0) return "pending";
    return aml[0].amlTenderStatus;
  };

  return getAmlTender();
};

export default useAmlStatus;
