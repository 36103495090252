import cn from "classnames";
import React from "react";
import { numberWithCommas } from "../../utils";
import { StyledPrice } from "./Price.styled";
type Props = React.HTMLProps<HTMLParagraphElement> & {
  price: number | string;
  postfix?: React.ReactNode;
};

const Price = ({ price, className, postfix, ...rest }: Props) => {
  if (!price) return <StyledPrice className="ui-price">-</StyledPrice>;
  return (
    <StyledPrice className={cn("ui-price", className)}>
      ${numberWithCommas(Number(price).toFixed(0))}
      <span className="ui-price__zeros">.00</span>
      {postfix && <span className="ui-price__postfix">{postfix}</span>}
    </StyledPrice>
  );
};

export default Price;
