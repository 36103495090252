import cn from "classnames";
import Price from "ui/Price";
import TenderProductSmallCard from "components/TenderProductSmallCard";
import { useMyBids } from "features/bids/queries";
import { setMyBidsIsOpen } from "features/bids/slice";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as Bid } from "../../assets/icons/bid.svg";
import styles from "./MyBids.module.css";
import MyBidsToggle from "./MyBidsToggle";
import useOnClickOutside from "hooks/useClickOutside";
import { useRef } from "react";
import { extractId, numberWithCommas } from "utils";
import Shimmer from "ui/Shimmer";
import { ReactComponent as Diamond } from "assets/icons/no-data.svg";
import { useAnalytics } from "hooks/useAnalytics";

interface Props {
  tenderId: string;
}

const MyBids = ({ tenderId }: Props) => {
  const track = useAnalytics();
  const mainRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const myBidsIsOpen = useAppSelector((state) => state.bids.myBidsIsOpen);
  const closeMyBids = () => dispatch(setMyBidsIsOpen(false));
  useOnClickOutside(mainRef, myBidsIsOpen ? closeMyBids : () => {});
  const {
    // isLoading, isError,
    isFetching,
    isFetched,
    data,
  } = useMyBids(extractId(tenderId));

  const handleClick = () => {
    if (myBidsIsOpen) {
      track("My Bids - Bar Press Close");
      dispatch(setMyBidsIsOpen(false));
    } else {
      track("My Bids - Bar Press Open");
      dispatch(setMyBidsIsOpen(true));
    }
  };

  const sum = (data || []).reduce(
    (prev, curr) => prev + curr.price * curr.tenderProduct.product.carat,
    0
  );

  return (
    <Shimmer shimmer={isFetching && !isFetched}>
      <div
        className={cn(styles.container, {
          [styles.containerActive]: myBidsIsOpen,
        })}
      >
        <MyBidsToggle
          onClick={handleClick}
          length={data?.length || 0}
          sum={sum}
        />
        <div className={styles.main} ref={mainRef}>
          <div className={styles.mainTop}>
            <p className={styles.mainTopTitle}>My Bids</p>
            <div
              className={styles.arrowContainer}
              onClick={() => dispatch(setMyBidsIsOpen(!myBidsIsOpen))}
            >
              <ChevronDown className={styles.arrow} />
            </div>
          </div>
          <div className={styles.bids}>
            <div className={styles.bidsTitle}>
              <Bid />
              <p>{data?.length || 0} bids</p>
              {!!sum && (
                <p className={styles.total}>
                  TOTAL AMOUNT: <span>${numberWithCommas(sum, 0)}</span>
                </p>
              )}
            </div>

            <div className={styles.bidsList}>
              {(data || []).length === 0 && (
                <div className={styles.noBids}>
                  <Diamond />
                  <p>
                    You don’t have any active bids yet.
                    <br /> After you’ve placed a bid (on a diamond) it will
                    appear here.
                  </p>
                </div>
              )}
              {data &&
                data.map(({ tenderProduct, _id, price }) => (
                  <TenderProductSmallCard
                    key={_id}
                    tenderProduct={tenderProduct}
                    customText="Current Bid"
                    customValue={<Price price={price} postfix="/ppc" />}
                    onlyView={false}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Shimmer>
  );
};

export default MyBids;
