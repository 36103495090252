import { useFormik } from "formik";
import GetLogin from "../../components/GetLogin";
import { Link } from "react-router-dom";
import { useLogin } from "features/users/mutations";
import { StyledLogin } from "./Login.styled";
import Button from "ui/Button";
import { useAnalytics } from "hooks/useAnalytics";
import { asyncCatch } from "utils";
import Field from "../../ui/Field";
import Input from "../../ui/Input";
import Label from "../../ui/Label";
import * as Yup from "yup";
import GoBack from "ui/GoBack";
export const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must contain at least 6 characters"),
});

const Login = () => {
  const track = useAnalytics();
  const { mutateAsync, isLoading } = useLogin();
  const formik = useFormik({
    validationSchema,
    initialValues: { email: "", password: "" },
    onSubmit: async (values) => {
      const trackPayload = { signinEmail: values.email };
      track("Sign In Press", trackPayload);
      const [err] = await asyncCatch(mutateAsync(values));
      if (err?.message === "Password is incorrect")
        formik.setFieldError("password", "Password is incorrect");
      if (err?.message === "User not found")
        formik.setFieldError("email", "Email does not exist");
      if (err?.message === "Your account hasn't been approved yet.")
        formik.setFieldError("email", "Your account hasn't been approved yet.");
      if (!err) return track("Sign In Success", trackPayload);
    },
  });

  return (
    <StyledLogin>
      <div className="login__inner">
        <p className="login__title">Welcome to Trade Diamonds</p>
        <p className="login__subtitle">Sign in to get started.</p>
        <GetLogin />
        <div className="login__get-register">
        <p>Don’t have an account?</p>
        <a href="https://www.get-diamonds.com/register" target="_blank" rel="noreferrer">Register now</a>
        </div>
        <p className="login__signwith">Or sign in with</p>
        <form onSubmit={formik.handleSubmit}>
          <Field>
            <Label htmlFor="email">Email*</Label>
            <Input
              type="email"
              id="email"
              placeholder="email@email.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email as any}
              isError={formik.touched.email && !!formik.errors.email}
              error={formik.errors.email}
            />
          </Field>
          <Field>
            <Label htmlFor="password">Password*</Label>
            <Input
              type="password"
              id="password"
              placeholder="*******"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password as any}
              isError={formik.touched.password && !!formik.errors.password}
              error={formik.errors.password}
            />
          </Field>
          <span className="login__forgot">
            Forgot your password?{" "}
            <Link to="/forgot" onClick={() => track("Login - Reset Password")}>
              Reset it here
            </Link>
          </span>
          <Button
            className="login__submit"
            kind="purple"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Sign In
          </Button>
          <span className="login__register">
            Don’t have an account?{" "}
            <Link to="/register" onClick={() => track("Register Now Button")}>
              Register now
            </Link>
          </span>
        </form>
      </div>
      <GoBack newTab to="https://www.get-diamonds.com/">
        Back to Get-Diamonds
      </GoBack>
    </StyledLogin>
  );
};

export default Login;
