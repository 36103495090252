import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";
import { useActiveTenderProductBids, useMyBids } from "./queries";

interface ICreateBid {
  price: number;
  tenderProductId: string;
  tenderId: string;
  tenderUserId: string;
  location: string;
}

const createBid = async (payload: ICreateBid) => {
  const { data } = await Axios.post(
    routeGenerator("/tenderProductsBids"),
    payload
  );
  return data;
};

export const useCreateBid = () => {
  const queryClient = useQueryClient();
  return useMutation<TenderProductBid, ApiError, ICreateBid>(
    (variables: ICreateBid) => createBid(variables),
    {
      onSuccess: async (data, variables) => {
        const useActiveTenderProductBidsQueryKey = useActiveTenderProductBids.queryKey(
          variables.tenderId,
          variables.tenderProductId
        );

        queryClient.setQueryData<TenderProductBid[]>(
          useActiveTenderProductBidsQueryKey,
          (old) => [data, ...(old || [])]
        );

        queryClient.setQueryData(
          [
            "active",
            "tenderProductBid",
            variables.tenderUserId,
            variables.tenderProductId,
          ],
          () => data
        );
        const useMyBidsQueryKey = useMyBids.queryKey(variables.tenderId);
        queryClient.invalidateQueries(useMyBidsQueryKey);
      },
    }
  );
};

type UseCancelBidPayload = {
  tenderProductBidId: string;
  tenderUserId: string;
  tenderProductId: string;
  tenderId: string;
};

const cancelBid = async (payload: UseCancelBidPayload) => {
  const { data } = await Axios.put(
    routeGenerator("/tenderProductsBids/cancel"),
    payload
  );
  return data;
};

export const useCancelBid = () => {
  const queryClient = useQueryClient();
  return useMutation<undefined, ApiError, UseCancelBidPayload>(
    (variables) => cancelBid(variables),
    {
      onSuccess: async (data, variables) => {
        queryClient.setQueryData(
          [
            "active",
            "tenderProductBid",
            variables.tenderUserId,
            variables.tenderProductId,
          ],
          () => undefined
        );
        const useMyBidsQueryKey = useMyBids.queryKey(variables.tenderId);
        queryClient.invalidateQueries(useMyBidsQueryKey);
      },
    }
  );
};
