import styled from "styled-components";

export const StyledTenderProductCertificate = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(167, 161, 161, 0.5);

  .tender-product__certificate__inner {
    padding: 1rem;
    height: 90%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
    margin-top: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .tender-product__certificate__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .tender-product__certificate__gia {
    width: 150px;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  .tender-product__certificate__top__left {
    display: flex;
    align-items: center;
  }

  .tender-product__certificate__close {
    width: 38px;
    height: 38px;
    border-radius: 6px;
    background-color: #eaeef2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tender-product__certificate__action {
    height: 38px;
    padding: 12px 16px;
    border-radius: 6px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    transition: all 0.3s ease-in;
  }

  .tender-product__certificate__action:hover {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.28);
  }

  .tender-product__certificate__action__text {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.65px;
    color: #687c97;
  }

  .tender-product__certificate__action__icon {
    margin-right: 8px;
  }

  .tender-product__certificate__wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    column-gap: 2.5rem;
    overflow: hidden;
  }

  .tender-product__certificate__left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }

  .tender-product__certificate__right {
    display: block;
    height: 100%;
    overflow: hidden;
  }

  .tender-product__certificate__right > div {
    height: 50%;
    width: 100%;
    overflow: hidden;
  }

  .tender-product__certificate__section__title {
    width: 100%;
    height: 30px;
    background-color: rgba(167, 149, 114, 0.9);
    display: flex;
    align-items: center;
    padding-left: 8px;

    font-family: var(--archivo);
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.26px;
    color: #fff;
  }

  .tender-product__certificate__table__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30px;
  }

  .tender-product__certificate__table__row:nth-child(even) {
    background-color: #f5f7f9;
  }

  .tender-product__certificate__table__cell__label,
  .tender-product__certificate__table__cell__value {
    display: flex;
    align-items: center;
    padding-left: 8px;
  }

  .tender-product__certificate__table__cell__label {
    font-family: var(--archivo);
    font-size: 17px;
    letter-spacing: 0.26px;
    color: #687c97;
  }

  .tender-product__certificate__table__cell__value {
    font-family: var(--archivo);
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.26px;
    color: #687c97;
    overflow-x: hidden;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.my-node-enter {
    opacity: 0;
    transform: translateY(90%);
    background-color: transparent;
  }
  &.my-node-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 400ms, transform 400ms;
    background-color: transparent;
  }
  &.my-node-exit {
    opacity: 1;

    transform: translateY(0);
    background-color: transparent;
  }
  &.my-node-exit-active {
    opacity: 0;
    transition: opacity 400ms, transform 400ms;
    transform: translateY(90%);
    background-color: transparent;
  }

  .tender-product__certificate__loading__text {
    color: rgba(0, 0, 0, 0.5);
  }

  .tender-product__certificate__comments {
    font-family: var(--archivo);
    font-size: 17px;
    letter-spacing: 0.26px;
    color: #687c97;
    margin-top: 1rem;
    margin: 1rem 0 0 8px;
  }

  .tender-product__certificate__proportions {
    height: calc(100% - 30px);
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tender-product__certificate__proportions img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .tender-product__certificate--small .tender-product__certificate__wrapper {
    grid-template-columns: 1fr;
    overflow: auto;
    row-gap: 1rem;
  }

  .tender-product__certificate--small .tender-product__certificate__left {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  .tender-product__certificate--small .tender-product__certificate__right {
    overflow: unset;
    height: 500px;
  }

  .tender-product__certificate--small
    .tender-product__certificate__right
    > div {
    height: 100%;
  }

  .tender-product__certificate--small
    .tender-product__certificate__right
    .tender-product__certificate__proportions
    img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .tender-product__certificate__table__cell__label,
    .tender-product__certificate__table__cell__value {
      span {
        font-size: 14px;
      }
    }
    .tender-product__certificate__comments {
      font-size: 14px;
    }
  }
`;
