import React from "react";
import * as RadixAccordion from "@radix-ui/react-accordion";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
type Props = { children?: React.ReactNode } & (
  | RadixAccordion.AccordionSingleProps
  | RadixAccordion.AccordionMultipleProps
) &
  React.RefAttributes<HTMLDivElement>;

const StyledAccordion = styled.div`
  & * {
    outline-style: none;
  }

  .ui-accordion__item {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    border-radius: 6px;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 4.875rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    outline-style: none;

    &:focus-within {
      box-shadow: 0 5px 30px 0 rgb(192 203 209 / 28%);
      border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
      outline-style: none;
      outline: none;
    }

    button {
      font-family: ${({ theme }) => theme.fonts.archivo};
      font-size: 1rem;
      color: #687c97;
      padding: 1.5rem 1.5rem;
      transition: color 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      overflow-y: hidden;
    }
  }

  .ui-accordion__arrow {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ui-accordion__item[data-state="open"] {
    max-height: unset;
    /* box-shadow: 0 5px 30px 0 rgb(192 203 209 / 28%); */

    button {
      font-family: ${({ theme }) => theme.fonts.archivo};
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.neutral[700]};
      padding: 1.5rem 1.5rem;
      padding-bottom: 8px;
    }

    .ui-accordion__arrow {
      transform: rotate(180deg);
    }
  }

  .ui-accordion__content {
    padding: 0 1.5rem 1.5rem 1.5rem;
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: 1rem;
    color: #222b45;
    line-height: 1.53;
    overflow: hidden;
    max-width: 1010px;
  }
`;

const Accordion = ({ children, ...rest }: Props) => {
  return (
    <StyledAccordion>
      <RadixAccordion.Root {...rest}>{children}</RadixAccordion.Root>
    </StyledAccordion>
  );
};

const AccordionArrow = () => {
  return (
    <div className="ui-accordion__arrow" aria-hidden>
      <ChevronDown />
    </div>
  );
};

Accordion.Item = RadixAccordion.Item;
Accordion.Trigger = RadixAccordion.Trigger;
Accordion.Content = RadixAccordion.Content;
Accordion.Header = RadixAccordion.Header;
Accordion.Arrrow = AccordionArrow;
Accordion.Item.defaultProps = {
  className: "ui-accordion__item",
};

Accordion.Content.defaultProps = {
  className: "ui-accordion__content",
};

export default Accordion;
