import tradeImage from "./assets/trade-page.png";
import tradeFullImage from "./assets/trade-page-full.png";
import { StyledAbout } from "./About.styled";
const About = () => {
  const tradeReasons = [
    {
      header: "THE WORLD'S LARGEST DATABASE",
      text: "Connected directly to the world's largest listing website, Get-Diamonds, with 7.3B available stock by 4600 suppliers.",
    },
    {
      header: "DATA-DRIVEN DIAMOND SELECTION SYSTEM",
      text: "An industry-unique AI algorithm that accurately pins down the industry's most competitive and sought-after diamonds by utilizing data from the GET-Diamonds database.",
    },
    {
      header: "GUARANTEED END-TO-END TRANSACTION BY HENNIG",
      text: "A seamless transaction process including stone verification, payment, and shipping, all managed from top to bottom by our 130-year industry leader Hennig, thus ensuring complete security, integrity, and trust.",
    },
    {
      header: "MAXIMUM EXPOSURE AND EXTENDED REACH TO ALL-SIZE BUSINESSES",
      text: "Our advanced features and rapid growth have helped us create a platform that is connected to thousands of potential clients with no restriction to business size, simply put, a digital presence on the Trade-Diamonds will help any business to be seen, heard, sell more, and reach new audiences.",
    },
  ];

  return (
    <StyledAbout>
      <div className="about-trade">
        <h1>
          Introducing the first-ever data-driven <br /> online tenders platform
        </h1>
        <p className="description">
          For the first time ever, suppliers of all sizes will have the
          opportunity to buy and sell diamonds through targeted sales events,
          previously available only to large suppliers. The new end-to-end
          online tenders service is designed to pinpoint the most sought-after
          and competitively priced diamonds, allowing businesses of any size to
          find growth opportunities via a digital trading environment.
        </p>
        <div className="main-content">
          <div className="start">
            <div className="content">
              <h4>What makes it so great?</h4>
              <div className="reasons-wrapper">
                <div className="reasons">
                  {tradeReasons.map((reason, idx) => (
                    <TradeReason
                      key={idx}
                      header={reason.header}
                      text={reason.text}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="end">
            <img
              src={window.innerWidth < 1600 ? tradeImage : tradeFullImage}
              alt="trade-diamonds"
              className="site-image"
            />
            <img
              src={window.innerWidth < 1600 ? tradeImage : tradeFullImage}
              alt="trade-diamonds"
              style={{ opacity: "0" }}
            />
          </div>
        </div>
      </div>
    </StyledAbout>
  );
};
const TradeReason = ({ header, text }: { header: any; text: any }) => {
  return (
    <div className="trade-reason">
      <div className="reason-ui">
        <div className="circle"></div>
      </div>
      <div className="content">
        <h5>{header}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default About;
