import { useEffect, useState } from "react";
import moment from "moment";

interface Props {
  startDate: string;
  endDate: string;
}

const getPercentage = ({ startDate, endDate }: Props) => {
  const start = startDate;
  const end = endDate;
  const data = new Date();

  const mStart = moment(start);
  const mEnd = moment(end);
  const mData = moment(data);

  return Math.round(
    (100.0 * mData.diff(mStart, "seconds")) / mEnd.diff(mStart, "seconds")
  );
};

const useDatesPercentage = ({ startDate, endDate }: Props) => {
  const [percentage, setPercentage] = useState(
    getPercentage({ startDate, endDate })
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newPer = getPercentage({ startDate, endDate });

      setPercentage(newPer > 100 ? 100 : newPer < 0 ? 0 : newPer);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  return { percentage };
};

export default useDatesPercentage;
