import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";
import { useWishlist } from "./queries";

const removeFromWishlist = async (payload: {
  tenderId: string;
  tenderProductId: string;
}) => {
  const { data } = await Axios.delete(
    routeGenerator(
      `/tenderUsers/${payload.tenderId}/saved/products/${payload.tenderProductId}/remove`
    )
  );
  return data;
};

export const useRemoveFromWishlist = () => {
  const queryClient = useQueryClient();
  const wishlistQueryKey = useWishlist.queryKey;
  return useMutation<
    null,
    ApiError,
    { tenderId: string; tenderProductId: string }
  >(["tenders", "wishlist", "remove"], removeFromWishlist, {
    onMutate: async ({ tenderProductId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(wishlistQueryKey);
      // Snapshot the previous value
      const previousWishlist =
        queryClient.getQueryData<TenderProduct[]>(wishlistQueryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<typeof previousWishlist>(
        wishlistQueryKey,
        (old) =>
          old
            ? [
                ...(old ?? []).filter(
                  (tenderProduct) => tenderProduct._id !== tenderProductId
                ),
              ]
            : undefined
      );
      // Return a context object with the snapshotted value
      return { previousWishlist };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, _, context) => {
      queryClient.setQueryData(
        wishlistQueryKey,
        (context as any)?.previousWishlist
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(wishlistQueryKey);
    },
  });
};

const clearWishlist = async () => {
  const { data } = await Axios.post(
    routeGenerator(`/tenderUsers/wishlist/clean`)
  );
  return data;
};

export const useClearWishlist = () => {
  const queryClient = useQueryClient();
  const wishlistQueryKey = useWishlist.queryKey;
  return useMutation<null, ApiError>(
    ["tenders", "wishlist", "remove"],
    clearWishlist,
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(wishlistQueryKey);
        // Snapshot the previous value
        const previousWishlist =
          queryClient.getQueryData<TenderProduct[]>(wishlistQueryKey);

        // Optimistically update to the new value
        queryClient.setQueryData<typeof previousWishlist>(
          wishlistQueryKey,
          () => []
        );
        // Return a context object with the snapshotted value
        return { previousWishlist };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _, context) => {
        queryClient.setQueryData(
          wishlistQueryKey,
          (context as any)?.previousWishlist
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(wishlistQueryKey);
      },
    }
  );
};
