import { routeGenerator } from "api";
import { tender, createArray } from "../../faker";
import { axios as Axios } from "lib/axios";
import { useQuery } from "react-query";

const getTender = async (id: string) => {
  if (!id) return null;
  const { data } = await Axios.get(routeGenerator(`/tenders/${id}`));
  return data;
};

export interface IUseTender {
  diamonds: number;
  bids: number;
  activeUsers: number;
  tender: Tender;
}

export const useTender = (id: string) =>
  useQuery<IUseTender, ApiError>(["tender", id], () => getTender(id));

export const useTenderRegister = (id: string) => {
  return useQuery<IUseTender, ApiError>(
    ["tenderregister", id],
    () => getTender(id),
    {
      placeholderData: {
        activeUsers: 10,
        bids: 10,
        diamonds: 100,
        tender,
      },
    }
  );
};

const getTendersOverview = async () => {
  const { data } = await Axios.get(routeGenerator(`/tenders/overview`));
  return data;
};

export interface IUseTendersOverview {
  liveTenders: Tender[];
  upcomingTenderProducts: string;
  upcomingTenders: Tender[];
  nextTenders: Tender[];
  tendersResults: Tender[];
}

const useTendersOverviewPlaceholder = {
  liveTenders: createArray(tender, 1) as Tender[],
  upcomingTenderProducts: "1",
  upcomingTenders: createArray(tender, 2) as Tender[],
  nextTenders: createArray(tender, 2) as Tender[],
  tendersResults: createArray(tender, 1) as Tender[],
};
export const useTendersOverview = () =>
  useQuery<IUseTendersOverview, ApiError>(
    "/tenders/overview",
    getTendersOverview,
    {
      placeholderData: useTendersOverviewPlaceholder,
      refetchInterval: 5 * 1000,
    }
  );

const getTendersHotDiamonds = async () => {
  const { data } = await Axios.get(
    routeGenerator("/tenderProducts/tender/hot")
  );
  return data;
};

export type IUseTendersHotDiamonds = TenderProduct[];

export const useTendersHotDiamonds = () =>
  useQuery<IUseTendersHotDiamonds, ApiError>(["/tenders/hot"], () =>
    getTendersHotDiamonds()
  );

type UseTenderLastActivity = TenderProductBid[];

const getTenderLastActivity = async (id: string) => {
  const { data } = await Axios.get(routeGenerator(`/tenders/activity/${id}`));
  return data;
};

export const useTenderLastActivity = (id: string) =>
  useQuery<UseTenderLastActivity, ApiError>(
    ["/tender/activity"],
    () => getTenderLastActivity(id),
    { refetchInterval: 30 * 1000 }
  );

type UseLiveTenders = Tender[];

const getLiveTenders = async () => {
  const { data } = await Axios.get(routeGenerator(`/tenders/live`));
  return data;
};

export const useLiveTenders = () =>
  useQuery<UseLiveTenders, ApiError>(["/tenders/live"], () => getLiveTenders());

const getTenderActiveUsers = async (tenderId: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderUsers/active/${tenderId}`)
  );
  return data;
};

export const useTenderActiveUsers = (tenderId: string) => {
  return useQuery<{ amount: number }, ApiError, { amount: number }>(
    ["/usersactiveusers", tenderId],
    async () => getTenderActiveUsers(tenderId)
  );
};

const getTenderAllBids = async (tenderId: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderProductsBids/count/${tenderId}`)
  );
  return data;
};

export const useTenderAllBids = (tenderId: string) => {
  return useQuery<{ amount: number }, ApiError, { amount: number }>(
    ["tender", "allbids", tenderId],
    async () => getTenderAllBids(tenderId),
    { refetchInterval: 30 * 1000 }
  );
};

type UseFinishedTenders = Tender[];

const getFinishedTenders = async () => {
  const { data } = await Axios.get(routeGenerator(`/tenders/results`));
  return data.tendersResults;
};

const UseFinishedTendersPlaceholder = createArray(tender, 1);

export const useFinishedTenders = () =>
  useQuery<UseFinishedTenders, ApiError>(
    ["/tenders/results"],
    () => getFinishedTenders(),
    {
      placeholderData: UseFinishedTendersPlaceholder,
      refetchInterval: 5 * 1000,
    }
  );
