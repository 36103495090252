import styled from "styled-components";

export const StyledFinishedTenderCard = styled.div`
  border-radius: 0.9375rem;
  border: solid 0.0625rem ${({ theme }) => theme.colors.neutral[200]};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing[24]};
  ${({ theme }) => theme.spacing[16]};

  .c-finishedTenderCard__main {
    display: flex;
    align-items: center;
  }

  .c-finishedTenderCard__details {
    flex: 1;
    overflow: hidden;
    margin-right: 1rem;
  }

  .c-finishedTenderCard__name {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[16]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    display: inline-block;
  }

  .c-finishedTenderCard__name span {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[16]};
    color: ${({ theme }) => theme.colors.neutral[700]};
    margin-right: 0.25rem;
    font-weight: 600;
  }

  .c-finishedTenderCard__filters {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    text-transform: uppercase;
  }

  .c-finishedTenderCard__filters > div {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  .c-finishedTenderCard__time {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[16]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin-top: 1rem;
  }

  .c-finishedTenderCard__time strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[700]};
  }

  .c-finishedTenderCard__progress__container {
    flex: 2;
  }

  .c-finishedTenderCard__progress__title {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[16]};
    font-weight: 600;
    letter-spacing: 0.0469rem;
    margin-bottom: ${({ theme }) => theme.spacing[16]};
    color: ${({ theme }) => theme.colors.neutral[700]};
  }

  .c-finishedTenderCard__progress {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .c-finishedTender__progress__step__title {
    font-family: var(--archivo);
    font-size: 0.75rem;
    font-weight: 600;
    color: #687c97;
  }

  .c-finishedTender__progress__step__subtitle {
    font-family: var(--archivo);
    font-size: 0.75rem;
    color: #687c97;
    margin-top: 0.125rem;
    max-width: 80%;
  }

  .c-finishedTender__progress__step__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.375rem;
  }

  .c-finishedTender__progress__step__number {
    padding: 0.25rem 0.75rem;
    border-radius: 1.875rem;
    background-color: #32dea5;
    font-family: var(--archivo);
    font-size: 0.8438rem;
    font-weight: 500;
    letter-spacing: 0.0425rem;
    color: #fff;
  }

  .c-finishedTender__progress__step__line {
    flex: 1;
    border: none;
    border-top: 0.125rem dotted rgba(0, 0, 0, 0.2);
    color: #fff;
    background-color: #fff;
    height: 0.0625rem;
    margin: 0 0.625rem;
  }

  div.c-finishedTenderCard__progress__container
    .c-finishedTenderCard__progress
    > .c-finishedTender__progress__step:nth-child(3)
    .c-finishedTender__progress__step__line {
    display: none;
  }

  div.c-finishedTenderCard__progress__container
    .c-finishedTenderCard__progress
    > .c-finishedTender__progress__step:nth-child(2),
  div.c-finishedTenderCard__progress__container
    .c-finishedTenderCard__progress
    > .c-finishedTender__progress__step:nth-child(3) {
    /* opacity: 0.5; */
  }

  div.c-finishedTenderCard__progress__container
    .c-finishedTenderCard__progress
    > .c-finishedTender__progress__step:nth-child(2)
    .c-finishedTender__progress__step__number,
  div.c-finishedTenderCard__progress__container
    .c-finishedTenderCard__progress
    > .c-finishedTender__progress__step:nth-child(3)
    .c-finishedTender__progress__step__number {
    background-color: ${({ theme }) => theme.colors.neutral[200]};
  }

  .c-finishedTenderCard__bids__title {
    font-family: var(--archivo);
    font-size: 1rem;
    font-weight: 600;
    color: #222b45;
  }

  .c-finishedTenderCard__bids {
    margin-top: 1.5rem;
    max-height: 25rem;
    overflow: auto;
  }

  .c-finishedTenderCard__bid {
    border-radius: 0.6875rem;
    border: solid 0.0625rem #e8ebf2;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem;
    cursor: pointer;
  }

  .c-finishedTenderCard__bid__price,
  .c-finishedTenderCard__bid__status {
    border-left: solid 0.0938rem #e8ebf2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
  }

  .c-finishedTenderCard__bid__main {
    flex: 1;
  }

  .c-finishedTenderCard__bid__photo {
    width: 6.25rem !important;
    height: 4.0625rem;
  }

  .c-finishedTenderCard__bid__title {
    font-family: var(--archivo);
    font-size: 1rem;
    color: #687c97;
    margin-bottom: 0.625rem;
  }

  .c-finishedTenderCard__bid__price__title,
  .c-finishedTenderCard__bid__status__title {
    font-family: var(--archivo);
    font-size: 0.7813rem;
    font-weight: 500;
    letter-spacing: 0.0313rem;
    color: #687c97;
    margin-bottom: 0.375rem;
  }

  .c-finishedTenderCard__bid__status__value {
    padding: 0.25rem 0.75rem;
    border-radius: 1.875rem;
    background-color: #f5a000;
    text-align: center;
    font-family: var(--archivo);
    font-size: 0.8438rem;
    font-weight: 500;
    letter-spacing: 0.0425rem;
    color: #fff;
    width: fit-content;
  }

  .c-finishedTenderCard__seperator {
    position: relative;
  }

  .c-finishedTenderCard__seperator::after {
    position: absolute;
    content: "";
    width: 0.0625rem;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.neutral[200]};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .c-finishedTenderCard__bid--mobile {
    background: #ffffff;
    border: 1px solid #d1d7e0;
    box-sizing: border-box;
    min-height: 100px;
    margin: 1.25rem 0;
    border-radius: 0.625rem;
    padding: 1rem;

    .top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaeef2;
      padding-bottom: 1rem;
    }

    .image {
      width: 4.375rem;
      height: 4.375rem;
    }

    .id {
      font-family: ${({ theme }) => theme.fonts.archivo};
      font-size: 1rem;
      color: #687c97;
      margin-left: 12px;
      span {
        display: block;
        font-weight: bold;
        margin-top: 4px;
      }
    }

    .properties {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 1px solid #eaeef2;
      padding: 1rem 0;
      margin-top: -8px;
      & > div {
        margin-right: 8px;
        margin-top: 8px;
      }
    }

    .details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding-top: 1rem;
      .bid {
        display: flex;
        align-items: center;
        justify-content: center;

        .bid__text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: rgba(104, 124, 151, 1);
          margin-right: 8px;
        }

        .ui-price {
          font-size: 16px;
        }
      }

      .status {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: rgba(104, 124, 151, 1);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid rgba(104, 124, 151, 0.75);

        .status__text {
          margin-right: 8px;
        }

        .status__bubble {
          height: 24px;
          background: #fab738;
          display: flex;
          align-items: center;
          padding: 0 10px;
          border-radius: 30px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    & {
      padding: var(--sectionPadding);
    }

    .c-finishedTenderCard__main {
      display: block;
    }

    .c-finishedTenderCard__progress {
      display: block;
    }

    .c-finishedTender__progress__step__line {
      display: none;
    }

    .c-finishedTender__progress__step {
      display: flex;
      align-items: flex-start;
    }

    .c-finishedTender__progress__step__top {
      margin-right: 0.75rem;
    }

    .c-finishedTender__progress__step__subtitle {
      max-width: 100%;
    }

    .c-finishedTender__progress__step {
      margin-bottom: 2.5rem;
      position: relative;
    }

    .c-finishedTender__progress__step::before {
      content: "";
      position: absolute;
      left: 0.9375rem;
      top: 1.875rem;
      height: 1.875rem;
      width: 0.125rem;
      border-left: 0.125rem dotted ${({ theme }) => theme.colors.neutral[700]};
      opacity: 0.35;
    }

    .c-finishedTender__progress__step:last-of-type {
      margin-bottom: 0;
    }

    .c-finishedTender__progress__step:last-of-type::before {
      display: none;
    }

    .c-finishedTenderCard__progress__container {
      border-top: 0.0625rem solid ${({ theme }) => theme.colors.neutral[300]};
    }

    .c-finishedTenderCard__progress__title {
      margin: 1rem 0;
      font-size: 1rem;
    }

    .c-finishedTenderCard__time {
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .c-finishedTenderCard__name {
      font-size: 1rem;
    }

    .c-finishedTenderCard__bids {
      max-height: unset;
      overflow: unset;

      .c-finishedTenderCard__bid--mobile:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
