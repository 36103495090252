import { useRegisterContext } from "../Register";
import FormFields, { TFormFields } from "../FormFields";
import FormStep from "./FormStep";
import { userSchema } from "../validation";
import { asyncCatch } from "utils";
import { useCheckEmail } from "features/users/mutations";
import { useEffect } from "react";
import { useAnalytics } from "hooks/useAnalytics";

const StepTwo = () => {
  const track = useAnalytics();
  const { formik } = useRegisterContext();
  const { mutateAsync, isLoading } = useCheckEmail();
  const fields: TFormFields = [
    {
      label: "First Name*",
      name: "user.firstName",
      id: "user.firstName",
      placeholder: "First Name",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.user.firstName,
      isError:
        !!formik.errors?.user?.firstName && !!formik.touched?.user?.firstName,
      error: formik.errors?.user?.firstName,
    },
    {
      label: "Last Name*",
      name: "user.lastName",
      id: "user.lastName",
      placeholder: "Last Name",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.user.lastName,
      isError:
        !!formik.errors?.user?.lastName && !!formik.touched?.user?.lastName,
      error: formik.errors?.user?.lastName,
    },
    {
      label: "Email*",
      name: "user.email",
      id: "user.email",
      type: "email",
      placeholder: "Email",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.user.email,
      isError: !!formik.errors?.user?.email && !!formik.touched?.user?.email,
      error: formik.errors?.user?.email,
    },
    {
      label: "Phone Number*",
      name: "phoneNumber",
      id: "phoneNumber",
      placeholder: "Phone Number",
      fieldType: "phone",
      value: formik.values.user.phoneNumber,
      onChange: (value) => formik.setFieldValue("user.phoneNumber", value),
      onBlur: () => formik.setFieldTouched("user.phoneNumber", true),
      isError:
        !!formik.errors?.user?.phoneNumber &&
        !!formik.touched?.user?.phoneNumber,
      enableSearch: true,
      error: formik.errors?.user?.phoneNumber,
    },
    {
      label: "Password*",
      name: "user.password",
      id: "user.password",
      type: "password",
      placeholder: "Password",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.user.password,
      isError:
        !!formik.errors?.user?.password && !!formik.touched?.user?.password,
      error: formik.errors?.user?.password,
    },
    {
      label: "Confirm Password*",
      name: "user.confirmPassword",
      id: "user.confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.user.confirmPassword,
      isError:
        !!formik.errors?.user?.confirmPassword &&
        !!formik.touched?.user?.confirmPassword,
      error: formik.errors?.user?.confirmPassword,
    },
  ];

  const onNext = async () => {
    if (userSchema.isValidSync(formik.values.user)) {
      // validate email
      const [err] = await asyncCatch(mutateAsync(formik.values.user.email));
      if (err)
        return formik.setFieldError("user.email", "Email is already taken");
    }
    formik.handleSubmit();
  };

  useEffect(() => {
    track("Registration Step - Create An Account");
  }, [track]);

  return (
    <FormStep
      title="Create An Account"
      subtitle="Fill in this quick form to complete your registration."
      fields={
        <FormFields
          fieldsClassName="register__step__two__fields"
          fields={fields}
        />
      }
      onNext={onNext}
      nextProps={{
        disabled: isLoading,
        loading: isLoading,
      }}
    />
  );
};

export default StepTwo;
