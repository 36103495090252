import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Styles, SubHeaderTabs } from "./Consts";
import "./styles/_sub-header-user.css";

const SubHeaderUser = ({ tab }) => {
  const { search } = useLocation();
  const navLinkProps = {
    activeClassName: "active",
  };

  if (search) return null;

  return (
    <div
      className={
        SubHeaderTabs[tab]
          ? Styles.SUB_HEADER_USER_OPEN
          : Styles.SUB_HEADER_USER
      }
    >
      {SubHeaderTabs[tab]?.map((tab, idx) =>
        tab.url ? (
          <NavLink {...navLinkProps} to={tab.url} key={idx}>
            {tab.label}
          </NavLink>
        ) : (
          <button key={idx}>{tab.label}</button>
        )
      )}
    </div>
  );
};

export const MemoSubHeaderUser = React.memo(SubHeaderUser);
