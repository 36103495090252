import { useFormik } from "formik";
import Field from "ui/Field";
import Label from "ui/Label";
import { StyledResetPassword } from "./ResetPassword.styled";
import * as Yup from "yup";
import Input from "ui/Input";
import Button from "ui/Button";
import { useResetPassword } from "features/users/mutations";
import { asyncCatch } from "utils";
import Animate from "ui/Animate";
import { useParams } from "react-router-dom";
import GoBack from "ui/GoBack";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "Password is too short")
    .max(50, "Password is too long")
    .required("Password is required"),
  confirmNewPassword: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords do not match"),
});

function ForgotPassword() {
  const token = useParams<{ token: string }>().token;
  const {
    mutateAsync: resetPassword,
    isSuccess: isSuccessResetPassword,
    isLoading: isLoadingResetPassword,
    isError: isErrorResetPassword,
  } = useResetPassword();

  const formik = useFormik({
    validationSchema,
    initialValues: { newPassword: "", confirmNewPassword: "" },
    onSubmit: async (values) => {
      asyncCatch(
        resetPassword({
          ...values,
          token,
        })
      );
    },
  });

  const newPasswordIsError =
    !!formik.errors.newPassword && !!formik.touched.newPassword;
  const confirmNewPasswordIsError =
    !!formik.errors.confirmNewPassword && !!formik.touched.confirmNewPassword;

  return (
    <StyledResetPassword
      isSuccess={isSuccessResetPassword}
      isError={isErrorResetPassword}
    >
      <div className="reset__inner">
        <p className="reset__title">Create a New Password</p>
        <p className="reset__subtitle">
          Your new password must be different from previus used passwords.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div className="reset__fields">
            <Field>
              <Label>New Password</Label>
              <Input
                name="newPassword"
                id="newPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                placeholder="*******"
                error={formik.errors.newPassword}
                isError={newPasswordIsError}
              />
            </Field>
            <Field>
              <Label>Confirm Password</Label>
              <Input
                name="confirmNewPassword"
                id="confirmNewPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmNewPassword}
                placeholder="*******"
                error={formik.errors.confirmNewPassword}
                isError={confirmNewPasswordIsError}
              />
            </Field>
            <ResetState
              className="reset__state--mobile"
              isLoadingResetPassword={isLoadingResetPassword}
              isErrorResetPassword={isErrorResetPassword}
              isSuccessResetPassword={isSuccessResetPassword}
            />
          </div>
          <Button kind="purple" type="submit" loading={isLoadingResetPassword}>
            Reset
          </Button>
        </form>
        <ResetState
          isLoadingResetPassword={isLoadingResetPassword}
          isErrorResetPassword={isErrorResetPassword}
          isSuccessResetPassword={isSuccessResetPassword}
        />
      </div>
      <GoBack to="/login">Back to login page</GoBack>
    </StyledResetPassword>
  );
}

const ResetState = ({
  isLoadingResetPassword,
  isErrorResetPassword,
  isSuccessResetPassword,
  className = "",
}: {
  isLoadingResetPassword?: boolean;
  isErrorResetPassword?: boolean;
  isSuccessResetPassword?: boolean;
  className?: string;
}) => {
  return (
    <div className={["reset__state", className].join(" ")}>
      {(() => {
        if (isLoadingResetPassword) return null;
        if (isErrorResetPassword)
          return (
            <Animate visible={isErrorResetPassword}>
              <p className="reset__password__state reset__password__state--error">
                Could not reset your password, please try again later
              </p>
            </Animate>
          );
        if (isSuccessResetPassword)
          return (
            <Animate visible={isSuccessResetPassword}>
              <p className="reset__password__state reset__password__state--success">
                Password changed successfully
              </p>
            </Animate>
          );
      })()}
    </div>
  );
};

export default ForgotPassword;
