import styled from "styled-components";

export const StyledUserDetails = styled.div`
  max-width: 550px;
  width: 100%;
  border-radius: 15px;
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  padding: 2rem;
  position: relative;

  .user-details__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .user-details__box {
    border-left: 1.5px solid rgba(104, 124, 151, 0.25);
    padding-left: 8px;
  }

  .user-details__label {
    font-family: var(--archivo);
    font-size: 12.5px;
    letter-spacing: 0.58px;
    color: #687c97;
  }

  .user-details__value {
    font-family: var(--archivo);
    font-size: 19px;
    color: #687c97;
    margin-top: 8px;
  }

  .user-details__company {
    font-family: var(--archivo);
    font-size: 18px;
    font-weight: 600;
    color: #222b45;
    padding-bottom: 1rem;
    border-bottom: 1.5px solid rgba(104, 124, 151, 0.25);
    margin-bottom: 1rem;
  }

  .user-details__user {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 100%;
    width: calc(100% - var(--sectionPadding) * 2);
    padding: var(--sectionPadding);

    .user-details__grid {
      grid-template-columns: 1fr;
    }

    .user-details__company {
      font-size: 16px;
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
    }

    .user-details__user {
      width: 48px;
      height: 48px;
    }
  }
`;
