import { routeGenerator } from "api";
import { createArray, tenderOrder, tenderProductOffer } from "../../faker";
import { axios as Axios } from "lib/axios";
import { useQuery } from "react-query";

const getSupplierDiamonds = async (status: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderProductsOffers?type=${status}`)
  );
  return data;
};

type UseSupplierDiamonds = TenderProductOffer[];

const useSupplierDiamondsPlaceholder = createArray(
  tenderProductOffer,
  5
) as UseSupplierDiamonds;

export const useSupplierDiamonds = (
  status: "pending" | "accepted" | "rejected"
) =>
  useQuery<UseSupplierDiamonds, ApiError>(
    [`supplier`, status],
    () => getSupplierDiamonds(status),
    {
      placeholderData: useSupplierDiamondsPlaceholder,
      staleTime: 3 * 60 * 1000,
    }
  );

export type OrderStatus = "all" | "accepted" | "pending";

const getSupllierOrders = async (status: OrderStatus) => {
  const { data } = await Axios.get(
    routeGenerator(
      `/tenderOrders${status === "all" ? "" : `?status=${status}`}`
    )
  );
  return data;
};

type IUseSupplierOrders = TenderOrder[];

const useSupplierOrdersPlaceholder = createArray(
  tenderOrder,
  3
) as TenderOrder[];

export const useSupplierOrders = (status: OrderStatus) => {
  return useQuery<IUseSupplierOrders, ApiError>(
    ["supplier/orders", status],
    () => getSupllierOrders(status),
    { placeholderData: useSupplierOrdersPlaceholder, staleTime: 60 * 60 * 1000 }
  );
};
