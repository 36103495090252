import { ReactComponent as Help } from "assets/icons/help-circle.svg";
import { ReactComponent as Layers } from "assets/icons/layers.svg";
import { ReactComponent as User } from "assets/icons/user.svg";
import {
  useBuyerOrdersOverview,
  useSupplierOrdersOverview,
} from "features/orders/queries";
import { useSupplierDiamonds } from "features/supplier/queries";
import { useRegisterToTender } from "features/tenders/mutations";
import { useTendersOverview } from "features/tenders/queries";
import {
  useAllUserRegisteredTenders,
  useIsUserSupplier,
  useUser,
} from "features/users/queries";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { asyncCatch } from "utils";
import { DrawerContainer } from "./Drawer.styled";
import { useAnalytics } from "hooks/useAnalytics";
import Loader from "ui/Loader";
import { useAppSelector } from "hooks";
import useUiActions from "hooks/useUiAction";
import useIsAmlVerified from "hooks/useIsAmlVerified";
import { useLogout } from "features/users/mutations";
import { GD_CLIENT_URL } from "config";

const Drawer = () => {
  const isAmlVerified = useIsAmlVerified();
  const drawerIsOpen = useAppSelector((state) => state.ui.drawerIsOpen);
  const { toggleDrawer } = useUiActions();
  const track = useAnalytics();
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: user } = useUser();
  const { isSupplier } = useIsUserSupplier(user);
  const { logout } = useLogout();
  const { data: registeredTenders } = useAllUserRegisteredTenders();
  const { mutateAsync: registerToTender } = useRegisterToTender();
  const {
    data: pendingDiamonds,
    isFetching: isFetchingSupplierPendingDiamonds,
  } = useSupplierDiamonds("pending");
  const { data: pendingOrders, isFetching: isFetchingSupplierOrders } =
    useSupplierOrdersOverview();

  const { data: tendersOverview } = useTendersOverview();

  const { data: buyerOrders, isFetching: isFetchingBuyerOrders } =
    useBuyerOrdersOverview();

  const onTenderEnter = async () => {
    toggleDrawer(false);
    if (
      !tendersOverview?.liveTenders ||
      !(tendersOverview.liveTenders?.length > 0)
    )
      return;
    const isRegistered = useAllUserRegisteredTenders.isRegistered(
      tendersOverview.liveTenders[0]._id,
      registeredTenders || []
    );
    if (!isRegistered) {
      const [err] = await asyncCatch(
        registerToTender(tendersOverview.liveTenders[0]._id)
      );
      if (err)
        return toast.error("Could not enter to event, please try again later");
    }
    track("Menu - Active Tender Page Press", {
      tenderId: tendersOverview.liveTenders[0]._id,
    });
    history.push(`/tender/${tendersOverview.liveTenders[0]._id}`);
  };

  const handleDrawerItemClick = (fn: Function) => () => {
    fn();
    toggleDrawer(false);
  };

  return (
    <DrawerContainer isOpen={drawerIsOpen}>
      <div className="drawer__main">
        <div className="drawer__item">
          <div className="drawer__item__top">
            <div className="drawer__item__icon">
              <Layers />
            </div>
            <p className="drawer__item__title">Lobby</p>
          </div>
          <div className="drawer__item__links">
            <NavLink
              to="/overview"
              className="drawer__item__link"
              activeClassName="drawer__item__link--active"
              onClick={handleDrawerItemClick(() =>
                track("Menu - Overview Page Press")
              )}
            >
              Overview
            </NavLink>
            {!tendersOverview?.liveTenders ||
            tendersOverview?.liveTenders.length === 0 ? (
              <p
                className="drawer__item__link"
                style={{ opacity: 0.5, cursor: "not-allowed" }}
              >
                Active Tender
              </p>
            ) : (
              <button
                onClick={onTenderEnter}
                disabled={!isAmlVerified}
                className={[
                  "drawer__item__link",
                  pathname.includes("/tender")
                    ? "drawer__item__link--active"
                    : "",
                ].join(" ")}
              >
                Active Tender
              </button>
            )}
          </div>
        </div>
        <div className="drawer__item">
          <div className="drawer__item__top">
            <div className="drawer__item__icon">
              <User />
            </div>
            <p className="drawer__item__title">Buy</p>
          </div>
          <div className="drawer__item__links">
            <NavLink
              to="/buyer/orders"
              className="drawer__item__link"
              activeClassName="drawer__item__link--active"
              onClick={handleDrawerItemClick(() =>
                track("Menu - My Purchases Page Press")
              )}
            >
              My Purchases
              <DrawerItemBubble
                detail={buyerOrders?.inProcessOrders || 0}
                loading={isFetchingBuyerOrders}
              />
            </NavLink>
          </div>
        </div>
        {isSupplier && (
          <div className="drawer__item">
            <div className="drawer__item__top">
              <div className="drawer__item__icon">
                <User />
              </div>
              <p className="drawer__item__title">Sell</p>
            </div>
            <div className="drawer__item__links">
              <NavLink
                to="/supplier/mydiamonds/pending"
                className="drawer__item__link"
                activeClassName="drawer__item__link--active"
                onClick={handleDrawerItemClick(() =>
                  track("Menu - My Diamonds Page Press")
                )}
              >
                My Diamonds
                <DrawerItemBubble
                  detail={
                    pendingDiamonds?.filter(
                      (tenderProductOffer) =>
                        new Date(tenderProductOffer.memoDate).getTime() >
                        new Date().getTime()
                    ).length || 0
                  }
                  loading={isFetchingSupplierPendingDiamonds}
                />
              </NavLink>
              <NavLink
                to="/supplier/orders/all"
                className="drawer__item__link"
                activeClassName="drawer__item__link--active"
                onClick={handleDrawerItemClick(() =>
                  track("Menu - My Orders Page Press")
                )}
              >
                My Orders
                <DrawerItemBubble
                  detail={pendingOrders?.inProcessOrders || 0}
                  loading={isFetchingSupplierOrders}
                />
              </NavLink>
            </div>
          </div>
        )}
        <div className="drawer__item">
          <div className="drawer__item__top">
            <div className="drawer__item__icon">
              <Help />
            </div>
            <p className="drawer__item__title">Help</p>
          </div>
          <div className="drawer__item__links">
            <a
              href={GD_CLIENT_URL + "/about-trade"}
              target="_parent"
              className="drawer__item__link"
              onClick={handleDrawerItemClick(() =>
                track("Menu - About Page Press")
              )}
            >
              About Us
            </a>
            <NavLink
              to="/faq"
              exact
              className="drawer__item__link"
              activeClassName="drawer__item__link--active"
              onClick={handleDrawerItemClick(() =>
                track("Menu - FAQ Page Press")
              )}
            >
              FAQ
            </NavLink>
            <a
              href={GD_CLIENT_URL + "/support"}
              className="drawer__item__link"
              onClick={handleDrawerItemClick(() =>
                track("Menu - Support Page Press")
              )}
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>
            <button
              className="drawer__item__link logout--mobile"
              onClick={() => {
                logout();
              }}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
      {/* <a
        href="https://www.get-diamonds.com/"
        target="_blank"
        rel="noreferrer"
        className="back__to__get"
        onClick={() => track("Menu - Back To Get")}
      >
        <ArrowLeftCircle width={20} />
        Back To Get-Diamonds
      </a> */}
    </DrawerContainer>
  );
};

const DrawerItemBubble = ({
  loading,
  detail,
}: {
  loading: boolean;
  detail: number;
}) => {
  if (!loading && !detail) return null;

  return (
    <div className="drawer__item__number">
      {(() => {
        if (loading)
          return (
            <Loader size={13} color="#fff" activeColor="rgba(45,26,118,1)" />
          );
        return detail;
      })()}
    </div>
  );
};

export default Drawer;
