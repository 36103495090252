import { ReactComponent as Search } from "assets/icons/search.svg";
import cn from "classnames";
// import Location from "components/Location";
import Price from "ui/Price";
import ProductImage from "components/ProductImage";
import Shimmer from "ui/Shimmer";
import Table from "ui/Table";
import { useTenderProducts } from "features/products/queries";
import { useTenderUser } from "features/users/queries";
import { useAppSelector } from "hooks";
import useModalActions from "hooks/useModalActions";
import { useEffect, useMemo } from "react";
import {
  Cell,
  Column,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { filterTenderProducts } from "../../util";
import styles from "./TenderProducts.module.css";
import FiltersSummary from "components/Filters/FiltersSummary";
import { useDispatch } from "react-redux";
import { onlySelectedFilter } from "features/filters/slice";
import {
  useSaveTenderProduct,
  useUnsaveTenderProduct,
} from "features/products/mutations";
import { ReactComponent as Wishlist } from "assets/icons/wishlist.svg";
import { ReactComponent as WishlistGreen } from "assets/icons/wishlist-green.svg";
import { useMyBids } from "features/bids/queries";
import { ReactComponent as Star } from "assets/icons/star.svg";
import Shine from "ui/Shimmer/Shine";
import {
  extractId,
  //  numberWithCommas
} from "utils";
import SwitchAnimation from "ui/SwitchAnimation";
import ExportCSV from "ui/ExportCSV";
import { useTender } from "features/tenders/queries";
import { useAnalytics } from "hooks/useAnalytics";
import TenderProductMobileCard from "components/TenderProductMobileCard";
// import { product } from "faker";

const exportHeaders = [
  {
    accessor: "product.diamondImage",
    label: "Image",
  },
  {
    accessor: "product.model",
    label: "Shape",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.carat",
    label: "Carat",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.color",
    label: "Color",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.clarity",
    label: "Clarity",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.cut",
    label: "Cut",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.polish",
    label: "Polish",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.symmetry",
    label: "Symmetry",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.fluorescenceIntensity",
    label: "Fluor.",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.lab",
    label: "Lab",
    format: (str: string) => str.toString().toUpperCase(),
  },
  {
    accessor: "product.country",
    label: "Location",
    format: (str: string) => str.toString().toUpperCase(),
  },
];

const TenderProducts = ({ tenderId }: { tenderId: string }) => {
  const track = useAnalytics();
  const dispatch = useDispatch();
  const { data: tender } = useTender(tenderId);
  const { data: tenderUser } = useTenderUser(extractId(tenderId));
  const {
    data: tenderProducts,
    isFetching,
    isFetched,
  } = useTenderProducts(tenderId);

  const filters = useAppSelector((state) => state.filters);
  const filteredTenderProducts = useMemo(
    () =>
      filterTenderProducts(tenderProducts || [], filters).filter(
        (tenderProduct) => {
          if (!filters.onlySelected || !tenderUser) return true;
          const savedTenderProducts =
            tenderUser.savedTenderProducts as string[];
          return savedTenderProducts.includes(tenderProduct._id);
        }
      ),
    [filters, tenderProducts, tenderUser]
  );

  useEffect(() => {
    if ((tenderUser?.savedTenderProducts || []).length === 0)
      dispatch(onlySelectedFilter(false));
  }, [tenderUser?.savedTenderProducts, dispatch]);

  if (!tenderProducts || !tender) return null;

  return (
    <Shimmer shimmer={isFetching && !isFetched}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.summary}>
            <Search className={styles.summaryIcon} />
            <p className={styles.summaryTitle}>
              <span className={cn("shine", styles.summaryNumber)}>
                {filteredTenderProducts.length}
              </span>{" "}
              matching diamonds were found
            </p>
          </div>
          <div className={styles.line}></div>
          <ExportCSV
            filename={tender.tender.name}
            data={filteredTenderProducts}
            headers={exportHeaders as any}
            onClick={() => track("Active Tender - Export Table")}
          >
            <p className={styles.exportText}>Export Items</p>
          </ExportCSV>
          <button
            className={cn(styles.selected, {
              [styles.selectedActive]: filters.onlySelected,
            })}
            onClick={() => dispatch(onlySelectedFilter(!filters.onlySelected))}
            disabled={(tenderUser?.savedTenderProducts?.length || 0) === 0}
          >
            <Star className={styles.selectedIcon} />
            <span>
              Wish List ({tenderUser?.savedTenderProducts?.length || 0})
            </span>
          </button>
        </div>
        <FiltersSummary className={styles.filtersSummary} />
        {window.innerWidth > 640 ? (
          <TenderProductsDesktop
            tenderProducts={filteredTenderProducts}
            tenderId={tender.tender._id}
            isWishlistActive={filters.onlySelected}
          />
        ) : (
          <TenderProductsMobile tenderProducts={filteredTenderProducts} />
        )}
      </div>
    </Shimmer>
  );
};

const TenderProductsDesktop = ({
  tenderProducts,
  tenderId,
  isWishlistActive,
}: {
  tenderProducts: TenderProduct[];
  tenderId: string;
  isWishlistActive?: boolean;
}) => {
  const { data: tenderUser } = useTenderUser(extractId(tenderId));
  const track = useAnalytics();
  const { openModal } = useModalActions();

  const columns = useMemo(
    (): Column<TenderProduct>[] => [
      {
        Header: "Image",
        Cell: ({ row }: Cell<TenderProduct>) => {
          return (
            <ProductImage
              className={cn(styles.productImage, "shine")}
              src={row.original.product.diamondImage}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Shape",
        accessor: (originalRow) => originalRow.product.model,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.model, "shine")}>
            {row.original.product.model}
          </p>
        ),
      },
      {
        Header: "Carat",
        accessor: (originalRow) => originalRow.product.carat,
        sortType: (a, b) => {
          if (a.original.product.carat === b.original.product.carat) {
            return a > b ? 1 : -1; // same length condition
          }
          return a.original.product.carat > b.original.product.carat ? 1 : -1; // comparing length of string
        },
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.carat, "shine")}>
            {row.original.product.carat}
          </p>
        ),
      },
      {
        Header: "Color",
        accessor: (originalRow) => originalRow.product.color,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.color, "shine")}>
            {row.original.product.color}
          </p>
        ),
      },
      {
        Header: "Clarity",
        accessor: (originalRow) => originalRow.product.clarity,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.clarity, "shine")}>
            {row.original.product.clarity}
          </p>
        ),
      },
      {
        Header: "Cut",
        accessor: (originalRow) => originalRow.product.cut,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.cut, "shine")}>{row.original.product.cut}</p>
        ),
      },
      {
        Header: "Polish",
        accessor: (originalRow) => originalRow.product.polish,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.polish, "shine")}>
            {row.original.product.polish}
          </p>
        ),
      },
      {
        Header: "Symmetry",
        accessor: (originalRow) => originalRow.product.symmetry,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.symmetry, "shine")}>
            {row.original.product.symmetry}
          </p>
        ),
      },
      {
        Header: "Fluor.",
        accessor: (originalRow) => originalRow.product.fluorescenceIntensity,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.fluor, "shine")}>
            {row.original.product.fluorescenceIntensity}
          </p>
        ),
      },
      {
        Header: "Lab",
        accessor: (originalRow) => originalRow.product.lab,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <p className={cn(styles.lab, "shine")}>{row.original.product.lab}</p>
        ),
      },
      {
        Header: "Location",
        accessor: (originalRow) => originalRow.product.location,
        Cell: ({ row }: Cell<TenderProduct>) => (
          <div className={cn(styles.location, "shine")}>
            {row.original.product.country ?? "-"}
          </div>
        ),
      },
      {
        Header: "My Bid",
        Cell: ({ row }: Cell<TenderProduct>) => {
          const { data } = useMyBids(row.original.tender as any);

          const getMyBid = () => {
            const bids = (data || []).filter(
              (tenderProductBid) =>
                tenderProductBid.tenderProduct._id === row.original._id
            );
            return bids.length === 0 ? (
              "-"
            ) : (
              <Shine>
                <Price
                  className="shine"
                  price={bids[bids.length - 1].price}
                  postfix="/ppc"
                />
              </Shine>
            );
          };

          return getMyBid();
        },
      },
      // {
      //   Header: "Asking Price",
      //   accessor: (originalRow) =>
      //     originalRow?.tenderProductOffer?.reservePrice,
      //   Cell: ({ row }: Cell<TenderProduct>) => (
      //     <div className={cn(styles.reservePrice, "shine")}>
      //       <Price price={row.original.tenderProductOffer.reservePrice} />{" "}
      //       <span className={cn(styles.reservePriceTotal)}>/ppc</span>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const selectedRowIds = useMemo(() => {
    const selected = {} as any;
    (tenderUser?.savedTenderProducts || []).forEach((id) => {
      selected[id as any] = true;
    });
    return selected;
  }, [tenderUser?.savedTenderProducts]) as any;

  const table = useTable<TenderProduct>(
    {
      columns,
      data: tenderProducts,
      getRowId: (row) => {
        return row._id;
      },
      autoResetPage: false,
      initialState: {
        selectedRowIds,
        sortBy: [{ id: "Asking Price", desc: false }],
        pageSize: 30,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Cell: ({ row }: { row: Row<TenderProduct> }) => {
            const { mutate: save, isLoading: isLoadingSave } =
              useSaveTenderProduct({
                tenderProduct: row.original,
              });
            const { mutate: unsave, isLoading: isLoadingUnsave } =
              useUnsaveTenderProduct({
                tenderProduct: row.original,
              });
            return (
              <Shine>
                <button
                  disabled={isLoadingSave || isLoadingUnsave}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (row.isSelected) {
                      track(
                        "Active Tender - Remove from Wishlist",
                        row.original
                      );
                      unsave();
                    } else {
                      track("Active Tender - Add to Wishlist", row.original);
                      save();
                    }
                  }}
                  className={styles.wishlist}
                >
                  <SwitchAnimation state={row.isSelected}>
                    <div>
                      {row.isSelected && <WishlistGreen />}
                      {!row.isSelected && <Wishlist />}
                    </div>
                  </SwitchAnimation>
                </button>
              </Shine>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const { gotoPage } = table;

  useEffect(() => {
    gotoPage(() => 0);
  }, [isWishlistActive, gotoPage]);

  return (
    <div className="flex flex-column flex-grow tender-products__switch">
      {tenderProducts.length > 0 && (
        <Table
          switchState={false}
          className={styles.table}
          table={table}
          onRowClick={(original) => {
            track("Active Tender - Diamond View", original);
            openModal({
              modalType: "TenderProduct",
              modalProps: { tenderProduct: original },
            });
          }}
        />
      )}
    </div>
  );
};

const TenderProductsMobile = ({
  tenderProducts,
}: {
  tenderProducts: TenderProduct[];
}) => {
  return (
    <div className="flex flex-column flex-grow tender-products__switch">
      {tenderProducts.map((tenderProduct) => (
        <TenderProductMobileCard
          key={tenderProduct._id}
          tenderProduct={tenderProduct}
        />
      ))}
    </div>
  );
};

export default TenderProducts;
