import { useRegisterContext } from "../Register";
import FormFields, { TFormFields } from "../FormFields";
import FormStep from "./FormStep";
import { businessTypes, countries, memberships } from "../countries";
import { businessSchema } from "../validation";
import { useCheckCompany } from "features/users/mutations";
// import { asyncCatch } from "utils";
import { useEffect } from "react";
import { useAnalytics } from "hooks/useAnalytics";

const StepTwo = () => {
  const track = useAnalytics();
  const { formik } = useRegisterContext();
  const {
    // mutateAsync,
    isLoading,
  } = useCheckCompany();
  const getMembershipOptions = (value: string) => {
    const international = memberships.filter(
      (membership) => membership.international === "1"
    );
    let country: { label: string; value: string }[] = [];
    if (value) {
      country = memberships.filter(
        (membership) => membership.country === value
      );
    }
    return [...country, ...international];
  };

  const fields: TFormFields = [
    {
      label: "Company Name*",
      name: "aml.business.companyName",
      id: "aml.business.companyName",
      placeholder: "Company Name",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyName,
      isError:
        !!formik.errors?.aml?.business?.companyName &&
        !!formik.touched.aml?.business?.companyName,
      error: formik.errors?.aml?.business?.companyName,
    },
    {
      label: "Address*",
      name: "aml.business.companyAddress",
      id: "aml.business.companyAddress",
      placeholder: "Address",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyAddress,
      isError:
        !!formik.errors?.aml?.business?.companyAddress &&
        !!formik.touched.aml?.business?.companyAddress,
      error: formik.errors?.aml?.business?.companyAddress,
    },
    {
      label: "City*",
      name: "aml.business.companyCity",
      id: "aml.business.companyCity",
      placeholder: "City",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyCity,
      isError:
        !!formik.errors?.aml?.business?.companyCity &&
        !!formik.touched.aml?.business?.companyCity,
      error: formik.errors?.aml?.business?.companyCity,
    },
    {
      label: "State",
      name: "aml.business.companyState",
      id: "aml.business.companyState",
      placeholder: "State",
      fieldType: "input",
      value: formik.values.aml.business.companyState,
      isError:
        !!formik.errors?.aml?.business?.companyState &&
        !!formik.touched.aml?.business?.companyState,
      error: formik.errors?.aml?.business?.companyState,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    },
    {
      label: "Zip Code*",
      name: "aml.business.companyZip",
      id: "aml.business.companyZip",
      placeholder: "Zip Code",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyZip,
      isError:
        !!formik.errors?.aml?.business?.companyZip &&
        !!formik.touched.aml?.business?.companyZip,
      error: formik.errors?.aml?.business?.companyZip,
    },
    {
      label: "Country*",
      name: "aml.business.companyCountry",
      id: "aml.business.companyCountry",
      placeholder: "Country",
      fieldType: "select",
      onChange: (value) =>
        formik.setFieldValue("aml.business.companyCountry", value),
      onBlur: () => formik.setFieldTouched("aml.business.companyCountry", true),
      value: formik.values.aml.business.companyCountry,
      options: countries,
      isError:
        !!formik.errors?.aml?.business?.companyCountry &&
        !!formik.touched.aml?.business?.companyCountry,
      error: formik.errors?.aml?.business?.companyCountry,
      isClearable: true,
    },
    {
      fieldType: "input",
      label: "Company Email*",
      name: "aml.business.companyEmailAddress",
      id: "aml.business.companyEmailAddress",
      placeholder: "email@gmail.com",
      type: "email",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyEmailAddress,
      isError:
        !!formik.errors?.aml?.business?.companyEmailAddress &&
        !!formik.touched.aml?.business?.companyEmailAddress,
      error: formik.errors?.aml?.business?.companyEmailAddress,
    },
    {
      fieldType: "input",
      label: "Date of Establishment",
      name: "aml.business.companyDate",
      id: "aml.business.companyDate",
      placeholder: "00.00.0000",
      type: "date",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.companyDate,
      isError:
        !!formik.errors?.aml?.business?.companyDate &&
        !!formik.touched.aml?.business?.companyDate,
      error: formik.errors?.aml?.business?.companyDate,
    },
    {
      fieldType: "select",
      label: "Type of Business*",
      name: "aml.business.businessType",
      id: "aml.business.businessType",
      placeholder: "Type",
      onChange: (value) =>
        formik.setFieldValue("aml.business.businessType", value),
      onBlur: () => formik.setFieldTouched("aml.business.businessType", true),
      value: formik.values.aml.business.businessType,
      options: businessTypes,
      isError:
        !!formik.errors?.aml?.business?.businessType &&
        !!formik.touched.aml?.business?.businessType,
      error: formik.errors?.aml?.business?.businessType,
      isClearable: true,
    },
    {
      fieldType: "input",
      label: "Company Identification Number",
      name: "aml.business.tradeNumber",
      id: "aml.business.tradeNumber",
      placeholder: "000000",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.tradeNumber,
      isError:
        !!formik.errors?.aml?.business?.tradeNumber &&
        !!formik.touched.aml?.business?.tradeNumber,
      error: formik.errors?.aml?.business?.tradeNumber,
    },
    {
      fieldType: "input",
      label: "Doing Business As(DBA)",
      name: "aml.business.dba",
      id: "aml.business.dba",
      placeholder: "Name",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.business.dba,
      isError:
        !!formik.errors?.aml?.business?.dba &&
        !!formik.touched.aml?.business?.dba,
      error: formik.errors?.aml?.business?.dba,
    },
    {
      fieldType: "select",
      label: "Trade Organiztion Membership",
      name: "aml.business.tradeMembership",
      placeholder: "Membership",
      onChange: (value) =>
        formik.setFieldValue("aml.business.tradeMembership", value),
      onBlur: () =>
        formik.setFieldTouched("aml.business.tradeMembership", true),
      value: formik.values.aml.business.tradeMembership,
      options: getMembershipOptions(
        formik.values.aml.business.companyCountry?.value || ""
      ),
      isClearable: true,
    },
  ];

  const onNext = async () => {
    if (businessSchema.isValidSync(formik.values.aml.business)) {
      // validate company name
      // const [err, data] = await asyncCatch(
      //   mutateAsync(formik.values.aml.business.companyName)
      // );
      // if (err || data)
      //   return formik.setFieldError(
      //     "aml.business.companyName",
      //     "Company name already taken"
      //   );
      formik.setFieldValue(
        "user.companyName",
        formik.values.aml.business.companyName
      );
    }
    formik.handleSubmit();
    // goToStep(3);
  };

  useEffect(() => {
    track("Registration Step - AML/KYC");
  }, [track]);

  return (
    <FormStep
      title="AML / KYC"
      subtitle="Fill in this quick form to complete your registration."
      fields={
        <FormFields
          fields={fields}
          fieldsClassName="register__step__three__fields"
        />
      }
      onNext={onNext}
      nextProps={{
        disabled: isLoading,
        loading: isLoading,
      }}
    />
  );
};

export default StepTwo;
