import { routeGenerator } from "../../api";
import { createArray, order } from "../../faker";
import { axios as Axios } from "../../lib/axios";
import { useQuery } from "react-query";

const getOrder = async (id: string) => {
  if (!id) return null;
  const { data } = await Axios.get(routeGenerator(`/orders/${id}`));
  return data;
};

const useOrderPlaceholder = order;

export const useOrder = (id: string) =>
  useQuery<Order, ApiError>(["order", id], () => getOrder(id), {
    placeholderData: useOrderPlaceholder,
  });

const getOrdersSummary = async () => {
  const { data } = await Axios.get(routeGenerator(`/tenderOrders/overview`));
  return data;
};

const useOrdersSummaryPlaceholderData = {
  activeOrders: 5,
  activeOrdersValue: 55000,
  completedOrders: 1,
  completedOrdersValue: 55000,
  inProcessOrders: 3,
  inProcessOrdersValue: 110000,
  totalOrders: 4,
};

export const useOrdersSummary = () =>
  useQuery<typeof useOrdersSummaryPlaceholderData, ApiError>(
    ["/orders/summary"],
    () => getOrdersSummary(),
    {
      placeholderData: useOrdersSummaryPlaceholderData,
    }
  );

// buyer
const getBuyerOrdersOverview = async () => {
  const { data } = await Axios.get(routeGenerator(`/orders/buyer/overview/`));
  return data;
};

type UseBuyerOrdersOverview = {
  totalOrders: number;
  inProcessOrders: number;
  rejectedOrders: number;
  completedOrders: number;
};

const useBuyerOrdersOverviewPlaceholder: UseBuyerOrdersOverview = {
  totalOrders: 10,
  inProcessOrders: 10,
  rejectedOrders: 10,
  completedOrders: 10,
};

export const useBuyerOrdersOverview = () => {
  return useQuery<UseBuyerOrdersOverview, ApiError>(
    ["buyer", "orders", "overview"],
    () => getBuyerOrdersOverview(),
    { placeholderData: useBuyerOrdersOverviewPlaceholder }
  );
};

const getBuyerOrders = async (status: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/orders/buyer?status=${status}`)
  );
  return data;
};

type UseBuyerOrders = Order[];

const useBuyerOrdersPlaceholder: UseBuyerOrders = createArray(
  order,
  4
) as Order[];

export const useBuyerOrders = (status: string) => {
  return useQuery<UseBuyerOrders, ApiError>(
    ["buyer", "orders", status],
    () => getBuyerOrders(status),
    { placeholderData: useBuyerOrdersPlaceholder }
  );
};

// supplier
const getSupplierOrdersOverview = async () => {
  const { data } = await Axios.get(
    routeGenerator(`/orders/supplier/overview/`)
  );
  return data;
};

type UseSupplierOrdersOverview = {
  totalOrders: number;
  inProcessOrders: number;
  rejectedOrders: number;
  completedOrders: number;
};

const useSupplierOrdersOverviewPlaceholder: UseSupplierOrdersOverview = {
  totalOrders: 10,
  inProcessOrders: 10,
  rejectedOrders: 10,
  completedOrders: 10,
};

export const useSupplierOrdersOverview = () => {
  return useQuery<UseSupplierOrdersOverview, ApiError>(
    ["supplier", "orders", "overview"],
    () => getSupplierOrdersOverview(),
    { placeholderData: useSupplierOrdersOverviewPlaceholder }
  );
};

const getSupplierOrders = async (status: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/orders/supplier?status=${status}`)
  );
  return data;
};

type UseSupplierOrders = Order[];

const useSupplierOrdersPlaceholder: UseSupplierOrders = createArray(
  order,
  4
) as Order[];

export const useSupplierOrders = (status: string) => {
  return useQuery<UseSupplierOrders, ApiError>(
    ["supplier", "orders", status],
    () => getSupplierOrders(status),
    { placeholderData: useSupplierOrdersPlaceholder }
  );
};
