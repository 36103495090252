import ProductImage from "components/ProductImage";
import {
  useSaveTenderProduct,
  useUnsaveTenderProduct,
} from "features/products/mutations";
import { useIsTenderProductSaved } from "features/products/queries";
import useModalActions from "hooks/useModalActions";
import { ReactComponent as Image } from "assets/icons/image.svg";
import { ReactComponent as Wishlist } from "assets/icons/wishlist.svg";
import { ReactComponent as WishlistGreen } from "assets/icons/wishlist-green.svg";
import ShortDetails from "components/ShortDetails";
import SwitchAnimation from "ui/SwitchAnimation";
import useTenderProductId from "hooks/useTenderProductId";
import { useAnalytics } from "hooks/useAnalytics";
import useBgmType from "hooks/useBgmType";
import ElipseDetail from "components/ElipseDetail";
import { StyledTenderProductDetails } from "./TenderProductDetails.styled";

const TenderProductDetails = ({
  tenderProduct,
  setCertificateIsOpen,
  withWishlist = true,
  certificateIsActive,
}: {
  withWishlist?: boolean;
  tenderProduct: TenderProduct;
  setCertificateIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  certificateIsActive: boolean;
}) => {
  const track = useAnalytics();
  const lotId = useTenderProductId({ tenderProduct });
  const { openModal } = useModalActions();
  const isSaved = useIsTenderProductSaved({ tenderProduct });
  const { mutate: save, isLoading: isLoadingSave } = useSaveTenderProduct({
    tenderProduct,
  });
  const { mutate: unsave, isLoading: isLoadingUnsave } = useUnsaveTenderProduct(
    {
      tenderProduct,
    }
  );

  const handleWishlist = () => {
    if (isSaved) {
      track("Diamond View - Remove from Wishlist");
      unsave();
    } else {
      track("Diamond View - Add to Wishlist");
      save();
    }
  };

  const handleCertificate = () => {
    track("Diamond View - View Certificate");
    setCertificateIsOpen(true);
  };

  const bgmType = useBgmType({ product: tenderProduct.product });

  return (
    <StyledTenderProductDetails className="tender-product__details">
      <div className="tender-product__details__left">
        <div
          className="tender-product__details__image"
          style={tenderProduct.product ? { cursor: "pointer" } : {}}
          onClick={() => {
            tenderProduct.product &&
              openModal({
                modalType: "MediaModal",
                modalProps: {
                  product: tenderProduct.product,
                },
              });
          }}
        >
          <ProductImage src={tenderProduct.product.diamondImage} />
        </div>
        <img
          src="/assets/images/gia.png"
          alt="gia"
          className="tender-product__details__certificate__logo--mobile"
        />
        {(() => {
          let sum = 0;
          const initialOptions = [
            { key: "diamondImage", type: "image" } as const,
            { key: "diamondImage2", type: "image" } as const,
            { key: "diamondImage3", type: "image" } as const,
            { key: "diamondImage360", type: "360" } as const,
          ] as const;
          initialOptions.forEach(
            ({ key }) => tenderProduct.product[key] && sum++
          );
          return sum > 0 ? (
            <div
              className="tender-product__details__more-images"
              onClick={() =>
                openModal({
                  modalType: "MediaModal",
                  modalProps: {
                    product: tenderProduct.product,
                  },
                })
              }
            >
              <Image className="tender-product__details__more-images__icon" />
              <p className="tender-product__details__more-images__text">
                +{sum}
              </p>
            </div>
          ) : null;
        })()}
      </div>
      <div className="tender-product__details__right">
        <div className="tender-product__details__payment">
          <div className="tender-product__details__payment__left">
            <p className="tender-product__details__payment__label">Lot ID</p>
            <p className="tender-product__details__payment__value">#{lotId}</p>
          </div>
          {withWishlist && (
            <button
              onClick={handleWishlist}
              className="tender-product__details__save-diamond"
              disabled={isLoadingSave || isLoadingUnsave}
            >
              <SwitchAnimation state={isSaved}>
                <div>{isSaved ? <WishlistGreen /> : <Wishlist />}</div>
              </SwitchAnimation>
            </button>
          )}
        </div>
        <ShortDetails
          className="tender-product__details__short-details"
          product={tenderProduct.product}
          values={[
            "model",
            "carat",
            "clarity",
            "color",
            "cut",
            "polish",
            "symmetry",
            "fluorescenceIntensity",
          ]}
        >
          {bgmType === "No BGM" && <ElipseDetail>No BGM</ElipseDetail>}
        </ShortDetails>
        <div className="tender-product__details__certificate">
          <img
            src="/assets/images/gia.png"
            alt="gia"
            className="tender-product__details__certificate__logo"
          />
          <button
            className="tender-product__details__view-certificate"
            disabled={
              !tenderProduct.product.certificateId || !certificateIsActive
            }
            onClick={handleCertificate}
            style={
              !certificateIsActive || !tenderProduct.product.certificateId
                ? { opacity: 0.5 }
                : { opacity: 1 }
            }
          >
            View Certificate
          </button>
        </div>
      </div>
    </StyledTenderProductDetails>
  );
};

export default TenderProductDetails;
