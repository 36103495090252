import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";

const approveTenderProuductOffer = async (products: string[]) => {
  const { data } = await Axios.post(
    routeGenerator(`/tenderProductsOffers/accept`),
    {
      products,
    }
  );
  return data;
};

export const useApprovePendingDiamond = (originals: TenderProductOffer[]) => {
  const queryClient = useQueryClient();
  return useMutation<boolean, ApiError>(
    () => approveTenderProuductOffer(originals.map((original) => original._id)),
    {
      onSuccess: () => {
        const ids = originals.map((original) => original._id);
        // removing from pending
        queryClient.setQueryData<TenderProductOffer[]>(
          [`supplier`, "pending"],
          (data) => {
            return data
              ? data.filter(
                  (tenderProductOffer) => !ids.includes(tenderProductOffer._id)
                )
              : [];
          }
        );
        // adding to approved
        queryClient.setQueryData<TenderProductOffer[]>(
          [`supplier`, "accepted"],
          (data) => {
            return data ? [...originals, ...data] : [];
          }
        );

        // invalidate tender products
        queryClient.resetQueries(["tenderproducts", originals[0].tender._id]);
      },
    }
  );
};

const declineTenderProuductOffer = async (products: string[]) => {
  const { data } = await Axios.post(
    routeGenerator(`/tenderProductsOffers/decline`),
    { products }
  );
  return data;
};

export const useDeclinePendingDiamond = (originals: TenderProductOffer[]) => {
  const queryClient = useQueryClient();
  return useMutation<boolean, ApiError>(
    () => declineTenderProuductOffer(originals.map((original) => original._id)),
    {
      onSuccess: () => {
        const ids = originals.map((original) => original._id);
        // removing from pending
        queryClient.setQueryData<TenderProductOffer[]>(
          [`supplier`, "pending"],
          (data) => {
            return data
              ? data.filter(
                  (tenderProductOffer) => !ids.includes(tenderProductOffer._id)
                )
              : [];
          }
        );

        // adding to rejected
        queryClient.setQueryData<TenderProductOffer[]>(
          [`supplier`, "rejected"],
          (data) => {
            return data ? [...originals, ...data] : [];
          }
        );

        // invalidate tender products
        queryClient.resetQueries(["tenderproducts", originals[0].tender._id]);
      },
    }
  );
};
