import { FiltersType } from "types/filters";

export const date = "2022-05-31T12:15:00.000Z";
const _id = "5ea6c1bb5794f1801820c344";
export const product: Product = {
  _id: "5ea6c1bb5794f1801820c344",
  isAvailable: false,
  markupPercent: 0,
  marketplace: false,
  productId: "21523",
  model: "rb",
  carat: 0.45,
  color: "j",
  clarity: "VVS1",
  cut: "EX",
  polish: "EX",
  symmetry: "EX",
  fluorescenceIntensity: "faint",
  measurement: "4.90-4.86-3.04",
  lab: "GIA",
  certificateId: "5343861053",
  ppc: 1455,
  depth: 62.4,
  table: 56,
  girdle: 4,
  girdleCondition: "Faceted",
  culetCondition: "none",
  crownHeight: "15.5",
  crownAngle: "35.5",
  pavilionDepth: 42.5,
  pavilionAngle: 40.6,
  country: "India",
  state: "Maharashtra",
  city: "India",
  certificateImage:
    "https://diamonds.kirangems.com:8080/Picture/5343861053.pdf",
  comment: "Pinpoint Surface Graining",
  diamondStatus: "auto",
  company: "5fb0e4cc72137430ff9e493b",
  colorType: "white",
  price: 655,
  updatedPrice: 655,
  updatedPpc: 1455,
  measLength: 4.9,
  measWidth: 4.86,
  measDepth: 3.04,
  listPrice: 1700,
  discount: -14.41,
  updatedAt: "2020-10-28T09:29:47.871+0000",
  createdAt: "2020-04-27T11:28:15.703+0000",
  status: "deleted",
  treatment: "no treatment",
  marketplaceLastStatus: true,
  uploadedAt: "2020-10-28T09:28:53.804+0000",
  diamondImage: "https://photo.diamondasset.in:8080/imagesM/6402072603.jpg",
  location: "India",
};

export const company: Company = {
  _id,
  name: "fake company",
  createdAt: date,
  updatedAt: date,
  isTenderSupplier: true,
};

export const user: User = {
  _id,
  email: "email@starrays.com",
  firstName: "First",
  lastName: "Last",
  phoneNumber: "+97263636366",
  token: "123",
  isSupplier: true,
  company,
  approvedTerms: false,
};

export const tender: Tender = {
  _id,
  name: "White polished diamonds",
  startDate: date,
  endDate: date,
  createdAt: date,
  updatedAt: date,
  filters: {
    model: ["rb"],
    color: ["d", "e"],
    carat: { minCarat: "2", maxCarat: "3" },
    colorType: ["White", "Fancy"],
    clarity: ["VS1", "VS1", "VS1", "VS1", "VS1"],
  },
  products: [],
  type: "",
  productsViewDate: date,
  description: "Round 3ct+ tender",
  tenderProducts: [],
  tenderUsers: [],
  isLive: true,
  isFinished: true,
  isNext: true,
};

export const order: Order = {
  _id,
  status: "completed",
  company,
  createdAt: date,
  updatedAt: date,
  user,
  totalPrice: 1000,
  orderStatus: "invoiced pending",
};

export const tenderProductOffer: TenderProductOffer = {
  _id,
  company,
  memoDate: date,
  product,
  tender,
  reservePrice: 1000,
  startingPrice: 1000,
  status: "accepted",
};

export const tenderProduct: TenderProduct = {
  _id,
  bids: [],
  currentPrice: 1000,
  endDate: date,
  startDate: date,
  product,
  tender,
  subscribedUsers: [],
  highestBid: {
    price: 1000,
    changeInPrice: 12,
    tender,
    user,
  } as any,
  usersViewed: [],
  tenderProductOffer,
};

export const tenderProductBid: TenderProductBid = {
  _id,
  changeInPrice: 0.5,
  price: 1000,
  user,
  tender,
  tenderProduct,
  createdAt: date,
  updatedAt: date,
  location: "IL",
};

tenderProduct.bids = [];

export const createArray = (data: any, n: number = 5) => {
  if (typeof data === "object")
    return new Array(n).fill(data).map((item, index) => ({
      ...item,
      _id: String(item._id).slice(0, -1) + index,
    }));
  return new Array(n).fill(data);
};

export const createProductsArray = (n: number = 10): Product[] => {
  return new Array(n).fill(product).map((product, index) => ({
    ...product,
    _id: String(product._id).slice(0, -1) + index,
    carat: Math.random().toFixed(2),
  }));
};

export const filtersList: FiltersType = [
  {
    displayName: "Shape",
    name: "model",
    white: true,
    fancy: true,
    type: "select",
    options: [
      { value: "rb", label: "Round" },
      { value: "ps", label: "Pear" },
      { value: "ov", label: "Oval" },
      { value: "em", label: "Emerald" },
      { value: "cu", label: "Cushion All" },
      { value: "cb", label: "Cushion .B." },
      { value: "hs", label: "Heart" },
      { value: "mq", label: "Marquise" },
      { value: "as", label: "Asscher" },
      { value: "ra", label: "Radiant" },
      { value: "pr", label: "Princess" },
    ],
  },
  {
    name: "carat",
    displayName: "Carat",
    type: "range",
    fancy: true,
    white: true,
    min: 1,
    max: 10,
  },
  {
    white: true,
    fancy: false,
    displayName: "Color",
    type: "select",
    name: "color",
    options: [
      { value: "d", label: "D" },
      { value: "e", label: "E" },
      { value: "f", label: "F" },
      { value: "g", label: "G" },
      { value: "h", label: "H" },
      { value: "i", label: "I" },
      { value: "j", label: "J" },
      { value: "k", label: "K" },
      { value: "l", label: "L" },
      { value: "m", label: "M" },
      { value: "n", label: "N" },
      { value: "o", label: "O" },
      { value: "p", label: "P" },
      { value: "q", label: "Q" },
      { value: "r", label: "R" },
      { value: "s", label: "S" },
      { value: "t", label: "T" },
      { value: "u", label: "U" },
      { value: "v", label: "V" },
      { value: "w", label: "W" },
      { value: "x", label: "X" },
      { value: "y", label: "Y" },
      { value: "z", label: "Z" },
    ],
  },
  {
    name: "clarity",
    displayName: "Clarity",
    fancy: true,
    white: true,
    type: "select",
    options: [
      { value: "FL", label: "FL" },
      { value: "IF", label: "IF" },
      { value: "LC", label: "LC" },
      { value: "VVS1", label: "VVS1" },
      { value: "VVS2", label: "VVS2" },
      { value: "VS1", label: "VS1" },
      { value: "VS2", label: "VS2" },
      { value: "SI1", label: "SI1" },
      { value: "SI2", label: "SI2" },
      { value: "SI3", label: "SI3" },
      { value: "I1", label: "I1" },
      { value: "P1", label: "P1" },
      { value: "I2", label: "I2" },
      { value: "P2", label: "P2" },
      { value: "I3", label: "I3" },
      { value: "P3", label: "P3" },
    ],
  },
  {
    type: "select",
    name: "lab",
    displayName: "Lab",
    white: true,
    fancy: true,
    options: [
      { value: "GIA", label: "GIA" },
      { value: "HRD", label: "HRD" },
      { value: "IGI", label: "IGI" },
      { value: "EGL", label: "EGL" },
      { value: "EGL USA", label: "EGL USA" },
      { value: "AGS", label: "AGS" },
      { value: "AIG", label: "AIG" },
      { value: "SGL", label: "SGL" },
      { value: "CGL", label: "CGL" },
      { value: "DCLA", label: "DCLA" },
      { value: "GCAL", label: "GCAL" },
      { value: "GSI", label: "GSI" },
      { value: "NGTC", label: "NGTC" },
      { value: "PGS", label: "PGS" },
      { value: "VGR", label: "VGR" },
      { value: "IGL", label: "IGL" },
      { value: "None", label: "None" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    displayName: "Cut",
    type: "select",
    white: true,
    fancy: true,
    name: "cut",
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  {
    name: "polish",
    displayName: "Polish",
    type: "select",
    white: true,
    fancy: true,
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  {
    white: true,
    fancy: true,
    type: "select",
    name: "symmetry",
    displayName: "Symmetry",
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  {
    name: "country",
    displayName: "Location",
    white: true,
    fancy: true,
    type: "select",
    options: [
      { value: "USA", label: "USA" },
      { value: "Hong Kong", label: "Hong Kong" },
      { value: "China", label: "China" },
      { value: "Belgium", label: "Belgium" },
      { value: "India", label: "India" },
      { value: "Israel", label: "Israel" },
      { value: "Australia", label: "Australia" },
      { value: "Canada", label: "Canada" },
      { value: "Denmark", label: "Denmark" },
      { value: "Dubai", label: "Dubai" },
      { value: "France", label: "France" },
      { value: "Indonesia", label: "Indonesia" },
      { value: "Italy", label: "Italy" },
      { value: "Japan", label: "Japan" },
      { value: "London", label: "London" },
      { value: "Monaco", label: "Monaco" },
      { value: "Philippines", label: "Philippines" },
      { value: "Russia", label: "Russia" },
      { value: "Spain", label: "Spain" },
      { value: "Switzerland", label: "Switzerland" },
      { value: "Thailand", label: "Thailand" },
      { value: "Turkey", label: "Turkey" },
      { value: "UAE", label: "UAE" },
      { value: "UK", label: "UK" },
    ],
  },
];

export const tenderOrder: TenderOrder = {
  _id,
  company,
  date,
  order: { ...order },
  status: "accepted",
  tenderProduct,
  tenderProductBid,
  createdAt: date,
  updatedAt: date,
  tender,
};

tenderOrder.tenderProduct = tenderProduct;
order.tenderOrder = tenderOrder;
