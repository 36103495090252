import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: var(--sectionPadding) var(--sectionPadding) 5rem
    var(--sectionPadding);
  height: 100%;
  overflow-y: auto;

  * {
    font-family: ${({ theme }) => theme.fonts.archivo};
  }

  li::marker {
    display: none;
  }

  padding: var(--sectionPadding) var(--sectionPadding) 5rem
    var(--sectionPadding);
  height: 100%;
  overflow: auto;

  * {
    font-size: ${(props) => props.theme.fonts.archivo};
  }

  ol {
    list-style: none;
  }

  .title {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: 20px 0 10px 0;
    display: inline-block;
    font-size: ${(props) => props.theme.fonts.archivo};
  }

  .text {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral[600]};
    line-height: 1.2;
  }

  table {
    .title {
      margin: 0;
      white-space: nowrap;
      margin-right: 8px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.primary[200]};
    text-decoration: underline;
  }
`;

function Privacy(): ReactElement {
  return (
    <Container>
      <p>
        <strong className="title">Last updated:&nbsp;21/12/2021</strong>
      </p>
      <p className="text">
        Lucy Platforms Ltd. (hereinafter: &ldquo;<strong>We</strong>&rdquo;, or
        &ldquo;<strong>the Company</strong>&rdquo;) honor the privacy of the
        users (hereinafter: the "<strong>User(s)</strong>&rdquo; or &ldquo;
        <strong>You</strong>&rdquo;) of our website whose address is{" "}
        <a href="http://www.tradediamonds.com">www.tradediamonds.com</a>{" "}
        (hereinafter the "<strong>Website</strong>&rdquo;) and are required to
        protect the private information that the Users share with us in the
        context of the Website. We believe that our Users have the right to know
        our policy pertaining to the collection and use of the information We
        receive during usage of the Website.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        The terms that appear in this privacy policy (hereinafter: the "
        <strong>Privacy Policy</strong>&rdquo; shall be construed in accordance
        with their definitions set forth in the terms of use, which are
        available on this link (hereinafter: &ldquo;
        <strong>Terms of Use</strong>&rdquo;) unless stated otherwise in this
        Privacy Policy.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        We believe that our Users have the right to know our policy pertaining
        to the collection and use of the information We receive during usage of
        the Website.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        <strong className="title">Please note</strong>: The Website is intended
        exclusively for Users over eighteen (18) years old. If You are not over
        eighteen (18) &ndash; You may not use the Website.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        The Privacy Policy is worded in the masculine form for convenience only,
        and naturally includes women aswell.
      </p>
      <ol>
        <li>
          <strong className="title">Consent</strong>
        </li>
      </ol>
      <p className="text">
        Please read carefully: By entering or connecting to the Website, or by
        using the Website, You are consenting to the terms that appear in this
        Privacy Policy, including Your consent to our collecting and processing
        Your Personal Information (as defined hereinafter). If You do not
        consent to any of the provisions specified hereinafter, You may not
        enter or connect to or use the Website.
      </p>
      <ol>
        <li>
          <strong className="title">
            What information are We entitled to collect about our Users?
          </strong>
        </li>
      </ol>
      <p className="text">
        We collect two types of information from Users of the Website:
      </p>
      <ol>
        <li className="text">
          The first type of information the Company collects is anonymous,
          non-identifying information (hereinafter: &ldquo;
          <strong className="title">Impersonal Information</strong>&rdquo;).
          Impersonal Information is any unclassified information available to
          the Company when the User uses the Website, which does not enable the
          identification of this User. Impersonal Information includes
          anonymous, technical information, and information collected regarding
          the Users&rsquo; behavior, and may include, inter alia, the activity
          of the User of the Website, the identity of the operation system, as
          well as the User&rsquo;s browser type, the type and version of the
          mobile device, the language of the device, the click-stream, the
          referral URL, the keyboard language, the screen resolution, the
          geographical location defined as default, etc.
        </li>
      </ol>
      <ol>
        <li className="text">
          The second type of information the Company collects is personal
          identification information (hereinafter: &ldquo;
          <strong className="title">Personal Information</strong>&rdquo;). The
          Personal Information enables the Company to personally identify the
          User and is generally private or sensitive in nature:
        </li>
      </ol>
      <ul>
        <li className="text">
          The Users automatically provide their IP address (or Mac address,
          whichever is relevant), in accordance with the network or device they
          are using. This information is collected in order to improve the User
          experience, to determine geographical location, and for security
          purposes.
        </li>
        <li className="text">
          Moreover, We collect Personal Information provided by Users
          consciously and of their own free will while using the Website. For
          example, the Personal Information will be collected from among the
          details provided in the context of registering to the Website as a
          subscriber or inquirer.
        </li>
      </ul>
      <p className="text">
        The following information is collected in the context of the
        registration process: name, address, means of contact including email.
        The mandatory fields will be explicitly indicated. With respect to the
        use of the Tenders' Platform, You will also be asked to provide certain
        documentation reasonably requested by the Company in order to enable the
        Company to fully comply with all anti-money laundering laws and
        regulations under applicable laws of all relevant jurisdictions. The
        documentation will include (but not necessarily be limited to) a copy of
        one of the following forms of identification: driving license, passport,
        identity card, sales tax registration or other government issued license
        or registration document and where relevant, corporate constitutional
        documentation. In the future, additional information may be requested in
        the context of the form.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        To remove doubt, any Impersonal Information which is connected or tied
        to Personal Information, shall be deemed Personal Information as long as
        this connection exists.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        <strong className="title">Please note</strong>: We will not collect
        Personal Information about You without Your consent, which is provided,
        inter alia, in the context of Your acceptance of the Terms of Use, the
        Tenders Terms of Use, the Cookie Policy and Privacy Policy, and by Your
        using the Website. You are not required to provide Personal Information
        by law, but without providing it, You will not be able to use the
        Tenders' Platform intended for registered users only. You hereby agree
        and declare that the Personal Information with which You provide us is
        rendered of Your own free will to enable us to provide You with the
        Website, and You likewise agree that We save the information with which
        You provide us in the information database (which may be registered in
        accordance with the requirements of the law).
      </p>
      <ol>
        <li>
          <strong className="title">
            How do We collect information about our Users?
          </strong>
        </li>
      </ol>
      <p className="text">
        We collect the information independently or by receiving services from
        third parties who are authorized service providers &ndash; through two
        media:
      </p>
      <ul>
        <li className="text">
          By Your entering and using the Website. When You visit or use the
          Website, We are aware of the actual use, and are entitled to collect
          and document information pertaining to Your use, including products
          and services You purchased or sought to purchase, information or
          advertisements You read, the pages You viewed, the offers and services
          that interested You, and more.
        </li>
        <li className="text">
          We collect information that You voluntarily shared with the Company
          upon registering for services or filling out the contact form.
        </li>
      </ul>
      <ol>
        <li>
          <strong className="title">
            The goals of collecting and using information
          </strong>
        </li>
      </ol>
      <p className="text">We use Impersonal Information:</p>
      <ul>
        <li className="text">
          To adapt, develop and improve the Website and the services provided in
          the context thereof.
        </li>
        <li className="text">To provide the services of the Website.</li>
        <li className="text">
          For analytical and research purposes, for instance, to analyze User
          preferences and Users&rsquo; manner of use of the services (for
          example, which features are popular among Users).
        </li>
        <li className="text">For statistical purposes.</li>
      </ul>
      <p className="text">We use Personal Information:</p>
      <ul className="text">
        <li>
          To adapt, develop and improve the Website and the services rendered in
          the context thereof.
        </li>
        <li>
          To send promotional messages and/or the newsletter and/or other
          commercial proposals to registered Users &ndash; both on behalf of the
          Company itself and on behalf of other advertisers, and both directly
          from us or from third parties sending on our behalf. You will be sent
          this information if You explicitly consented to this during
          registration or at any other time. You may revoke Your consent at any
          time and You will no longer receive these messages.
        </li>
        <li>
          To respond to User queries regarding the use of the Website and to
          provide technical support services.
        </li>
        <li>
          To contact You via any of the modes of contact You provided upon
          registering to the Website (including sending text messages).
        </li>
        <li>
          To obtain general information regarding the geographical location from
          which the User is connected to the internet, both for purposes of
          information security and to learn the general location of the Users of
          our Website.
        </li>
        <li>
          To verify the User&rsquo;s identity upon entry the Website, and to
          deal with complaints about improper interactions on the User&rsquo;s
          part and/or unauthorized use of the Website.
        </li>
        <li>
          For any other purposes specified in this Privacy Policy or in the
          general Terms of Use of the Website and in the Terms of Use for
          subscribers.
        </li>
      </ul>
      <ol className="text">
        <li>
          <strong className="title">
            Sharing Personal Information with third parties
          </strong>
        </li>
      </ol>
      <p className="text">
        We are entitled to disclose Personal Information to third parties in the
        following cases:
      </p>
      <p className="text">
        (a) To satisfy the requirements of any law for purposes of legal
        proceedings, orders, including orders of disclosure, or on the request
        of a government authority;
      </p>
      <p className="text">
        (b) To enforce this Privacy Policy or our Terms of Use, including in the
        context of possible breaches of the Privacy Policy or Terms of Use;
      </p>
      <p className="text">
        (c) To expose, prevent or otherwise deal with deceit, fraud, or safety
        and security issues, as well as technical problems;
      </p>
      <p className="text">
        (d) To respond to Users&rsquo; requests for support;
      </p>
      <p className="text">
        (e) In any dispute, claim, suit, complaint or legal proceedings, should
        there be such between You and the Company;
      </p>
      <p className="text">
        (f) To answer any suit, complaint or claim that the content that appears
        on the Website and/or Application is, and is used in a manner that is,
        unauthorized or violates third party rights;
      </p>
      <p className="text">
        (g) To answer suits, claims or complaints that the contact information
        (for example: name, address, email address etc.) of a third party was
        published or transferred without his consent or in a manner that
        constitutes harassment;
      </p>
      <p className="text">
        (h) To protect the rights, property, or the personal safety of the
        Company, the Users or the general public;
      </p>
      <p className="text">
        (i) In the event of a change in the control of the Company, including by
        way of a merger, purchase, or the purchase of all the Company&rsquo;s
        assets, or of its primary assets;
      </p>
      <p className="text">
        (j) Collaboration with third parties for purposes of providing the
        tenders' services offered hereunder;
      </p>
      <p className="text">
        (k) Collaboration with third parties for purposes of improving the User
        experience; and or
      </p>
      <p className="text">
        (l) On the User&rsquo;s explicit approval prior to the disclosure.
      </p>
      <p className="text">&nbsp;</p>
      <p className="text">
        To remove doubt, the Company may collect, disclose, and transfer
        Impersonal Information to third parties at its own exclusive discretion.
      </p>
      <ol>
        <li>
          <strong className="title">
            Changing or deleting Personal Information
          </strong>
        </li>
      </ol>
      <p className="text">
        If for any reason You desire to change, update or delete the Personal
        Information with which You provided us, please{" "}
        <a
          href="https://www.get-diamonds.com/support"
          target="_blank"
          rel="noreferrer"
        >
          contact
        </a>{" "}
        us via the communication means indicated in the Website and We will make
        reasonable efforts to change, update or delete said Personal Information
        in accordance with the applicable privacy laws. Please note that there
        are certain circumstances that will obviate the possibility to delete
        material on Your request.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        <strong className="title">Please note</strong>: Unless You instruct
        otherwise, We are entitled to hold on to Your Personal Information for
        as long as it may reasonably be required for the purpose for which Your
        Personal Information was collected, including, but not limited to, for
        purposes of satisfying our legal or business requirements, duties or
        undertakings; to resolve disputes; enforce the provisions; all in
        accordance with the privacy laws that apply. Moreover, even pursuant to
        the deletion of content from the Company&rsquo;s servers, it is possible
        that this content may be saved on the Company&rsquo;s backup systems for
        an additional period. The company may also be required by the law
        enforcement authorities to suspend the deletion of information from the
        Company&rsquo;s server with regard to specific information.
      </p>
      <ol>
        <li>
          <strong className="title">Minors</strong>
        </li>
      </ol>
      <p className="text">
        To use the Website, You must be over eighteen (18). We reserve the right
        to demand proof of age at any stage, to ensure that there are no minors
        under age eighteen (18) using the Website. Should it be brought to our
        attention that a person under age eighteen (18) is using the Website, We
        will prohibit and bar this User from entering the Website, and We will
        take every measure to immediately delete any Personal Information
        (within this term&rsquo;s meaning in our Privacy Policy) regarding said
        User. Please contact us via the communication means indicated in the
        Website&nbsp;if You believe that We collected Personal Information from
        Users under age eighteen (18).
      </p>
      <ol>
        <li>
          <strong className="title">Security</strong>
        </li>
      </ol>
      <p className="text">
        We take reasonable measures to implement and maintain the security of
        the Website and the Users&rsquo; Personal Information (personally and by
        way of services provided by third parties who are authorized service
        providers). We implement policies and information security policies as
        is generally accepted in the industry to ensure the security of the
        Users&rsquo; information and to prevent any unauthorized use of this
        information.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        Notwithstanding the aforesaid, We cannot commit and guarantee that
        unauthorized access to said information will never occur, and We bear no
        liability for any said unauthorized access or the results thereof.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong className="title">Third party services</strong>
        </li>
      </ol>
      <p className="text">
        We are entitled to use software or third party services to collect,
        store and/or process the information collected in the context of the
        Website. We make efforts to contract with third parties that publish
        privacy policies applicable to the collection, storage, processing and
        use of personal and Impersonal Information. Third party services
        include, inter alia, Google Analytics whose Privacy Policies are
        published on:
      </p>
      <p>
        <a href="http://www.google.com/policies/privacy/partners/">
          www.google.com/policies/privacy/partners/
        </a>
      </p>
      <p>
        <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">
          http://www.google.com/intl/en/analytics/privacyoverview.html
        </a>
      </p>
      <p className="text">
        Nonetheless, the Company has no control over these third parties. We
        recommend that You read the terms of use and privacy policies of these
        third parties and/or contact them directly to verify what types of
        information are being collected about You.
      </p>
      <ol>
        <li>
          <strong className="title">Cookies</strong>
        </li>
      </ol>
      <p className="text">
        Please see, read and accept the Cookie Policy&nbsp;available at&nbsp;
        <Link to="/terms" />
      </p>
      <ol>
        <li>
          <strong className="title">Third party advertisements</strong>
        </li>
      </ol>
      <p className="text">
        The Company may allow some companies to manage the advertisement
        campaigns on the Website. The advertisements You view on visiting the
        Website come from those companies&rsquo; computers. To manage their
        advertisements, these companies place cookies in Your computer. The
        cookies enable them to collect information about the Websites You
        viewed, the advertisements placed, and which advertisements You clicked
        on. In this manner, they seek to attempt adjusting the advertisements
        displayed to You in the future to the issues that interest You. The use
        these companies make of cookies is subject to their privacy policies and
        not to this Policy of the Website. These companies have no access to the
        Company&rsquo;s cookies, and the Company has no access to their cookies.
        If You wish to check out the privacy policies of the companies that
        manage the advertisement campaign on the Website, and prevent them from
        sending cookies to Your computer and reading cookies therefrom, You may
        do this through their Websites.
      </p>
      <ol>
        <li>
          <strong className="title">Social features</strong>
        </li>
      </ol>
      <p className="text">
        The Website may include features of social networks (such as
        Facebook&rsquo;s &ldquo;like&rdquo; or &ldquo;share&rdquo;). The social
        features operate or enable integration with specific third party social
        networks or platforms (hereinafter: &ldquo;
        <strong className="title">Social Networks</strong>&rdquo; or &ldquo;
        <strong className="title">Platform</strong>&rdquo;). These social
        networks and platforms are formed and maintained by third parties that
        are not connected to the Company, or which are not controlled thereby.
        If said integration with said social networks or platforms is enabled,
        Your use of these social features shall be subject to the terms of use
        and the privacy policy of the relevant social network or platform. You
        must ensure that You have read and understood the terms of use and
        privacy policy of the relevant social network or platform. You must
        ensure that You have read and understood the terms of use and privacy
        policy of the relevant social network to learn what information was
        collected about You, and to learn about Your options to change Your
        private definitions in the context thereof. If You do not consent to the
        practices specified in the terms of the social networks or platforms,
        you should not enable our Website to contact You and/or integrate You
        (in such an event, please take into account that You will be unable to
        benefit from the Services which our Website offers). You must understand
        that should integration with a social network or platform be enabled,
        the Company shall be entitled to provide details about You to Your
        contact people (and vice versa) to enable You to benefit from the social
        features. This data may include Your name and Your profile photo on
        social media. In using the social features, You agree and understand
        that the relevant social networks or platforms may collect specific
        Personal Information such as Your IP address, Impersonal Information,
        and may even plant cookies and/or other online Tracking Technology to
        enable the social features. The use of these features enables You to
        share information with Your friends or the general public, taking into
        account the specifications You set at the third party that provides the
        social networks or platforms. You understand and agree that when You
        share content through social networks or platforms, Your content may
        become public and others may publish it.
      </p>
      <p>&nbsp;</p>
      <p className="text">
        Your entry to, use of, and reliance on the Websites, Tenders' Platform
        or third party content, and all Your interactions with third parties, is
        your own liability, and You, exclusively, shall bear all the related
        expenses. You agree and affirm that the Company shall not be liable,
        directly or indirectly, for any damage or loss caused (or allegedly
        caused) in connection with, or as a result of, any service, content,
        product or other information available through these Websites or
        Services.
      </p>
      <ol>
        <li>
          <strong className="title">International data transfer</strong>
        </li>
      </ol>
      <p className="text">
        The Company may transfer information collected about You, including
        Personal Information, to related bodies or third party service providers
        from Your country or the jurisdiction in which You are located, to other
        countries or jurisdictions worldwide. Please note that the Company may
        transfer information to a country and jurisdiction governed by
        information protection laws that are not identical to those applicable
        in the jurisdiction in which You are located, and You agree to this
        transfer of information.
      </p>
      <ol>
        <li>
          <strong className="title">Promotional information</strong>
        </li>
      </ol>
      <p className="text">
        You agree that We may use Your details which were provided to us of Your
        own free will, to inform You about the Company&rsquo;s services and
        services of other third parties who are the Company&rsquo;s commercial
        partners and send You commercial and promotional information, which We
        will send to the email address with which You provided us. You may
        revoke Your consent by sending us a notice via the communication means
        indicated in the Website&nbsp;or, alternatively, by following the
        instructions for removal from the mailing list which appear on the email
        messages You are sent.
      </p>
      <ol>
        <li>
          <strong className="title">Changes in the Privacy Policy</strong>
        </li>
      </ol>
      <p className="text">
        The provisions of this Privacy Policy are an integral part of the
        Website, and of the information collected in the context thereof, and it
        cannot be divorced, in any manner, from our Terms of Use. The Company
        reserves the right to change this Privacy Policy at any time, and
        requests that all Users revisit this page as frequently as possible. We
        will inform the Users of any material change in the provisions of this
        Privacy Policy on the Website&rsquo;s homepage and/or We will send the
        User an email message pertaining to the changes to the email address We
        were given in the context of the User&rsquo;s use of the Website.
        Material changes will go into effect seven (7) days after the message
        was published on our Website, or sent by email, whichever is first. Any
        other changes in our Privacy Policy will go into effect on the date of
        the most recent update of this Privacy Policy, and the User&rsquo;s
        continued use of the Website pursuant to most recent date of update,
        constitute the User&rsquo;s consent that he will be bound by the
        revisions. Should the Privacy Policy be amended to comply with any legal
        requirement, the corrections may go into effect immediately, or as
        required by law, without any prior notice.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong className="title">Questions</strong>
        </li>
      </ol>
      <p className="text">
        If You have any questions or other comments regarding this Privacy
        Policy, please us via the communication means indicated in the Website{" "}
        <a
          href="https://www.get-diamonds.com/support"
          target="_blank"
          rel="noreferrer"
        >
          contact
        </a>{" "}
        . We will do our best to get back to You within a reasonable time.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </Container>
  );
}

export default Privacy;
