import styled from "styled-components";
import TradePageFull from "./assets/trade-page-full.png";
export const StyledAbout = styled.div`
  .about-trade {
    padding: 62px 0;
    padding-top: 140px;
    overflow: hidden;
    h1 {
      font-family: var(--poppins);
      font-size: 32px;
      font-weight: 600;
      line-height: 1.47;
      color: #212121;
      margin-left: 5%;
    }
    .description {
      opacity: 0.8;
      font-family: var(--roboto);
      font-size: 18px;
      line-height: 1.61;
      color: #212121;
      margin-top: 6px;
      width: 50%;
      margin-left: 5%;
    }
    .main-content {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .start {
        margin-left: 5%;
        margin-top: 12px;
        width: 40%;
        h3 {
          font-family: var(--poppins);
          font-size: 32px;
          font-weight: 600;
          line-height: 1.47;
          text-align: left;
          color: #1565c0;
          width: fit-content;
          white-space: nowrap;
          &::after {
            content: “”;
            width: 100%;
            height: 10px;
            background-color: #1565c0;
            opacity: 0.2;
            display: flex;
            transform: translateY(-150%);
          }
        }
        button {
          padding: 14px 26px 14.5px 29px;
          border-radius: 6px;
          background-image: linear-gradient(97deg, #003f69, #7499ff);
          color: #fff;
          font-family: var(--poppins);
          font-size: 12px;
          font-weight: 600;
          border: none;
          margin-top: 18px;
          display: flex;
          align-items: center;
          width: 360px;
          height: 52px;
          justify-content: center;
          svg {
            stroke: #fff;
            margin-right: 10px;
            transform: rotate(30deg);
          }
          img {
            margin-right: 10px;
          }
          &.success {
            background-color: #32dea5;
            color: #fff;
            background-image: none;
            font-family: var(--poppins);
            font-weight: 600;
            svg {
              display: none;
            }
          }
          &.tracked {
            &::after {
              content: "We will send you an email before our next online tender start.";
            }
          }
        }
        .tracked {
          margin-top: 12px;
          font-family: var(--poppins);
          font-size: 12px;
          color: #313131;
        }
        .content {
          margin-top: 48px;
          h4 {
            font-family: var(--poppins);
            font-size: 21px;
            font-weight: 500;
            line-height: 1.43;
            color: #212121;
          }
          .reasons-wrapper {
            margin-top: 16px;
            .reasons {
              .trade-reason {
                display: flex;
                &:nth-last-child(1) {
                  .reason-ui {
                    &::after {
                      height: 0;
                    }
                  }
                }
                .reason-ui {
                  margin-right: 21px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .circle {
                    background-color: #a1c1e6;
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                  }
                  &::after {
                    content: “”;
                    display: flex;
                    width: 2px;
                    height: calc(100% - 21px);
                    background-color: #a1c1e6;
                  }
                }
                .content {
                  padding-bottom: 20px;
                  margin: 0;
                  h5 {
                    font-family: var(--roboto);
                    font-weight: bold;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.56;
                    color: #212121;
                    margin: 0;
                  }
                  p {
                    font-family: var(--roboto);
                    font-size: 16px;
                    line-height: 1.38;
                    color: #212121;
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
      .end {
        position: relative;
        img {
          max-height: 600px;
        }
        .site-image {
          max-height: 600px;
          position: absolute;
          top: 0;
          animation-name: imgSlider;
          animation-duration: 3s;
          transition: all ease;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .about-trade {
      padding: 64px calc((100vw - 1600px) / 2);
      h1 {
        margin-left: 0;
      }
      .description {
        margin-left: 0;
      }
      .main-content {
        .start {
          margin-left: 0;
        }
        .end {
          display: flex;
          justify-content: flex-end;
          img {
            width: 90%;
          }
        }
      }
    }
  }
  @media (max-width: 1280px) {
    .about-trade {
      padding: 36px 5%;
      h1 {
        margin: 0;
      }
      .description {
        margin: 0;
        width: 100%;
        margin-top: 16px;
      }
      .main-content {
        flex-direction: column;
        .start {
          width: 100%;
          margin-left: 0;
          h3 {
            white-space: unset;
            text-decoration: underline;
            text-decoration-thickness: 6px;
            text-decoration-color: #a1c1e6;
            &::after {
              height: 0;
            }
          }
        }
        .end {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 16px;
          img {
            width: 100%;
            max-width: 600px;
            max-height: 800px;
            // transform: translateX(9%);
            content: url(${TradePageFull});
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .about-trade {
      .main-content {
        .start {
          button {
            width: 100%;
          }
        }
        .end {
          margin-top: 16px;
          img {
            content: url(${TradePageFull});
          }
        }
      }
    }
  }
  // Animation
  @keyframes imgSlider {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }
  @keyframes imgSliderSmallScreen {
    0% {
      right: -105%;
    }
    100% {
      right: -5%;
    }
  }
`;
