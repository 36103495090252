import { API_URL } from "config";
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const Context = createContext(null as null | Socket);

export const useContextSocket = () => {
  const context = useContext(Context);
  return context;
};

const SocketContext = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState(null as null | Socket);

  useEffect(() => {
    const socketClient = io(API_URL.replace("/api", ""), {
      reconnection: false,
    }).connect();

    socketClient.on("connect", () => {
      setSocket(socketClient);
    });

    return () => {
      socketClient.disconnect();
    };
  }, []);

  return <Context.Provider value={socket}>{children}</Context.Provider>;
};

export default SocketContext;
