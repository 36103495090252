import React, { ReactElement } from "react";
import { StyledField } from "./Field.styled";

interface Props {
  children: React.ReactNode;
}

function Field({ children }: Props): ReactElement {
  return <StyledField className="ui-field">{children}</StyledField>;
}

export default Field;
