import * as Yup from "yup";
export const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(6, "Password is too short")
    .max(50, "Password is too long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

export const businessSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Company name is too short")
    .required("Company name is required"),
  companyAddress: Yup.string()
    .min(2, "Company address is too short")
    .required("Company address is required"),
  companyCity: Yup.string()
    .min(2, "Company city is too short")
    .required("Company city is required"),
  // state: Yup.string().min(2, "Company address is too short").required("Company address is required"),
  companyZip: Yup.string()
    .min(2, "Company zip code is too short")
    .required("Company zip code is required"),
  companyCountry: Yup.object()
    .nullable()
    .required("Company country is required"),
  companyEmailAddress: Yup.string()
    .required("Company email is required")
    .email("Company email is not valid"),
  businessType: Yup.object().nullable().required("Business type is required"),
  // tradeNumber: Yup.string().min(2, "Too Short!").required("Required"),
});

export const adminSchema = Yup.object().shape({
  adminFullname: Yup.string()
    .min(2, "Admin full name is too short")
    .required("Admin full name is required"),
  adminPhoneNumber: Yup.string().required("Admin phone number is required"),
  adminEmailAddress: Yup.string()
    .required("Admin email address is required")
    .email("Admin email is not valid"),
  adminFullAddress: Yup.string().required("Admin full address is required"),
  adminCity: Yup.string().required("Admin city is required"),
  // state: Yup.string().min(2, "Too Short!").required("Required"),
  adminZip: Yup.string()
    .min(2, "Admin zip code is too short")
    .required("Admin zip code is required"),
  adminCountry: Yup.object().nullable().required("Admin country is required"),
});

export const referenceSchema = Yup.object().shape({
  nameOfCompany: Yup.string().required("Company name is required"),
  country: Yup.object().nullable().required("Country is required"),
  contactPerson: Yup.string().required("Contact person is required"),
  referencesPhoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .required("Email address is required")
    .email("Email is not valid"),
});

export const referencesSchema = (index: number) => {
  return Yup.object().shape({
    firstReference: referenceSchema,
    secondReference: index >= 2 ? referenceSchema : Yup.object(),
    thirdReference: index >= 3 ? referenceSchema : Yup.object(),
  });
};

export const loginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  password: Yup.string()
    .required("")
    .min(6, "Password must contain at least 6 characters"),
});

export const getValidationSchema = (
  step: number,
  requireFullValidation: boolean
) => {
  if (requireFullValidation)
    return Yup.object().shape({
      user: step >= 1 ? userSchema : Yup.object(),
      aml: Yup.object({
        business: step >= 2 ? businessSchema : Yup.object(),
        admin: step >= 3 ? adminSchema : Yup.object(),
        firstReference: step >= 4 ? referenceSchema : Yup.object(),
      }),
      files: step >= 5 ? Yup.array().min(1, "To short!") : Yup.array(),
      approvedTerms:
        step >= 5
          ? Yup.boolean().oneOf([true], "Must Accept Terms of Service")
          : Yup.boolean(),
    });

  return Yup.object().shape({
    user: step === 1 ? userSchema : Yup.object(),
    aml: Yup.object({
      business: step === 2 ? businessSchema : Yup.object(),
      admin: step === 3 ? adminSchema : Yup.object(),
      firstReference: step === 4 ? referenceSchema : Yup.object(),
    }),
    files: step === 5 ? Yup.array().min(1, "To short!") : Yup.array(),
    approvedTerms:
      step === 5
        ? Yup.boolean().oneOf([true], "Must Accept Terms of Service")
        : Yup.boolean(),
  });
};
