import { Link } from "react-router-dom";
import { StyledFooter } from "./Footer.styled";

function Footer() {
  return (
    <StyledFooter className="footer">
      <p className="footer__left">
        © All rights reserved to Trade Diamonds 2022
      </p>
      <div className="footer__right">
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/terms">Terms & Conditions</Link>
        {/* <a target="_blank" href="/privacy">
          Cookies Policy
        </a> */}
      </div>
    </StyledFooter>
  );
}

export default Footer;
