import defaultImage from "assets/images/default-diamond.png";
import cn from "classnames";
import Loader from "ui/Loader";
import { ImgHTMLAttributes, useState } from "react";
import "./ProductImage.css";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  defaultImageSrc?: string;
  is360?: boolean;
}

const ProductImage = ({
  defaultImageSrc = defaultImage,
  className = "",
  is360 = false,
  ...rest
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setError(true);
  };

  return (
    <div className={cn("product-image__container", className)}>
      {!!rest.src && !error && !!is360 && (
        <iframe
          width="100%"
          height="100%"
          className={cn("product-image", "product-image--360", {
            "product-image--visible": loaded,
          })}
          style={{ overflow: "scroll" }}
          onLoad={onLoad}
          onError={onError}
          src={rest.src ?? defaultImage}
          title="diamond 360"
        />
      )}
      {!!rest.src && !error && !is360 && (
        <img
          alt="product"
          className={cn("product-image", {
            "product-image--visible": loaded && !error,
          })}
          onLoad={onLoad}
          onError={onError}
          src={rest.src ?? defaultImage}
          {...rest}
        />
      )}
      <img
        alt="default product"
        className={cn("product-image__default", {
          "product-image__default--visible": !loaded || error || !rest.src,
        })}
        src={defaultImage}
      />
      {!error && !loaded && <Loader centered color="rgba(255,255,255,0.5)" />}
    </div>
  );
};

export default ProductImage;
