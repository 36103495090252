import styles from "./MyBidsToggle.module.css";
import cn from "classnames";
import { ReactComponent as Bid } from "../../assets/icons/bid.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { numberWithCommas } from "utils";

interface Props {
  onClick: () => void;
  length: number;
  sum: number;
}

const MyBidsToggle = ({ onClick, length, sum }: Props) => {
  return (
    <div className={cn(styles.container)} onClick={onClick}>
      <div className={styles.left}>
        <Bid className={styles.leftIcon} />
        <p className={styles.leftText}>
          My Bids {length ? `(${length})` : null}
        </p>
      </div>
      <div className={styles.right}>
        <span className={styles.total}>
          {!!sum && <>Total ${numberWithCommas(sum, 0)}</>}
        </span>
        <div className={styles.arrowContainer}>
          <ChevronDown className={styles.arrow} />
        </div>
      </div>
    </div>
  );
};

export default MyBidsToggle;
