import Price from "ui/Price";
import ProductImage from "components/ProductImage";
import Shine from "ui/Shimmer/Shine";
// import Button from "ui/Button";
import Countdown from "ui/Countdown";
// import { useAnalytics } from "hooks/useAnalytics";
import useModalActions from "hooks/useModalActions";
import moment from "moment";
import { Cell, Column } from "react-table";
import { useAnalytics } from "hooks/useAnalytics";
import Button from "ui/Button";

export const pending: Column<TenderProductOffer>[] = [
  {
    Header: "Image",
    accessor: (originalRow) => originalRow.product.diamondImage,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <ProductImage
          className="p-supplier__diamonds-table__image"
          src={original.product.diamondImage}
        />
      </Shine>
    ),
    withWrapper: false,
    disableSortBy: true,
  },
  {
    Header: "Lot ID",
    accessor: (originalRow) => originalRow.product.productId,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>
          <span
            className="p-supplier__diamonds-table__lotid"
            title={original.productId}
          >
            #
            {String(original.productId).slice(
              (original.productId?.length ?? 0) / 2
            )}
          </span>
        </p>
      </Shine>
    ),
  },
  {
    Header: "Shape",
    accessor: (originalRow) => originalRow.product.model,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.model}</p>
      </Shine>
    ),
  },
  {
    Header: "Carat",
    accessor: (originalRow) => originalRow.product.carat,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.carat}</p>
      </Shine>
    ),
  },
  {
    Header: "Color",
    accessor: (originalRow) => originalRow.product.color,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.color}</p>
      </Shine>
    ),
  },
  {
    Header: "Clarity",
    accessor: (originalRow) => originalRow.product.clarity,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.clarity}</p>
      </Shine>
    ),
  },
  {
    Header: "Lab",
    accessor: (originalRow) => originalRow.product.lab,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p className="p-supplier__diamonds-table__lab">
          {original.product.lab}
        </p>
      </Shine>
    ),
  },
  {
    Header: "Location",
    accessor: (originalRow) => originalRow.product.location,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.country ?? "-"}</p>
      </Shine>
    ),
  },
  {
    Header: "Reserve Price",
    accessor: (originalRow) => originalRow.reservePrice,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <Price price={original.reservePrice} postfix="/ppc" />
      </Shine>
    ),
  },
  {
    Header: "Event",
    accessor: (originalRow) => originalRow.tender.name,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => {
      const { openModal } = useModalActions();
      return (
        <Shine>
          <button
            onClick={(e) => {
              e.stopPropagation();
              openModal({
                modalType: "Event",
                modalProps: { tender: original.tender, withSeeCatalog: false },
              });
            }}
            className="p-supplier__diamonds-table__tender"
          >
            {original.tender.name}
          </button>
        </Shine>
      );
    },
  },
  {
    Header: "Time Left to Approve",
    accessor: (originalRow) => originalRow.reservePrice,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <div className="p-supplier__diamonds-table__timeleft">
          <Countdown
            date={original.memoDate}
            withLabels={false}
            // withSeconds={false}
          />
        </div>
      </Shine>
    ),
    disableSortBy: true,
  },
  {
    Header: "Add to Event",
    id: "Action",
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => {
      const track = useAnalytics();
      const { openModal } = useModalActions();
      return (
        <Shine>
          <Button
            kind="green"
            onClick={(e) => {
              e.stopPropagation();
              track("My Diamonds - Approve Button", original);
              openModal({
                modalType: "ApproveDiamond",
                modalProps: { tenderProductOffers: [original] },
              });
            }}
            className="p-supplier__diamonds-table__approve"
          >
            Approve
          </Button>
        </Shine>
      );
    },
    disableSortBy: true,
  },
];

export const approved: Column<TenderProductOffer>[] = [
  {
    Header: "Image",
    accessor: (originalRow) => originalRow.product.diamondImage,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <ProductImage
          className="p-supplier__diamonds-table__image"
          src={original.product.diamondImage}
        />
      </Shine>
    ),
    withWrapper: false,
    disableSortBy: true,
  },
  {
    Header: "Lot ID",
    accessor: (originalRow) => originalRow.product.productId,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>
          <span className="elipsis p-supplier__diamonds-table__lotid">
            #
            {String(original.productId).slice(
              (original.productId?.length ?? 0) / 2
            )}
          </span>
        </p>
      </Shine>
    ),
  },
  {
    Header: "Shape",
    accessor: (originalRow) => originalRow.product.model,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.model}</p>
      </Shine>
    ),
  },
  {
    Header: "Carat",
    accessor: (originalRow) => originalRow.product.carat,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.carat}</p>
      </Shine>
    ),
  },
  {
    Header: "Color",
    accessor: (originalRow) => originalRow.product.color,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.color}</p>
      </Shine>
    ),
  },
  {
    Header: "Clarity",
    accessor: (originalRow) => originalRow.product.clarity,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.clarity}</p>
      </Shine>
    ),
  },
  {
    Header: "Lab",
    accessor: (originalRow) => originalRow.product.lab,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p className="p-supplier__diamonds-table__lab">
          {original.product.lab}
        </p>
      </Shine>
    ),
  },
  {
    Header: "Location",
    accessor: (originalRow) => originalRow.product.location,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <p>{original.product.country ?? "-"}</p>
      </Shine>
    ),
  },
  {
    Header: "Reserve Price",
    accessor: (originalRow) => originalRow.reservePrice,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <Shine>
        <Price price={original.reservePrice} postfix="/ppc" />
      </Shine>
    ),
  },
  {
    Header: "Event",
    accessor: (originalRow) => originalRow.tender.name,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => {
      const { openModal } = useModalActions();
      return (
        <Shine>
          <button
            onClick={(e) => {
              e.stopPropagation();
              openModal({
                modalType: "Event",
                modalProps: { tender: original.tender, withSeeCatalog: false },
              });
            }}
            className="p-supplier__diamonds-table__tender"
          >
            {original.tender.name}
          </button>
        </Shine>
      );
    },
  },
  {
    Header: "Tender Time",
    accessor: (originalRow) => originalRow.reservePrice,
    Cell: ({ row: { original } }: Cell<TenderProductOffer>) => (
      <div className="p-supplier__diamonds-table__auction">
        <Shine>
          <p>{moment(original.tender.startDate).format("DD/MM/YYYY")}</p>
        </Shine>
        <Shine>
          <p>{moment(original.tender.startDate).format("LT")}</p>
        </Shine>
      </div>
    ),
    withWrapper: false,
  },
];
