import styled from "styled-components";

interface ButtonProps {
  approved: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 180px;
  height: 42px;
  background-color: ${(props) => props.theme.colors.success[200]};
  font-family: ${(props) => props.theme.fonts.archivo};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius[6]};
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.approved ? 1 : 0.5)};
  cursor: ${(props) => (props.approved ? "pointer" : "not-allowed")};
  &:hover {
    background-color: ${(props) => props.theme.colors.success[300]};
  }
`;

export const Container = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  max-height: 70%;
  background-color: ${(props) => props.theme.colors.neutral[800]};
  padding-top: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[24]};
  padding-right: ${(props) => props.theme.spacing[40]};
  padding-left: ${(props) => props.theme.spacing[40]};
  border-radius: ${(props) => props.theme.borderRadius[10]};
  h1 {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.lg};
    font-weight: 600;
    color: ${(props) => props.theme.colors.neutral[600]};
  }

  .terms__title {
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 600;
    color: #212121;
  }

  .terms__subtitle {
    font-family: var(--roboto);
    font-size: 16px;
    font-weight: 500;
    color: #99a2b0;
    font-weight: 500;
  }

  .terms__text {
    width: 100%;
    height: 350px;
    max-height: 400px;
    background-color: ${(props) => props.theme.colors.white};
    margin-top: ${(props) => props.theme.spacing[20]};
    border-radius: ${(props) => props.theme.borderRadius[10]};
    overflow: auto;
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: 1.48;
    color: #687c97;
    padding: ${(props) => props.theme.spacing[16]};
  }

  .terms__text::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(128, 128, 128, 0.3);
  }

  .terms__text::-webkit-scrollbar-thumb {
    background-color: black;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${(props) => props.theme.spacing[24]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% - var(--sectionPadding) * 2);
    padding: var(--sectionPadding);
    height: 100%;
    max-height: 85%;
    display: grid;
    grid-template-rows: auto min-content;

    .terms__text {
      height: 100%;
      max-height: 100%;
      margin-top: 0;
      padding: 0;

      div {
        padding: var(--sectionPadding);
      }
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: unset;
      row-gap: var(--sectionPadding);
    }

    .ui-label {
      font-size: 12px;
    }

    .ui-button {
      width: 100%;
    }
  }
`;
