import Modal from "components/Modal";
import TenderProductDetails from "./comps/TenderProductDetails";
import TenderProductTable from "./comps/TenderProductTable";
import TenderProductBidding from "./comps/TenderProductBidding";
// import TenderProductEvent from "./comps/TenderProductEvent";
import TenderProductAnalysis from "./comps/TenderProductAnalysis";
import { useTenderUser } from "features/users/queries";
import { useState } from "react";
import TenderProductCertificate from "./comps/TenderProductCertificate";
import { extractId } from "utils";
import { StyledTenderProduct } from "./TenderProduct.styled";
const TenderProduct = ({
  tenderProduct,
  onlyView,
}: {
  tenderProduct: TenderProduct;
  onlyView?: boolean;
}) => {
  const [certificateIsOpen, setCertificateIsOpen] = useState(false);
  const [certificateIsActive, setCertificateIsActive] = useState(true);
  const tenderId = extractId(tenderProduct.tender as any);
  const { data: tenderUser } = useTenderUser(tenderId);

  return (
    <Modal>
      <StyledTenderProduct
        className={[
          "tender-product__wrapper",
          onlyView ? "tender-product__view" : "",
          certificateIsOpen
            ? "tender-product__certificate--open"
            : "tender-product__certificate--closed",
        ].join(" ")}
      >
        <Modal.Close />
        <div className="tender-product__container scrollbar">
          <TenderProductDetails
            tenderProduct={tenderProduct}
            setCertificateIsOpen={setCertificateIsOpen}
            certificateIsActive={certificateIsActive}
            withWishlist={!onlyView}
          />
          <TenderProductTable tenderProduct={tenderProduct} />
          {!onlyView && (
            <>
              {/* <TenderProductEvent tenderProduct={tenderProduct} />  */}
              <TenderProductBidding
                tenderProduct={tenderProduct}
                tenderId={tenderId}
                tenderUserId={tenderUser?._id || ""}
              />
              <TenderProductAnalysis
                tenderProduct={tenderProduct}
                tenderId={tenderId}
                tenderUserId={tenderUser?._id || ""}
              />
            </>
          )}
          <TenderProductCertificate
            tenderProduct={tenderProduct}
            certificateIsOpen={certificateIsOpen}
            setCertificateIsOpen={setCertificateIsOpen}
            certificateIsActive={certificateIsActive}
            setCertificateIsActive={setCertificateIsActive}
            small={!!onlyView}
          />
        </div>
      </StyledTenderProduct>
    </Modal>
  );
};

export default TenderProduct;
