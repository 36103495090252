import React from "react";
import {
  StyledLoader,
  StyledLoaderContainer,
  StyledLoaderProps,
} from "./Loader.styled";

export interface Props extends StyledLoaderProps {
  text?: React.ReactNode;
  centered?: boolean;
}

function Loader({ text, centered, ...rest }: Props) {
  return (
    <StyledLoaderContainer
      centered={!!centered}
      className="ui-loader__container"
    >
      <StyledLoader className="ui-loader" {...rest} />
      {!!text && text}
    </StyledLoaderContainer>
  );
}

export default Loader;
