import { routeGenerator } from "api";
import { axiosGD } from "lib/axiosGD";
import { useQuery } from "react-query";
import { product } from "../../faker";
// Get Diamonds - User /GET
const getGDUser = async () => {
  const { data } = await axiosGD.get(
    routeGenerator(`/dashboard/users/me/marketplace`)
  );
  return data;
};

export const useGDUser = () => {
  return useQuery<User, ApiError>("/GD/users/me", getGDUser, {
    retry: false,
    enabled: false,
    staleTime: 60 * 60 * 1000,
    cacheTime: 0,
  });
};

// Get Diamonds - User Wishlist /GET
const getGDWishlist = async (id: string) => {
  const { data } = await axiosGD.get(
    routeGenerator(`/dashboard/users/${id}/basket`)
  );
  return data;
};

const GDWishlistPlaceholderData = [{ product }];
const useGDWishlistQueryKey = "/GD/wishlist";

export const useGDWishlist = () => {
  const { data: user } = useGDUser();
  return useQuery<{ product: Product }[], ApiError>(
    useGDWishlistQueryKey,
    () => getGDWishlist(user?._id ?? ""),
    {
      placeholderData: GDWishlistPlaceholderData,
      refetchOnWindowFocus: true,
    }
  );
};
useGDWishlist.queryKey = useGDWishlistQueryKey;
useGDWishlist.placeholderData = GDWishlistPlaceholderData;
