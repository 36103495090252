import ElipseDetail from "components/ElipseDetail";
import ProductImage from "components/ProductImage";
import { useIsTenderProductSaved } from "features/products/queries";
import useModalActions from "hooks/useModalActions";
import Price from "ui/Price";
import { StyledTenderProductMobileCard } from "./TenderProductMobileCard.styled";
import { ReactComponent as Wishlist } from "assets/icons/wishlist.svg";
import { ReactComponent as WishlistGreen } from "assets/icons/wishlist-green.svg";
import {
  useSaveTenderProduct,
  useUnsaveTenderProduct,
} from "features/products/mutations";
import SwitchAnimation from "ui/SwitchAnimation";
import { useMyBids } from "features/bids/queries";
type Props = { tenderProduct: TenderProduct };

const table: { label: string; key: keyof Product }[] = [
  {
    label: "Shape",
    key: "model",
  },
  {
    label: "Carat",
    key: "carat",
  },
  {
    label: "Lab(Cert.)",
    key: "lab",
  },
  {
    label: "Color",
    key: "color",
  },
  {
    label: "C. Intensity",
    key: "fluorescenceIntensity",
  },
  {
    label: "Clarity",
    key: "clarity",
  },
  {
    label: "Cut",
    key: "cut",
  },
  {
    label: "Polish",
    key: "polish",
  },
  {
    label: "Symmetry",
    key: "symmetry",
  },
  {
    label: "Flour.",
    key: "fluorescenceColor",
  },
  {
    label: "Location",
    key: "country",
  },
];

const TenderProductMobileCard = ({ tenderProduct }: Props) => {
  const { data: myBids } = useMyBids(tenderProduct.tender as any);
  const { openModal } = useModalActions();
  const { mutate: save, isLoading: isLoadingSave } = useSaveTenderProduct({
    tenderProduct,
  });
  const { mutate: unsave, isLoading: isLoadingUnsave } = useUnsaveTenderProduct(
    { tenderProduct }
  );
  const isInWishlist = useIsTenderProductSaved({ tenderProduct });
  const handleClick = () => {
    openModal({ modalType: "TenderProduct", modalProps: { tenderProduct } });
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    isInWishlist ? unsave() : save();
  };

  const getMyBid = () => {
    const bids = (myBids || []).filter(
      (tenderProductBid) =>
        tenderProductBid.tenderProduct._id === tenderProduct._id
    );
    return bids.length > 0 ? bids[0].price : null;
  };

  const myBid = getMyBid();

  return (
    <StyledTenderProductMobileCard onClick={handleClick}>
      <div className="tender-product-mobile__top">
        <ProductImage src={tenderProduct.product.diamondImage} />
        <div className="tender-product-mobile__top__right">
          <button
            onClick={handleSave}
            disabled={isLoadingSave || isLoadingUnsave}
            className="tender-product-mobile__wishlist"
          >
            <SwitchAnimation state={isInWishlist}>
              <div className="flex">
                {isInWishlist ? <WishlistGreen /> : <Wishlist />}
              </div>
            </SwitchAnimation>
          </button>
          <div className="tender-product-mobile__top__details">
            {(["model", "carat", "color", "clarity"] as (keyof Product)[]).map(
              (key) => {
                if (!tenderProduct.product[key]) return null;
                return (
                  <ElipseDetail>
                    {tenderProduct.product[key] ?? "-"}
                  </ElipseDetail>
                );
              }
            )}
          </div>
          <div className="tender-product-mobile__my-bid">
            <p className="tender-product-mobile__my-bid__text">My Bid</p>
            {myBid ? <Price price={myBid} /> : "-"}
          </div>
        </div>
      </div>
      <div className="tender-product-mobile__table">
        {table.map(({ label, key }, index) => {
          return (
            <div className="tender-product-mobile__table__row" key={index}>
              <p className="tender-product-mobile__table__label">{label}</p>
              <p className="tender-product-mobile__table__value">
                {tenderProduct.product[key] ?? "-"}
              </p>
            </div>
          );
        })}
      </div>
    </StyledTenderProductMobileCard>
  );
};

export default TenderProductMobileCard;
