import "./Animate.css";
import React, { useEffect, useState } from "react";

function Animate({
  visible,
  children,
  delay = 0,
}: {
  visible: boolean;
  children: React.ReactNode;
  delay?: number;
}) {
  const [visibleAfterDelay, setvisibleAfterDelay] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setvisibleAfterDelay(visible);
    }, delay);
  }, [visible, delay]);
  const mappedChildren = () =>
    React.Children.map(children as any, (child) => {
      return React.cloneElement(child, {
        className: `${child.props.className} animate__appear ${
          visibleAfterDelay ? "inview" : ""
        }`,
      });
    });
  return mappedChildren();
}

export default Animate;
