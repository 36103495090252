// /dashboard/users/5e8c66ace9b12e1d80ba4ce6/basket/622803f6f292fe1b9a8c0b5d

import { routeGenerator } from "api";
import { axiosGD } from "lib/axiosGD";
import { useMutation, useQueryClient } from "react-query";
import { useGDUser, useGDWishlist } from "./queries";

const clearGDWishlist = async (userId: string) => {
  const { data } = await axiosGD.delete(
    routeGenerator(`/dashboard/users/${userId}/all/basket`)
  );
  return data;
};

export const useClearGDWishlist = () => {
  const { data: user } = useGDUser();
  const queryClient = useQueryClient();
  const GDWishlistQueryKey = useGDWishlist.queryKey;
  return useMutation<null, ApiError>(
    "clearGDWishlist",
    () => clearGDWishlist(user?._id ?? ""),
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(GDWishlistQueryKey);
        // Snapshot the previous value
        const previousGDWishlist =
          queryClient.getQueryData<typeof useGDWishlist.placeholderData>(
            GDWishlistQueryKey
          );

        // Optimistically update to the new value
        queryClient.setQueryData<typeof previousGDWishlist>(
          GDWishlistQueryKey,
          (old) => (old ? [] : undefined)
        );
        // Return a context object with the snapshotted value
        return { previousGDWishlist };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _, context) => {
        queryClient.setQueryData(
          GDWishlistQueryKey,
          (context as any)?.previousGDWishlist
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(GDWishlistQueryKey);
      },
    }
  );
};

// Get Diamonds - User Wishlist item /Delete
const removeFromGDWishlist = async (userId: string, productId: string) => {
  const { data } = await axiosGD.delete(
    routeGenerator(`/dashboard/users/${userId}/basket/${productId}`)
  );
  return data;
};

export const useRemoveFromGDWishlist = () => {
  const { data: user } = useGDUser();
  const queryClient = useQueryClient();
  const GDWishlistQueryKey = useGDWishlist.queryKey;
  return useMutation<null, ApiError, string>(
    "removeFromGDWishlist",
    (productId: string) => removeFromGDWishlist(user?._id ?? "", productId),
    {
      onMutate: async (productId) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(GDWishlistQueryKey);
        // Snapshot the previous value
        const previousGDWishlist =
          queryClient.getQueryData<typeof useGDWishlist.placeholderData>(
            GDWishlistQueryKey
          );

        // Optimistically update to the new value
        queryClient.setQueryData<typeof previousGDWishlist>(
          GDWishlistQueryKey,
          (old) =>
            old
              ? [
                  ...(old ?? []).filter(
                    ({ product }) => product._id !== productId
                  ),
                ]
              : undefined
        );
        // Return a context object with the snapshotted value
        return { previousGDWishlist };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _, context) => {
        queryClient.setQueryData(
          GDWishlistQueryKey,
          (context as any)?.previousGDWishlist
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(GDWishlistQueryKey);
      },
    }
  );
};
