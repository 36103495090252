import { RangeNames, IRangeFilter } from "types/filters";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { useRef, useState } from "react";
import useOnClickOutside from "hooks/useClickOutside";
import styles from "./RangeFilter.module.css";
import cn from "classnames";
interface Props {
  filter: IRangeFilter;
  handleOnRange: (
    filterName: RangeNames,
    type: "from" | "to",
    value: number
  ) => void;
  from: number | string;
  to: number | string;
}

const RangeFilter = ({ filter, from, to, handleOnRange }: Props) => {
  let { name, displayName, min, max } = filter;
  min = Math.floor(min);
  max = Math.ceil(max);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  useOnClickOutside(dropdownRef, close);

  const active = isOpen || !!from || !!to;

  return (
    <div
      className={cn(styles.container, "shine", "relative", {
        [styles.containerActive]: active,
      })}
      ref={dropdownRef}
    >
      <div className={styles.toggle} onClick={isOpen ? close : open}>
        <p
          className={cn(styles.toggleName, {
            [styles.toggleNameActive]: active,
          })}
        >
          {displayName}
        </p>
        <ChevronDown className={styles.toggleArrow} />
        {active && (
          <p className={styles.values}>
            {from || min} - {to || max}
          </p>
        )}
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <Range
            value={[Number(from) || Number(min), Number(to) || Number(max)]}
            onChange={(value) => {
              handleOnRange(name, "from", Number(Number(value[0]).toFixed(2)));
              handleOnRange(name, "to", Number(Number(value[1]).toFixed(2)));
            }}
            max={max}
            min={min}
            allowCross={false}
            className={styles.slider}
            step={Number((Math.ceil(max) - Math.floor(min)) / 15)}
          />
          <div className={styles.fields}>
            <div className={styles.field}>
              <label className={styles.fieldLabel} htmlFor={`from${name}`}>
                From
              </label>
              <input
                className={styles.fieldInput}
                name={`from${name}`}
                id={`from${name}`}
                type="number"
                value={from || min}
                onChange={(e) =>
                  handleOnRange(name, "from", Number(e.target.value))
                }
                max={Number(max.toFixed(2))}
                min={Number(min.toFixed(2))}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.fieldLabel} htmlFor={`to${name}`}>
                To
              </label>
              <input
                className={styles.fieldInput}
                name={`to${name}`}
                id={`to${name}`}
                type="number"
                value={to || max}
                onChange={(e) =>
                  handleOnRange(name, "to", Number(e.target.value))
                }
                max={max}
                min={min}
              />
            </div>
          </div>
        </div>
      )}
      {/* <p
        className={cn(styles.placeholder, {
          [styles.placeholderActive]: open || value.length > 0,
        })}
      >
        {displayName}
      </p> */}
    </div>
  );
};

export default RangeFilter;
