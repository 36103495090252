import { useUser } from "features/users/queries";
import useAmlStatus from "./useAmlStatus";

const useIsAmlVerified = () => {
  const { data: user } = useUser();
  const amlStatus = useAmlStatus();
  if (user?.getLogin) return true;
  return amlStatus === "approved" || false;
};

export default useIsAmlVerified;
