import styled, { keyframes } from "styled-components";

const bounce = keyframes`
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(-50%);
		-ms-transform:     translateY(-50%);
		transform:         translateY(-50%)
	}
	40% {
		-webkit-transform: translateY(-10px);
		-ms-transform:     translateY(-10px);
		transform:         translateY(-10px)
	}
	60% {
		-webkit-transform: translateY(-4px);
		-ms-transform:     translateY(-4px);
		transform:         translateY(-4px)
	}
`;

export const StyledTooltipError = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-name: ${bounce};
`;

export const StyledTooltipErrorContainer = styled.div`
  --tooltipBackground: #ffc7c7;
  color: #ef0000;
  font-family: ${({ theme }) => theme.fonts.archivo};

  .tooltip-arrow > * {
    border-color: #ffc7c7;
  }
`;
