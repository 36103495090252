import Modal from "components/Modal";
import Price from "ui/Price";
import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import TenderProductSmallCard from "components/TenderProductSmallCard";
import { useOrder } from "features/orders/queries";
import useModalActions from "hooks/useModalActions";
import moment from "moment";
import { Fragment } from "react";
import styles from "./Order.module.css";
const Order = ({ tenderOrder: orderFlat }: { tenderOrder: Order }) => {
  const { closeModal } = useModalActions();
  const { data, isFetching, isFetched } = useOrder(orderFlat._id);

  if (!data) return null;

  const getCurrentStep = () => {
    const status = data.orderStatus;
    if (status === "pending" || status.includes("verification")) return 1;
    if (status.includes("invoiced")) return 2;
    if (status.includes("shipping")) return 3;
    return 1;
  };

  const currentStep = getCurrentStep();

  return (
    <Modal>
      <Shimmer shimmer={isFetching && !isFetched}>
        <div className={styles.container}>
          <div className={styles.main}>
            <Modal.Close onClick={() => closeModal()} />
            <Shine fitContent>
              <p className={styles.title}>
                Event Order - #{String(data._id).substr(0, 7)}
              </p>
            </Shine>
            <div className={styles.divider}></div>
            <div>
              <p className={styles.progressTitle}>Order progress</p>
              <Shine>
                <div className={styles.progressBox}>
                  {["Verification", "Invoiced", "Shipped"].map(
                    (item, index, arr) => {
                      const getBackgroundColor = () => {
                        const status = data.orderStatus;
                        const GREEN = "var(--green)";
                        const RED = "var(--red)";
                        const ORANGE = "var(--orange)";
                        const GRAY = "#c2c4c5";
                        if (currentStep > index + 1) return GREEN;

                        if (currentStep === index + 1) {
                          if (status.includes("pending")) return ORANGE;
                          if (
                            status.includes("completed") ||
                            status.includes("approved")
                          )
                            return GREEN;
                          if (status.includes("rejected")) return RED;
                        }
                        return GRAY;
                      };

                      const style = {
                        opacity: index + 1 <= currentStep ? 1 : 0.5,
                        backgroundColor: getBackgroundColor(),
                      };

                      return (
                        <Fragment key={item}>
                          <div
                            className={styles.progressStep}
                            style={style}
                            key={item}
                          >
                            {item}
                          </div>
                          {arr.length - 1 !== index && (
                            <div className={styles.progressStepSpacer}>
                              &#183;&#183;&#183;
                            </div>
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </div>
              </Shine>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.boxes}>
              <div className={styles.box}>
                <p className={styles.boxLabel}>Event name</p>
                <Shine fitContent>
                  <p className={styles.boxValue}>
                    {data.tenderOrder?.tender.name}
                  </p>
                </Shine>
              </div>
              <div className={styles.box}>
                <p className={styles.boxLabel}>Date of purchase</p>
                <Shine fitContent>
                  <p className={styles.boxValue}>
                    {moment(data.createdAt).format("LL")}
                  </p>
                </Shine>
              </div>
              <div className={styles.box}>
                <p className={styles.boxLabel}>Total order value</p>
                <Shine fitContent>
                  <div>
                    <Price
                      className={styles.boxValue}
                      price={data.totalPrice}
                    />
                  </div>
                </Shine>
              </div>
              <div className={styles.box}>
                <p className={styles.boxLabel}>Total diamonds</p>
                <Shine fitContent>
                  <p className={styles.boxValue}>1</p>
                </Shine>
              </div>
            </div>
            <div className={styles.diamonds}>
              <p className={styles.diamondsTitle}>Diamond in this order</p>
              {!!data.tenderOrder?.tenderProduct && (
                <TenderProductSmallCard
                  withWishlist={false}
                  tenderProduct={data.tenderOrder?.tenderProduct as any}
                  customText={"Total Price"}
                  customValue={
                    <Price
                      className={styles.boxValue}
                      price={data.totalPrice}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
      </Shimmer>
    </Modal>
  );
};

export default Order;
