import { createArray, tender, tenderProduct } from "../../faker";
import { IUseTender } from "features/tenders/queries";
import { FiltersType } from "types/filters";

export const filtersList: FiltersType = [
  {
    displayName: "Shape",
    name: "model",
    white: true,
    fancy: true,
    type: "select",
    options: [
      { value: "rb", label: "Round" },
      { value: "ps", label: "Pear" },
      { value: "ov", label: "Oval" },
      { value: "em", label: "Emerald" },
      { value: "cu", label: "Cushion All" },
      { value: "cb", label: "Cushion .B." },
      { value: "hs", label: "Heart" },
      { value: "mq", label: "Marquise" },
      { value: "as", label: "Asscher" },
      { value: "ra", label: "Radiant" },
      { value: "pr", label: "Princess" },
    ],
  },
  {
    name: "carat",
    displayName: "Carat",
    type: "range",
    fancy: true,
    white: true,
    max: 10,
    min: 1,
  },
  {
    white: true,
    fancy: false,
    displayName: "Color",
    type: "select",
    name: "color",
    options: [
      { value: "d", label: "D" },
      { value: "e", label: "E" },
      { value: "f", label: "F" },
      { value: "g", label: "G" },
      { value: "h", label: "H" },
      { value: "i", label: "I" },
      { value: "j", label: "J" },
      { value: "k", label: "K" },
      { value: "l", label: "L" },
      { value: "m", label: "M" },
      { value: "n", label: "N" },
      { value: "o", label: "O" },
      { value: "p", label: "P" },
      { value: "q", label: "Q" },
      { value: "r", label: "R" },
      { value: "s", label: "S" },
      { value: "t", label: "T" },
      { value: "u", label: "U" },
      { value: "v", label: "V" },
      { value: "w", label: "W" },
      { value: "x", label: "X" },
      { value: "y", label: "Y" },
      { value: "z", label: "Z" },
    ],
  },
  {
    name: "clarity",
    displayName: "Clarity",
    fancy: true,
    white: true,
    type: "select",
    options: [
      { value: "FL", label: "FL" },
      { value: "IF", label: "IF" },
      { value: "LC", label: "LC" },
      { value: "VVS1", label: "VVS1" },
      { value: "VVS2", label: "VVS2" },
      { value: "VS1", label: "VS1" },
      { value: "VS2", label: "VS2" },
      { value: "SI1", label: "SI1" },
      { value: "SI2", label: "SI2" },
      { value: "SI3", label: "SI3" },
      { value: "I1", label: "I1" },
      { value: "P1", label: "P1" },
      { value: "I2", label: "I2" },
      { value: "P2", label: "P2" },
      { value: "I3", label: "I3" },
      { value: "P3", label: "P3" },
    ],
  },
  {
    type: "select",
    name: "lab",
    displayName: "Lab",
    white: true,
    fancy: true,
    options: [
      { value: "GIA", label: "GIA" },
      { value: "HRD", label: "HRD" },
      { value: "IGI", label: "IGI" },
      { value: "EGL", label: "EGL" },
      { value: "EGL USA", label: "EGL USA" },
      { value: "AGS", label: "AGS" },
      { value: "AIG", label: "AIG" },
      { value: "SGL", label: "SGL" },
      { value: "CGL", label: "CGL" },
      { value: "DCLA", label: "DCLA" },
      { value: "GCAL", label: "GCAL" },
      { value: "GSI", label: "GSI" },
      { value: "NGTC", label: "NGTC" },
      { value: "PGS", label: "PGS" },
      { value: "VGR", label: "VGR" },
      { value: "IGL", label: "IGL" },
      { value: "None", label: "None" },
      { value: "Other", label: "Other" },
    ],
  },
  {
    displayName: "Cut",
    type: "select",
    white: true,
    fancy: true,
    name: "cut",
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  {
    name: "polish",
    displayName: "Polish",
    type: "select",
    white: true,
    fancy: true,
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  {
    white: true,
    fancy: true,
    type: "select",
    name: "symmetry",
    displayName: "Symmetry",
    options: [
      { value: "i", label: "ID" },
      { value: "ex", label: "EX" },
      { value: "vg", label: "VG" },
      { value: "g", label: "G" },
      { value: "f", label: "F" },
      { value: "p", label: "P" },
    ],
  },
  // {
  //   name: "country",
  //   displayName: "Location",
  //   white: true,
  //   fancy: true,
  //   type: "select",
  //   options: [
  //     { value: "USA", label: "USA" },
  //     { value: "Hong Kong", label: "Hong Kong" },
  //     { value: "China", label: "China" },
  //     { value: "Belgium", label: "Belgium" },
  //     { value: "India", label: "India" },
  //     { value: "Israel", label: "Israel" },
  //     { value: "Australia", label: "Australia" },
  //     { value: "Canada", label: "Canada" },
  //     { value: "Denmark", label: "Denmark" },
  //     { value: "Dubai", label: "Dubai" },
  //     { value: "France", label: "France" },
  //     { value: "Indonesia", label: "Indonesia" },
  //     { value: "Italy", label: "Italy" },
  //     { value: "Japan", label: "Japan" },
  //     { value: "London", label: "London" },
  //     { value: "Monaco", label: "Monaco" },
  //     { value: "Philippines", label: "Philippines" },
  //     { value: "Russia", label: "Russia" },
  //     { value: "Spain", label: "Spain" },
  //     { value: "Switzerland", label: "Switzerland" },
  //     { value: "Thailand", label: "Thailand" },
  //     { value: "Turkey", label: "Turkey" },
  //     { value: "UAE", label: "UAE" },
  //     { value: "UK", label: "UK" },
  //   ],
  // },
];

export const defaultTenderData: IUseTender = {
  diamonds: 67,
  bids: 1200,
  activeUsers: 657,
  tender,
};

export const defaultTenderProductsData = createArray(
  tenderProduct,
  10
) as TenderProduct[];
