import { routeGenerator } from "api";
import { axios } from "lib/axios";
import { useQuery } from "react-query";
// Get wishlist
const getWishlist = async () => {
  const { data } = await axios.get(routeGenerator(`/tenderUsers/wishlist`));
  return data;
};

const useWishlistQueryKey = ["tenders", "wishlist"];
export const useWishlist = () => {
  return useQuery<TenderProduct[], ApiError>(useWishlistQueryKey, getWishlist, {
    staleTime: 60 * 60 * 1000,
  });
};

useWishlist.queryKey = useWishlistQueryKey;
