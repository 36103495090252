import "react-phone-input-2/lib/style.css";
import { StyledPhoneInput } from "./PhoneInput.styled";
import PhoneInput2, { PhoneInputProps } from "react-phone-input-2";
import "react-popper-tooltip/dist/styles.css";
import TooltipError from "ui/TooltipError";
function PhoneInput(
  props: PhoneInputProps & { isError?: boolean; error?: string }
) {
  return (
    <StyledPhoneInput error={!!props.error}>
      <PhoneInput2
        containerClass="ui-phone-input"
        country={"us"}
        searchPlaceholder="Search Country"
        {...props}
      />
      {props.isError && props.error && <TooltipError error={props.error} />}
    </StyledPhoneInput>
  );
}

export default PhoneInput;
