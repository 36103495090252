import styled from "styled-components";

export const StyledApproveDiamonds = styled.div`
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  position: relative;
  padding: 1rem;
  border-radius: 15px;
  width: 100%;
  max-width: 620px;

  .approve-diamond__title {
    font-family: var(--archivo);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.28;
    color: #687c97;
    margin-bottom: 1rem;
  }

  .approve-diamonds__terms {
    width: 100%;
    height: 220px;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 15px;
    border: solid 1px #dfeaf2;
    background-color: #fff;
    font-family: var(--archivo);
    font-size: 13.5px;
    line-height: 1.48;
    letter-spacing: 0.27px;
    color: #687c97;
    padding: 12px 20px;
  }

  .approve-diamond__details {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 45px 45px;
    row-gap: 1rem;
    column-gap: 1rem;
    border-bottom: 1.5px solid rgba(104, 124, 151, 0.3);
    padding-bottom: 1rem;
  }

  .approve-diamond__detail {
    border-left: 1.5px solid rgba(104, 124, 151, 0.3);
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .approve-diamond__detail__title {
    font-family: var(--archivo);
    font-size: 11.5px;
    line-height: 1.48;
    letter-spacing: 0.58px;
    color: #687c97;
    text-transform: uppercase;
  }

  .approve-diamond__detail__value {
    font-family: var(--archivo);
    font-size: 17px;
    line-height: 1.21;
    color: #687c97;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  .approve-diamond__checkboxes {
    display: grid;
    row-gap: 12px;
  }

  .approve-diamond__actions {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .approve-diamond__approve {
  }

  .approve-diamond__cancel {
    font-family: var(--archivo);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.92;
    letter-spacing: 0.6px;
    color: #687c97;
    background-color: transparent;
    text-decoration: underline;
    transition: all 0.3s ease-in;
    margin-left: 12px;
  }

  .approve-diamond__mark {
    font-family: var(--archivo);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.92;
    letter-spacing: 0.24px;
    color: #687c97;
    margin-top: 10px;
  }

  .tooltip-container {
    background-color: #fff;
    box-shadow: 0 5px 30px 0 rgba(192, 203, 209, 0.28);
    border: solid 1px rgba(192, 203, 209, 0.28);
    font-family: var(--archivo);
    color: #687c97;
    cursor: default;
  }

  .ui-loader__container {
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 6px;
    z-index: 3;
  }

  .ui-loader__container span {
    margin-bottom: 8px;
  }

  .ui-loader__container {
    color: #687c97;
    font-family: var(--archivo);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% - var(--sectionPadding) * 2);
    max-width: 100%;
    max-height: calc(100% - var(--headerHeight));
    overflow: auto;
    overflow-x: hidden;
    padding: var(--sectionPadding);

    .approve-diamond__details {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: unset;
    }

    .approve-diamond__mark {
      line-height: unset;
      font-size: 11px;
    }
  }
`;
