import styled from "styled-components";

export const StyledCheckbox = styled.div<{
  error?: boolean;
  checked?: boolean;
}>`
  display: flex;
  align-items: center;

  .ui-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid #687c97;
    border-radius: 4px;

    & > * {
      cursor: pointer;
    }
  }

  .ui-checkbox__icon {
    position: absolute;
    opacity: ${(props) => (props.checked ? "1" : "0")};
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
    color: #687c97;
  }

  .ui-checkbox__input {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease-in-out;
    /* position: absolute; */
    position: relative;
  }

  .ui-label {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;
