import styled from "styled-components";

export const StyledTenderProductDetails = styled.div`
  height: 215px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 20px 0 rgb(74 85 100 / 8%);
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  grid-column: 1;
  grid-row: 1;

  .tender-product__details__left {
    height: 100%;
    width: 150px;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
  }

  .tender-product__details__image {
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .tender-product__details__more-images {
    width: 100%;
    height: 35px;
    min-height: 35px;
    border-radius: 6px;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 5%);
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .tender-product__details__more-images__icon {
    width: 18px;
    color: #687c97;
  }

  .tender-product__details__more-images__text {
    margin-left: 4px;
    font-family: var(--archivo);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.48px;
    color: #687c97;
  }

  .tender-product__details__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
  }

  .tender-product__details__payment {
    padding: 0 0 14px 0;
    border-bottom: solid 1.7px #edf2f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tender-product__details__payment__label {
    font-family: var(--archivo);
    font-size: 12px;
    letter-spacing: 0.53px;
    color: #687c97;
  }

  .tender-product__details__payment__value {
    font-family: var(--archivo);
    font-weight: 500;
    font-size: 16px;
    color: #687c97;
    margin-top: 4px;
  }

  .tender-product__details__save-diamond {
    padding: 10px 16px 10px 16px;
    border-radius: 6px;
    box-shadow: 0 3px 12px 0 rgb(0 0 0 / 8%);
    background-color: #fff;
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.65px;
    color: #687c97;
  }

  .tender-product__details__short-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -8px;
    padding: 12px 0;
  }

  .tender-product__details__short-details > * {
    margin: 8px 8px 0 0;
  }

  .tender-product__details__certificate {
    border-top: solid 1.7px #edf2f6;
    padding-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tender-product__details__certificate__logo--mobile {
    display: none;
  }

  .tender-product__details__certificate__logo {
    width: 100px;
    object-fit: contain;
  }

  .tender-product__details__view-certificate {
    padding: 6px 8px;
    background-color: #f5a000;
    border-radius: 5px;
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }
`;
