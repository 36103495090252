// Main
import Login from "pages/Login";
import Register from "pages/Register/Register";
import Overview from "pages/Overview";
import Supplier from "pages/Supplier";
import Tender from "pages/Tender";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import ModalManager from "components/Modal/ModalManager";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "components/Routes";
import Support from "pages/Support";
import SocketContext from "contexts/SocketContext";
import { useUser, useUserLocation } from "features/users/queries";
import { useGDUser } from "features/gd/queries";
import Orders from "pages/Orders";
// import { addCSS } from "utils";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";
import About from "pages/About";
// import Test from "pages/Test";
import Terms from "pages/Terms";
import Privacy from "pages/Privacy";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import useTerms from "hooks/useTerms";
import { useEffect, useState } from "react";
// import useRegister from "hooks/useRegister";
import { PageViewAnalytics } from "hooks/useAnalytics";
import Loader from "ui/Loader";
import { GD_CLIENT_URL } from "config";
// import ComingSoon from "pages/ComingSoon";
function App() {
  useTerms();
  const [ready, setReady] = useState(false);
  const { refetch: fetchUser } = useUser();
  const { refetch: fetchGDUser } = useGDUser();
  const { refetch: fetchUserLocation } = useUserLocation();

  useEffect(() => {
    (async () => {
      await fetchUserLocation();
      const { data: user } = await fetchUser();
      const { data: GDUser } = await fetchGDUser();
      if (!user || !GDUser) return (window.location.href = GD_CLIENT_URL);
      setReady(true);
    })();
  }, [fetchUserLocation, fetchUser, fetchGDUser]);

  if (!ready) return <Transfer />;

  return (
    <ThemeProvider theme={theme}>
      <SocketContext>
        <Router>
          <ModalManager />
          <PageViewAnalytics />
          <ToastContainer
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            autoClose={3000}
            position="bottom-left"
          />
          {/* {loggedIn && <Header isSupplier={true}>{null}</Header>} */}
          <Switch>
            <Route path="/GD" children={null} />
            <Routes.Auth path="/login" children={<Login />} />
            <Routes.Auth path="/register" children={<Register />} />
            <Routes.Auth path="/forgot" children={<ForgotPassword />} />
            <Routes.Auth path="/reset/:token" children={<ResetPassword />} />
            <Routes.Private path="/overview" children={<Overview />} />
            <Routes.Private path="/tender/:id" children={<Tender />} />
            <Routes.Private
              path="/supplier/mydiamonds/:type"
              children={<Supplier />}
            />
            <Routes.Private
              path="/supplier/orders/:type"
              children={<Orders type="supplier" />}
            />
            <Routes.Private
              path="/buyer/orders"
              children={<Orders type="buyer" />}
            />
            <Routes.Private path="/support" children={<Support />} />
            <Routes.Private path="/faq" children={<Support />} />
            <Routes.Private path="/about" children={<About />} />
            <Routes.Private path="/terms" children={<Terms />} />
            <Routes.Private path="/privacy" children={<Privacy />} />
            <Redirect to="/overview" />
          </Switch>
        </Router>
      </SocketContext>
    </ThemeProvider>
  );
}

const Transfer = () => {
  return (
    <Loader
      centered
      size={55}
      color={"#005790"}
      text={
        <>
          <span className="transfer__title">
            You're being transferred to Trade Diamonds
          </span>
          <span className="transfer__subtitle">Powered by Lucy</span>
        </>
      }
    />
  );
};

export default App;
