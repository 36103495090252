import Shine from "ui/Shimmer/Shine";

const Description = () => {
  return (
    <div className="p-overview__description">
      <p className="p-overview__description__title">
        Welcome to Trade Diamonds!
      </p>
      <div className="p-overview__description__texts">
        <Shine>
          <p className="p-overview__description__text">
            Trade Diamonds is an online tenders platform for polished diamonds,
            which utilizes sophisticated market analysis and AI algorithms to
            provide the most sought-after diamonds in the marketplace,
            competitively priced in the most accurate manner and available for
            sale via a user-friendly online bidding system.
          </p>
        </Shine>
      </div>
    </div>
  );
};

export default Description;
