import { useFormik } from "formik";
import Field from "ui/Field";
import Label from "ui/Label";
import { StyledForgotPassword } from "./ForgotPassword.styled";
import * as Yup from "yup";
import Input from "ui/Input";
import Button from "ui/Button";
import { useCheckEmail, useForgotPassword } from "features/users/mutations";
import { asyncCatch } from "utils";
import Animate from "ui/Animate";
import GoBack from "ui/GoBack";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
});

function ForgotPassword() {
  const {
    mutateAsync: checkEmail,
    isLoading: isLoadingCheckEmail,
  } = useCheckEmail();

  let {
    mutateAsync: forgotPassword,
    isSuccess: isSuccessForgotPassword,
    isLoading: isLoadingForgotPassword,
  } = useForgotPassword();

  const formik = useFormik({
    validationSchema,
    initialValues: { email: "" },
    onSubmit: async (values, helpers) => {
      // validate email
      const [checkEmailError] = await asyncCatch(checkEmail(values.email));
      if (!checkEmailError)
        return helpers.setFieldError("email", "Email not found");
      // send email
      const url = window.location.origin + "/reset/";
      const [forgotPasswordErr] = await asyncCatch(
        forgotPassword({ email: values.email, url })
      );
      if (forgotPasswordErr)
        return helpers.setFieldError(
          "email",
          "Could not send email, please try again later"
        );
    },
  });

  const emailIsError = !!formik.errors.email && !!formik.touched.email;

  return (
    <StyledForgotPassword>
      <div className="forgot__inner">
        <p className="forgot__title">Forgot your password?</p>
        <p className="forgot__subtitle">
          Enter the email associated with your account and we’ll send an email
          with instructions to reset you password.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <Field>
            <Label>Email</Label>
            <Input
              name="email"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="email@email.com"
              error={formik.errors.email}
              isError={emailIsError}
            />
            <Animate visible={isSuccessForgotPassword}>
              <p className="forgot__email__sent forgot__email__sent--mobile">
                Email sent successfully
              </p>
            </Animate>
          </Field>
          <Button
            kind="purple"
            type="submit"
            loading={isLoadingForgotPassword || isLoadingCheckEmail}
          >
            Send
          </Button>
          <Animate visible={isSuccessForgotPassword}>
            <p className="forgot__email__sent">Email sent successfully</p>
          </Animate>
        </form>
      </div>
      <GoBack to="/login">Back to login page</GoBack>
    </StyledForgotPassword>
  );
}

export default ForgotPassword;
