import styled from "styled-components";

export const StyledWishlistCard = styled.div<{ type: "GET" | "TRADE" }>`
  background: #ffffff;
  border: 1px solid #f5f8fa;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.light};
  transition: all 0.3s ease-in;
  border: 3px solid
    ${(props) => (props.type === "GET" ? "transparent" : "#1565c0")};
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.md};
  }

  .product__image {
    height: 64px;
    width: 64px;
    min-height: 64px;
    min-width: 64px;
  }

  .product__details {
    text-align: center;
    font-family: var(--archivo);
    line-height: 20px;
    color: #687c97;

    span {
      display: inline-block;
    }

    .elipsis {
      max-width: 70px;
    }
  }

  .product__properties {
    display: flex;
    align-items: center;
    flex: 1;
    & > * {
      text-transform: uppercase;
      margin-right: 8px;
    }
  }

  .product__certificate {
    span {
      margin-left: 4px;
    }
  }

  .product__price {
  }

  .remove__card {
    color: #687c97;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product__counter {
    padding: 0 1rem;
    background: #1565c0;
    border-radius: 4px;
    padding: 8px 12px;

    .product__counter__text {
      font-family: ${({ theme }) => theme.fonts.roboto};
      font-size: 10px;
      color: #fff;
      text-align: center;
    }

    .ui-countdown__value {
      font-size: 14px;
      color: #fff;
    }

    .ui-countdown__seperator::after {
      color: #fff;
      font-size: 14px;
      margin: 0 2px;
    }
  }

  .tenders__tag {
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: #1565c0;
    z-index: 2;
    color: #fff;
    font-size: 14px;
    padding: 2px 4px;
    border-radius: 3px;
  }
`;

export const StyledWishlistModal = styled.div`
  background: #ffffff;
  border: 1px solid #f5f8fa;
  box-sizing: border-box;
  border-radius: 7px;
  width: calc(100% - var(--sectionPadding) * 2);
  max-width: 980px;
  padding: 1.5rem;
  position: relative;

  .wishlist__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wishlist__actions {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 1rem;
      height: 40px;
      font-size: 14px;
    }
  }

  h1 {
    font-family: var(--archivo);
    font-weight: 600;
    font-size: 20px;
    color: #212121;
  }

  .wishlist__cards {
    margin-top: 1rem;
    min-height: 250px;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 8px;

    ${StyledWishlistCard}:last-of-type {
      margin-bottom: 0;
    }
  }

  .wishlist__cards--empty {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--archivo);
    font-size: 1.125rem;
    color: #212121;
    opacity: 0.4;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
  }
`;
