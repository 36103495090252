import { ReactComponent as Buyer } from "../../../assets/images/buyer-acc.svg";
// import { ReactComponent as Broker } from "../../../assets/images/broker-acc.svg";
import { ReactComponent as Seller } from "../../../assets/images/seller-acc.svg";
import { ReactComponent as PurpleCheck } from "../../../assets/icons/purple-check-circle.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { useRegisterContext } from "../Register";
import { useEffect } from "react";
import { useAnalytics } from "hooks/useAnalytics";
import Button from "ui/Button";
import { RegisterStepOne, RegisterStepOneOption } from "../Register.styled";

const options = [
  {
    title: "Buyer Account",
    subtitle: "Search, add to your wishlist and bid on any diamond.",
    Icon: Buyer,
    value: "buyer",
  },
  // {
  //   title: "Broker Account",
  //   subtitle: "Upload your diamond stock, contact with suppliers and trade. ",
  //   Icon: Broker,
  //   value: "broker",
  // },
  {
    title: "Buyer And Seller Account",
    subtitle: "Upload your diamond stock, manage bids and trade.",
    Icon: Seller,
    value: "buyer-and-seller",
  },
];

const StepOne = () => {
  const track = useAnalytics();
  const { formik, goToStep } = useRegisterContext();
  const selectedValue = formik.values.user.accountType;
  const setSelectedValue = (value: string) =>
    formik.setFieldValue("user.accountType", value);

  useEffect(() => {
    track("Registration Step - Account Type");
  }, [track]);

  return (
    <RegisterStepOne>
      <p className="register__step__title">Please choose an account type</p>
      <div className="register__step__one__options">
        {options.map(({ title, subtitle, Icon, value }, index) => {
          return (
            <RegisterStepOneOption
              selected={selectedValue === value}
              onClick={() => setSelectedValue(value)}
              key={index}
            >
              <div className="register__step__one__option__icon">
                <Icon />
              </div>
              <div className="register__step__one__option__texts">
                <p className="register__step__one__option__title">{title}</p>
                <p className="register__step__one__option__subtitle">
                  {subtitle}
                </p>
              </div>

              <PurpleCheck
                className="register__step__one__option__check"
                style={{ opacity: selectedValue === value ? 1 : 0 }}
              />
            </RegisterStepOneOption>
          );
        })}
      </div>
      <Button
        kind="purple"
        type={"button"}
        onClick={() => {
          goToStep(1);
        }}
        disabled={!selectedValue}
      >
        Next <ChevronRight />
      </Button>
    </RegisterStepOne>
  );
};

export default StepOne;
