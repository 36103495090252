import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { StyledGoBack } from "./GoBack.styled";
interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  newTab?: boolean;
  to: string;
  children: React.ReactNode;
}

const GoBack = ({ to, children, newTab = false, ...rest }: Props) => {
  const history = useHistory();
  return (
    <StyledGoBack
      onClick={() => (!newTab ? history.push(to) : window.open(to))}
      {...rest}
      className={["ui-goback", rest.className].join(" ")}
    >
      <ChevronLeft />
      {children}
    </StyledGoBack>
  );
};

export default GoBack;
