import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: calc(100% - var(--headerHeight));
  width: 100%;
  overflow-x: hidden;

  .layout__right {
    flex: 1;
    height: 100%;
    width: calc(100% - var(--drawerWidth));
    overflow-x: hidden;
  }
`;
