import useCountdown from "hooks/useCountdown";
import { StyledCountdown } from "./Countdown.styled";

interface Props {
  date: string;
  withDays?: boolean;
  withSeconds?: boolean;
  onFinish?: () => void;
  withLabels?: boolean;
}

const Countdown = ({
  date,
  withDays = true,
  withSeconds = true,
  onFinish = () => {},
  withLabels = true,
}: Props) => {
  const { days, hours, minutes, seconds } = useCountdown(date, onFinish);

  return (
    <StyledCountdown className="ui-countdown" withLabels={withLabels}>
      {withDays && (
        <>
          <div className="ui-countdown__box ui-countdown__box--days">
            <p className="ui-countdown__value ">{days}</p>
            <p className="ui-countdown__label">Days</p>
          </div>
          <div className="ui-countdown__seperator ui-countdown__box--days-seperator"></div>
        </>
      )}
      <div className="ui-countdown__box ui-countdown__box--hours">
        <p className="ui-countdown__value">{hours}</p>
        <p className="ui-countdown__label">Hours</p>
      </div>
      <div className="ui-countdown__seperator ui-countdown__box--hours-seperator"></div>
      <div className="ui-countdown__box ui-countdown__box--minutes">
        <p className="ui-countdown__value">{minutes}</p>
        <p className="ui-countdown__label">Minutes</p>
      </div>
      <div className="ui-countdown__seperator ui-countdown__box--minutes-seperator"></div>
      {withSeconds && (
        <div className="ui-countdown__box ui-countdown__box--seconds">
          <p className="ui-countdown__value">{seconds}</p>
          <p className="ui-countdown__label">Seconds</p>
        </div>
      )}
    </StyledCountdown>
  );
};

export default Countdown;
