import styled from "styled-components";

export const StyledTenderProductAnalysis = styled.div`
  border-radius: 11px;
  box-shadow: 0 5px 30px 0 rgba(74, 85, 100, 0.14);
  background-color: #fff;
  width: 100%;
  flex: 1;
  padding: 1rem;
  display: grid;
  grid-template-rows: min-content auto;
  gap: 10px;
  display: flex;
  flex-direction: column;

  .tender-product__analysis__top {
    display: flex;
    align-items: center;
  }

  .tender-product__analysis__top__icon {
    color: #687c97;
    width: 20px;
    margin-right: 4px;
  }

  .tender-product__analysis__top__title {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.65px;
    color: #687c97;
    /* display: none; */
  }

  .tender-product__analysis__graph {
    flex: 1;
  }

  .tender-product__analysis__no-data {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    color: #687c97;
    background-color: #fafafa;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .tender-product__analysis__no-data svg {
    transform: scale(0.75);
    opacity: 0.75;
  }

  .tender-product__analysis__no-data p {
    opacity: 0.75;
  }

  .recharts-default-legend {
    display: none;
  }

  .recharts-text.recharts-cartesian-axis-tick-value {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
  }

  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-grid-horizontal {
    display: none;
  }

  .recharts-cartesian-axis-line {
    stroke: rgba(104, 124, 151, 0.4);
    stroke-dasharray: none;
  }

  .recharts-cartesian-grid-vertical > line {
    stroke-dasharray: none;
    /* display: none; */
  }

  .recharts-cartesian-grid-vertical > line:last-of-type {
    /* display: none; */
  }

  .recharts-xAxis .recharts-cartesian-axis-line {
    /* display: none; */
  }

  .recharts-dot.recharts-line-dot {
    fill: #687c97;
    r: 3;
  }

  .recharts-yAxis .recharts-cartesian-axis-tick-value {
    display: none;
  }

  .tender-product__analysis__tooltip {
    box-shadow: 0 3px 4px 0 #eaeef2;
    border: solid 1px rgba(104, 124, 151, 0.19);
    background-color: #fff;
    padding: 12px;
    border-radius: 6px;
  }

  .tender-product__analysis__tooltip__text {
    font-family: var(--archivo);
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #687c97;
    margin-bottom: 4px;
  }

  .tender-product__analysis__tooltip .price {
    font-size: 14px;
  }

  .tender-product__analysis__summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 0.75rem;
    /* margin-bottom: 12px; */
  }

  .tender-product__analysis__summary__box {
    overflow: hidden;
    border-left: 1px solid #edf2f6;
    padding-left: 12px;
  }

  .tender-product__analysis__summary__box__label {
    font-family: var(--archivo);
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #687c97;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  .tender-product__analysis__summary__box__value {
    font-family: var(--archivo);
    font-size: 15px;
    color: #687c97;
  }

  .tender-product__analysis__summary__box__value > * {
    font-size: 15px;
  }

  .tender-product__analysis__summary__box__label > * {
    font-size: 12px;
  }

  .dot {
    width: 6px;
    height: 6px;
    min-height: 6px;
    min-width: 6px;
    border-radius: 6px;
    margin-right: 4px;
  }

  .dot.orange {
    background-color: #f5a000;
  }

  .dot.green {
    background-color: #32dea5;
  }

  .dot.purple {
    background-color: #492cbd;
  }

  .recharts-cartesian-axis-tick {
    display: none;
  }
`;
