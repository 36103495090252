import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { modalComponentLookupTable } from "../../components/Modal/ModalManager";

type ArgumentTypes<F> = F extends (...args: infer A) => any ? A : never;

export type BaseState<T> = {
  [K in keyof T]: {
    modalType: K;
    modalProps: ArgumentTypes<T[K]>[0];
    withoutClose?: boolean;
  };
}[keyof T];

export type ModalState = BaseState<typeof modalComponentLookupTable>;

const initialState: ModalState[] = [];

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalState>) => {
      if (state?.[state.length - 1]?.modalType !== action.payload.modalType)
        state.push(action.payload);
    },
    closeModal: (state) => {
      state.pop();
    },
    closeSpecificModal: (
      state,
      action: PayloadAction<{ modalType: string }>
    ) => {
      return state.filter(
        ({ modalType }) => modalType !== action.payload.modalType
      );
    },
    clearModals: () => {
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openModal,
  closeModal,
  closeSpecificModal,
  clearModals,
} = modalsSlice.actions;

export default modalsSlice.reducer;
