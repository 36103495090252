import styled from "styled-components";

export const StyledTenderProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1050px;
  width: 100%;
  height: 680px;
  max-height: 680px;
  position: relative;

  .tender-product__view {
    max-width: calc(1050px / 2);
  }

  .tender-product__container {
    padding: 16px;
    /* border: solid 1px #edf2f6; */
    background-color: #f5f7f9;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 215px auto;
    grid-gap: 1rem;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    border-radius: 15px;
  }

  &.tender-product__view .tender-product__container {
    display: block;
    overflow: auto;
  }

  .tender-product__certificate {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1100px) {
    max-width: 680px;
    width: 80%;
    max-height: 80%;

    .tender-product__container {
      display: flex;
      flex-direction: column;
      gap: unset;
      overflow: auto;
    }

    .tender-product__details,
    .tender-product__bidding,
    .tender-product__analysis,
    .tender-product__tables {
      overflow: unset;
      margin: 0;
      margin-bottom: 1rem;
    }

    .tender-product__bidding {
      order: 2;
    }

    .tender-product__analysis {
      order: 3;
    }

    .tender-product__tables {
      order: 4;
      display: block;
    }

    .tender-product__analysis__graph {
      min-height: 200px;
    }

    .product-table__grid .product-table__grid-box {
      height: 24px;
    }

    .product-table:first-of-type {
      margin-bottom: 1rem;
    }

    &.tender-product__certificate--open .tender-product__container {
      overflow: hidden;
    }

    .tender-product__certificate__inner {
      display: block;
      overflow: auto;
      padding-top: 0;
    }

    .tender-product__certificate__top {
      padding-top: 10px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }

    .tender-product__certificate__wrapper,
    .tender-product__certificate__left {
      all: unset;
      display: grid;
      row-gap: 1rem;
      grid-template-columns: unset;
    }

    .tender-product__certificate__right > div {
      height: unset;
    }

    .tender-product__certificate__section__title {
      font-size: 12px;
    }

    .tender-product__certificate__table__cell__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: calc(100% - var(--sectionPadding) * 2);
    width: 100%;

    .tender-product__analysis__summary {
      grid-template-columns: 1fr 1fr;
    }

    .product-table__grid {
      display: block;
    }

    .tender-product__table__two .product-table__grid .product-table__grid-box,
    .tender-product__table .product-table__grid .product-table__grid-box {
      height: 28px;
    }

    .product-table__table:last-of-type {
      margin-bottom: -12px;
      margin-top: 1rem;
    }

    .tender-product__bidding__main {
      display: block;
    }

    .tender-product__bidding__main input {
      display: block;
      width: 100%;
      height: 38px;
      margin-bottom: 12px;
    }

    .tender-product__bidding__reserve svg {
      display: none;
    }

    .tender-product__bidding__reserve > span {
      margin: 0;
    }

    .tender-product__bidding__cancel {
      min-height: 38px;
      height: 38px;
    }

    .ui-price {
      font-size: 14px;
      margin-left: 4px;
    }

    .tender-product__details__certificate__logo {
      display: none;
    }

    .tender-product__details__certificate__logo--mobile {
      display: none;
    }

    .tender-product__details__certificate__logo--mobile {
      display: block !important;
      max-width: 100%;
      flex: 1;
      margin: 4px 0;
      object-fit: contain;
      max-height: 35px;
      height: 35px;
      overflow: hidden;
    }

    .tender-product__details__view-certificate {
      height: 35px;
      width: 100%;
    }

    .tender-product__details {
      order: 1;
      display: grid;
      grid-template-columns: 0.65fr 1fr;
      gap: 10px;
      height: unset;
    }

    .tender-product__details__left {
      width: auto;
      gap: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .tender-product__details__short-details {
      all: unset;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      margin-top: -8px;
      padding: 8px 0;

      & > * {
        margin-top: 8px;
        margin-right: 8px;
      }
    }

    .tender-product__details__image {
      height: 100px;
    }

    .tender-product__details__save-diamond {
      padding: 5px 8px;
    }
  }
`;
