import React, { ReactElement } from "react";
import { StyledCheckbox } from "./Checkbox.styled";
import Label from "../Label";
import { ReactComponent as Check } from "assets/icons/check.svg";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  error?: boolean;
  ref?: React.MutableRefObject<any>;
  containerClassName?: string;
}

function Checkbox({
  label,
  error,
  ref,
  containerClassName,
  ...rest
}: Props): ReactElement {
  return (
    <StyledCheckbox
      className={["ui-checkbox__container", containerClassName].join(" ")}
      checked={!!rest.checked}
      error={!!error}
    >
      <div className="ui-checkbox">
        <input
          type="checkbox"
          ref={ref}
          {...rest}
          className="ui-checkbox__input"
        />
        <Check className="ui-checkbox__icon" />
      </div>
      {label && <Label>{label}</Label>}
    </StyledCheckbox>
  );
}

export default Checkbox;
