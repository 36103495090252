import styled from "styled-components";

export const Container = styled.div`
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: var(--sectionPadding);
  padding-bottom: 0;
  overflow-x: hidden;

  .inner {
    max-width: 75rem;
  }

  .title {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.xl};
    font-weight: bold;
    color: ${(props) => props.theme.colors.neutral[700]};
    margin-bottom: ${(props) => props.theme.spacing[16]};
  }

  .subtitle {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props.theme.colors.neutral[600]};
    margin-top: ${(props) => props.theme.spacing[16]};
  }

  .contacts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${(props) => props.theme.spacing[32]};
    margin-top: ${(props) => props.theme.spacing[24]};
    margin-bottom: ${(props) => props.theme.spacing[64]};
  }

  .contact {
    border-radius: ${(props) => props.theme.borderRadius[6]};
    box-shadow: 0 5px 30px 0 rgba(192, 203, 209, 0.28);
    border: solid 1px rgba(192, 203, 209, 0.28);
    background-color: #fff;
    padding: var(--sectionPadding);
    display: flex;
    align-items: center;

    a:hover {
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }

  .contact__title {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.lg};
    font-weight: 500;
    color: ${(props) => props.theme.colors.neutral[700]};
  }

  .contact__subtitle {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.base};
    color: #687c97;
    margin-top: ${(props) => props.theme.spacing[12]};
    line-height: 1.53;
  }

  .contact__link {
    margin-top: ${(props) => props.theme.spacing[20]};
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize.md};
    color: ${(props) => props.theme.colors.neutral[600]};
    text-decoration: underline;
  }

  .contact__left {
    margin-right: ${(props) => props.theme.spacing[24]};
  }

  .faq__subtitile {
    margin-bottom: ${(props) => props.theme.spacing[16]};
  }

  .footer {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    .contact {
      display: grid;
      row-gap: 1rem;
      gap: var(--sectionPadding);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .contact {
      display: flex;
      gap: unset;
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .contact {
      display: flex;
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr;
      gap: var(--sectionPadding);
      margin-bottom: calc(var(--sectionPadding) * 2);
    }

    .contact__left {
      margin-right: var(--sectionPadding);
    }

    .contact__title {
      font-size: ${(props) => props.theme.fontSize[18]};
    }

    .contact__subtitle {
      font-size: ${(props) => props.theme.fontSize[14]};
    }

    .ui-accordion__header {
      padding: var(--sectionPadding);
    }
  }
`;
