import styled from "styled-components";

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sectionPadding) 0;
  margin: 0 var(--sectionPadding) 0 var(--sectionPadding);
  border-top: 1.5px solid ${(props) => props.theme.colors.neutral[200]};
  .footer__left,
  .footer__right {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize[12]};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .footer__right {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.neutral[600]};
    outline: none;

    a {
      all: unset;
      margin: 0 1rem;
      cursor: pointer;
    }

    a:last-of-type {
      margin-left: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    row-gap: 0.5rem;
    justify-content: center;
  }
`;
