import Loader from "ui/Loader";
import { useUser } from "features/users/queries";
import { useGDUser } from "features/gd/queries";
import { Redirect, RouteProps } from "react-router";

const Auth = (props: RouteProps) => {
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { data: GDuser, isLoading: isLoadingGDUser } = useGDUser();
  if (isLoadingUser || isLoadingGDUser)
    return <Loader text="Setting everything up..." centered size={50} />;
  if (!user || !GDuser) {
    return <Redirect to="/overview" />;
  }
  return <Redirect to="/overview" />;
};

export default Auth;
