import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsType } from "react-select";
import { RangeNames, SelectNames } from "types/filters";

export type FiltersStateSelect = {
  [K in SelectNames]?: OptionsType<{
    value: string;
    label: string;
  }>;
};

export type FiltersStateRange = {
  [K in RangeNames]?: {
    from?: number;
    to?: number;
  };
};

export type FiltersState = {
  select: FiltersStateSelect;
  range: FiltersStateRange;
  onlySelected: boolean;
};

const initialState: FiltersState = {
  select: {},
  range: {},
  onlySelected: false,
};

export interface RangeFilterPayload {
  filterName: RangeNames;
  type: "from" | "to";
  value: number;
}

export interface SelectFilterPayload {
  filterName: SelectNames;
  value: OptionsType<{
    value: string;
    label: string;
  }>;
}

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    selectFilter: (state, action: PayloadAction<SelectFilterPayload>) => {
      const { filterName, value } = action.payload;
      state.select[filterName] = [...value];
    },
    rangeFilter: (state, action: PayloadAction<RangeFilterPayload>) => {
      const { filterName, type, value } = action.payload;
      if (!state.range[filterName]) {
        state.range[filterName] = {};
      }
      state.range[filterName] = { ...state.range[filterName], [type]: value };
    },
    onlySelectedFilter: (state, action: PayloadAction<boolean>) => {
      state.onlySelected = action.payload;
    },
    clearFilters: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectFilter,
  rangeFilter,
  clearFilters,
  onlySelectedFilter,
} = filtersSlice.actions;

export default filtersSlice.reducer;
