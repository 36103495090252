import Price from "ui/Price";
import Checkbox from "ui/Checkbox";
import {
  useApprovePendingDiamond,
  useDeclinePendingDiamond,
} from "features/supplier/mutations";
import { useAnalytics } from "hooks/useAnalytics";
import useModalActions from "hooks/useModalActions";
import moment from "moment";
import { useState } from "react";
import Modal from "..";
import Loader from "ui/Loader";
import { theme } from "theme";
import { StyledApproveDiamonds } from "./ApproveDiamond.styled";
import Button from "ui/Button";

interface Props {
  tenderProductOffers: TenderProductOffer[];
}

const ApproveDiamond = ({ tenderProductOffers }: Props) => {
  const type = tenderProductOffers.length === 1 ? "single" : "multiple";

  const track = useAnalytics();
  const [checked, setChecked] = useState(false);
  const [notSell, setNotSell] = useState(false);
  const { closeModal } = useModalActions();

  const { mutateAsync: approve, isLoading: isLoadingApprove } =
    useApprovePendingDiamond(tenderProductOffers);

  const { mutateAsync: decline, isLoading: isLoadingDecline } =
    useDeclinePendingDiamond(tenderProductOffers);

  const onApprove = async () => {
    track("Approve Diamond Popup - Approve Button");
    await approve();
    closeModal();
  };

  const onDecline = async () => {
    track("Approve Diamond Popup - Decline Button");
    await decline();
    closeModal();
  };

  const totalAmount = tenderProductOffers.reduce((prev, curr) => {
    return prev + curr.reservePrice;
  }, 0);

  return (
    <Modal>
      <StyledApproveDiamonds className="approve-diamond" id="approvediamond">
        <Modal.Close parentId="approvediamond" />
        <p className="approve-diamond__title">
          {type === "single" ? "Diamond" : "Diamonds"} Approval - Terms &
          Conditions
        </p>
        <p className="approve-diamonds__terms scrollbar">
          <strong>Sellers’ Acceptance to include Goods in a Tender</strong>
          <br /> Once Goods are selected by the Company via the AI algorithms,
          the respective Sellers will be asked to provide their consent for
          including the Goods in a specific Tender event. Once such consent is
          obtained, these Goods cannot be sold by the Seller on any other
          platform until the Tender is closed (and on the assumption that the
          Goods are not sold during this Tender event). Failure to comply may
          lead to exclusion of a Seller from future Tenders at the Company’s
          sole discretion. <br />
          <br />
          <strong>No Warranties </strong>
          <br /> The content presented on the Website on all Goods is taken
          As-Is from the respective Sellers. The Company has no obligation to
          examine or monitor such content. All Goods are sold As-Is. The Company
          provides no representations and/or warranties and/or guarantees as to
          the quality, origin, physical characteristics or any other aspect of
          the Goods. The Company’s sole liability is to verify, once the Good is
          accepted at the respective Verification Lab, compliance of the Good
          with the parameters indicated in the GIA Certificate. Any descriptions
          of Goods made by the Company’s personnel or its agents are merely
          general and not a guarantee or representation of the quality, origin,
          physical characteristics or any other aspect of the Goods. It is the
          responsibility of the Bidder to adequately examine all information
          provided on the Website before making any Bids. <br />
          <br />
          <strong>Liability</strong>
          <br /> By participating in the Tender, Bidders, Buyers and Sellers:
          (a) are deemed to agree to these Tenders Terms of Use; and (b)
          specifically hereby agree that: (i) any and all claims by Bidders or
          Buyers against the Company shall be limited to the return of the
          amounts actually paid by the Buyer for the Good in question; (ii)
          Neither the Company nor any third party acting on its behalf shall be
          liable to any Bidder, Buyer or Seller for any direct, secondary or
          incidental loss or damage resulting from the withdrawal of any Good or
          rejection of any Bid; (iii) Neither the Company nor any third party
          acting on its behalf will have any obligation of any nature whatsoever
          to Buyers, Bidders or Sellers other than to deliver the purchased
          Goods to the Buyers and to forward the agreed consideration (after
          deduction of agreed commission) to the Sellers after all conditions
          set forth in these Tenders Terms of Use, have been met.
        </p>
        <div className="approve-diamond__details">
          <Detail
            title="EVENT NAME"
            value={tenderProductOffers[0].tender.name}
          />
          <Detail
            title="EVENT Date"
            value={moment(tenderProductOffers[0].tender.startDate).format(
              "MMM Do YY"
            )}
          />
          {type === "single" && (
            <Detail
              title="PRODCUT ID"
              value={"#" + tenderProductOffers[0].productId}
            />
          )}
          {type === "multiple" && (
            <Detail title="TOTAL DIAMONDS" value={tenderProductOffers.length} />
          )}

          <Detail title={<span>COMMISSION*</span>} value={"2%"} />
          {type === "single" && (
            <Detail
              title="RESERVE PRICE"
              value={<Price price={totalAmount} />}
            />
          )}
          {type === "multiple" && (
            <Detail
              title="TOTAL AMOUNT"
              value={<Price price={totalAmount} />}
            />
          )}
        </div>
        <div className="approve-diamond__checkboxes">
          <Checkbox
            id="terms"
            name="terms"
            checked={checked}
            onChange={() => setChecked(!checked)}
            label="I agree to terms and conditions"
          />
          <Checkbox
            id="notsell"
            name="notsell"
            checked={notSell}
            onChange={() => setNotSell(!notSell)}
            label="I agree not to sell the diamond before the end of the tender"
          />
        </div>
        <div className="approve-diamond__actions">
          <Button
            className="approve-diamond__approve"
            onClick={onApprove}
            disabled={
              !checked || !notSell || isLoadingApprove || isLoadingDecline
            }
            kind="green"
          >
            Approve
          </Button>
          <button
            className="approve-diamond__cancel"
            disabled={isLoadingApprove || isLoadingDecline}
            onClick={onDecline}
          >
            Decline
          </button>
        </div>
        <div className="approve-diamond__mark">
          * In order to continue, you must agree to the terms and conditions.
        </div>
        <div className="approve-diamond__mark">
          * The commission fee is deducted from the selling price.
        </div>
        {(isLoadingApprove || isLoadingDecline) && (
          <Loader
            centered
            size={60}
            text="Loading..."
            activeColor={theme.colors.primary[200]}
            color={theme.colors.neutral[200]}
          />
        )}
      </StyledApproveDiamonds>
    </Modal>
  );
};

const Detail = ({
  title,
  value,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
}) => {
  return (
    <div className="approve-diamond__detail">
      <p className="approve-diamond__detail__title">{title}</p>
      <p className="approve-diamond__detail__value">{value}</p>
    </div>
  );
};

export default ApproveDiamond;
