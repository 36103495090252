import styled from "styled-components";

export const StyledGoBack = styled.button`
  display: flex;
  align-items: center;

  font-family: "Archivo", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
  z-index: 12;
  color: ${({ theme }) => theme.colors.neutral[600]};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.neutral[700]};

    svg {
      transition: all 0.3s ease-in-out;
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }
`;
