import { StyledPageTabs } from "./PageTabs.styled";

interface Props {
  children: React.ReactNode;
}

const PageTabs = ({ children }: Props) => {
  return <StyledPageTabs>{children}</StyledPageTabs>;
};

export default PageTabs;
