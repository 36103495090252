// import Price from "components/Price";
import { useEffect, useRef, useState } from "react";
import { numberWithCommas } from "utils";
import cn from "classnames";
import { useCancelBid, useCreateBid } from "features/bids/mutations";
import { useSaveTenderProduct } from "features/products/mutations";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Info } from "../../../assets/icons/info.svg";
// import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Bid } from "../../../assets/icons/bid.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/x.svg";
// import useModalActions from "hooks/useModalActions";
import { useActiveTenderProductBid } from "features/bids/queries";
import {
  useIsTenderProductSaved,
  useTenderProductAnalysis,
} from "features/products/queries";
// import Countdown from "components/ui/Countdown";
// import { useTender } from "features/tenders/queries";
// import useDatesPercentage from "hooks/useDatesPercentage";
// import Progress from "components/ui/Progress";
import { useUser, useUserLocation } from "features/users/queries";
import Price from "ui/Price";
import { useAnalytics } from "hooks/useAnalytics";
import { usePopperTooltip } from "react-popper-tooltip";
import Loader from "ui/Loader";
import { theme } from "theme";
import { StyledTenderProductBidding } from "./TenderProductBidding.styled";
// import Progress from "components/ui/Progress";
// import useDatesPercentage from "hooks/useDatesPercentage";
interface Props {
  tenderProduct: TenderProduct;
  tenderId: string;
  tenderUserId: string;
}

type Field = "discount" | "ppc" | "totalPrice";

const _roundNumber = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
const setFields = (
  value: number,
  field: Field,
  totalPrice: number,
  carat: number,
  rapDiscount: number
) => {
  if (field === "discount") {
    const valueToNumber = Number(String(value).replace("%", ""));
    const cleanPercentValue = (valueToNumber - rapDiscount) * -1;
    const newTotal = totalPrice - (cleanPercentValue * totalPrice) / 100;
    const newPpc = newTotal / carat;
    return {
      discount: _roundNumber(valueToNumber, 2),
      ppc: _roundNumber(newPpc, 0),
      totalPrice: _roundNumber(newTotal, 0),
    };
  }
  if (field === "ppc") {
    const valueToNumber = Number(
      String(value).replace("$", "").replace(",", "")
    );
    const newTotal = valueToNumber * carat;
    const newDiscount =
      (100 - (newTotal * 100) / totalPrice) * -1 + rapDiscount;
    return {
      discount: _roundNumber(newDiscount, 2),
      ppc: _roundNumber(valueToNumber, 0),
      totalPrice: _roundNumber(newTotal, 0),
    };
  }
  const valueToNumber = Number(String(value).replace("$", "").replace(",", ""));
  const newDiscount =
    (100 - (valueToNumber * 100) / totalPrice) * -1 + rapDiscount;
  const newPpc = valueToNumber / carat;
  return {
    discount: _roundNumber(newDiscount, 2),
    ppc: _roundNumber(newPpc, 0),
    totalPrice: _roundNumber(valueToNumber, 0),
  };
};

const TenderProductBidding = ({
  tenderProduct,
  tenderId,
  tenderUserId,
}: Props) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: "bottom",
    });
  const track = useAnalytics();
  const ppcRef = useRef<null | HTMLInputElement>(null);
  const priceRef = useRef<null | HTMLInputElement>(null);
  const { data: userLocation } = useUserLocation();
  const { data: user } = useUser();
  const tenderProductId = tenderProduct._id;
  const {
    mutateAsync: createBid,
    isLoading: isLoadingCreateBid,
    isSuccess: isSuccessCreateBid,
    reset: resetCreateBid,
  } = useCreateBid();
  const { mutate: saveTenderProduct } = useSaveTenderProduct({ tenderProduct });
  const isSaved = useIsTenderProductSaved({ tenderProduct });
  let {
    data: tenderProductAnalysis,
    isFetching: isFetchingTenderProductAnalysis,
    isFetched: isFetchedTenderProductAnalysis,
  } = useTenderProductAnalysis(tenderProduct._id);
  const [state, setState] = useState({
    ppc: 0,
    totalPrice: 0,
    discount: 0,
  });

  const {
    data: activeTenderProductBid,
    isLoading: isLoadingActiveTenderProductBid,
  } = useActiveTenderProductBid(tenderUserId, tenderProductId);

  const {
    mutateAsync: cancelBid,
    isLoading: isLoadingCancelBid,
    isSuccess: isSuccessCancelBid,
    reset: resetCancelBid,
  } = useCancelBid();

  const ppc = tenderProduct.tenderProductOffer.reservePrice;
  const carat = tenderProduct.product.carat;
  const totalPrice = ppc * carat;
  const discount = 0;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replaceAll(/[^0-9.]/g, "");
    if (e.target.value.length > 8) return;
    setState(
      setFields(
        Number(e.target.value),
        e.target.name as Field,
        totalPrice,
        carat,
        discount
      )
    );
  };

  const handleKeyDown = (
    name: string,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      e.key === "Delete" ||
      e.key === "delete" ||
      e.key === "Backspace" ||
      e.key === "backspace"
    ) {
      let value: undefined | string = "";

      if (name === "ppc")
        value = ppcRef.current?.value.replaceAll(/[^0-9.]/g, "").slice(0, -1);

      if (name === "totalPrice")
        value = priceRef.current?.value.replaceAll(/[^0-9.]/g, "").slice(0, -1);

      if (value !== "1" && (value || "").length > 8) return;
      setState(
        setFields(Number(value), name as Field, totalPrice, carat, discount)
      );
    }
  };

  const handleSubmit = async () => {
    const payload = {
      location: userLocation?.country ?? "",
      price: state.ppc,
      tenderId,
      tenderProductId,
      tenderUserId,
    };
    track("Diamond View - Create Bid", payload);
    await createBid(payload);
    !isSaved && saveTenderProduct();
    setTimeout(() => resetCreateBid(), 2000);
    // setTimeout(() => closeModal(), 2000);
  };

  const handleCancel = async () => {
    if (activeTenderProductBid) {
      const payload = {
        tenderId,
        tenderUserId,
        tenderProductId: tenderProduct._id,
        tenderProductBidId: activeTenderProductBid._id,
      };
      track("Diamond View - Cancel Bid", payload);
      await cancelBid(payload);
      setTimeout(() => resetCancelBid(), 2000);
    }
  };

  useEffect(() => {
    if (activeTenderProductBid) {
      setState(
        setFields(
          Number(activeTenderProductBid.price),
          "ppc",
          tenderProduct.tenderProductOffer.reservePrice,
          tenderProduct.product.carat,
          10
        )
      );
    } else {
      setState({ discount: 0, ppc: 0, totalPrice: 0 });
    }
  }, [
    activeTenderProductBid,
    tenderProduct.tenderProductOffer.reservePrice,
    tenderProduct.product.carat,
  ]);

  return (
    <StyledTenderProductBidding className="tender-product__bidding">
      <div className="tender-product__bidding__reserve__container">
        <div className="tender-product__bidding__reserve">
          {isFetchedTenderProductAnalysis && (
            <Info
              ref={setTriggerRef as any}
              style={{
                width: 20,
                color: "#04895d",
                marginRight: 8,
              }}
            />
          )}
          {visible && (
            <div
              ref={setTooltipRef as any}
              {...getTooltipProps({
                className: "tooltip-container info-tooltip",
                style: { zIndex: 10000 },
              })}
            >
              <p>
                This is the price the supplier is asking for.
                <br /> You may choose to bid more or less than the online
                average price.
                <br />
                Friendly reminder - the highest bid will win the tender!
              </p>
            </div>
          )}
          {(() => {
            if (
              isFetchingTenderProductAnalysis &&
              !isFetchedTenderProductAnalysis
            ) {
              return <Loader color={theme.colors.neutral[600]} />;
            }

            if (!tenderProductAnalysis || tenderProductAnalysis.average === "0")
              return (
                <>
                  <span>ONLINE AVERAGE PRICE</span>
                  <Price
                    postfix="/PPC"
                    price={tenderProduct.tenderProductOffer.reservePrice}
                  />
                </>
              );

            return (
              <>
                <span>ONLINE AVERAGE PRICE</span>
                <Price postfix="/PPC" price={tenderProductAnalysis.average} />
              </>
            );
          })()}
        </div>
      </div>
      <div className="tender-product__bidding__place-bid">
        <Bid />
        Place your bid
      </div>
      <div className="tender-product__bidding__main">
        <input
          ref={ppcRef}
          onKeyDown={(e) => handleKeyDown("ppc", e)}
          onChange={handleChange}
          type="text"
          placeholder="$0.00 / PPC"
          name="ppc"
          value={
            state.ppc === 0 ? "" : "$" + numberWithCommas(state.ppc) + "/PPC"
          }
          disabled={!!activeTenderProductBid}
          autoComplete="off"
        />
        <input
          ref={priceRef}
          onChange={handleChange}
          type="text"
          placeholder="$0.00 / Total"
          name="totalPrice"
          onKeyDown={(e) => handleKeyDown("totalPrice", e)}
          value={
            state.totalPrice === 0
              ? ""
              : "$" + numberWithCommas(state.totalPrice) + "/Total"
          }
          disabled={!!activeTenderProductBid}
          autoComplete="off"
        />
        {!activeTenderProductBid && (
          <button
            className="tender-product__bidding__submit"
            onClick={handleSubmit}
            disabled={!!!state.ppc}
          >
            <Plus className="tender-product__bidding__submit__icon" />
            Place Bid
          </button>
        )}
        {activeTenderProductBid && (
          <button
            className="tender-product__bidding__cancel"
            onClick={handleCancel}
          >
            <Cancel className="tender-product__bidding__submit__icon" />
            Cancel Bid
          </button>
        )}
        <p className="tender-product__bidding__notice">
          You can change your bid later if you want.
          <br />
          Shipments costs, via a company of your choosing, is excluded.﻿
        </p>
      </div>
      {(isLoadingCreateBid || isSuccessCreateBid) && (
        <LoadingState
          isLoading={isLoadingCreateBid}
          isSuccess={isSuccessCreateBid}
          loadingText={
            isLoadingCreateBid
              ? "Placing bid..."
              : isSuccessCreateBid
              ? "Your bid has been successfully submitted"
              : ""
          }
        />
      )}
      {user?.company?._id !== tenderProduct.product.company &&
        isLoadingActiveTenderProductBid && (
          <LoadingState
            isLoading={isLoadingActiveTenderProductBid}
            isSuccess={false}
            loadingText={"Checking for active bid..."}
          />
        )}
      {(isLoadingCancelBid || isSuccessCancelBid) && (
        <LoadingState
          isLoading={isLoadingCancelBid}
          isSuccess={isSuccessCancelBid}
          loadingText={
            isLoadingCancelBid
              ? "Cancelling your bid..."
              : "Your bid is cancelled"
          }
        />
      )}
      {false && (
        <div
          style={{
            opacity: 0.75,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            background: "#fff",
            fontFamily: "var(--archivo)",
            fontSize: 14,
          }}
        >
          You cannot bid on your own diamond
        </div>
      )}
    </StyledTenderProductBidding>
  );
};

export default TenderProductBidding;

const LoadingState = ({
  loadingText,
  isLoading,
  isSuccess,
}: {
  isLoading?: boolean;
  isSuccess?: boolean;
  loadingText: string;
}) => {
  return (
    <div className="tender-product__bidding__loading">
      <div
        className={cn("tender-product__bidding__circle-loader", {
          "tender-product__bidding__circle-loader--complete": !isLoading,
        })}
      >
        <div
          className={cn(
            { "tender-product__bidding__checkmark": isSuccess },
            "draw"
          )}
        >
          <div className="after"></div>
        </div>
      </div>
      <p className="tender-product__bidding__loading__text">{loadingText}</p>
    </div>
  );
};
