import { useEffect, useState } from "react";
import { StyledStepper } from "./Stepper.styled";

interface Props {
  currentStep: number;
  stepperSteps: { label: string; goToStep: number }[];
}

const stepId = (index: number) => `stepper__step${index}`;

const Stepper = ({ currentStep, stepperSteps }: Props) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    let width = 0;
    for (let index = 0; index <= currentStep; index++) {
      width += document.getElementById(stepId(index))?.clientWidth || 0;
    }
    setWidth(width);
  }, [currentStep]);

  return (
    <StyledStepper className="ui-stepper">
      <div className="ui-stepper__steps">
        {stepperSteps.map(({ label }, index) => {
          return (
            <p id={stepId(index)} className="ui-stepper__step" key={index}>
              {label}
            </p>
          );
        })}
      </div>
      <div className="ui-stepper__progress">
        <div className="ui-stepper__progress__active" style={{ width }} />
      </div>
    </StyledStepper>
  );
};

export default Stepper;
