import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleDrawerIsOpen as toggleDrawerAction } from "../features/ui/slice";
const useUiActions = () => {
  const dispatch = useDispatch();
  const toggleDrawer = useCallback(
    (payload: boolean) => {
      dispatch(toggleDrawerAction(payload));
    },
    [dispatch]
  );
  return { toggleDrawer };
};

export default useUiActions;
