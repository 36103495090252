import { useRegisterContext } from "../Register";
import FormFields, { TFormFields } from "../FormFields";
import FormStep from "./FormStep";
import { countries } from "../countries";
import Checkbox from "ui/Checkbox";
// import { adminSchema } from "../validation";
import { useAnalytics } from "hooks/useAnalytics";
import { useEffect } from "react";

const StepFour = () => {
  const track = useAnalytics();
  const { formik, showIsAdminCheckbox } = useRegisterContext();
  const fields: TFormFields = [
    {
      label: "Full Name*",
      name: "aml.admin.adminFullname",
      id: "aml.admin.adminFullname",
      placeholder: "Full Name",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminFullname,
      isError:
        !!formik.errors?.aml?.admin?.adminFullname &&
        !!formik.touched.aml?.admin?.adminFullname,
      error: formik.errors?.aml?.admin?.adminFullname,
    },
    {
      label: "Phone Number*",
      name: "aml.admin.adminPhoneNumber",
      id: "aml.admin.adminPhoneNumber",
      placeholder: "+9720000000",
      fieldType: "phone",
      onChange: (value) =>
        formik.setFieldValue("aml.admin.adminPhoneNumber", value),
      onBlur: () => formik.setFieldTouched("aml.admin.adminPhoneNumber", true),
      value: formik.values.aml.admin.adminPhoneNumber,
      enableSearch: true,
      isError:
        !!formik.errors?.aml?.admin?.adminPhoneNumber &&
        !!formik.touched.aml?.admin?.adminPhoneNumber,
      error: formik.errors?.aml?.admin?.adminPhoneNumber,
    },
    {
      label: "Email*",
      name: "aml.admin.adminEmailAddress",
      id: "aml.admin.adminEmailAddress",
      placeholder: "email@gmail.com",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminEmailAddress,
      isError:
        !!formik.errors?.aml?.admin?.adminEmailAddress &&
        !!formik.touched.aml?.admin?.adminEmailAddress,
      error: formik.errors?.aml?.admin?.adminEmailAddress,
    },
    {
      label: "Address*",
      name: "aml.admin.adminFullAddress",
      id: "aml.admin.adminFullAddress",
      placeholder: "Address",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminFullAddress,
      isError:
        !!formik.errors?.aml?.admin?.adminFullAddress &&
        !!formik.touched.aml?.admin?.adminFullAddress,
      error: formik.errors?.aml?.admin?.adminFullAddress,
    },
    {
      label: "City*",
      name: "aml.admin.adminCity",
      id: "aml.admin.adminCity",
      placeholder: "City",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminCity,
      isError:
        !!formik.errors?.aml?.admin?.adminCity &&
        !!formik.touched.aml?.admin?.adminCity,
      error: formik.errors?.aml?.admin?.adminCity,
    },
    {
      label: "State",
      name: "aml.admin.adminState",
      id: "aml.admin.adminState",
      placeholder: "State",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminState,

      isError:
        !!formik.errors?.aml?.admin?.adminState &&
        !!formik.touched.aml?.admin?.adminState,
      error: formik.errors?.aml?.admin?.adminState,
    },
    {
      label: "Zip Code*",
      name: "aml.admin.adminZip",
      id: "aml.admin.adminZip",
      placeholder: "Zip Code",
      fieldType: "input",
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values.aml.admin.adminZip,
      isError:
        !!formik.errors?.aml?.admin?.adminZip &&
        !!formik.touched.aml?.admin?.adminZip,
      error: formik.errors?.aml?.admin?.adminZip,
    },
    {
      label: "Country*",
      name: "aml.admin.adminCountry",
      id: "aml.admin.adminCountry",
      placeholder: "Country",
      fieldType: "select",
      onChange: (value) =>
        formik.setFieldValue("aml.admin.adminCountry", value),
      onBlur: () => formik.setFieldTouched("aml.admin.adminCountry", true),
      value: formik.values.aml.admin.adminCountry,
      options: countries,
      isError:
        !!formik.errors?.aml?.admin?.adminCountry &&
        !!formik.touched.aml?.admin?.adminCountry,
      error: formik.errors?.aml?.admin?.adminCountry,
      isClearable: true,
    },
  ];

  const onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isAdmin = e.target.checked;
    if (isAdmin) {
      formik.setValues({
        ...formik.values,
        user: {
          ...formik.values.user,
          isAdmin: true,
        },
        aml: {
          ...formik.values.aml,
          admin: {
            ...formik.values.aml.admin,
            adminFullname:
              formik.values.user.firstName + " " + formik.values.user.lastName,
            adminPhoneNumber: formik.values.user.phoneNumber,
            adminEmailAddress: formik.values.user.email,
          },
        },
      });
    } else {
      formik.setValues({
        ...formik.values,
        user: {
          ...formik.values.user,
          isAdmin: false,
        },
        aml: {
          ...formik.values.aml,
          admin: {
            ...formik.values.aml.admin,
            adminFullname: "",
            adminPhoneNumber: "",
            adminEmailAddress: "",
          },
        },
      });
    }
  };

  useEffect(() => {
    track("Registration Step - Director/Admin Details");
  }, [track]);

  return (
    <FormStep
      title="Director / Admin Details"
      subtitle={`Our AML form requires the personal details of the director of the company. ${
        showIsAdminCheckbox
          ? `If you are the director or person in charge, kindly click “I am the director” 
      so we can assist you in filling the form faster.`
          : ""
      }`}
      fields={
        <div>
          {showIsAdminCheckbox && (
            <Checkbox
              containerClassName="i-am-admin"
              type="checkbox"
              name="admin"
              id="admin"
              checked={formik.values.user.isAdmin}
              onChange={onCheckBoxChange}
              label="I am the director"
            />
          )}
          <FormFields
            fields={fields}
            fieldsClassName="register__step__four__fields"
          />
        </div>
      }
      onNext={() => {
        formik.handleSubmit();
      }}
    />
  );
};

export default StepFour;
