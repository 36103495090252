import Shine from "ui/Shimmer/Shine";
import { theme } from "theme";
import Divider from "ui/Divider";
import { StyledPageSummary } from "./PageSummary.styled";
import CompleteAml from "components/CompleteAml";
import React from "react";

interface Props {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  values: {
    label: React.ReactNode;
    value: React.ReactNode;
    handleClick?: () => void;
  }[];
  className?: string;
}

const PageSummary = ({ title, subtitle, values, className = "" }: Props) => {
  return (
    <div>
      <StyledPageSummary className={["ui-page-summary", className].join(" ")}>
        <div className="ui-page-summary__texts">
          <p className="ui-page-summary__title">{title}</p>
          <p className="ui-page-summary__subtitle">{subtitle}</p>
        </div>
        <div className="ui-page-summary__values">
          {values.map(({ label, value, handleClick }, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className="ui-page-summary__value"
                  style={handleClick ? { cursor: "pointer" } : {}}
                  onClick={handleClick}
                >
                  <p className="ui-page-summary__value__label">{label}</p>
                  <Shine>
                    <div className="ui-page-summary__value__number">
                      <p>{value}</p>
                    </div>
                  </Shine>
                </div>
                {index + 1 !== values.length && (
                  <Divider
                    width={1}
                    height={35}
                    color={theme.colors.neutral[500]}
                    spacing={15}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </StyledPageSummary>
      <CompleteAml />
    </div>
  );
};

export default PageSummary;
