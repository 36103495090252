import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  width?: number;
  height?: number;
};

const StyledCounter = styled.div<{ width?: number; height?: number }>`
  border-radius: 100%;
  width: ${(props) => (props.width ?? 16) + "px"};
  height: ${(props) => (props.height ?? 16) + "px"};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #f58927;
  font-family: var(--archivo);
  font-size: 12px;
`;

const Counter = ({ children, ...rest }: Props) => {
  return (
    <StyledCounter className="ui-counter" {...rest}>
      {children}
    </StyledCounter>
  );
};

export default Counter;
