import Checkbox from "ui/Checkbox";
import { useApproveTerms, useMutateUser } from "features/users/mutations";
import { useUser } from "features/users/queries";
import useModalActions from "hooks/useModalActions";
import { useState } from "react";
import Modal from "..";
import { Container } from "./Terms.styled";
import Button from "ui/Button";
import Terms from "pages/Terms";

const TermsPopup = () => {
  const { data: user } = useUser();
  const { mutateAsync: approveTerms } = useApproveTerms();
  const mutateUser = useMutateUser();
  const { closeSpecificModal } = useModalActions();
  const [approved, setApproved] = useState(false);
  const onSubmit = async () => {
    approveTerms();
    user && mutateUser({ ...user, approvedTerms: true });
    closeSpecificModal("Terms");
  };

  return (
    <Modal onClose={() => {}}>
      <Container>
        <h1 className="terms__title">Trade Diamonds - Terms & Conditions</h1>
        <p className="terms__subtitle">Powered by - Lucy</p>
        <div className="terms__text">
          <Terms />
        </div>
        <div className="actions">
          <div>
            <Checkbox
              checked={approved}
              onChange={(e) => setApproved(e.target.checked)}
              label="*In order to continue, you must agree to the terms and conditions."
            />
          </div>
          <Button kind="green" disabled={!approved} onClick={onSubmit}>
            Approve
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default TermsPopup;
