import styled from "styled-components";

export const DrawerContainer = styled.div<{ isOpen: boolean }>`
  width: ${({ theme }) => theme.drawerWidth};
  height: 100%;
  box-shadow: -10px 0 40px 0 rgba(0, 0, 0, 0.3);
  /* border: solid 1.8px rgba(217, 217, 217, 0.59); */
  background: linear-gradient(170deg, #003f69 1%, #7499ff 75%);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .drawer__main {
    flex: 1;
    padding-top: 1rem;
    padding: 1rem;
  }

  .drawer__item {
    margin-bottom: 1rem;
  }

  .drawer__item__top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.29);
    padding-bottom: 12px;
    margin-bottom: 8px;

    svg {
      color: rgba(255, 255, 255, 0.79);
    }
  }

  .drawer__item__icon {
    display: flex;
    align-items: center;
    width: ${(props) => props.theme.spacing[36]};
  }

  .drawer__item__title {
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize[16]};
    font-weight: bold;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, 0.79);
  }

  .drawer__item__link {
    font-size: ${(props) => props.theme.fontSize[14]};
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, 0.79);
    font-family: ${(props) => props.theme.fonts.archivo};
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    padding: 8px 0 8px ${(props) => props.theme.spacing[36]};
    &:hover {
      color: white;
    }
  }

  .drawer__item__link--active {
    color: #f5a000 !important;
    font-weight: bold;
  }

  .drawer__item__link__detail {
    border-radius: 30px;
    background-color: rgba(126, 153, 224, 0.24);
    position: absolute;
    right: 0;
    height: 18px;
    padding: 0 8px;
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #c7b9ff;
    display: flex;
    top: 50%;
    align-items: center;
    transform: translateY(-50%);
  }

  .back__to__get {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    margin: 0 1rem 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.46px;
    color: #fff;
    min-height: 40px;
    svg {
      margin-right: 8px;
    }
  }

  .drawer__item__title {
    display: flex;
    align-items: center;

    svg {
      width: ${(props) => props.theme.spacing[20]};
      min-width: ${(props) => props.theme.spacing[20]};
      margin-right: ${(props) => props.theme.spacing[12]};
    }
  }

  .drawer__item__number {
    border-radius: 30px;
    background-color: rgba(126, 153, 224, 0.24);
    position: absolute;
    right: 0;
    height: 18px;
    padding: 0 8px;
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #c7b9ff;
    display: flex;
    top: 50%;
    align-items: center;
    transform: translateY(-50%);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: fixed;
    top: ${({ theme }) => theme.headerHeight};
    height: ${({ theme }) => `calc(100% - ${theme.headerHeight})`};
    width: 100%;
    z-index: 12;
    transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 0.3s ease-in-out;
  }
`;
