import { countries } from "../countries";
import FormFields, { TFormFields } from "../FormFields";
import { useRegisterContext } from "../Register";
import FormStep from "./FormStep";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import { referencesSchema } from "../validation";
import { useEffect } from "react";
import { useAnalytics } from "hooks/useAnalytics";
interface Props {}

const StepFive = (props: Props) => {
  const track = useAnalytics();
  const { formik } = useRegisterContext();
  const fieldsCreator = (
    key: "firstReference" | "secondReference" | "thirdReference"
  ) => {
    return [
      {
        fieldType: `input`,
        name: `aml.${key}.nameOfCompany`,
        id: `aml.${key}.nameOfCompany`,
        placeholder: `Company Name`,
        label: `Company Name*`,
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        value: formik.values.aml[key].nameOfCompany,
        isError:
          !!formik.errors?.aml?.[key]?.nameOfCompany &&
          !!formik.touched?.aml?.[key]?.nameOfCompany,
        error: formik.errors?.aml?.[key]?.nameOfCompany,
      },
      {
        fieldType: `select`,
        name: `aml.${key}.country`,
        id: `aml.${key}.country`,
        placeholder: `Country`,
        label: `Country*`,
        options: countries,
        onChange: (value) =>
          formik.setFieldValue(`aml.${key}.country`, value || null),
        onBlur: () => formik.setFieldTouched(`aml.${key}.country`, true),
        value: formik.values.aml[key].country,
        isError:
          !!formik.errors?.aml?.[key]?.country &&
          !!formik.touched?.aml?.[key]?.country,
        error: formik.errors?.aml?.[key]?.country,
        isClearable: true,
      },
      {
        fieldType: `input`,
        name: `aml.${key}.contactPerson`,
        id: `aml.${key}.contactPerson`,
        placeholder: `Name`,
        label: `Contact Person*`,
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        value: formik.values.aml[key].contactPerson,
        isError:
          !!formik.errors?.aml?.[key]?.contactPerson &&
          !!formik.touched?.aml?.[key]?.contactPerson,
        error: formik.errors?.aml?.[key]?.contactPerson,
      },
      {
        fieldType: `phone`,
        name: `aml.${key}.referencesPhoneNumber`,
        id: `aml.${key}.referencesPhoneNumber`,
        placeholder: `+9721234567`,
        label: `Phone Number*`,
        onChange: (value) =>
          formik.setFieldValue(`aml.${key}.referencesPhoneNumber`, value),
        onBlur: () =>
          formik.setFieldTouched(`aml.${key}.referencesPhoneNumber`, true),
        value: formik.values.aml[key].referencesPhoneNumber,
        enableSearch: true,
        isError:
          !!formik.errors?.aml?.[key]?.referencesPhoneNumber &&
          !!formik.touched?.aml?.[key]?.referencesPhoneNumber,
        error: formik.errors?.aml?.[key]?.referencesPhoneNumber,
      },
      {
        fieldType: `input`,
        name: `aml.${key}.email`,
        id: `aml.${key}.email`,
        placeholder: `email@gmail.com`,
        label: `Email*`,
        onChange: formik.handleChange,
        onBlur: formik.handleBlur,
        value: formik.values.aml[key].email,
        isError:
          !!formik.errors?.aml?.[key]?.email &&
          !!formik.touched?.aml?.[key]?.email,
        error: formik.errors?.aml?.[key]?.email,
      },
    ] as TFormFields;
  };

  const setNumReferences = (value: any) =>
    formik.setFieldValue("aml.numReferences", value);
  const numReferences = formik.values.aml.numReferences;

  useEffect(() => {
    track("Registration Step - References");
  }, [track]);

  return (
    <FormStep
      fields={
        <div>
          <TransitionGroup>
            {[
              "firstReference" as const,
              "secondReference" as const,
              "thirdReference" as const,
            ]
              .slice(0, numReferences)
              .map((key, index) => (
                <CSSTransition
                  key={key}
                  timeout={500}
                  classNames="reference-anim"
                >
                  <div
                    className="relative"
                    key={key}
                    style={index !== 0 ? { marginTop: "1rem" } : {}}
                  >
                    <div className="reference-top">
                      <p className="register__step__title">{`Reference ${
                        index + 1
                      }`}</p>
                      <p className="register__step__subtitle">
                        We do everything we can to keep the integrity of our
                        community intact, and we wish to make sure all of our
                        traders are known by others in our community. Therefore,
                        we require at least one reference by companies you've
                        done business with.
                      </p>
                      {index !== 0 && (
                        <button
                          className="remove-reference"
                          onClick={() => {
                            if (numReferences === 2) {
                              formik.setFieldValue(
                                "aml.numReferences",
                                numReferences > 1 ? numReferences - 1 : 1
                              );
                            }

                            if (numReferences === 3) {
                              // second reference
                              if (index === 1) {
                                formik.setFieldValue(
                                  "aml.secondReference",
                                  formik.values.aml.thirdReference
                                );
                                formik.setFieldValue(
                                  "aml.numReferences",
                                  numReferences > 1 ? numReferences - 1 : 1
                                );

                                return;
                              }

                              formik.setFieldValue(
                                "aml.numReferences",
                                numReferences > 1 ? numReferences - 1 : 1
                              );
                            }
                          }}
                          type="button"
                        >
                          <XIcon />
                        </button>
                      )}
                    </div>
                    <FormFields
                      key={key}
                      fields={fieldsCreator(key)}
                      fieldsClassName="register__step__five__fields"
                    />
                  </div>
                </CSSTransition>
              ))}
          </TransitionGroup>
          {formik.values.aml.numReferences < 3 && (
            <p
              className="add-reference"
              onClick={() => setNumReferences(numReferences + 1)}
            >
              + add more references
            </p>
          )}
        </div>
      }
      onNext={formik.handleSubmit}
    />
  );
};

export default StepFive;
