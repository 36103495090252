import { useLocation } from "react-router";
import { HeaderGuest } from "./HeaderGuest";
import { HeaderUser } from "./HeaderUser";

export const HeaderProvider = ({ user, setShowModal }) => {
  const { pathname } = useLocation();

  if (pathname.includes("dpl")) return null;
  return (
    <>
      {!user && <HeaderGuest setShowModal={setShowModal} />}
      {user && (
        <HeaderUser
          isSupplier={user.company.isSupplier}
          user={user}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};
