import { useEffect } from "react";
import { useAppSelector } from "../../hooks";
import useModalActions from "../../hooks/useModalActions";
import TenderProduct from "pages/TenderProduct";
import Order from "pages/Order";
import TenderRegister from "pages/TenderRegister";
import GetLogin from "components/GetLogin";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import UserDetails from "./modals/UserDetails";
import Catalog from "./modals/Catalog";
import ApproveDiamond from "./modals/ApproveDiamond";
import Event from "./modals/Event";
import FinishedTender from "./modals/FinishedTender";
import Terms from "./modals/Terms";
import RegisterPopup from "./modals/RegisterPopup";
import MediaModal from "./modals/MediaModal";
import ConfirmModal from "./modals/ConfirmModal";
import WishlistModal from "./modals/WishlistModal";

export const modalComponentLookupTable = {
  TenderProduct,
  Order,
  TenderRegister,
  GetLogin: GetLogin.Modal,
  MediaModal,
  ApproveDiamond,
  UserDetails,
  Catalog,
  Event,
  FinishedTender,
  Terms,
  RegisterPopup,
  WishlistModal,
  ConfirmModal,
} as const;

const ModalManager = () => {
  const { closeModal } = useModalActions();
  const modals = useAppSelector((state) => state.modals);
  const renderedModals = () =>
    modals.map((modalDescription, index) => {
      const { modalType, modalProps = {} as any } = modalDescription;
      const ModalComponent = modalComponentLookupTable[modalType];
      return (
        <CSSTransition
          key={index}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <ModalComponent {...modalProps} key={modalType} />
        </CSSTransition>
      );
    });

  // close modal on escape key
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const keys = ["Escape", "Esc", "escape"];
      if (keys.includes(e.key) && modals.length > 0) {
        !modals[modals.length - 1].withoutClose && closeModal();
      }
    };
    document.addEventListener("keyup", handler);
    return () => {
      document.removeEventListener("keyup", handler);
    };
  }, [closeModal, modals]);

  // close modal browser back
  useEffect(() => {
    const root = document.getElementById("#__next") as HTMLElement;
    if (modals.length > 0) {
      document.body.style.overflow = "hidden";
      if (root) root.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
      if (root) root.style.overflow = "unset";
    }

    window.onpopstate = (e) => {
      closeModal();
    };

    return () => {
      window.onpopstate = (e) => {};
    };
  }, [closeModal, modals]);

  return (
    <div id="modals">
      <TransitionGroup>{renderedModals()}</TransitionGroup>
    </div>
  );
};

export default ModalManager;
