import { modalComponentLookupTable } from "components/Modal/ModalManager";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ModalState,
  openModal as openModalAction,
  closeModal as closeModalAction,
  clearModals as clearModalsAction,
  closeSpecificModal as closeSpecificModalAction,
} from "../features/modals/slice";
const useModalActions = () => {
  const dispatch = useDispatch();
  const openModal = useCallback(
    (payload: ModalState) => {
      dispatch(openModalAction(payload));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);

  const closeSpecificModal = useCallback(
    (modalType: keyof typeof modalComponentLookupTable) => {
      dispatch(closeSpecificModalAction({ modalType }));
    },
    [dispatch]
  );

  const clearModals = useCallback(() => {
    dispatch(clearModalsAction());
  }, [dispatch]);

  return { closeModal, closeSpecificModal, openModal, clearModals };
};

export default useModalActions;
