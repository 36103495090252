import styled from "styled-components";

export interface StyledDividerProps {
  width?: number;
  height?: number;
  color?: string;
  spacing?: number;
}

export const StyledDivider = styled.div<StyledDividerProps>`
  border-left: ${(props) => (props.width || 2) + "px"} solid
    ${(props) => props.color || props.theme.colors.neutral[600]};
  height: ${(props) => (props.height ? props.height + "px" : "100%")};
  margin: 0 ${(props) => (props.spacing ? props.spacing + "px" : 0)};
`;
