import styled from "styled-components";

export const StyledStepper = styled.div`
  width: 100%;
  max-width: 100%;

  .ui-stepper__steps {
    display: flex;
    align-items: center;
  }

  .ui-stepper__step {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[14]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    text-transform: uppercase;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ui-stepper__progress {
    background-color: #e8ebf2;
    position: relative;
    height: 0.3125rem;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing[16]};
    overflow: hidden;
  }

  .ui-stepper__progress__active {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #492cbd;
    transition: width 0.5s linear;
    border-radius: 1.875rem;
  }
`;
