import { useAppSelector } from "hooks";
import styles from "./FiltersSummary.module.css";
import cn from "classnames";
import ElipseDetail from "components/ElipseDetail";
import { useDispatch } from "react-redux";
import {
  rangeFilter,
  selectFilter,
  // onlySelectedFilter,
} from "features/filters/slice";
import { OptionsType } from "react-select";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ReactComponent as Xcircle } from "../../assets/icons/x-circle.svg";
const FiltersSummary = ({ className }: { className?: string }) => {
  const filters = useAppSelector((state) => state.filters);
  const dispatch = useDispatch();

  const removeSelect = (
    filterName: keyof typeof filters.select,
    value: string
  ) => {
    if (!Array.isArray(filters.select[filterName])) return;
    if (filters.select[filterName] !== undefined) {
      const newValues = (filters.select[filterName] as OptionsType<{
        label: string;
        value: string;
      }>).filter((option) => option.value !== value);
      dispatch(selectFilter({ filterName, value: newValues }));
    }
  };

  const removeRange = (filterName: keyof typeof filters.range) => {
    dispatch(rangeFilter({ filterName, type: "from", value: 0 }));
    dispatch(rangeFilter({ filterName, type: "to", value: 0 }));
  };

  // const removeOnlySelected = () => {
  //   dispatch(onlySelectedFilter(false));
  // };

  const displayFilters = () => {
    const selectfilters = (Object.keys(filters.select) as Array<
      keyof typeof filters.select
    >)
      .map((key) => {
        if (!filters.select[key] || filters.select[key]?.length === 0)
          return null;
        return (
          <CSSTransition key={key} timeout={300} classNames="fade">
            <FilterBox label={key} key={key}>
              <TransitionGroup className={styles.values}>
                {filters.select[key]?.map(({ label, value }) => {
                  return (
                    <CSSTransition key={value} timeout={300} classNames="fade">
                      <FilterValue onClick={() => removeSelect(key, value)}>
                        {label}
                      </FilterValue>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </FilterBox>
          </CSSTransition>
        );
      })
      .filter((child) => child !== null);

    const rangeFilters = (Object.keys(filters.range) as Array<
      keyof typeof filters.range
    >)
      .map((key) => {
        if (!filters.range[key]) return null;
        const from = filters.range[key]?.from;
        const to = filters.range[key]?.to;
        const hasFrom = !!from && from !== 0;
        const hasTo = !!to && to !== 0;
        if (!hasFrom && !hasTo) return null;
        return (
          <CSSTransition key={key} timeout={300} classNames="fade">
            <FilterBox label={key} key={key}>
              <FilterValue onClick={() => removeRange(key)}>
                {hasFrom && from}
                {hasFrom && hasTo && " - "}
                {hasTo && to}
              </FilterValue>
            </FilterBox>
          </CSSTransition>
        );
      })
      .filter((child) => child !== null);

    if (selectfilters.length === 0 && rangeFilters.length === 0) return null;

    return (
      <TransitionGroup className={cn(styles.container, className)}>
        {/* {filters.onlySelected && (
          <CSSTransition key={"onlyselected"} timeout={300} classNames="fade">
            <FilterBox label="Show Only Wishlist">
              <FilterValue onClick={removeOnlySelected}>{"Active"}</FilterValue>
            </FilterBox>
          </CSSTransition>
        )} */}
        {selectfilters}
        {rangeFilters}
      </TransitionGroup>
    );
  };

  return displayFilters();
};

const FilterBox = ({
  label,
  children,
}: {
  label: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className={styles.box}>
      <p className={styles.name}>{label}</p>
      <div className={styles.values}>{children}</div>
    </div>
  );
};

const FilterValue = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <ElipseDetail className={styles.value}>
      {children}
      <div className={styles.remove} onClick={onClick}>
        <Xcircle />
      </div>
    </ElipseDetail>
  );
};

export default FiltersSummary;
