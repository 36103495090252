import { usePopperTooltip } from "react-popper-tooltip";
import { theme } from "theme";
import {
  StyledTooltipError,
  StyledTooltipErrorContainer,
} from "./Tooltip.styled";
import "react-popper-tooltip/dist/styles.css";
import { ReactComponent as AlertCircle } from "../../assets/icons/alert-circle.svg";

interface Props {
  error?: string;
}

function TooltipError({ error }: Props) {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "right",
  });

  return (
    <>
      {visible && (
        <StyledTooltipErrorContainer
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {error}
        </StyledTooltipErrorContainer>
      )}
      <StyledTooltipError>
        <AlertCircle
          ref={setTriggerRef as any}
          color={theme.colors.error[200]}
        />
      </StyledTooltipError>
    </>
  );
}

export default TooltipError;
