import Axios, { AxiosRequestConfig } from "axios";
import { GD_URL } from "config/index";
import storage from "utils/storage";

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getGDToken();
  if (token) {
    config.headers.token = token;
  }
  config.headers.Accept = "application/json";
  return config;
}

export const axiosGD = Axios.create({
  baseURL: GD_URL,
});

axiosGD.interceptors.request.use(authRequestInterceptor);
