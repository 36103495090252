import { StyledProgress, StyledProgressProps } from "./Progress.styled";

const Progress = (props: StyledProgressProps) => {
  return (
    <StyledProgress className="ui-progress" {...props}>
      <div className="ui-progress__current"></div>
    </StyledProgress>
  );
};

export default Progress;
