// import useAmlStatus from "hooks/useAmlStatus";
// import useModalActions from "hooks/useModalActions";
// import Button from "ui/Button";
// import { StyledCompleteAml } from "./CompleteAml.styled";

const CompleteAml = () => {
  // const amlStatus = useAmlStatus();
  // const { openModal } = useModalActions();

  return null;
  // if (amlStatus !== "pending") return null;

  // return (
  //   <StyledCompleteAml className="complete-aml">
  //     <p className="complete-aml__text">
  //       Please complete the mandatory AML process in order to enjoy the full
  //       experience and features
  //     </p>
  //     <Button
  //       kind="white"
  //       onClick={() =>
  //         openModal({ modalType: "RegisterPopup", modalProps: {} })
  //       }
  //     >
  //       FINISH PROCESS
  //     </Button>
  //   </StyledCompleteAml>
  // );
};

export default CompleteAml;
