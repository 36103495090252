import Drawer from "ui/Drawer";
import ErrorBoundary from "components/ErrorBoundary";
import { Container } from "./Layout.styled";
interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <Container>
      <Drawer />
      <ErrorBoundary>
        <div className="layout__right">{children}</div>
      </ErrorBoundary>
    </Container>
  );
};

export default Layout;
