import React from "react";
import cn from "classnames";
import { useRegisterContext } from "../Register";
import { ReactComponent as ChevronLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevron-right.svg";
import Button from "ui/Button";
interface Props {
  containerClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  formClassName?: string;
  bottomClassName?: string;
  bottomLabelClassName?: string;
  nextClassName?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  fields?: React.ReactNode;
  bottomLabel?: React.ReactNode;
  nextProps?: React.ComponentProps<typeof Button>;
  onNext?: () => void;
  onGoBack?: () => void;
}

const FormStep = ({
  containerClassName = "",
  titleClassName = "",
  subtitleClassName = "",
  formClassName = "",
  title,
  subtitle,
  fields,
  onNext,
  onGoBack,
  bottomLabel = "Fields with * are mandatory.",
  nextProps = {},
}: Props) => {
  const { goBack, initialStep, currentStep } = useRegisterContext();
  return (
    <>
      {!!title && (
        <p className={cn("register__step__title", titleClassName)}>{title}</p>
      )}
      {!!subtitle && (
        <p className={cn("register__step__subtitle", subtitleClassName)}>
          {subtitle}
        </p>
      )}
      <form className="register__step__form">
        {!!fields && fields}
        <div className="register__step__bottom">
          <p className="register__step__bottom__label">{bottomLabel}</p>

          <div
            className="register__step__bottom__back"
            style={{ opacity: currentStep === initialStep ? 0 : 1 }}
            onClick={() => {
              if (currentStep === initialStep) return;
              onGoBack && onGoBack();
              goBack();
            }}
          >
            <ChevronLeft className="register__step__bottom__back__icon" />
            <p className="register__step__bottom__back__text">GO BACK</p>
          </div>

          <Button kind="purple" {...nextProps} type="button" onClick={onNext}>
            {nextProps?.children ? (
              nextProps?.children
            ) : (
              <>
                Next <ChevronRight />
              </>
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormStep;
