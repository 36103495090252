import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { ReactComponent as Users } from "assets/icons/users-gray.svg";
// import { ReactComponent as Bid } from "assets/icons/bid-gray.svg";
import { ReactComponent as DiamondGray } from "assets/icons/diamond-gray.svg";
import { ReactComponent as Diamond } from "assets/icons/diamond-white.svg";
import { ReactComponent as Bell } from "assets/icons/bell.svg";
import Shimmer from "ui/Shimmer";
import Countdown from "ui/Countdown";
import Progress from "ui/Progress";
// import { useEffect } from "react";
import styles from "./TenderHeader.module.css";
import {
  useTenderActiveUsers,
  useTenderAllBids,
  useTenderLastActivity,
} from "features/tenders/queries";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useModalActions from "hooks/useModalActions";
import Shine from "ui/Shimmer/Shine";
import useDatesPercentage from "hooks/useDatesPercentage";
import SwitchAnimation from "ui/SwitchAnimation";
import moment from "moment";
import Flag from "ui/Flag";

// Tender header
const TenderHeader = ({
  tenderId,
  name,
  endDate,
  diamonds,
  shimmer,
  tender,
  tenderUsers,
}: {
  tender: Tender;
  tenderId: string;
  name: string;
  startDate: string;
  endDate: string;
  diamonds: number;
  bids: number;
  activeUsers: number;
  shimmer?: boolean;
  tenderUsers: number;
}) => {
  const { openModal } = useModalActions();
  const {
    data: activeUsers,
    isFetching: isFetchingActiveUsers,
    isFetched: isFetchedActiveUsers,
  } = useTenderActiveUsers(tenderId);
  const {
    // data: allbids,
    isFetching: isFetchingAllBids,
    isFetched: isFetchedAllBids,
  } = useTenderAllBids(tenderId);

  const onFinish = () => {
    openModal({
      modalType: "FinishedTender",
      modalProps: { tender },
      withoutClose: true,
    });
  };

  return (
    <Shimmer shimmer={!!shimmer}>
      <div className="tender-page__header">
        <div className="tender-page__header-top">
          <div className="tender-page__header-top__diamond-circle">
            <Diamond />
          </div>
          <div>
            <p className="tender-page__header-top__name">
              <span className="tender-page__header-top__active-tender">
                Active Tender:
              </span>
              <span className="shine">{name}</span>
            </p>
            <div className={styles.filters}>
              <p className={styles.filter}>
                {tender.filters.model[0]} -{" "}
                {tender.filters.model[tender.filters.model.length - 1]}
              </p>
              <p className={styles.filter}>
                {tender.filters.color[0]} -{" "}
                {tender.filters.color[tender.filters.color.length - 1]}
              </p>
              <p className={styles.filter}>
                {tender.filters.carat.minCarat}-{tender.filters.carat.maxCarat}{" "}
                carat
              </p>
              <p className={styles.filter}>
                {tender.filters.clarity[0]} -{" "}
                {tender.filters.clarity[tender.filters.clarity.length - 1]}
              </p>
            </div>
          </div>
        </div>
        <Shimmer
          shimmer={
            (isFetchingActiveUsers && !isFetchedActiveUsers) ||
            (isFetchingAllBids && !isFetchedAllBids)
          }
        >
          <div className="tender-page__header-bottom">
            <DetailBox
              title={diamonds}
              subtitle={"Diamonds"}
              icon={<DiamondGray />}
            />
            <DetailBox
              title={(activeUsers?.amount || 0) + tenderUsers}
              subtitle={
                <>
                  <span className={styles.greenDot}></span>
                  Participants
                </>
              }
              icon={<Users />}
            />
            <EventTimer tender={tender} date={endDate} onFinish={onFinish} />
            <LatestActivity tenderId={tenderId} />
          </div>
        </Shimmer>
      </div>
    </Shimmer>
  );
};

// Tender lastest activity
const LatestActivity = ({ tenderId }: { tenderId: string }) => {
  const { data: updates } = useTenderLastActivity(tenderId);

  return (
    <div
      className={["tender-page__last-activity", styles.lastActivity].join(" ")}
    >
      <div className={styles.lastActivityTop}>
        <div className={styles.lastActivityCircle}>
          <Bell width={20} />
        </div>
        <p className={styles.lastActivityTitle}>Latest activities</p>
      </div>
      <SwitchAnimation state={(updates || []).length > 0}>
        <div>
          {(updates || []).length > 0 && (
            <TransitionGroup className={styles.lastActivityUpdates}>
              {(updates || [])
                ?.slice(0, 2)
                .map(({ _id, createdAt, location }, index) => {
                  return (
                    <CSSTransition
                      classNames="update"
                      timeout={300}
                      unmountOnExit
                      key={_id}
                    >
                      <div className={styles.lastActivityUpdate}>
                        <div className={styles.lastActivityUpdateIcon}>
                          <Flag flag={location ? location : "USA"} />
                        </div>
                        <p className={styles.lastActivityUpdateText}>
                          User placed a bid
                        </p>
                        <p
                          className={styles.lastActivityUpdateDate}
                          style={{ opacity: 0, visibility: "hidden" }}
                        >
                          {moment(createdAt).fromNow()}
                        </p>
                      </div>
                    </CSSTransition>
                  );
                })}
            </TransitionGroup>
          )}
          {(updates || []).length <= 0 && (
            <p className={styles.noActivity}>
              There were no recent activities.
            </p>
          )}
        </div>
      </SwitchAnimation>
    </div>
  );
};

// Tender event timer
const EventTimer = ({
  date,
  onFinish,
  tender,
}: {
  tender: Tender;
  date: string;
  onFinish: () => void;
}) => {
  const { percentage } = useDatesPercentage({
    startDate: tender.startDate,
    endDate: tender.endDate,
  });

  return (
    <div className="tender-page__event-timer">
      <div className="tender-page__event-timer__top">
        <div className="tender-page__event-timer__top-icon-circle">
          <Clock />
        </div>
        <p className="tender-page__event-timer__top-title">Event ends in:</p>
      </div>
      <Shine>
        <div className="tender-page__event-timer__clock">
          <Countdown date={tender.endDate} onFinish={onFinish} />
        </div>
      </Shine>
      <Shine>
        <div className="tender-page__event-timer__progress">
          <Progress progress={percentage} height={4} />
        </div>
      </Shine>
    </div>
  );
};

// Tender detail box
const DetailBox = ({
  title,
  subtitle,
  icon,
}: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <div className="tender-page__detail-box">
      <div className="tender-page__detail-box__icon-circle">{icon}</div>
      <p className="tender-page__detail-box__title shine">{title}</p>
      <p className="tender-page__detail-box__subtitle shine">{subtitle}</p>
    </div>
  );
};

export default TenderHeader;
