import styled from "styled-components";

export const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize[16]};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;
