import { useUser } from "features/users/queries";

interface Props {
  tenderProduct: TenderProduct;
}

function useTenderProductId({ tenderProduct }: Props) {
  const { data: user } = useUser();
  return user?.company?._id === tenderProduct.product.company
    ? tenderProduct.product.productId
    : tenderProduct.productLotId || "";
}

export default useTenderProductId;
