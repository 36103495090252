import MyBids from "components/MyBids";
import { useTender } from "features/tenders/queries";
import { useTenderUser } from "features/users/queries";
import { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { filtersList } from "./data";
import "./Tender.css";
import TenderFilters from "./TenderFilters";
import TenderHeader from "./TenderHeader";
import TenderProducts from "./TenderProducts";
import { useSetTenderActiveUsers } from "features/tenders/setters";
import { useContextSocket } from "contexts/SocketContext";
import useIsAmlVerified from "hooks/useIsAmlVerified";
const Tender = () => {
  const history = useHistory();
  const isAmlVerified = useIsAmlVerified();
  const socket = useContextSocket();
  const { id: tenderId } = useParams<{ id: string }>();
  const { data, isFetching, isFetched, isError, error } = useTender(tenderId);

  const {
    data: tenderUser,
    // isFetching: isFetchingTenderUser,
    // isFetched: isFetchedTenderUser,
  } = useTenderUser(tenderId);
  const setTenderActiveUsers = useSetTenderActiveUsers();

  useEffect(() => {
    if (!isAmlVerified) history.push("/");
  }, [isAmlVerified, history]);

  useEffect(() => {
    if (data && tenderUser) {
      socket?.emit("join", {
        tenderId: data.tender._id,
        tenderUserId: tenderUser._id,
      });
      socket?.on("updateUsersList", (amount: number) => {
        setTenderActiveUsers(data.tender._id, amount);
      });

      return () => {
        socket?.removeListener("updateUsersList");
        socket?.emit("unjoin");
      };
    }
  }, [data, tenderUser, setTenderActiveUsers, socket]);

  const filtersListMemo = useMemo(() => filtersList, []);
  if (!data || !tenderUser) return null;
  if (isError || !tenderId) return <p>{error?.message}</p>;

  return (
    <div className="tender-page">
      <MyBids tenderId={tenderId} />
      <TenderHeader
        tender={data.tender}
        tenderId={tenderId}
        name={data.tender.name}
        startDate={data.tender.startDate}
        endDate={data.tender.endDate}
        diamonds={data.diamonds}
        bids={data.bids}
        activeUsers={data.activeUsers}
        tenderUsers={data.tender?.tenderUsers.length ?? 0}
        shimmer={isFetching && !isFetched}
      />
      <TenderFilters filtersList={filtersListMemo} tenderId={tenderId} />
      <TenderProducts tenderId={tenderId} />
    </div>
  );
};

export default Tender;
