import Modal from "../Modal";
import { StyledUserDetails } from "./UserDetails.styled";

interface Props {
  user: User;
}

const UserDetails = ({ user }: Props) => {
  return (
    <Modal>
      <StyledUserDetails className="user-details">
        <Modal.Close />
        <img
          src="/assets/images/user-details.png"
          alt="user"
          className="user-details__user"
        />
        <p className="user-details__company">
          Alon Avinoam diamonds ltd (321134)
        </p>
        <div className="user-details__grid">
          {[
            { label: "First Name", value: user.firstName },
            { label: "Last Name", value: user.lastName },
            { label: "Email", value: user.email },
            { label: "Phone", value: user.phoneNumber },
            { label: "Type", value: user.isSupplier ? "Supplier" : "Buyer" },
          ].map((item, idx) => {
            return (
              <div key={idx} className="user-details__box">
                <p className="user-details__label">{item.label}</p>
                <p className="user-details__value">{item.value}</p>
              </div>
            );
          })}
        </div>
      </StyledUserDetails>
    </Modal>
  );
};

export default UserDetails;
