import ElipseDetail from "components/ElipseDetail";
import Price from "ui/Price";
import ProductImage from "components/ProductImage";
import Shine from "ui/Shimmer/Shine";
import ShortDetails from "components/ShortDetails";
import DateTime from "ui/DateTime/DateTime";
// import { useUser } from "features/users/queries";
import useModalActions from "hooks/useModalActions";
import { StyledFinishedTenderCard } from "./FinishedTenderCard.styled";

interface Props {
  tender: Tender;
}

const FinishedTenderCard = ({ tender }: Props) => {
  const bids = (tender.tenderProductBid || []) as TenderProductBid[];

  return (
    <StyledFinishedTenderCard className="c-finishedTenderCard">
      <div className="c-finishedTenderCard__main">
        <div className="c-finishedTenderCard__details">
          <Shine fitContent>
            <p className="c-finishedTenderCard__name">
              Tender: <span>{tender.name}</span>
            </p>
          </Shine>
          <Shine fitContent>
            <div className="c-finishedTenderCard__filters">
              {/* <ElipseDetail>{tender.filters.colorType.join(", ")}</ElipseDetail> */}
              <ElipseDetail>{tender.filters.model.join(", ")}</ElipseDetail>
              <ElipseDetail>{tender.filters.color.join(", ")}</ElipseDetail>
              <ElipseDetail>
                {(tender.filters.carat.minCarat * 1).toFixed(2)} -{" "}
                {(tender.filters.carat.maxCarat * 1).toFixed(2)}
              </ElipseDetail>
              <ElipseDetail>{tender.filters.clarity.join(", ")}</ElipseDetail>
            </div>
          </Shine>
          <Shine fitContent>
            <p className="c-finishedTenderCard__time">
              Closed At:
              <strong>
                {" "}
                <DateTime date={tender.endDate} format={"DD/MM/YYYY HH:mm"} />
              </strong>
            </p>
          </Shine>
        </div>
        <div className="c-finishedTenderCard__seperator"></div>
        <div className="c-finishedTenderCard__progress__container">
          <Shine fitContent>
            <p className="c-finishedTenderCard__progress__title">
              Order Progress
            </p>
          </Shine>
          <div className="c-finishedTenderCard__progress">
            {[
              { title: "Review", subtitle: "We are reviewing the bids." },
              {
                title: "Verification",
                subtitle: "We are now collecting and verifying the goods.",
              },
              {
                title: "Billing and shipping",
                subtitle:
                  "We are now processing the invoicing and payments. Once complete goods will be shipped to buyer.",
              },
            ].map((step, index) => (
              <div key={index} className="c-finishedTender__progress__step">
                <div className="c-finishedTender__progress__step__top">
                  <Shine fitContent>
                    <p className="c-finishedTender__progress__step__number">
                      {index + 1}
                    </p>
                  </Shine>
                  <Shine fitContent>
                    <div className="c-finishedTender__progress__step__line" />
                  </Shine>
                </div>
                <div>
                  <Shine fitContent>
                    <p className="c-finishedTender__progress__step__title">
                      {step.title}
                    </p>
                  </Shine>
                  <Shine fitContent>
                    <p className="c-finishedTender__progress__step__subtitle">
                      {step.subtitle}
                    </p>
                  </Shine>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {bids.length > 0 && (
        <div className="c-finishedTenderCard__bids">
          <p className="c-finishedTenderCard__bids__title">
            My Bids ({bids.length})
          </p>
          {bids.map((bid, index) => {
            if (window.innerWidth <= 768)
              return (
                <FinishedTenderProductBidMobile
                  key={bid._id}
                  tenderProductBid={bid}
                />
              );
            return <FinishedTenderProductBidDesktop key={bid._id} bid={bid} />;
          })}
        </div>
      )}
    </StyledFinishedTenderCard>
  );
};

const FinishedTenderProductBidDesktop = ({
  bid,
}: {
  bid: TenderProductBid;
}) => {
  const { openModal } = useModalActions();

  return (
    <div
      key={bid._id}
      className="c-finishedTenderCard__bid"
      onClick={() =>
        openModal({
          modalType: "TenderProduct",
          modalProps: {
            tenderProduct: bid.tenderProduct,
            onlyView: true,
          },
        })
      }
    >
      <div></div>
      <ProductImage
        className="c-finishedTenderCard__bid__photo"
        src={bid.tenderProduct.product.diamondImage}
      />
      <div className="c-finishedTenderCard__bid__main">
        <p className="c-finishedTenderCard__bid__title">
          LOT ID: {bid.tenderProduct.product.productId}
        </p>
        <ShortDetails
          values={[
            "model",
            "carat",
            "color",
            "clarity",
            "polish",
            "symmetry",
            "fluorescenceIntensity",
            "lab",
          ]}
          product={bid.tenderProduct.product}
        />
      </div>
      <div className="c-finishedTenderCard__bid__price">
        <p className="c-finishedTenderCard__bid__price__title">My Bid</p>
        <Price price={bid.price} postfix="/ppc" />
      </div>
      <div className="c-finishedTenderCard__bid__status">
        <p className="c-finishedTenderCard__bid__status__title">Status</p>
        <p className="c-finishedTenderCard__bid__status__value">Pending</p>
      </div>
    </div>
  );
};

const FinishedTenderProductBidMobile = ({
  tenderProductBid,
}: {
  tenderProductBid: TenderProductBid;
}) => {
  const { openModal } = useModalActions();
  return (
    <div
      className="c-finishedTenderCard__bid--mobile"
      onClick={() =>
        openModal({
          modalType: "TenderProduct",
          modalProps: {
            tenderProduct: tenderProductBid.tenderProduct,
            onlyView: true,
          },
        })
      }
    >
      <div className="top">
        <div className="image">
          <ProductImage
            src={tenderProductBid.tenderProduct.product.diamondImage}
          />
        </div>
        <div className="id">
          LOT ID:{" "}
          <span>
            #{tenderProductBid.tenderProduct.product.productId.slice(0, 8)}
          </span>
        </div>
      </div>
      <div className="properties">
        {(
          [
            "model",
            "carat",
            "color",
            "clarity",
            "polish",
            "symmetry",
            "lab",
          ] as ProductKey[]
        ).map((key) => {
          return (
            <ElipseDetail key={key}>
              {tenderProductBid.tenderProduct.product[key] ?? "-"}
            </ElipseDetail>
          );
        })}
      </div>
      <div className="details">
        <div className="bid">
          <span className="bid__text">My Bid</span>
          <Price price={tenderProductBid.price} postfix="/ppc" />
        </div>
        <div className="status">
          <span className="status__text">My Bid</span>
          <span className="status__bubble">Pending</span>
        </div>
      </div>
    </div>
  );
};

export default FinishedTenderCard;
