import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";
import { IUseTendersOverview } from "./queries";

const registerTender = async (id: string) => {
  const { data } = await Axios.post(routeGenerator(`/tenderUsers/${id}`));
  return data;
};

export const useRegisterToTender = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => registerTender(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(["registered", "tenders"], (old) => {
        if (!old) return old;
        return [...(old as any), data];
      });
    },
  });
};

export const useCleanTendersOverview = () => {
  const queryClient = useQueryClient();
  const queryKey = "/tenders/overview";
  const run = () => {
    queryClient.setQueryData<IUseTendersOverview | undefined>(
      queryKey,
      (old) => {
        if (!old) return old;

        const newLiveTenders = [
          // only tenders that end date is larger
          ...old.liveTenders.filter(
            (tender) => new Date(tender.endDate) <= new Date()
          ),
          // only tenders that end date is larger
          ...old.nextTenders.filter(
            (tender) => new Date(tender.startDate) <= new Date()
          ),
        ];

        const newNextTenders = old.nextTenders.filter(
          (tender) => new Date(tender.startDate) > new Date()
        );

        return {
          ...old,
          liveTenders: newLiveTenders,
          nextTenders: newNextTenders,
        };
      }
    );
  };

  return run;
};
