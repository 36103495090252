import styled from "styled-components";
import bg from "../../assets/images/register-bg.jpg";
export const StyledOverview = styled.div`
  background: #f9fbfd;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  --padding: 2rem;

  /* & *:not(.p-overview__top) {
    max-width: 1400px;
  } */

  /* top start */
  .p-overview__top {
    padding-top: var(--sectionPadding);
    padding-bottom: var(--sectionPadding);
    padding-left: var(--sectionPadding);
    background-color: #f9fbfd;
    background-image: url(${bg});
    background-size: 100% 6.375rem;
    background-repeat: no-repeat;
    background-position: bottom;
    background-origin: border-box;
  }

  .ui-page-summary__title {
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: 0;

    span {
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }

  .ui-page-summary {
    align-items: center;
  }

  /* description start */

  .p-overview__description {
    padding: var(--sectionPadding);
  }

  .p-overview__description__title {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[25]};
    font-weight: 600;
    letter-spacing: -0.5008px;
    color: #222b45;
    margin-bottom: 1rem;
  }

  .p-overview__description__texts {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    column-gap: 2rem;
    max-width: 80%;
  }

  .p-overview__description__text {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[14]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    line-height: 1.53;
  }

  /* section seperator start*/

  .p-overview__seperator {
    padding: ${({ theme }) => theme.spacing[32]} 0
      ${({ theme }) => theme.spacing[12]} 0;
    display: flex;
    align-items: center;
  }

  .p-overview__seperator__title {
    font-family: var(--archivo);
    font-size: ${({ theme }) => theme.fontSize[20]};
    font-weight: 600;
    letter-spacing: -0.4192px;
    color: ${({ theme }) => theme.colors.neutral[700]};
  }

  .p-overview__seperator::after {
    content: "";
    display: block;
    flex: 1;
    height: 0.125rem;
    background-color: #eaeef2;
    margin-left: 1.25rem;
  }

  /* tenders start */

  .p-overview__tenders {
    padding: 0 var(--sectionPadding);
  }

  .p-overview__seperator {
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }

  /* faqs start */
  .p-overview__faqs {
    padding: 0 var(--sectionPadding);
  }

  .p-overview__faqs .accordion-header:first-of-type {
    margin-top: 0;
  }

  /* no tenders start */

  .p-overview__notenders {
    height: 11.25rem;
    width: 100%;
    border-radius: 0.9375rem;
    border: solid 1px #edf2f6;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-overview__notenders__text {
    font-family: var(--archivo);
    font-size: 0.875rem;
    font-weight: 600;
    color: #687c97;
    margin-left: 0.5rem;
  }

  .ui-accordion__header .ui-accordion__arrow {
    color: #687c97;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .p-overview__description__texts {
      max-width: 100%;
    }

    .ui-accordion__header {
      padding: 0.75rem;
    }

    .p-overview__seperator__title {
      font-size: 1.125rem;
    }

    .p-overview__notenders {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        margin-bottom: 0.5rem;
      }
    }
  }
`;
