import styled from "styled-components";

export const StyledEvent = styled.div`
  padding: 1.5rem;
  width: 100%;
  max-width: 640px;
  border-radius: 15px;
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  position: relative;

  .event__summary {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(104, 124, 151, 0.2);
    border-top: 1px solid rgba(104, 124, 151, 0.2);
    display: none;
  }

  .event__summary__box {
    display: flex;
    align-items: center;
  }

  .event__summary__box__circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event__desc {
    font-family: var(--archivo);
    font-size: 13.5px;
    line-height: 1.48;
    letter-spacing: 0.27px;
    color: #687c97;
    margin: 20px 0;
    border-top: 1px solid rgba(104, 124, 151, 0.2);
    padding: 1rem 0;
  }

  .event__actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 42px;
    column-gap: 12px;

    button,
    a {
      border-radius: 6px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .event__register {
    background-color: #32dea5;
    font-family: var(--archivo);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: #fff;
  }

  .event__registered {
    border-radius: 6px;
    border: solid 1px transparent;
    background-color: transparent;
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    color: #222b45;
    display: flex;
    background-color: #e8ebf2;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    opacity: 0.5;

    svg {
      margin-right: 4px;
      width: 20px;
    }
  }

  .event__catalog,
  .event__calendar {
    border: solid 1.5px #e0e4e9;
    background-color: transparent;
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    color: #687c97;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event__summary__box__value {
    font-family: var(--archivo);
    font-size: 17px;
    font-weight: 600;
    color: #222b45;
    margin-right: 8px;
  }

  .event__summary__box__label {
    font-family: var(--archivo);
    font-size: 13px;
    font-weight: 600;
    color: #687c97;
  }

  .event__summary .event__summary__box:first-of-type {
    margin-right: 2rem;
  }

  .event__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .event__tender {
    font-family: var(--archivo);
    font-size: 19px;
    font-weight: 600;
    letter-spacing: -0.42px;
    /* color: #222b45; */
    color: rgba(104, 124, 151, 0.67);
  }

  .event__date {
    font-family: var(--archivo);
    font-size: 15px;
    color: #687c97;
  }

  .event__tender__name {
    font-family: var(--archivo);
    font-size: 19px;
    font-weight: 600;
    letter-spacing: -0.42px;
    color: #222b45;
  }

  .event__filters {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .event__filters > div:not(.event__filters > div:last-of-type) {
    margin-right: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc(100% - var(--sectionPadding) * 2);
    max-width: 100%;
    .event__details {
      display: grid;
      row-gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .event__actions {
      all: unset;
      display: flex;
      flex-direction: column;

      button,
      a {
        height: 42px;
      }

      & > * {
        margin-bottom: 12px;
      }

      & > *:last-of-type {
        margin-bottom: 0;
      }
    }

    .event__desc {
      margin: 0;
    }

    .event__filters {
      margin: -10px 0 1rem 0;
    }
    /* add a corresponding positive top margin to all flex items (all direct children of the flex container) */
    .event__filters > * {
      margin-top: 10px;
    }
  }
`;
