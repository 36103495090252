import React, { ReactElement } from "react";
import Loader from "ui/Loader";
import { StyledButton, StyledButtonProps } from "./Button.styled";

function Button({
  children,
  loading,
  className,
  type,
  ...rest
}: StyledButtonProps &
  React.HTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
  }): ReactElement {
  return (
    <StyledButton
      {...rest}
      kind={rest.kind ?? "purple"}
      isLoading={loading}
      className={["ui-button", className ?? ""].join(" ")}
      type={type}
      onClick={loading ? () => {} : rest.onClick}
    >
      {loading ? (
        <>
          <Loader size={20} />
          Loading...
        </>
      ) : (
        children
      )}
    </StyledButton>
  );
}

export default Button;
