import cn from "classnames";
import { memo, useRef, useState } from "react";
import Select, { OptionsType } from "react-select";
import { ISelectFilter, SelectNames } from "types/filters";
import styles from "./SelectFilter.module.css";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import useOnClickOutside from "hooks/useClickOutside";

interface Props {
  filter: ISelectFilter;
  values: OptionsType<{ value: string; label: string }>;
  handleOnSelect: (
    filterName: SelectNames,
    value: OptionsType<{ value: string; label: string }>
  ) => void;
}

const SelectFilter = ({
  filter,
  handleOnSelect,
  values: value,
  ...rest
}: Props) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { name, displayName, options } = filter;
  useOnClickOutside(ref, open ? () => setOpen(false) : () => {});
  return (
    <div className="relative shine" ref={ref}>
      <Select
        styles={{
          valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "initial",
          }),
        }}
        options={options}
        isMulti={true}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isSearchable={false}
        value={value}
        onChange={(value) => handleOnSelect(name, value)}
        classNamePrefix="select-filter"
        className={cn(styles.container, {
          [styles.containerActive]: open || value.length > 0,
        })}
        menuIsOpen={open}
        placeholder=""
        noOptionsMessage={() => "No results"}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        {...rest}
      />
      <p
        className={cn(styles.placeholder, {
          [styles.placeholderActive]: open || value.length > 0,
        })}
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((prev) => !prev)}
      >
        {displayName}
      </p>
      <ChevronDown className={cn(styles.arrow, {})} />
    </div>
  );
};

export default memo(SelectFilter);
