import styled from "styled-components";

export interface StyledButtonProps {
  readonly size?: "sm" | "md" | "lg";
  readonly kind?: "green" | "purple" | "grey" | "white";
  readonly disabled?: boolean;
  readonly type?: "button" | "submit" | "reset" | undefined;
}

export const StyledButton = styled.button<
  StyledButtonProps & {
    isLoading?: boolean;
  }
>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  /* fonts */
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize[16]};
  line-height: 1;
  /* width */
  width: 9.375rem;
  /* height */
  height: 3rem;

  /* border radius */
  border-radius: 4px;

  /* background color */
  background-color: ${({ theme, kind, disabled }) =>
    (!!disabled && theme.colors.neutral[200]) ||
    (kind === "green" && theme.colors.success[200]) ||
    (kind === "white" && theme.colors.white) ||
    (kind === "purple" && theme.colors.primary[200]) ||
    (kind === "grey" && theme.colors.neutral[600])};

  /* colors */
  color: ${({ theme, kind, disabled }) =>
    (!!disabled && "#D1D7E0") ||
    (kind === "white" && theme.colors.neutral[700]) ||
    theme.colors.white};

  a {
    color: ${({ theme, kind, disabled }) =>
      (!!disabled && "#D1D7E0") ||
      (kind === "white" && theme.colors.neutral[700]) ||
      theme.colors.white};
  }

  /* border */
  border: ${({ theme, kind, disabled }) =>
    `1px solid ${
      kind === "white" && !disabled ? theme.colors.neutral[700] : "transparent"
    }`};

  /* cursor */
  cursor: ${({ disabled, isLoading }) =>
    (isLoading && "wait") || (disabled && "not-allowed") || "pointer"};

  opacity: ${({ disabled }) => (disabled ? 1.75 : 1)};

  /* hover */
  &:hover {
    /* background color */
    background-color: ${({ theme, kind, disabled }) =>
      (!!disabled && theme.colors.neutral[200]) ||
      (kind === "green" && theme.colors.success[300]) ||
      (kind === "white" && theme.colors.neutral[700]) ||
      (kind === "purple" && theme.colors.primary[300]) ||
      (kind === "grey" && theme.colors.neutral[700])};

    /* colors */
    color: ${({ theme, disabled }) =>
      (!!disabled && "#D1D7E0") || theme.colors.white};

    svg,
    a {
      color: ${({ theme, disabled }) =>
        (!!disabled && "#D1D7E0") || theme.colors.white};
    }
  }

  svg {
    width: 1.125rem;
    transition: all 0.3s ease-in-out;
    margin-right: ${({ theme }) => theme.spacing[4]};
    /* colors */
    color: ${({ theme, kind, disabled }) =>
      (!!disabled && "#D1D7E0") ||
      (kind === "white" && theme.colors.neutral[700]) ||
      theme.colors.white};
    /* fill */
    /* fill: ${({ theme, kind, disabled }) =>
      (!!disabled && "#D1D7E0") ||
      (kind === "white" && theme.colors.neutral[700]) ||
      theme.colors.white}; */
  }

  .ui-loader {
    margin-right: ${({ theme }) => theme.spacing[4]};
  }
`;
