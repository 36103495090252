import styled from "styled-components";

export const StyledCatalog = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 15px;
  border: solid 1px #edf2f6;
  background-color: #f5f7f9;
  width: 100%;
  max-width: 900px;

  .catalog__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .catalog__name {
    font-family: var(--archivo);
    font-size: 21px;
    font-weight: 600;
    letter-spacing: -0.42px;
    color: #222b45;
  }
  .catalog__tender {
    color: rgba(104, 124, 151, 0.67);
  }

  .catalog__dates {
    font-family: var(--archivo);
    font-size: 15px;
    color: #687c97;
  }

  .catalog__filters {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 1rem;
    text-transform: uppercase;
  }

  .catalog__filters > div:not(.catalog__filters > div:last-of-type) {
    margin-right: 8px;
    /* font-size: 14px; */
  }

  .catalog__table__container {
    border-radius: 15px;
    box-shadow: 0 5px 20px 0 rgba(74, 85, 100, 0.08);
    background-color: #fff;
    width: 100%;
    margin-top: 1rem;
    overflow: auto;
  }

  .ui-table__container {
    --columns: repeat(6, minmax(130px, 1fr));
    --tableBodyRows: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 350px;
    width: calc(100% - 2rem);
    margin: 1rem auto;
  }

  .ui-table__body__row {
    grid-template-rows: 40px;
  }

  .catalog__table::-webkit-scrollbar {
    width: 4px;
    background-color: #e8ebef;
    direction: rtl;
  }

  .catalog__table::-webkit-scrollbar-thumb {
    background-color: #687c97;
    border-radius: 10px;
  }

  .catalog__bottom {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .catalog__actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .catalog__export {
    margin-right: 1rem;
    border-left: none !important;
  }

  .catalog__total {
    font-family: var(--archivo);
    font-size: 12px;
    color: #687c97;
  }

  .catalog__register {
    width: 158px;
    height: 43px;
  }

  .catalog__registered {
    border-radius: 6px;
    border: solid 1px transparent;
    background-color: transparent;
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    color: #222b45;
    display: flex;
    background-color: #e8ebf2;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    opacity: 0.5;
    width: 158px;
    height: 43px;
  }

  .catalog__registered svg {
    margin-right: 4px;
    width: 20px;
  }

  .catalog__export {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    padding: 0 1rem;
    height: 35px;
    font-family: var(--archivo);
    font-size: 14px;
    font-weight: 600;
    color: #687c97;

    border-right: solid 1.5px rgba(192, 203, 209, 0.28);
    border-left: solid 1.5px rgba(192, 203, 209, 0.28);
  }

  .catalog__export > svg {
    margin-right: 8px;
  }

  .data-table__head {
    position: sticky;
    top: 0;
  }

  .catalog__wishlist {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .data-table__body-row {
    grid-template-rows: 40px;
  }

  .catalog__must_be_registered {
    margin: 1rem 1.5rem;
    font-family: var(--archivo);
    font-size: 16px;
    color: #ef0000;
  }

  .pagination {
    margin: 1rem 1.5rem;
  }

  .data-table__body-cell {
    cursor: default;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: calc(100% - var(--sectionPadding) * 2);

    .catalog__table {
      --tableColumns: repeat(6, minmax(120px, 1fr));
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .catalog__export {
      display: none;
    }

    .ui-button {
      height: 42px;
    }
  }
`;
