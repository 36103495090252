/**
 * @param { Promise } promise
 * @param { Object= } errorExt - Additional Information you can pass to the err object
 * @return { Promise }
 */
export async function asyncCatch<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object
): Promise<[U | null, T | undefined]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        Object.assign(err, errorExt);
      }
      return [err, undefined];
    });
}

export function mySleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const numberWithCommas = (x: string | number, fixed: number = 0) => {
  return Number(x)
    .toFixed(fixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getGreetingTime = () => {
  var today = new Date();
  var curHr = today.getHours();
  if (curHr < 12) {
    return "Good Morning";
  } else if (curHr < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const extractId = (id: any) => {
  if (typeof id === "undefined") return "";
  return typeof id === "string" ? id : id._id;
};

export const isValidHttpUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const addCSS = (s: string) =>
  (document.head.appendChild(document.createElement("style")).innerHTML = s);

export const scrollToElement = (id: string) => {
  const el = document.getElementById(id);
  el?.scrollIntoView({ behavior: "smooth" });
};

export const distinctArray = (array: any[], propertyName: string) => {
  return array.filter(
    (e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i
  );
};

export const mergeObjects = (originalObject: object, objectToMerge: object) => {
  const keys = Object.keys(objectToMerge);
  keys.forEach((key) => {
    if (originalObject.hasOwnProperty(key)) {
      // @ts-ignore
      originalObject[key] = { ...objectToMerge[key] } as any;
    }
  });
  return { ...originalObject };
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getProductPpc = (product: Product) => {
  if (product.updatedPpc) return product.updatedPpc;
  if (product.updatedPrice && product.carat)
    return product.updatedPrice / product.carat;
  return 0;
};
