import "./UpcomingTenderCard.css";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import Shine from "ui/Shimmer/Shine";
import ElipseDetail from "components/ElipseDetail";
import AddToCalendar from "ui/AddToCalendar";
import DateTime from "ui/DateTime/DateTime";
import Flag from "ui/Flag";
import {
  useAllUserRegisteredTenders,
  useUserLocation,
} from "features/users/queries";
import { useRegisterToTender } from "features/tenders/mutations";
import { asyncCatch } from "utils";
import { toast } from "react-toastify";
import SwitchAnimation from "ui/SwitchAnimation";
import { useAnalytics } from "hooks/useAnalytics";
import useIsAmlVerified from "hooks/useIsAmlVerified";
import Button from "ui/Button";
interface Props {
  tender: Tender;
}

const UpcomingTenderCard = ({ tender }: Props) => {
  const isAmlVerified = useIsAmlVerified();
  const track = useAnalytics();
  const { data: userLocation } = useUserLocation();

  const {
    data: registeredTenders,
    isLoading: isLoadingAllUserRegisteredTenders,
  } = useAllUserRegisteredTenders();

  const { mutateAsync, isLoading } = useRegisterToTender();

  const isRegistered = useAllUserRegisteredTenders.isRegistered(
    tender._id,
    registeredTenders || []
  );

  const register = async () => {
    track("Upcoming Tender - Register Button", tender);
    const [err] = await asyncCatch(mutateAsync(tender._id));
    if (err) toast.error("Could not register to tender");
  };

  return (
    <div className="c-upcomingtender">
      <div className="c-upcomingtender__main">
        <Shine>
          <p className="c-upcomingtender__name">
            <span className="c-upcomingtender__tender">Tender:</span>
            {tender.name}
          </p>
        </Shine>
        <div className="c-upcomingtender__filters">
          {/* <ElipseDetail>{tender.filters.colorType.join(", ")}</ElipseDetail> */}
          <ElipseDetail>{tender.filters.model.join(", ")}</ElipseDetail>
          <ElipseDetail>{tender.filters.color.join(", ")}</ElipseDetail>
          <ElipseDetail>
            {(tender.filters.carat.minCarat * 1).toFixed(2)} -{" "}
            {(tender.filters.carat.maxCarat * 1).toFixed(2)}
          </ElipseDetail>
          <ElipseDetail>{tender.filters.clarity.join(", ")}</ElipseDetail>
        </div>
      </div>
      <div className="c-upcomingtender__date">
        <Shine>
          <div className="c-upcomingtender__clock">
            <Clock />
          </div>
        </Shine>
        <Shine>
          <p className="c-upcomingtender__time">
            <DateTime
              date={tender.startDate}
              format={"DD/MM/YYYY"}
              withTimezone={false}
            />
            <br />
            <DateTime date={tender.startDate} format={"HH:mm"} />
          </p>
        </Shine>
        <Shine fitContent>
          <div>
            <Flag flag={userLocation?.country} style={{ width: 30 }} />
          </div>
        </Shine>
      </div>
      <div className="c-upcomingtender__calendar">
        <Shine>
          <AddToCalendar
            className="c-upcomingtender__calendar__btn"
            text={tender.name}
            details={tender.description}
            startDate={tender.startDate}
            endDate={tender.endDate}
            onClick={() =>
              track("Upcoming Tender - Add to Calendar Button", tender)
            }
          >
            <Calendar className="c-upcomingtender__calendar__icon" />
            Add To Calendar
          </AddToCalendar>
        </Shine>
      </div>
      <SwitchAnimation state={isRegistered}>
        <Shine>
          <Button
            kind="green"
            onClick={register}
            disabled={
              isLoadingAllUserRegisteredTenders ||
              isRegistered ||
              !isAmlVerified
            }
            loading={isLoadingAllUserRegisteredTenders || isLoading}
          >
            {isRegistered ? (
              <>
                <Check />
                Registered
              </>
            ) : (
              "Register"
            )}
          </Button>
        </Shine>
      </SwitchAnimation>
    </div>
  );
};

export default UpcomingTenderCard;
