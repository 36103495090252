import { useQueryClient } from "react-query";
import { useCallback } from "react";
export const useSetTenderActiveUsers = () => {
  const queryClient = useQueryClient();

  const setter = useCallback(
    (tenderId: string, amount: number) => {
      queryClient.setQueryData(["/usersactiveusers", tenderId], () => ({
        amount,
      }));
    },
    [queryClient]
  );

  return setter;
};
