import { routeGenerator } from "api";
import { GD_CLIENT_URL } from "config";
import { axios as Axios } from "lib/axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { RegisterAml, RegisterUser } from "types/register";
import storage from "utils/storage";

export const useMutateUser = () => {
  const queryClient = useQueryClient();

  const mutate = useCallback(
    (user: User | null) => {
      queryClient.setQueryData("/users/me", () => user);
    },
    [queryClient]
  );

  return mutate;
};

const getLogin = async (token: string) => {
  const { data } = await Axios.post(routeGenerator(`/users/getLogin`), {
    token,
  });
  return data;
};

export const useGetLogin = () => {
  return useMutation<User, ApiError, string>("getLogin", (token) =>
    getLogin(token)
  );
};

export const checkEmail = async (email: string) => {
  const { data } = await Axios.post(routeGenerator(`/users/check/email`), {
    email,
  });
  return data;
};

export const useCheckEmail = () =>
  useMutation<undefined, ApiError, string>((email) => checkEmail(email));

export const checkCompany = async (name: string) => {
  const { data } = await Axios.get(routeGenerator(`/companies/name/${name}`));
  return data;
};

export const useCheckCompany = () =>
  useMutation<undefined, ApiError, string>((name) => checkCompany(name));

export interface RegisterPayload {
  user: RegisterUser;
  aml: RegisterAml;
  files: Base64File[];
  isSupplier: boolean;
  amlTenderStatus: "pending" | "in-process";
}

const register = async (payload: RegisterPayload) => {
  const { data } = await Axios.post(routeGenerator(`/companies`), payload);
  return data;
};

export const useRegister = () => {
  // const { mutateUser } = useUser();
  return useMutation<User, ApiError, RegisterPayload>(
    "register",
    (payload) => register(payload),
    {
      onSuccess: () => {
        localStorage.removeItem("register");
        // mutateUser(user);
      },
    }
  );
};

interface LoginPayload {
  email: string;
  password: string;
}

const login = async (payload: LoginPayload) => {
  const { data } = await Axios.post(routeGenerator(`/users/login`), payload);
  return data;
};

export const useLogin = () => {
  const mutateUser = useMutateUser();
  return useMutation<User, ApiError, LoginPayload>(
    "register",
    (payload) => login(payload),
    {
      onSuccess: (user) => {
        storage.setToken(user.token);
        mutateUser(user);
      },
    }
  );
};

export const useLogout = () => {
  const mutateUser = useMutateUser();
  const queryClient = useQueryClient();
  const logout = () => {
    queryClient.cancelQueries();
    storage.clearToken();
    storage.clearGDToken();
    mutateUser(null);
    window.open(GD_CLIENT_URL + "/logout", "_parent");
  };
  return { logout };
};

const forgotPassword = async (payload: { email: string }) => {
  const { data } = await Axios.post(
    routeGenerator(`/users/forgot/password`),
    payload
  );
  return data;
};

export const useForgotPassword = () => {
  return useMutation<null, ApiError, { email: string; url: string }>(
    "forgotPassword",
    (payload) => forgotPassword(payload)
  );
};

interface ResetPasswordPayload {
  token: string;
  newPassword: string;
  confirmNewPassword: string;
}

const resetPassword = async (payload: ResetPasswordPayload) => {
  const { data } = await Axios.post(
    routeGenerator(`/users/reset/password`),
    payload
  );
  return data;
};

export const useResetPassword = () => {
  return useMutation<null, ApiError, ResetPasswordPayload>(
    "resetPassword",
    (payload) => resetPassword(payload)
  );
};

const approveTerms = async () => {
  const { data } = await Axios.put(routeGenerator(`/users/terms`));
  return data;
};

export const useApproveTerms = () => {
  return useMutation<null, ApiError>("approveTerms", () => approveTerms());
};

// =================================================================
// Update AML  =====================================================
// =================================================================

export interface UpdateAmlPayload {
  user: RegisterUser;
  aml: RegisterAml;
  files: Base64File[];
  isSupplier: boolean;
  amlTenderStatus: "pending" | "in-process";
}

const updateAml = async (payload: UpdateAmlPayload) => {
  const { data } = await Axios.post(
    routeGenerator(`/companies/update/amlTender`),
    payload
  );
  return data;
};

export const useUpdateAml = () => {
  return useMutation<User, ApiError, UpdateAmlPayload>(
    "updateAml",
    (payload) => updateAml(payload),
    {
      onSuccess: () => {
        localStorage.removeItem("register");
      },
    }
  );
};
