import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
// import { useEffect } from "react";
import { useQuery } from "react-query";
import storage from "utils/storage";

const getUser = async () => {
  const { data } = await Axios.get(routeGenerator(`/users/me`), {
    headers: { token: storage.getToken() },
  });
  return data;
};

export const useUser = () => {
  return useQuery<User, ApiError>("/users/me", getUser, {
    retry: false,
    enabled: false,
    staleTime: 60 * 60 * 1000,
    cacheTime: 0,
  });
};

export const useIsUserSupplier = (user?: User | null | undefined) => {
  const { data: tenderUser } = useUser();
  return {
    isSupplier: !tenderUser ? false : !!tenderUser.company.isTenderSupplier,
  };
};

const getTenderUser = async (tenderId: string) => {
  const { data } = await Axios.get(routeGenerator(`/tenderUsers/${tenderId}`));
  return data;
};

interface IUseTenderUser extends Timestamps {
  _id: string;
  tenderProductNotify: TenderProduct[];
  tenderProductBid: TenderProductBid[];
  user: User;
  company: Company;
  updatedAt: string;
  createdAt: string;
  __v: 0;
  registeredTender: Tender;
  savedTenderProducts: string[];
  savedTenderProductsObj: object;
}

export const useTenderUser = (tenderId: string) => {
  return useQuery<IUseTenderUser, ApiError>(
    ["tenderuser", tenderId],
    () => getTenderUser(tenderId),
    { staleTime: 60 * 60 * 1000 }
  );
};

export interface UserLocation {
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  timezone: string;
}

const IP_INFO_TOKEN = "879eeb5fac2bce";

const getUserLocation = async () => {
  const { data } = await Axios.get(
    `https://ipinfo.io/?token=${IP_INFO_TOKEN}`,
    {
      headers: { token: storage.getToken() },
    }
  );
  return data;
};

export const useUserLocation = () =>
  useQuery<UserLocation, ApiError>(
    ["user", "location"],
    () => getUserLocation(),
    { retry: false, enabled: false }
  );

type UseAllUserRegisteredTenders = TenderUser[];

const getAllUserRegisteredTenders = async () => {
  const { data } = await Axios.get(routeGenerator(`/tenderUsers/all`));
  return data;
};

export const useAllUserRegisteredTenders = () =>
  useQuery<UseAllUserRegisteredTenders, ApiError>(
    ["registered", "tenders"],
    () => getAllUserRegisteredTenders()
  );

useAllUserRegisteredTenders.isRegistered = (
  id: string,
  registeredTenders: TenderUser[]
) => {
  return (
    (registeredTenders || []).findIndex(
      (value) => (value.registeredTender as any) === id
    ) !== -1
  );
};
