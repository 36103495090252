import styled from "styled-components";

export const StyledPrice = styled.p`
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-size: ${({ theme }) => theme.fontSize[18]};
  color: ${({ theme }) => theme.colors.neutral[600]};
  .ui-price__zeros {
    color: rgba(104, 124, 151, 0.3);
  }
  .ui-price__postfix {
    font-size: ${({ theme }) => theme.fontSize[14]};
    padding-left: 2px;
    position: relative;
  }
`;
