import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import Tabs from "ui/Tabs";
import { useSupplierDiamonds } from "features/supplier/queries";
// import { useUser } from "features/users/queries";
import useModalActions from "hooks/useModalActions";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  Cell,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { numberWithCommas } from "utils";
import { approved, pending } from "./columns";
import { useAnalytics } from "hooks/useAnalytics";
import ExportCSV from "ui/ExportCSV";
import Page from "ui/Page";
import { StyledSupplier } from "./Supplier.styled";
import Table from "ui/Table";
import Checkbox from "ui/Checkbox";
import Button from "ui/Button";

interface IParams {
  type: "pending" | "approved" | "rejected";
}

const tabs = [
  { key: "pending", label: "Pending Diamonds" },
  { key: "approved", label: "Approved Diamonds" },
  { key: "rejected", label: "Rejected Diamonds" },
];

const Supplier = () => {
  const track = useAnalytics();
  const { openModal } = useModalActions();
  const { type } = useParams<IParams>();
  const [tab, setTab] = useState(type);
  const queryClient = useQueryClient();
  // const { data: user } = useUser();
  // const history = useHistory();

  useEffect(() => {
    if (tab === "pending") track("My Diamonds - Pending Tab");
    if (tab === "approved") track("My Diamonds - Approved Tab");
    if (tab === "rejected") track("My Diamonds - Rejected Tab");
  }, [tab, track]);

  const {
    data: pendingDiamonds,
    isFetching: isFetchingPendingDiamonds,
    isFetched: isFetchedPendingDiamonds,
  } = useSupplierDiamonds("pending");

  const {
    data: approvedDiamonds,
    isFetching: isFetchingApprovedDiamonds,
    isFetched: isFetchedApprovedDiamonds,
  } = useSupplierDiamonds("accepted");

  const {
    data: rejectedDiamonds,
    isFetching: isFetchingRejectedDiamonds,
    isFetched: isFetchedRejectedDiamonds,
  } = useSupplierDiamonds("rejected");

  const filteredPendingDiamonds = useMemo(
    () =>
      pendingDiamonds?.filter((pendingDiamond) => {
        return (
          new Date(pendingDiamond.memoDate).getTime() > new Date().getTime()
        );
      }),
    [pendingDiamonds]
  );

  const shimmerTable = () => {
    if (tab === "pending") {
      return isFetchingPendingDiamonds && !isFetchedPendingDiamonds;
    }
    if (tab === "approved") {
      return isFetchingApprovedDiamonds && !isFetchedApprovedDiamonds;
    }
    return isFetchingRejectedDiamonds && !isFetchedRejectedDiamonds;
  };

  const columns = useMemo(() => {
    if (tab === "pending") return pending;
    return approved;
  }, [tab]);

  const getData = () => {
    if (tab === "pending") return filteredPendingDiamonds ?? [];
    if (tab === "approved") return approvedDiamonds ?? [];
    return rejectedDiamonds ?? [];
  };

  const data = getData();

  const table = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 30 },
      autoResetPage: false,
      getRowId: (original) => original._id,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (tab !== "pending") return hooks;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ toggleAllRowsSelected, isAllRowsSelected }) => {
            return (
              <Shine>
                <div className="select-all">
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    checked={isAllRowsSelected}
                    onChange={(e) => {
                      toggleAllRowsSelected(!isAllRowsSelected);
                    }}
                    disabled={tab !== "pending"}
                  />
                </div>
              </Shine>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: Cell<TenderProductOffer>) => {
            return (
              <Shine>
                <div>
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    checked={row.isSelected}
                    onChange={(e) => {
                      row.toggleRowSelected(!row.isSelected);
                    }}
                    disabled={tab !== "pending"}
                  />
                </div>
              </Shine>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    queryClient.resetQueries(["supplier", "approved"]);
    queryClient.resetQueries(["supplier", "pending"]);
    queryClient.resetQueries(["supplier", "rejected"]);
  }, [queryClient]);

  const onApproveAll = () => {
    track("My Diamonds - Approve Button");
    openModal({
      modalType: "ApproveDiamond",
      modalProps: {
        tenderProductOffers: table.selectedFlatRows.map(
          ({ original }) => original
        ),
      },
    });
  };

  const shimmer =
    (isFetchingPendingDiamonds && !isFetchedPendingDiamonds) ||
    (isFetchingApprovedDiamonds && !isFetchedApprovedDiamonds) ||
    (isFetchingRejectedDiamonds && !isFetchedRejectedDiamonds);

  return (
    <StyledSupplier className="p-supplier" tab={tab}>
      <Shimmer shimmer={shimmer}>
        <Page.Summary
          title="My Diamonds"
          subtitle={`You can mange your tender’s diamonds here`}
          values={[
            {
              label: "Pending Diamonds:",
              value: numberWithCommas(filteredPendingDiamonds?.length || 0),
            },
            {
              label: "Approved Diamonds:",
              value: numberWithCommas(approvedDiamonds?.length || 0),
            },
            {
              label: "Rejected Diamonds:",
              value: numberWithCommas(rejectedDiamonds?.length || 0),
            },
          ]}
        />
      </Shimmer>
      <Page.Tabs>
        <Tabs
          orientation="horizontal"
          onValueChange={(newTab) => setTab(newTab as any)}
          defaultValue={tab}
        >
          <Tabs.List aria-label="choose your orders type">
            {tabs.map((tab) => {
              return <Tabs.Trigger value={tab.key}>{tab.label}</Tabs.Trigger>;
            })}
          </Tabs.List>
        </Tabs>
        {/* <Tabs
          activeTab={tab}
          setActiveTab={setTab}
          tabs={tabs.map(({ label }) => label)}
        /> */}
        <div className="p-supplier__actions">
          <Shine>
            <ExportCSV
              className="p-supplier__export"
              data={table.rows}
              headers={[
                {
                  label: "Image",
                  accessor: "original.product.diamondImage",
                },
                {
                  label: "Lot ID",
                  accessor: "original.product.productId",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Shape",
                  accessor: "original.product.model",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Carat",
                  accessor: "original.product.carat",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Color",
                  accessor: "original.product.color",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Clarity",
                  accessor: "original.product.clarity",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Lab",
                  accessor: "original.product.lab",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Location",
                  accessor: "original.product.country",
                  format: (str) => String(str).toUpperCase(),
                },
                {
                  label: "Reserve Price",
                  accessor: "original.reservePrice",
                  format: (str) => `"${numberWithCommas(str)}/total"`,
                },
                {
                  label: "Tender Name",
                  accessor: "original.tender.name",
                },
                {
                  label: "Tender Date",
                  accessor: "original.tender.startDate",
                  format: (str) =>
                    [
                      moment(str).format("DD/MM/YYYY"),
                      moment(str).format("LT"),
                    ].join(" "),
                },
              ]}
              filename={`${
                tab === "pending"
                  ? "Pending"
                  : tab === "approved"
                  ? "Approved"
                  : "Rejected"
              } Diamonds
            `}
            >
              Export To CSV
            </ExportCSV>
          </Shine>
          {tab === "pending" && (
            <Button
              onClick={onApproveAll}
              className="p-supplier__approve-all"
              kind="green"
              disabled={table.selectedFlatRows.length === 0}
            >
              Approve Selected
            </Button>
          )}
        </div>
      </Page.Tabs>
      <Table
        switchState={table.rows.length > 0}
        shimmer={shimmerTable()}
        table={table}
        onRowClick={(original) =>
          !shimmer &&
          openModal({
            modalType: "TenderProduct",
            modalProps: {
              tenderProduct: original,
              onlyView: true,
            },
          })
        }
        className={`p-supplier__diamonds-table 
  p-supplier__diamonds-table--${tab === "pending" ? "pending" : "approved"}`}
        emptyText={(() => {
          if (tab === "pending")
            return (
              <p>
                Looks like there are no diamonds here.
                <br />
                Approve your diamonds for tenders here.
              </p>
            );
          if (tab === "approved")
            return (
              <p>
                Looks like there is no diamonds here.
                <br />
                Once you’ve approved a diamond for a tender, it will appear
                here.
              </p>
            );
          if (tab === "rejected")
            return (
              <p>
                Looks like there is no diamonds here.
                <br />
                Diamonds you rejected will appear here.
              </p>
            );
        })()}
      />
    </StyledSupplier>
  );
};

export default Supplier;
