import { GD_CLIENT_URL } from "config";

export const Styles = {
  HEADER_CONTAINER_GUEST: "header-container-guest",
  HEADER_CONTAINER_USER: "header-container-user",
  HEADER_CONTAINER_GUEST_WITH_BG: "header-container-guest scrolled",
  NAV_CONTAINER: "navigation-container",
  USER_ACTIONS_CONTAINER: "user-actions-container",
  BUTTON: "global-button",
  HEADER_WRAPPER: "header-wrapper",
  MOBILE_CONTAINER_USER: "mobile-container-user",
  MOBILE_CONTAINER_GUEST: "mobile-container-guest",
  MOBILE_CONTAINER_USER_OPEN: "mobile-container-user open",
  MOBILE_CONTAINER_GUEST_OPEN: "mobile-container-guest open",
  NAV_CONTAINER_MOBILE: "navigation-container-mobile",
  NAV_CONTAINER_MOBILE_TRADE: "navigation-container-mobile trade",
  USER_ACTIONS_CONTAINER_MOBILE: "user-actions-container-mobile",
  HAMBURGER: "hamburger",
  LOGO: "logo",
  LEFT: "left",
  RIGHT: "right",
  STROKE: "stroke",
  FILL: "fill",
  SUB_HEADER_USER: "sub-header-user",
  SUB_HEADER_USER_OPEN: "sub-header-user open",
  USER_ACTIONS_POPUP: "user-actions-popup",
  USER_ACTIONS_POPUP_OPEN: "user-actions-popup open",
  USER_ACTIONS_POPUP_OPEN_BUYER: "user-actions-popup open-buyer",
  USER_ACTIONS_POPUP_LNG: "user-actions-popup-lng",
  USER_ACTIONS_POPUP_MOBILE: "user-actions-popup-mobile",
  USER_ACTIONS_POPUP_MOBILE_OPEN: "user-actions-popup-mobile open",
  TRIANGLE: "triangle",
  TRIANGLE_OPEN: "triangle open",
  USER: "user",
  ACCOUNT: "account",
  MANAGE: "manage",
  SUPPORT: "support",
  ACTIONS: "actions",
  CLOSE_MENU_SCREEN: "close-menu-screen",
  CLOSE_MENU_SCREEN_OPEN: "close-menu-screen open",
  NOTIFICATIONS_CONTAINER: "notifications-container",
  WISHLIST_CONATINER: "wishlist-container",
  USER_INFO: "user-info",
  USERNAME: "username",
  COMPANY: "company",
};

export const Urls = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  ABOUT: "/about",
  SEARCH: "/login",
  SUPPLIERS: "/login",
  PRICING: "/login",
  SUPPORT: "/login",
  LOGIN: "/login",
  PLAN: "/register",
  DIAMONDS: "/search",
  JEWELRY: "/jewelry",
  MEMBERS: "/members",
  NEWS: "/news",
  TRADE: "/trade",
};

export const headerLinksGuest = [
  { label: "Home", url: "/", isReady: true },
  // { label: "Diamonds", url: "/search", isReady: true },
  // { label: "Jewelry", url: "/search", isReady: false },
  // { label: "Members", url: "/members/suppliers", isReady: true },
  { label: "About", url: "/about", isReady: true },
  { label: "Support", url: "/support", isReady: true },
  // { label: "News", external: "http://news.get-diamonds.com/", isReady: true },
  { label: "News", url: "/news", isReady: true },
];

export const headerLinksSupplier = [
  {
    label: "Home",
    external: GD_CLIENT_URL + "/",
    isReady: true,
    target: "_parent",
  },
  {
    label: "Diamonds",
    external: GD_CLIENT_URL + "/search",
    isReady: true,
    target: "_parent",
  },
  // { label: "Trade", url:"/trade", isReady: true, isNew: true},
  { label: "Trade", isReady: true, isNew: true, url: "/" },
  // { label: "Jewelry", url: false, isReady: false },
  {
    label: "Members",
    external: GD_CLIENT_URL + "/members/sellers",
    isReady: true,
    target: "_parent",
  },
  {
    label: "Manage",
    external: "https://dashboard.get-diamonds.com/",
    isReady: true,
    target: "_blank",
  },
  // {label: "About", url: "/about/about-get", isReady: true},
  {
    label: "News",
    external: GD_CLIENT_URL + "/news",
    isReady: true,
    target: "_blank",
  },
  // { label: "News", external: "http://news.get-diamonds.com/", isReady: true },
];

export const headerLinksBuyer = [
  { label: "Home", url: "/", isReady: true },
  { label: "Diamonds", url: "/search", isReady: true },
  { label: "Trade", isReady: true, isNew: true, external: GD_CLIENT_URL },
  // { label: "Jewelry", url: false, isReady: false },
  { label: "Members", url: "/members/sellers", isReady: true },
  // {
  //   label: "News",
  //   // url: "/news/shows",
  //   external: "http://news.get-diamonds.com/",
  //   isReady: true,
  // },
  { label: "News", url: "/news", isReady: true },
];

export const SubHeaderTabs = {
  diamonds: [
    { label: "Single", type: "single" },
    { label: "Pairs", type: "pairs" },
    { label: "Parcels", type: "parcels" },
    { label: "Create Call", url: "/creatediamondneed" },
    // {label: "Price-lists", },
    // {label: "Data Insight", },
  ],
  creatediamondneed: [
    { label: "Search Diamonds", url: "/search" },
    { label: "Create Call", url: "/creatediamondneed" },
    // {label: "Price-lists", },
    // {label: "Data Insight", },
  ],
  trading: [
    { label: "Tenders", url: "/trading/tenders" },
    { label: "Auctions", url: "/trading/auctions" },
    { label: "My Bids", url: "/trading/bids" },
    { label: "My Wishlist", url: "/trading/wishlist" },
    { label: "Popular Diamonds", url: "/trading/popular" },
    { label: "Analytics", url: "/trading/analytics" },
  ],
  members: [
    { label: "Sellers", url: "/members/sellers" },
    { label: "Buyers", url: "/members/buyers" },
    // {label: "Brokers", url: "/members/brokers"},
    { label: "My Favorites", url: "/members/favorites" },
  ],
  // news: [
  //     {label: "Shows", url: "/news/shows"},
  //     {label: "Rough Markets", url: "/news/rough-markets"},
  //     {label: "Mining", url: "/news/mining"},
  //     {label: "Polished Markets", url: "/news/polished-markets"},
  //     {label: "Manufacturing", url: "/news/manufacturing"},
  //     {label: "Retail and Jewelry", url: "/news/retail&jewelry"},
  // ],
  // about: [
  //     {label: "About GET", url: "/about/about-get"},
  //     {label: "Contact us", url: "/about/contact"},
  // ]
};
