import styled from "styled-components";

export const StyledRegisterPopup = styled.div`
  width: 80%;
  height: 80%;
  position: relative;
  max-width: 1100px;
  max-height: 753px;

  .register-popup__inner {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }

  .register {
    height: 100%;
    --fieldsGap: 1.5rem;
  }

  .register__step__title {
    font-size: 1.5rem;
  }

  .register__bottom_text {
    font-size: 13px;
    padding-bottom: 2rem;
  }

  .register__inner .ui-stepper {
    padding-top: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: calc(100% - var(--sectionPadding) * 2);
    width: 100%;

    .register {
      padding: 0;
    }

    .register__inner .ui-stepper {
      padding: 1rem 1rem 0.5rem 1rem;
    }

    .register__inner {
      padding: 0;
    }

    .register__steps {
      padding: 0 1rem 0 1rem;
    }

    .register__bottom_text {
      padding: 1rem;
    }
  }
`;
