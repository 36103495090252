import React from "react";
interface Props {
  children: JSX.Element | JSX.Element[];
  shimmer: boolean;
  className?: string;
}
const Shimmer = ({ children, shimmer = false, className = "" }: Props) => {
  const shimmerClassName = `shimmer ${
    shimmer ? "shimmer--active" : "shimmer--finished"
  }`;

  const styledChildren = () =>
    React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        className: `${child.props.className} ${shimmerClassName}`,
      });
    });

  return styledChildren()[0];
};

export default Shimmer;
