import styled from "styled-components";

export interface StyledInputProps {
  isError?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  border: 2px solid
    ${({ theme, isError }) =>
      (isError && theme.colors.error[200]) || theme.colors.neutral[200]};
  height: 3.125rem;
  padding-left: ${(props) => props.theme.spacing[24]};
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-size: ${({ theme }) => theme.fontSize[16]};
  color: ${({ theme }) => theme.colors.neutral[700]};
  width: 100%;
  border-radius: 6px;
  outline-style: none;
  /* background-color: ${({ theme }) => theme.colors.white}; */

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 50px #f9fbfd inset;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[200]};
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.light};
    background-color: transparent;
  }

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.neutral[600]};
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
    margin-right: 8px;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;

  .ui-input__eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: ${({ theme }) => theme.colors.neutral[600]};
    cursor: pointer;
  }
`;
