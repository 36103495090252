import React, { InputHTMLAttributes } from "react";
import { PhoneInputProps } from "react-phone-input-2";
import { Props as SelectProps } from "react-select";
import Field from "ui/Field";
import Label from "ui/Label";
import Input from "ui/Input";
import Select from "ui/Select";
import PhoneInput from "ui/PhoneInput";

interface InputField extends InputHTMLAttributes<HTMLInputElement> {
  fieldType: "input";
  label: string;
  isError?: boolean;
  error?: string;
}

interface PhoneField extends PhoneInputProps {
  fieldType: "phone";
  label: string;
  name: string;
  id: string;
  isError?: boolean;
  error?: string;
}

interface SelectField extends SelectProps {
  fieldType: "select";
  label: string;
  isError?: boolean;
  error?: string;
}

export type FormField = InputField | SelectField | PhoneField;

export type TFormFields = FormField[];

type Props = {
  fieldsClassName?: string;
  fieldClassName?: string;
  fields: TFormFields;
  children?: React.ReactNode;
};

const FormFields = ({ fieldsClassName = "", fields }: Props) => {
  return (
    <div className={fieldsClassName}>
      {fields.map((field) => {
        const { label, fieldType, ...rest } = field;
        return (
          <Field key={field.name}>
            <Label htmlFor={rest.name}>{field.label}</Label>
            {(() => {
              switch (fieldType) {
                case "input":
                  return (
                    <Input
                      {...(rest as any)}
                      isError={!!rest.isError}
                      error={rest.error && !!rest.isError}
                    />
                  );
                case "phone":
                  return (
                    <PhoneInput
                      {...(rest as any)}
                      isError={!!rest.isError}
                      error={rest.error && !!rest.isError}
                    />
                  );
                case "select":
                  return (
                    <Select
                      {...(rest as any)}
                      isError={!!rest.isError}
                      error={rest.error && !!rest.isError}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Field>
        );
      })}
    </div>
  );
};

export default FormFields;
