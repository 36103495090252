import styled from "styled-components";

export const StyledModal = styled.div`
  background-color: rgba(167, 161, 161, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 12121212121212;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* backdrop-filter: blur(3px); */

  .modal__close {
    --size: 35px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: calc(var(--size) / -2);
    top: calc(var(--size) / -2);
    background-color: #222b45;
    cursor: pointer;
    z-index: 1000;
  }

  .modal__close__icon {
    color: #fff;
    width: 20px;
  }
`;
