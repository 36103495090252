import Footer from "ui/Footer";
import { StyledPage } from "./Page.styled";
import PageSummary from "./PageSummary";
import PageTabs from "./PageTabs";

export interface PageProps {
  className?: string;
  children?: React.ReactNode;
}

const Page = ({ className, children }: PageProps) => {
  return (
    <StyledPage className={["ui-page", className].join(" ")}>
      {children}
      <Footer />
    </StyledPage>
  );
};

Page.Summary = PageSummary;
Page.Tabs = PageTabs;

export default Page;
