import ElipseDetail from "components/ElipseDetail";
import Shine from "ui/Shimmer/Shine";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as GrayDiamond } from "../../../assets/icons/gray-diamond.svg";
// import { ReactComponent as Users } from "../../../assets/icons/users.svg";
import { ReactComponent as BookOpen } from "../../../assets/icons/book-open.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import Countdown from "ui/Countdown";
import Progress from "ui/Progress";
import useModalActions from "hooks/useModalActions";
import {
  useCleanTendersOverview,
  useRegisterToTender,
} from "features/tenders/mutations";
import { asyncCatch } from "utils";
import { useHistory } from "react-router";
import Flag from "ui/Flag";
import DateTime from "ui/DateTime/DateTime";
import "./TenderCard.css";
import {
  useAllUserRegisteredTenders,
  useUserLocation,
} from "features/users/queries";
import { toast } from "react-toastify";
import useDatesPercentage from "hooks/useDatesPercentage";
// import SwitchAnimation from "components/SwitchAnimation";
import Button from "ui/Button";
import { useAnalytics } from "hooks/useAnalytics";
import useIsAmlVerified from "hooks/useIsAmlVerified";
interface Props {
  tender: Tender;
  status?: React.ReactNode;
  isNext?: boolean;
}

const TenderCard = ({ tender, status, isNext = false }: Props) => {
  const isAmlVerified = useIsAmlVerified();
  const track = useAnalytics();
  const { data: userLocation } = useUserLocation();

  const clean = useCleanTendersOverview();
  const history = useHistory();

  const { percentage } = useDatesPercentage({
    startDate: tender.startDate,
    endDate: tender.endDate,
  });

  const { openModal, closeModal } = useModalActions();
  const { isLoading, mutateAsync } = useRegisterToTender();
  const { data: registeredTenders } = useAllUserRegisteredTenders();
  const onEnter = async () => {
    track("Live Tender - Enter Button", tender);
    const [err, data] = await asyncCatch(mutateAsync(tender._id));
    if (!err && data) history.push(`/tender/${tender._id}`);
    closeModal();
  };

  const onRegister = async () => {
    track("Next Tender - Register Button", tender);
    const [err] = await asyncCatch(mutateAsync(tender._id));
    err && toast.error("Could not register to tender");
  };

  const onCatalog = () => {
    isNext
      ? track("Next Tender - Catalog Button", tender)
      : track("Live Tender - Catalog Button", tender);

    openModal({
      modalType: "Catalog",
      modalProps: {
        tender,
        withRegister: isNext,
        withEnter: !isNext,
      },
    });
  };

  const isRegistered = useAllUserRegisteredTenders.isRegistered(
    tender._id,
    registeredTenders || []
  );

  return (
    <div className="c-tendercard">
      <div className="c-tendercard__details">
        <Shine fitContent>
          <p className="c-tendercard__tender">
            <span className="c-tendercard__name">Tender: </span> {tender.name}
          </p>
        </Shine>
        <Shine fitContent>
          <div className="c-tendercard__filters">
            {/* <ElipseDetail>{tender.filters.colorType.join(", ")}</ElipseDetail> */}
            <ElipseDetail>{tender.filters.model.join(", ")}</ElipseDetail>
            <ElipseDetail>{tender.filters.color.join(", ")}</ElipseDetail>
            <ElipseDetail>
              {(tender.filters.carat.minCarat * 1).toFixed(2)} -{" "}
              {(tender.filters.carat.maxCarat * 1).toFixed(2)}
            </ElipseDetail>
            <ElipseDetail>{tender.filters.clarity.join(", ")}</ElipseDetail>
          </div>
        </Shine>
        <Shine>
          <p className="c-tendercard__title">Description</p>
        </Shine>
        <Shine>
          <p className="c-tendercard__description">{tender.description}</p>
        </Shine>
      </div>
      <div className="c-tendercard__summary">
        <div className="c-tendercard__summary__box">
          <div className="c-tendercard__icon">
            <Clock />
          </div>
          <Shine>
            <div className="c-tendercard__summary__box__value">
              <DateTime
                date={tender.startDate}
                format={"DD/MM/YYYY"}
                withTimezone={false}
              />
            </div>
          </Shine>
          <Shine>
            <div className="c-tendercard__summary__box__label">
              <DateTime date={tender.startDate} format={"HH:mm"} />
              <Flag
                style={{ width: 30 }}
                flag={userLocation?.country || ""}
                className="c-tendercard__flag"
              />
            </div>
          </Shine>
        </div>
        {/* <div className="c-tendercard__summary__box">
          <div className="c-tendercard__icon">
            <Users />
          </div>
          <Shine>
            <div className="c-tendercard__summary__box__value">
              {((tender as any).tenderUsers || []).length}
            </div>
          </Shine>
          <Shine>
            <div className="c-tendercard__summary__box__label">
              Registered Users
            </div>
          </Shine>
        </div> */}
        <div className="c-tendercard__summary__box">
          <div className="c-tendercard__icon">
            <GrayDiamond />
          </div>
          <Shine>
            <div className="c-tendercard__summary__box__value">
              {tender.tenderProducts.length}
            </div>
          </Shine>
          <Shine>
            <div className="c-tendercard__summary__box__label">
              Available Diamonds
            </div>
          </Shine>
        </div>
      </div>
      <div className="c-tendercard__right">
        {!!status && status}
        <Shine>
          <div className="c-tendercard__countdown">
            <Countdown
              date={isNext ? tender.startDate : tender.endDate}
              onFinish={clean}
            />
            <Progress height={4} progress={percentage} />
          </div>
        </Shine>
        <div className="c-tendercard__actions">
          <Shine>
            <Button
              kind="white"
              disabled={
                (tender.tenderProducts || []).length === 0 ||
                !isAmlVerified ||
                new Date(tender.startDate).getTime() - new Date().getTime() >
                  24 * 60 * 60 * 1000
              }
              onClick={onCatalog}
              className="c-tendercard__catalog"
            >
              <BookOpen />
              See Catalog
            </Button>
            {/* className="c-tendercard__catalog" */}
          </Shine>
          {isNext && (
            <Shine>
              <Button
                kind="green"
                disabled={isRegistered || !isAmlVerified}
                loading={isLoading}
                onClick={onRegister}
                className="c-tendercard__catalog"
              >
                {isRegistered ? (
                  <>
                    <Check
                      color="#222b45
"
                    />
                    Registered
                  </>
                ) : (
                  "Register"
                )}
              </Button>
            </Shine>
          )}
          {!isNext && (
            <Shine>
              <Button
                kind="green"
                loading={isLoading}
                onClick={onEnter}
                className="c-tendercard__enter"
                disabled={!isAmlVerified}
              >
                Enter
              </Button>
            </Shine>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenderCard;
