import { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import FormStep from "./FormStep";
import { ReactComponent as CloudIcon } from "assets/icons/upload-cloud.svg";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Checkbox from "ui/Checkbox";
import * as Yup from "yup";
import { useRegisterContext } from "../Register";
import { useAnalytics } from "hooks/useAnalytics";
import { distinctArray } from "utils";
const acceptedFormats = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/vnd.ms-word",
  "application/msword",
  "application/docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const validationSchema = Yup.object().shape({
  files: Yup.array().min(1, "To short!"),
  approvedTerms: Yup.boolean().oneOf([true], "Must Accept Terms of Service"),
});

const StepSix = () => {
  const track = useAnalytics();
  const { formik, isLoading } = useRegisterContext();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      formik.setFieldValue(
        "files",
        distinctArray([...formik.values.files, ...acceptedFiles], "name")
      );
    },
    [formik]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFormats,
  });

  const removeFile = (index: number) => {
    const files = [...formik.values.files];
    files.splice(index, 1);
    formik.setFieldValue("files", files);
  };

  useEffect(() => {
    track("Registration Step - Upload Files & Terms");
  }, [track]);

  return (
    <FormStep
      title="Upload Passport and a Copy of Your Governmental Business License"
      fields={
        <div>
          <div
            {...getRootProps()}
            className="upload-file"
            style={
              isDragActive
                ? {
                    border: "3px dashed rgba(49, 221, 164,0.3)",
                  }
                : {}
            }
          >
            <input {...getInputProps()} />
            <>
              <CloudIcon className="upload-file__icon" />
              <p className="upload-file__title">
                Drag and drop your files here, or upload from your computer
              </p>
              <p className="upload-file__support">
                Supported files: JPG, JPEG, PNG, PDF, DOC
              </p>
              <p className="upload-file__info">
                Kindly upload a clear photo of your passport and a copy of your
                governmental business license. Please note that your application
                will not be processed without the submission of these documents.
              </p>
            </>
          </div>
          <TransitionGroup>
            {formik.values.files.map((file, index) => (
              <CSSTransition
                key={file.name}
                timeout={500}
                classNames="item"
                // unmountOnExit
              >
                <div className="file">
                  <p className="file__title">
                    {index + 1}. {file.name}
                  </p>
                  <div className="file__progress"></div>
                  <div
                    className="file__remove"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="file__remove-icon" />
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <Checkbox
            containerClassName="approve-terms"
            name="approvedTerms"
            id="approvedTerms"
            type="checkbox"
            checked={formik.values.approvedTerms}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={
              <>
                By clicking Finish process, you agree to our{" "}
                <a
                  style={{ textDecoration: "underline", color: "inherit" }}
                  href="/privacy"
                  target="_blank"
                  rel="noopener"
                >
                  Terms and Conditions{" "}
                </a>{" "}
                and{" "}
                <a
                  style={{ textDecoration: "underline", color: "inherit" }}
                  href="/privacy"
                  target="_blank"
                  rel="noopener"
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            error={
              !!formik.errors.approvedTerms && !!formik.touched.approvedTerms
            }
          />
        </div>
      }
      onNext={formik.handleSubmit}
      nextProps={{
        kind: "green",
        loading: isLoading,
        disabled: !validationSchema.isValidSync(formik.values) || isLoading,
        children: "Finish Process",
      }}
    />
  );
};

export default StepSix;
