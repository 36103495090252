import Modal from "..";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { useHistory } from "react-router";
import useModalActions from "hooks/useModalActions";
import Lottie from "react-lottie";
import animationData from "../../../assets/lotties/13643-confetti.json";
import Accordion from "ui/Accordion";
import { StyledFinishedTender } from "./FinishedTender.styled";
// import { useEffect, useState } from "react";
interface Props {
  tender: Tender;
}

const faqs = [
  {
    Question: "How do I win a diamond on a tender?",
    Answer:
      "Each tender event lasts 12 hours. Each buyer can place bids until the close of the event. \r\nThe highest bidder equal to or above the Asking price automatically wins the diamond.\r\nNote:  It is possible to place several different bids on different diamonds in each tender.",
  },
  {
    Question: "How do I receive the diamond after I won the tender?",
    Answer:
      "Following the stone verification and receiving the payment, the stone will be shipped from Hennig’s office using a 3rd party courier service. \r\nAs long as the courier allows the client and destination to use collect charges the shipping will be carried out  “Collect charges”. \r\nIn such cases, the client will be notified and will have to accept to pay the charges before the shipment can leave our office. It is important that the client reacts swiftly to avoid delays.",
  },
  {
    Question: "How does the payment process work?",
    Answer: `After the diamond is verified by Hennig as part of the logistics process, Hennig will issue an invoice to the buyer. The buyer will then pay via bank transfer to Hennig Group. Once the transfer is received, Hennig Group will ask the seller to issue an invoice and after receiving the receipt, they will transfer the money (minus commission) to the seller. The payment will be transferred to the seller’s bank account after Hennig receives the money and issues a receipt. Hennig will remit the funds to the seller usually within 24 hrs from receipt of invoice.`,
  },
];

const FinishedTender = ({ tender }: Props) => {
  const history = useHistory();
  const { clearModals } = useModalActions();

  return (
    <Modal onClose={() => {}}>
      <Lottie
        isClickToPauseDisabled
        width={1200}
        height={1200}
        options={{
          animationData,
          autoplay: true,
          loop: true,
          rendererSettings: {},
        }}
        speed={0.5}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-60%)",
        }}
      />
      <StyledFinishedTender className="finished-tender">
        <p className="finished-tender__timesup">Time’s up!</p>
        <p className="finished-tender__title">THis tender is finished</p>
        <p className="finished-tender__results">
          The results of the tender will be announced in the next couple of
          hours. While you wait, check out these helpful guides.
        </p>
        <div className="finished-tender__faq">
          <p className="finished-tender__faq__title">FAQ</p>
          <Accordion type="multiple">
            {faqs.map(({ Question, Answer }, index) => (
              <Accordion.Item value={`item-${index}`} key={index}>
                <Accordion.Trigger>
                  {Question}
                  <Accordion.Arrrow />
                </Accordion.Trigger>
                <Accordion.Content>{Answer}</Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <button
          className="finished-tender__gotooverview"
          onClick={() => {
            clearModals();
            history.push("/overview");
          }}
        >
          Go To Overview
          <ChevronRight />
        </button>
      </StyledFinishedTender>
    </Modal>
  );
};

export default FinishedTender;
