import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type bidsState = {
  myBidsIsOpen: boolean;
};

const initialState: bidsState = {
  myBidsIsOpen: false,
};

export const bidsSlice = createSlice({
  name: "bids",
  initialState,
  reducers: {
    setMyBidsIsOpen: (state, action: PayloadAction<boolean>) => {
      state.myBidsIsOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMyBidsIsOpen } = bidsSlice.actions;

export default bidsSlice.reducer;
