import styled, { css } from "styled-components";

export const StyledSelect = styled.div<{ error?: boolean }>`
  position: relative;
  .ui-select__control {
    height: 3.125rem;
    background-color: transparent;
    border-width: 2px;
    border-color: ${({ theme, error }) =>
      (error && theme.colors.error[200]) || theme.colors.neutral[200]};
    box-shadow: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[200]};
  }

  .ui-select__control:hover {
    box-shadow: ${({ theme }) => theme.shadows.light};
    border-color: ${({ theme, error }) =>
      (error && theme.colors.error[200]) || theme.colors.neutral[200]};
    background-color: transparent;
  }

  .ui-select__control--menu-is-open {
    border-color: ${({ theme }) => theme.colors.neutral[600]} !important;
  }

  .ui-select__placeholder {
    color: ${({ theme }) => theme.colors.neutral[200]};
  }

  .ui-select__menu {
    z-index: 11;
  }

  ${(props) =>
    !!props.error &&
    css`
      .ui-select__indicator {
        display: none;
      }
    `}
`;
