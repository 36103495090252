import { routeGenerator } from "api";
// import { tenderProductBid, createArray } from "../../faker";
import { axios as Axios } from "lib/axios";
import { useQuery } from "react-query";
import { extractId } from "utils";

const getMyBids = async (tenderId: string) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderProductsBids/${extractId(tenderId)}`)
  );
  return data;
};

type UseMyBids = TenderProductBid[];

export const useMyBids = (tenderId: string) => {
  return useQuery<UseMyBids, ApiError>(
    useMyBids.queryKey(extractId(tenderId)),
    () => getMyBids(tenderId)
  );
};

useMyBids.queryKey = (tenderId: string) => ["mybids", tenderId];

const getActiveTenderProductBids = async (
  tenderId: string,
  tenderProductId: string
) => {
  const { data } = await Axios.get(
    routeGenerator(
      `/tenderProductsBids/tender/${tenderId}/product/${tenderProductId}`
    )
  );
  return data;
};

type useActiveTenderProductBidsType = TenderProductBid[];

export const useActiveTenderProductBids = (
  tenderId: string,
  tenderProductId: string
) =>
  useQuery<useActiveTenderProductBidsType, ApiError>(
    useActiveTenderProductBids.queryKey(tenderId, tenderProductId),
    () => getActiveTenderProductBids(tenderId, tenderProductId)
  );

useActiveTenderProductBids.queryKey = (
  tenderId: string,
  tenderProductId: string
) => ["activeTenderProductBids", tenderId, tenderProductId];

type useActiveTenderProductBidType = TenderProductBid;

const getActiveTenderProductBid = async (
  tenderUserId: string,
  tenderProductId: string
) => {
  const { data } = await Axios.get(
    routeGenerator(`/tenderProductsBids/${tenderProductId}/${tenderUserId}`)
  );
  return data;
};

export const useActiveTenderProductBid = (
  tenderUserId: string,
  tenderProductId: string
) =>
  useQuery<useActiveTenderProductBidType, ApiError>(
    ["active", "tenderProductBid", tenderUserId, tenderProductId],
    () => getActiveTenderProductBid(tenderUserId, tenderProductId),
    { staleTime: 30 * 60 * 1000 }
  );
