import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

// Styles & Icons
import { Styles, Urls, SubHeaderTabs, headerLinksGuest } from "./Consts";
import "./styles/_header-guest.css";
import Logo from "./assets/get-diamonds.png";
import LogoBlue from "./assets/get-diamonds-blue.png";
import { useIntersectionObserver } from "./hooks/useIntersectionObserver";
import { ReactComponent as Hamburger } from "./assets/hamburger.svg";
import Accordion from "./Accordion";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const HeaderGuest = ({ setShowModal }) => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const LinkProps = {
    activeclassname: "active",
    className:
      scrolled &&
      !(
        pathname.includes("/login") ||
        pathname.includes("/register") ||
        pathname.includes("/privacy") ||
        pathname.includes("/terms") ||
        pathname.includes("/contact") ||
        pathname.includes("/reset") ||
        pathname.includes("/support")
      )
        ? ""
        : "scrolled",
  };

  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (prevPathname !== pathname) setMenuOpen(false);
  }, [pathname]);

  const callback = (entry) => setScrolled(entry.isIntersecting);

  useIntersectionObserver({ id: "scroll-tracker-element", callback });
  return (
    <>
      <div id="scroll-tracker-element"></div>
      <div
        className={
          scrolled &&
          !(
            pathname.includes("/login") ||
            pathname.includes("/register") ||
            pathname.includes("/privacy") ||
            pathname.includes("/terms") ||
            pathname.includes("/contact") ||
            pathname.includes("/reset") ||
            pathname.includes("/support")
          )
            ? Styles.HEADER_CONTAINER_GUEST
            : Styles.HEADER_CONTAINER_GUEST_WITH_BG
        }
      >
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className={Styles.HAMBURGER}
        >
          <Hamburger
            stroke={
              scrolled &&
              !(
                pathname.includes("/login") ||
                pathname.includes("/register") ||
                pathname.includes("/privacy") ||
                pathname.includes("/terms") ||
                pathname.includes("/contact") ||
                pathname.includes("/reset") ||
                pathname.includes("/support")
              )
                ? "#ffffff"
                : "#2a589f"
            }
          />
        </button>

        <Link to={Urls.HOME}>
          <img
            src={
              scrolled &&
              !(
                pathname.includes("/login") ||
                pathname.includes("/register") ||
                pathname.includes("/privacy") ||
                pathname.includes("/terms") ||
                pathname.includes("/contact") ||
                pathname.includes("/reset") ||
                pathname.includes("/support")
              )
                ? Logo
                : LogoBlue
            }
            alt=""
          />
        </Link>
        <div className={Styles.NAV_CONTAINER}>
          {/* <Link {...LinkProps} to={Urls.HOME}>
            Home
          </Link>
          <Link to={Urls.TRADING} {...LinkProps}>
            Trading
          </Link>
          <Link to={Urls.SEARCH} {...LinkProps}>
            Search
          </Link>
          <Link to={Urls.SUPPLIERS} {...LinkProps}>
            Suppliers
          </Link>
          <Link to={Urls.PRICING} {...LinkProps}>
            Pricing
          </Link>
          <Link to={Urls.SUPPORT} {...LinkProps}>
            Support
          </Link> */}
          {headerLinksGuest.map((link, idx) =>
            link.isReady ? (
              link.url ? (
                <NavLink exact {...LinkProps} to={link.url} key={idx}>
                  {link.label}
                </NavLink>
              ) : (
                <a
                  href={link.external}
                  target="_blank"
                  key={idx}
                  {...LinkProps}
                >
                  {link.label}
                </a>
              )
            ) : (
              <a key={idx} onClick={() => setShowModal(true)} {...LinkProps}>
                {link.label}
              </a>
            )
          )}
        </div>

        <div className={Styles.USER_ACTIONS_CONTAINER}>
          <Link to={Urls.LOGIN} style={{ marginRight: "24px" }} {...LinkProps}>
            Login
          </Link>
          <Link
            className={
              scrolled &&
              !(
                pathname.includes("/login") ||
                pathname.includes("/register") ||
                pathname.includes("/privacy") ||
                pathname.includes("/terms") ||
                pathname.includes("/contact") ||
                pathname.includes("/reset") ||
                pathname.includes("/support")
              )
                ? Styles.BUTTON
                : Styles.BUTTON + " scrolled"
            }
            to={Urls.PLAN}
          >
            Register
          </Link>
        </div>
      </div>
      <div
        className={
          menuOpen
            ? Styles.MOBILE_CONTAINER_GUEST
            : Styles.MOBILE_CONTAINER_GUEST_OPEN
        }
      >
        <div className={Styles.NAV_CONTAINER_MOBILE}>
          {/* {headerLinksGuest.map((item, idx) => (
            <Accordion className="accordion-drawer" key={idx}>
              <Accordion.Header
                showArrow={
                  SubHeaderTabs[item.label.toLowerCase()] ? true : false
                }
                arrowSrc="chevron-down.svg"
              >
                {item.label}
              </Accordion.Header>
              <Accordion.Body>
                {SubHeaderTabs[item.label.toLowerCase()]?.map((subItem, idx) =>
                  subItem.url ? (
                    <NavLink to={subItem.url} key={idx}>
                      {subItem.label}
                    </NavLink>
                  ) : (
                    <button key={idx}>{subItem.label}</button>
                  )
                )}
              </Accordion.Body>
            </Accordion>
          ))} */}
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <NavLink to="/">Home</NavLink>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={true} arrowSrc="chevron-down.svg">
              <NavLink to="/search">Diamonds</NavLink>
            </Accordion.Header>
            <Accordion.Body>
              {SubHeaderTabs["diamonds"]?.map((subItem, idx) =>
                subItem.url ? (
                  <NavLink to={subItem.url} key={idx}>
                    {subItem.label}
                  </NavLink>
                ) : (
                  <button key={idx} onClick={() => history.push("/login")}>
                    {subItem.label}
                  </button>
                )
              )}
            </Accordion.Body>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <NavLink to="/login">Jewelry</NavLink>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <NavLink to="/login">Members</NavLink>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <NavLink to="/about">About</NavLink>
            </Accordion.Header>
          </Accordion>
          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <NavLink to="/support">Support</NavLink>
            </Accordion.Header>
          </Accordion>

          <Accordion className="accordion-drawer">
            <Accordion.Header showArrow={false} arrowSrc="chevron-down.svg">
              <a
                href="http://news.get-diamonds.com/"
                target="_blank"
                rel="noopener"
              >
                News
              </a>
            </Accordion.Header>
          </Accordion>
        </div>
      </div>
      <div
        className={
          menuOpen ? Styles.CLOSE_MENU_SCREEN_OPEN : Styles.CLOSE_MENU_SCREEN
        }
        onClick={() => setMenuOpen(!menuOpen)}
      ></div>
    </>
  );
};
