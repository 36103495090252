import Layout from "ui/Layout";
import { useUser } from "features/users/queries";
import { Route, RouteProps } from "react-router";
import { useGDUser } from "features/gd/queries";
import { HeaderProvider } from "ui/header-get/HeaderProvider";
const Private = (props: RouteProps) => {
  const { data: user } = useUser();
  const { data: GDuser } = useGDUser();

  if (!user || !GDuser) return null;

  return (
    <>
      <HeaderProvider user={user} setShowModal={() => {}} />
      <Layout>
        <Route {...props} />
      </Layout>
    </>
  );
};

export default Private;
