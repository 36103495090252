import styled from "styled-components";

export const GetLoginIframeStyled = styled.div`
  height: 35.625rem;
  width: 31.25rem;
  background-color: #fff;
  border-radius: 10px;
  overflow: unset;
  margin: auto;
  padding: 10px;
  position: relative;

  .get-login__iframe {
    height: 100%;
    width: 100%;
    border: none;
    transition: opacity 0.3s ease;
    overflow: hidden;
  }

  .get-login__iframe__loading {
    color: #212121;
    font-family: var(--roboto);
    font-weight: 600;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 80%;
    width: calc(100% - var(--sectionPadding) * 2);

    button {
      right: -5px;
    }
  }
`;

export const GetLoginButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  transition: all 0.3s ease-in;

  .left {
    font-family: var(--roboto);
    font-size: 19px;
    font-weight: 900;
    letter-spacing: 0.68px;
    text-align: center;
    color: var(--orange);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0 12px;
  }

  .right {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
    font-family: var(--roboto);
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.57px;
    color: #fff;
    transition: all 0.3s ease;
  }

  &:hover .right {
    background-color: #fa7d00;
  }
`;
