import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

function SwitchAnimator({ state, children }) {
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={state}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div className="switch-animator">{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
}

export default SwitchAnimator;
