import { createContext, useContext, useEffect, useMemo, useState } from "react";
import StepOne from "./comps/StepOne";
import StepTwo from "./comps/StepTwo";
import StepFour from "./comps/StepFour";
import StepThree from "./comps/StepThree";
import StepFive from "./comps/StepFive";
import StepSix from "./comps/StepSix";
import LastStep from "./comps/LastStep";
import { Option, Step, Values } from "types/register";
import { FormikProps, useFormik } from "formik";
import {
  RegisterPayload,
  useRegister,
  useUpdateAml,
} from "features/users/mutations";
import base64File from "../../utils/file";
import { asyncCatch } from "utils";
import get from "lodash.get";
import set from "lodash.set";
import { getValidationSchema } from "./validation";
import Stepper from "ui/Stepper";
import { StyledRegister } from "./Register.styled";
import SwitchAnimation from "ui/SwitchAnimation";
import { useUser } from "features/users/queries";
// import storage from "utils/storage";
export type Field = {
  label: string;
  name: string;
  id: string;
  type: string;
};

const steps: Step[] = [
  { label: "Choose an account type", Comp: StepOne }, //0
  { label: "CREATE AN ACCOUNT", Comp: StepTwo }, //1
  { label: "AML / KYC", Comp: StepThree }, //2
  { label: "FINISH PROCESS", Comp: StepFour }, //3
  { label: "References", Comp: StepFive }, //4
  { label: "File", Comp: StepSix }, //5
  { label: "Last", Comp: LastStep }, //6
];

const stepperStepsBoilerplate = [
  {
    label: "Choose an account type",
    goToStep: 0,
  },
  {
    label: "CREATE AN ACCOUNT",
    goToStep: 1,
  },
  {
    label: "AML / KYC",
    goToStep: 2,
  },
  {
    label: "FINISH PROCESS",
    goToStep: 6,
  },
];

export const registerInitialValues: Values = {
  user: {
    accountType: "",
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    fullAddress: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    type: "",
    isAdmin: false,
  },
  aml: {
    business: {
      companyName: "",
      companyPhone: "",
      companyEmailAddress: "",
      companyDate: "",
      businessType: null,
      companyAddress: "",
      companyCountry: null,
      companyCity: "",
      companyState: "",
      companyZip: "",
      tradeNumber: "",
      dba: "",
      tradeMembership: null,
    },
    personal: {
      personalFullName: "",
      personalEmailAddress: "",
      personalPhoneNumber: "",
    },
    admin: {
      adminFullname: "",
      adminEmailAddress: "",
      adminPhoneNumber: "",
      adminFullAddress: "",
      adminCity: "",
      adminState: "",
      adminZip: "",
      adminCountry: null,
    },
    bank: {
      bankName: "",
      swift: "",
      iban: "",
      bankAddress: "",
      accountNumber: "",
    },
    numReferences: 1,
    firstReference: {
      nameOfCompany: "",
      country: null,
      contactPerson: "",
      referencesPhoneNumber: "",
      email: "",
    },
    secondReference: {
      nameOfCompany: "",
      country: null,
      contactPerson: "",
      referencesPhoneNumber: "",
      email: "",
    },
    thirdReference: {
      nameOfCompany: "",
      country: null,
      contactPerson: "",
      referencesPhoneNumber: "",
      email: "",
    },
  },
  files: [],
  approvedTerms: false,
};

const stepConfigBoilerplate = (step: number) => {
  if (step === 0) return 0;
  if (step === 1) return 1;
  if (step <= 5) return 2;
  return 3;
};

const RegisterContext = createContext<null | {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStepperStep: number;
  setCurrentStepperStep: React.Dispatch<React.SetStateAction<number>>;
  steps: typeof steps;
  stepperSteps: typeof stepperStepsBoilerplate;
  formik: FormikProps<Values>;
  goBack: () => void;
  goForward: () => void;
  isLoading: boolean;
  isError: boolean;
  user: null | User;
  goToStep: (step: number) => void;
  initialStep: number;
  showIsAdminCheckbox: boolean;
}>(null);

export const useRegisterContext = () => {
  const context = useContext(RegisterContext);
  if (!context) throw new Error("Must be inside Register");
  return context;
};

const formatRegisterInitialValues = (values: Values) => {
  const toOption = (val: any) => {
    if (typeof val !== "string") return val;
    return val ? { label: val, value: val } : null;
  };
  values.aml.numReferences = 1;

  values.aml.business.businessType = toOption(values.aml.business.businessType);
  values.aml.business.companyCountry = {
    ...toOption(values.aml.business.companyCountry),
  } as any;
  values.aml.business.tradeMembership = toOption(
    values.aml.business.tradeMembership
  );
  values.aml.admin.adminCountry = toOption(values.aml.admin.adminCountry);
  values.aml.firstReference.country = toOption(
    values.aml.firstReference.country
  );
  values.aml.secondReference.country = toOption(
    values.aml.secondReference.country
  );
  values.aml.thirdReference.country = toOption(
    values.aml.thirdReference.country
  );

  return values;
};

const Register = ({
  initialStep = 0,
  initialValues = registerInitialValues,
  requireFullValidation = true,
  initialStepperSteps = stepperStepsBoilerplate,
  stepConfig = stepConfigBoilerplate,
  updateAml,
}: {
  initialStep?: number;
  initialValues?: Values;
  requireFullValidation?: boolean;
  initialStepperSteps?: { label: string; goToStep: number }[];
  stepConfig?: (step: number) => number;
  updateAml?: boolean;
}) => {
  const { data: loggedInUser } = useUser();
  const [stepperSteps] = useState(initialStepperSteps);
  const [user, setUser] = useState(null as null | User);
  const { mutateAsync, isLoading, isError } = (updateAml
    ? useUpdateAml
    : useRegister)();
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [currentStepperStep, setCurrentStepperStep] = useState(
    stepConfig(initialStep)
  );

  const goBack = () => {
    setCurrentStepperStep((prev) => stepConfig(currentStep - 1));
    setCurrentStep((prev) => prev - 1);
  };

  const goForward = () => {
    setCurrentStepperStep((prev) => stepConfig(currentStep + 1));
    setCurrentStep((prev) => prev + 1);
  };

  const goToStep = (step: number) => {
    setCurrentStepperStep(stepConfig(step));
    setCurrentStep(step);
  };

  const initialValuesMemo = useMemo(
    () =>
      initialValues
        ? formatRegisterInitialValues(initialValues)
        : localStorage.getItem("register")
        ? JSON.parse(localStorage.getItem("register") as string)
        : {},
    [initialValues]
  );

  const validationSchema = useMemo(() => {
    const res = getValidationSchema(currentStep, requireFullValidation);
    return res;
  }, [currentStep, requireFullValidation]);

  const formik = useFormik<Values>({
    initialValues: initialValuesMemo,
    validationSchema,
    onSubmit: async (values, helpers) => {
      if (currentStep === steps.length - 2) {
        const base64Files = await Promise.all(
          values.files.map(async (file) => {
            return await base64File.convertToBase64(file);
          })
        );

        const payload: RegisterPayload = {
          user: values.user,
          aml: values.aml,
          isSupplier:
            values.user.accountType === "seller" ||
            values.user.accountType === "broker",
          files: base64Files,
          amlTenderStatus: "in-process",
        };

        const fields = [
          "aml.business.businessType",
          "aml.business.companyCountry",
          "aml.business.tradeMembership",
          "aml.admin.adminCountry",
          "aml.firstReference.country",
          "aml.secondReference.country",
          "aml.thirdReference.nameOfCompany",
        ];

        // mapping and removing value/label fields
        fields.forEach((field) => {
          const fieldValue = get(payload, field) as null | Option;
          if (fieldValue) set(payload, field, fieldValue.value);
        });

        const [err, data] = await asyncCatch(mutateAsync(payload));
        if (err || !data) return;
        if (!loggedInUser) setUser(null);
        // storage.setToken(data.token);
        localStorage.removeItem("register");
      }

      goForward();
      helpers.setTouched({});
      // localStorage.removeItem("register");
    },
  });

  useEffect(() => {
    const registerValues = JSON.stringify({ ...formik.values, files: [] });
    localStorage.setItem("register", registerValues);
  }, [formik.values]);

  return (
    <RegisterContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        currentStepperStep,
        setCurrentStepperStep,
        steps,
        stepperSteps,
        formik,
        goBack,
        goForward,
        goToStep,
        isLoading,
        isError,
        user,
        initialStep,
        showIsAdminCheckbox: !updateAml,
      }}
    >
      <StyledRegister className="register">
        <div className="register__inner">
          <Stepper
            currentStep={currentStepperStep}
            stepperSteps={stepperSteps as any}
          />
          <SwitchAnimation state={currentStep}>
            <div className="register__steps">
              {steps.map((Step, index) =>
                index === currentStep ? <Step.Comp key={index} /> : null
              )}
            </div>
          </SwitchAnimation>
          <p className="register__bottom_text">
            Trade Diamonds is in full compliance with maximum security protocols
            in order to make sure your details are sec ured and confidential.
            You can read more on our security and privacy features, along with
            more important details regarding our terms of use. By clicking Next,
            you agree to our{" "}
            <a
              style={{ textDecoration: "underline", color: "inherit" }}
              href="/terms"
              target="_blank"
              rel="noopener"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              style={{ textDecoration: "underline", color: "inherit" }}
              href="/privacy"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </StyledRegister>
    </RegisterContext.Provider>
  );
};

export default Register;
