import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { API_URL } from "config/index";
import { useMutateUser } from "features/users/mutations";
import { useEffect } from "react";
import storage from "utils/storage";

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.token = token;
  }
  config.headers.Accept = "application/json";
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

export const AxiosInterceptor = () => {
  const mutateUser = useMutateUser();
  // const dispatch = useDispatch();
  useEffect(() => {
    axios.interceptors.request.use(authRequestInterceptor);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        const isUnauthenticated = [401, 403].includes(
          error.response?.status ?? 0
        );

        if (isUnauthenticated) {
          //logout
          mutateUser(null);
        }
        const message = error.response?.data?.message || error.response?.data;
        error.message = message;
        return Promise.reject(error);
      }
    );
  }, [mutateUser]);
  return null;
};
