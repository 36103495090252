import "./ShortDetails.css";
import ElipseDetail from "components/ElipseDetail";
import cn from "classnames";
interface Props {
  className?: string;
  product: Product;
  values: ProductKey[];
  children?: React.ReactNode;
}

const ShortDetails = ({ product, values, className = "", children }: Props) => {
  return (
    <div className={cn("short-details", className)}>
      {values.map((key) => {
        if (!product[key]) return null;
        return <ElipseDetail key={key}>{product[key]}</ElipseDetail>;
      })}
      {children}
    </div>
  );
};

export default ShortDetails;
