export const countries = [
  { value: `Afghanistan`, label: `Afghanistan`, country: `Afghanistan` },
  { value: `Albania`, label: `Albania`, country: `Albania` },
  { value: `Algeria`, label: `Algeria`, country: `Algeria` },
  { value: `Andorra`, label: `Andorra`, country: `Andorra` },
  { value: `Angola`, label: `Angola`, country: `Angola` },
  {
    value: `Antigua and Barbuda`,
    label: `Antigua and Barbuda`,
    country: `Antigua and Barbuda`,
  },
  { value: `Argentina`, label: `Argentina`, country: `Argentina` },
  { value: `Armenia`, label: `Armenia`, country: `Armenia` },
  { value: `Australia`, label: `Australia`, country: `Australia` },
  { value: `Austria`, label: `Austria`, country: `Austria` },
  { value: `Azerbaijan`, label: `Azerbaijan`, country: `Azerbaijan` },
  { value: `Bahamas `, label: `Bahamas `, country: `Bahamas ` },
  { value: `Bahrain`, label: `Bahrain`, country: `Bahrain` },
  { value: `Bangladesh`, label: `Bangladesh`, country: `Bangladesh` },
  { value: `Barbados`, label: `Barbados`, country: `Barbados` },
  { value: `Belarus`, label: `Belarus`, country: `Belarus` },
  { value: `Belgium`, label: `Belgium`, country: `Belgium` },
  { value: `Belize`, label: `Belize`, country: `Belize` },
  { value: `Benin`, label: `Benin`, country: `Benin` },
  { value: `Bhutan`, label: `Bhutan`, country: `Bhutan` },
  { value: `Bolivia `, label: `Bolivia `, country: `Bolivia ` },
  {
    value: `Bosnia and Herzegovina`,
    label: `Bosnia and Herzegovina`,
    country: `Bosnia and Herzegovina`,
  },
  { value: `Botswana`, label: `Botswana`, country: `Botswana` },
  { value: `Brazil`, label: `Brazil`, country: `Brazil` },
  {
    value: `Brunei Darussalam`,
    label: `Brunei Darussalam`,
    country: `Brunei Darussalam`,
  },
  { value: `Bulgaria`, label: `Bulgaria`, country: `Bulgaria` },
  { value: `Burkina Faso`, label: `Burkina Faso`, country: `Burkina Faso` },
  { value: `Burundi`, label: `Burundi`, country: `Burundi` },
  { value: `Cabo Verde`, label: `Cabo Verde`, country: `Cabo Verde` },
  { value: `Cambodia`, label: `Cambodia`, country: `Cambodia` },
  { value: `Cameroon`, label: `Cameroon`, country: `Cameroon` },
  { value: `Canada`, label: `Canada`, country: `Canada` },
  {
    value: `Central African Republic `,
    label: `Central African Republic `,
    country: `Central African Republic `,
  },
  { value: `Chad`, label: `Chad`, country: `Chad` },
  { value: `Chile`, label: `Chile`, country: `Chile` },
  { value: `China`, label: `China`, country: `China` },
  { value: `Colombia`, label: `Colombia`, country: `Colombia` },
  { value: `Comoros `, label: `Comoros `, country: `Comoros ` },
  { value: `Congo `, label: `Congo `, country: `Congo ` },
  { value: `Costa Rica`, label: `Costa Rica`, country: `Costa Rica` },
  { value: `Côte d'Ivoire`, label: `Côte d'Ivoire`, country: `Côte d'Ivoire` },
  { value: `Croatia`, label: `Croatia`, country: `Croatia` },
  { value: `Cuba`, label: `Cuba`, country: `Cuba` },
  { value: `Cyprus`, label: `Cyprus`, country: `Cyprus` },
  { value: `Czechia`, label: `Czechia`, country: `Czechia` },
  {
    value: `Democratic People's Republic of Korea `,
    label: `Democratic People's Republic of Korea `,
    country: `Democratic People's Republic of Korea `,
  },
  {
    value: `Democratic Republic of the Congo `,
    label: `Democratic Republic of the Congo `,
    country: `Democratic Republic of the Congo `,
  },
  { value: `Denmark`, label: `Denmark`, country: `Denmark` },
  { value: `Djibouti`, label: `Djibouti`, country: `Djibouti` },
  { value: `Dominica`, label: `Dominica`, country: `Dominica` },
  {
    value: `Dominican Republic `,
    label: `Dominican Republic `,
    country: `Dominican Republic `,
  },
  { value: `Ecuador`, label: `Ecuador`, country: `Ecuador` },
  { value: `Egypt`, label: `Egypt`, country: `Egypt` },
  { value: `El Salvador`, label: `El Salvador`, country: `El Salvador` },
  {
    value: `Equatorial Guinea`,
    label: `Equatorial Guinea`,
    country: `Equatorial Guinea`,
  },
  { value: `Eritrea`, label: `Eritrea`, country: `Eritrea` },
  { value: `Estonia`, label: `Estonia`, country: `Estonia` },
  { value: `Eswatini`, label: `Eswatini`, country: `Eswatini` },
  { value: `Ethiopia`, label: `Ethiopia`, country: `Ethiopia` },
  { value: `Fiji`, label: `Fiji`, country: `Fiji` },
  { value: `Finland`, label: `Finland`, country: `Finland` },
  { value: `France`, label: `France`, country: `France` },
  { value: `Gabon`, label: `Gabon`, country: `Gabon` },
  { value: `Gambia `, label: `Gambia `, country: `Gambia ` },
  { value: `Georgia`, label: `Georgia`, country: `Georgia` },
  { value: `Germany`, label: `Germany`, country: `Germany` },
  { value: `Ghana`, label: `Ghana`, country: `Ghana` },
  { value: `Greece`, label: `Greece`, country: `Greece` },
  { value: `Grenada`, label: `Grenada`, country: `Grenada` },
  { value: `Guatemala`, label: `Guatemala`, country: `Guatemala` },
  { value: `Guinea`, label: `Guinea`, country: `Guinea` },
  { value: `Guinea-Bissau`, label: `Guinea-Bissau`, country: `Guinea-Bissau` },
  { value: `Guyana`, label: `Guyana`, country: `Guyana` },
  { value: `Haiti`, label: `Haiti`, country: `Haiti` },
  { value: `Honduras`, label: `Honduras`, country: `Honduras` },
  { value: `Hungary`, label: `Hungary`, country: `Hungary` },
  { value: `Iceland`, label: `Iceland`, country: `Iceland` },
  { value: `India`, label: `India`, country: `India` },
  { value: `Indonesia`, label: `Indonesia`, country: `Indonesia` },
  { value: `Iran `, label: `Iran `, country: `Iran ` },
  { value: `Iraq`, label: `Iraq`, country: `Iraq` },
  { value: `Ireland`, label: `Ireland`, country: `Ireland` },
  { value: `Israel`, label: `Israel`, country: `Israel` },
  { value: `Italy`, label: `Italy`, country: `Italy` },
  { value: `Jamaica`, label: `Jamaica`, country: `Jamaica` },
  { value: `Japan`, label: `Japan`, country: `Japan` },
  { value: `Jordan`, label: `Jordan`, country: `Jordan` },
  { value: `Kazakhstan`, label: `Kazakhstan`, country: `Kazakhstan` },
  { value: `Kenya`, label: `Kenya`, country: `Kenya` },
  { value: `Kiribati`, label: `Kiribati`, country: `Kiribati` },
  { value: `Kuwait`, label: `Kuwait`, country: `Kuwait` },
  { value: `Kyrgyzstan`, label: `Kyrgyzstan`, country: `Kyrgyzstan` },
  {
    value: `Lao People's Democratic Republic `,
    label: `Lao People's Democratic Republic `,
    country: `Lao People's Democratic Republic `,
  },
  { value: `Latvia`, label: `Latvia`, country: `Latvia` },
  { value: `Lebanon`, label: `Lebanon`, country: `Lebanon` },
  { value: `Lesotho`, label: `Lesotho`, country: `Lesotho` },
  { value: `Liberia`, label: `Liberia`, country: `Liberia` },
  { value: `Libya`, label: `Libya`, country: `Libya` },
  { value: `Liechtenstein`, label: `Liechtenstein`, country: `Liechtenstein` },
  { value: `Lithuania`, label: `Lithuania`, country: `Lithuania` },
  { value: `Luxembourg`, label: `Luxembourg`, country: `Luxembourg` },
  { value: `Madagascar`, label: `Madagascar`, country: `Madagascar` },
  { value: `Malawi`, label: `Malawi`, country: `Malawi` },
  { value: `Malaysia`, label: `Malaysia`, country: `Malaysia` },
  { value: `Maldives`, label: `Maldives`, country: `Maldives` },
  { value: `Mali`, label: `Mali`, country: `Mali` },
  { value: `Malta`, label: `Malta`, country: `Malta` },
  {
    value: `Marshall Islands `,
    label: `Marshall Islands `,
    country: `Marshall Islands `,
  },
  { value: `Mauritania`, label: `Mauritania`, country: `Mauritania` },
  { value: `Mauritius`, label: `Mauritius`, country: `Mauritius` },
  { value: `Mexico`, label: `Mexico`, country: `Mexico` },
  { value: `Micronesia `, label: `Micronesia `, country: `Micronesia ` },
  { value: `Monaco`, label: `Monaco`, country: `Monaco` },
  { value: `Mongolia`, label: `Mongolia`, country: `Mongolia` },
  { value: `Montenegro`, label: `Montenegro`, country: `Montenegro` },
  { value: `Morocco`, label: `Morocco`, country: `Morocco` },
  { value: `Mozambique`, label: `Mozambique`, country: `Mozambique` },
  { value: `Myanmar`, label: `Myanmar`, country: `Myanmar` },
  { value: `Namibia`, label: `Namibia`, country: `Namibia` },
  { value: `Nauru`, label: `Nauru`, country: `Nauru` },
  { value: `Nepal`, label: `Nepal`, country: `Nepal` },
  { value: `Netherlands `, label: `Netherlands `, country: `Netherlands ` },
  { value: `New Zealand`, label: `New Zealand`, country: `New Zealand` },
  { value: `Nicaragua`, label: `Nicaragua`, country: `Nicaragua` },
  { value: `Niger `, label: `Niger `, country: `Niger ` },
  { value: `Nigeria`, label: `Nigeria`, country: `Nigeria` },
  {
    value: `North Macedonia`,
    label: `North Macedonia`,
    country: `North Macedonia`,
  },
  { value: `Norway`, label: `Norway`, country: `Norway` },
  { value: `Oman`, label: `Oman`, country: `Oman` },
  { value: `Pakistan`, label: `Pakistan`, country: `Pakistan` },
  { value: `Palau`, label: `Palau`, country: `Palau` },
  { value: `Panama`, label: `Panama`, country: `Panama` },
  {
    value: `Papua New Guinea`,
    label: `Papua New Guinea`,
    country: `Papua New Guinea`,
  },
  { value: `Paraguay`, label: `Paraguay`, country: `Paraguay` },
  { value: `Peru`, label: `Peru`, country: `Peru` },
  { value: `Philippines `, label: `Philippines `, country: `Philippines ` },
  { value: `Poland`, label: `Poland`, country: `Poland` },
  { value: `Portugal`, label: `Portugal`, country: `Portugal` },
  { value: `Qatar`, label: `Qatar`, country: `Qatar` },
  {
    value: `Republic of Korea `,
    label: `Republic of Korea `,
    country: `Republic of Korea `,
  },
  {
    value: `Republic of Moldova `,
    label: `Republic of Moldova `,
    country: `Republic of Moldova `,
  },
  { value: `Romania`, label: `Romania`, country: `Romania` },
  {
    value: `Russian Federation `,
    label: `Russian Federation `,
    country: `Russian Federation `,
  },
  { value: `Rwanda`, label: `Rwanda`, country: `Rwanda` },
  {
    value: `Saint Kitts and Nevis`,
    label: `Saint Kitts and Nevis`,
    country: `Saint Kitts and Nevis`,
  },
  { value: `Saint Lucia`, label: `Saint Lucia`, country: `Saint Lucia` },
  {
    value: `Saint Vincent and the Grenadines`,
    label: `Saint Vincent and the Grenadines`,
    country: `Saint Vincent and the Grenadines`,
  },
  { value: `Samoa`, label: `Samoa`, country: `Samoa` },
  { value: `San Marino`, label: `San Marino`, country: `San Marino` },
  {
    value: `Sao Tome and Principe`,
    label: `Sao Tome and Principe`,
    country: `Sao Tome and Principe`,
  },
  { value: `Saudi Arabia`, label: `Saudi Arabia`, country: `Saudi Arabia` },
  { value: `Senegal`, label: `Senegal`, country: `Senegal` },
  { value: `Serbia`, label: `Serbia`, country: `Serbia` },
  { value: `Seychelles`, label: `Seychelles`, country: `Seychelles` },
  { value: `Sierra Leone`, label: `Sierra Leone`, country: `Sierra Leone` },
  { value: `Singapore`, label: `Singapore`, country: `Singapore` },
  { value: `Slovakia`, label: `Slovakia`, country: `Slovakia` },
  { value: `Slovenia`, label: `Slovenia`, country: `Slovenia` },
  {
    value: `Solomon Islands`,
    label: `Solomon Islands`,
    country: `Solomon Islands`,
  },
  { value: `Somalia`, label: `Somalia`, country: `Somalia` },
  { value: `South Africa`, label: `South Africa`, country: `South Africa` },
  { value: `South Sudan`, label: `South Sudan`, country: `South Sudan` },
  { value: `Spain`, label: `Spain`, country: `Spain` },
  { value: `Sri Lanka`, label: `Sri Lanka`, country: `Sri Lanka` },
  { value: `Sudan `, label: `Sudan `, country: `Sudan ` },
  { value: `Suriname`, label: `Suriname`, country: `Suriname` },
  { value: `Sweden`, label: `Sweden`, country: `Sweden` },
  { value: `Switzerland`, label: `Switzerland`, country: `Switzerland` },
  {
    value: `Syrian Arab Republic `,
    label: `Syrian Arab Republic `,
    country: `Syrian Arab Republic `,
  },
  { value: `Tajikistan`, label: `Tajikistan`, country: `Tajikistan` },
  { value: `Thailand`, label: `Thailand`, country: `Thailand` },
  { value: `Timor-Leste`, label: `Timor-Leste`, country: `Timor-Leste` },
  { value: `Togo`, label: `Togo`, country: `Togo` },
  { value: `Tonga`, label: `Tonga`, country: `Tonga` },
  {
    value: `Trinidad and Tobago`,
    label: `Trinidad and Tobago`,
    country: `Trinidad and Tobago`,
  },
  { value: `Tunisia`, label: `Tunisia`, country: `Tunisia` },
  { value: `Turkey`, label: `Turkey`, country: `Turkey` },
  { value: `Turkmenistan`, label: `Turkmenistan`, country: `Turkmenistan` },
  { value: `Tuvalu`, label: `Tuvalu`, country: `Tuvalu` },
  { value: `Uganda`, label: `Uganda`, country: `Uganda` },
  { value: `Ukraine`, label: `Ukraine`, country: `Ukraine` },
  {
    value: `United Arab Emirates `,
    label: `United Arab Emirates `,
    country: `United Arab Emirates `,
  },
  {
    value: `United Kingdom of Great Britain and Northern Ireland `,
    label: `United Kingdom of Great Britain and Northern Ireland `,
    country: `United Kingdom of Great Britain and Northern Ireland `,
  },
  {
    value: `United Republic of Tanzania `,
    label: `United Republic of Tanzania `,
    country: `United Republic of Tanzania `,
  },
  {
    value: `United States of America `,
    label: `United States of America `,
    country: `United States of America `,
  },
  { value: `Uruguay`, label: `Uruguay`, country: `Uruguay` },
  { value: `Uzbekistan`, label: `Uzbekistan`, country: `Uzbekistan` },
  { value: `Vanuatu`, label: `Vanuatu`, country: `Vanuatu` },
  { value: `Venezuela `, label: `Venezuela `, country: `Venezuela ` },
  { value: `Viet Nam`, label: `Viet Nam`, country: `Viet Nam` },
  { value: `Yemen`, label: `Yemen`, country: `Yemen` },
  { value: `Zambia`, label: `Zambia`, country: `Zambia` },
  { value: `Zimbabwe`, label: `Zimbabwe`, country: `Zimbabwe` },
] as const;

export const businessTypes = [
  { value: "Independent Jeweler", label: "Independent Jeweler" },
  { value: "Online Jewelry Store", label: "Online Jewelry Store" },
  { value: "Diamond Dealer/ Broker", label: "Diamond Dealer/ Broker" },
  {
    value: "Diamond Manufacturer / Cutter",
    label: "Diamond Manufacturer / Cutter",
  },
  { value: "Jewelry Manufacturer", label: "Jewelry Manufacturer" },
  { value: "Jewelry Retail Chain", label: "Jewelry Retail Chain" },
  { value: "Pawn shop", label: "Pawn shop" },
  { value: "Appraiser", label: "Appraiser" },
  { value: "Designer", label: "Designer" },
  {
    value: "Not in the Diamond / Jewelry Trade",
    label: "Not in the Diamond / Jewelry Trade",
  },
  { value: "Gold Buyer", label: "Gold Buyer" },
  { value: "Diamond Mining", label: "Diamond Mining" },
  { value: "Auctioneer", label: "Auctioneer" },
  { value: "Trade Association", label: "Trade Association" },
  { value: "Watch Dealer", label: "Watch Dealer" },
  { value: "Finance / Banking", label: "Finance / Banking" },
  { value: "Investor", label: "Investor" },
  { value: "Jewelry Appraiser", label: "Jewelry Appraiser" },
  { value: "Jewelry Retailer", label: "Jewelry Retailer" },
  {
    value: "Diamond and Pearl Dealer / Broker",
    label: "Diamond and Pearl Dealer / Broker",
  },
  {
    value: "Colored Stone Dealer / Broker",
    label: "Colored Stone Dealer / Broker",
  },
  {
    value: "Estate Jewelry Dealer / Broker",
    label: "Estate Jewelry Dealer / Broker",
  },
  { value: "Other", label: "Other" },
];

export const memberships = [
  {
    value: `Armenian Jewellers Association `,
    label: `Armenian Jewellers Association `,
    country: `Armenia`,
    international: `1`,
  },
  {
    value: `Jewellers and Metalsmiths Group of Queensland (JMGQ)`,
    label: `Jewellers and Metalsmiths Group of Queensland (JMGQ)`,
    country: `Australia`,
    international: `0`,
  },
  {
    value: `Jewellers and Metalsmiths Group of Australia (JMGA NSW)`,
    label: `Jewellers and Metalsmiths Group of Australia (JMGA NSW)`,
    country: `Australia`,
    international: `0`,
  },
  {
    value: `Diamond Dealers Club of Australia`,
    label: `Diamond Dealers Club of Australia`,
    country: `Australia`,
    international: `0`,
  },
  {
    value: `Jewellers Association of Australia `,
    label: `Jewellers Association of Australia `,
    country: `Australia `,
    international: `0`,
  },
  {
    value: `National Council of Jewellery Valuers `,
    label: `National Council of Jewellery Valuers `,
    country: `Australia `,
    international: `0`,
  },
  {
    value: `Diamant-Club Wien`,
    label: `Diamant-Club Wien`,
    country: `Austria`,
    international: `0`,
  },
  {
    value: `Antwerpsche Diamantkring cvba`,
    label: `Antwerpsche Diamantkring cvba`,
    country: `Belgium`,
    international: `0`,
  },
  {
    value: `Beurs Voor Diamanthandel cvba`,
    label: `Beurs Voor Diamanthandel cvba`,
    country: `Belgium`,
    international: `0`,
  },
  {
    value: `Diamantclub Van Antwerpen Cvba`,
    label: `Diamantclub Van Antwerpen Cvba`,
    country: `Belgium`,
    international: `0`,
  },
  {
    value: `Vrije Diamanthandel Nv`,
    label: `Vrije Diamanthandel Nv`,
    country: `Belgium`,
    international: `0`,
  },
  {
    value: `Instituto Brasileiro de Gemas & Metais Preciosos `,
    label: `Instituto Brasileiro de Gemas & Metais Preciosos `,
    country: `Brazil `,
    international: `0`,
  },
  {
    value: `Canadian Gemmological Association `,
    label: `Canadian Gemmological Association `,
    country: `Canada `,
    international: `0`,
  },
  {
    value: `Canadian Jewellers Association `,
    label: `Canadian Jewellers Association `,
    country: `Canada `,
    international: `0`,
  },
  {
    value: `Jewellers Vigilance Canada `,
    label: `Jewellers Vigilance Canada `,
    country: `Canada `,
    international: `0`,
  },
  {
    value: `Shanghai Gold & Jewellery Trade Association `,
    label: `Shanghai Gold & Jewellery Trade Association `,
    country: `China`,
    international: `0`,
  },
  {
    value: `Guangzhou Diamond Exchange`,
    label: `Guangzhou Diamond Exchange`,
    country: `China`,
    international: `0`,
  },
  {
    value: `Shanghai Diamond Exchange`,
    label: `Shanghai Diamond Exchange`,
    country: `China`,
    international: `0`,
  },
  {
    value: `Gems and Jewelry Trade Association of China (GAC)`,
    label: `Gems and Jewelry Trade Association of China (GAC)`,
    country: `China `,
    international: `0`,
  },
  {
    value: `Suomen Kultaseppeine Liitto [Union of Finnish Goldsmiths `,
    label: `Suomen Kultaseppeine Liitto [Union of Finnish Goldsmiths `,
    country: `Finland `,
    international: `0`,
  },
  {
    value: `Diamant- Und Edelsteinbörse Idar-Oberstein E.V.`,
    label: `Diamant- Und Edelsteinbörse Idar-Oberstein E.V.`,
    country: `Germany`,
    international: `0`,
  },
  {
    value: `BV Schmuck + Uhren `,
    label: `BV Schmuck + Uhren `,
    country: `Germany `,
    international: `0`,
  },
  {
    value: `Hong Kong Diamond Bourse Ltd.`,
    label: `Hong Kong Diamond Bourse Ltd.`,
    country: `Hong Kong `,
    international: `0`,
  },
  {
    value: `Hong Kong Gemstone Manufacturers’ Association Ltd. `,
    label: `Hong Kong Gemstone Manufacturers’ Association Ltd. `,
    country: `Hong Kong `,
    international: `0`,
  },
  {
    value: `Hong Kong Jewellery & Jade Manufacturers Association `,
    label: `Hong Kong Jewellery & Jade Manufacturers Association `,
    country: `Hong Kong `,
    international: `0`,
  },
  {
    value: `Hong Kong Jewelry Manufacturers’ Association `,
    label: `Hong Kong Jewelry Manufacturers’ Association `,
    country: `Hong Kong `,
    international: `0`,
  },
  {
    value: `Bharat Diamond Bourse`,
    label: `Bharat Diamond Bourse`,
    country: `India`,
    international: `0`,
  },
  {
    value: `All India Gems & Jewellery Trade Federation (GJF)`,
    label: `All India Gems & Jewellery Trade Federation (GJF)`,
    country: `India `,
    international: `0`,
  },
  {
    value: `Gem Jewellery Export Promotion Council India (GJEPC)`,
    label: `Gem Jewellery Export Promotion Council India (GJEPC)`,
    country: `India `,
    international: `0`,
  },
  {
    value: `India Bullion and Jewellers Association Ltd. `,
    label: `India Bullion and Jewellers Association Ltd. `,
    country: `India `,
    international: `0`,
  },
  {
    value: `Irish Jewellers’ Association `,
    label: `Irish Jewellers’ Association `,
    country: `Ireland `,
    international: `0`,
  },
  {
    value: `Israel Diamond Manufacturers Association `,
    label: `Israel Diamond Manufacturers Association `,
    country: `Israel `,
    international: `0`,
  },
  {
    value: `Israel Jewelers Association `,
    label: `Israel Jewelers Association `,
    country: `Israel `,
    international: `0`,
  },
  {
    value: `Israel Diamond Exchange Ltd.`,
    label: `Israel Diamond Exchange Ltd.`,
    country: `Israel `,
    international: `0`,
  },
  {
    value: `Israel Precious Stones and Diamonds Exchange Ltd.`,
    label: `Israel Precious Stones and Diamonds Exchange Ltd.`,
    country: `Israel `,
    international: `0`,
  },
  {
    value: `New Israel Club for Commerce In Diamonds Ltd.`,
    label: `New Israel Club for Commerce In Diamonds Ltd.`,
    country: `Israel `,
    international: `0`,
  },
  {
    value: `Gold & Silver Italian Group `,
    label: `Gold & Silver Italian Group `,
    country: `Italy `,
    international: `0`,
  },
  {
    value: `Borsa Diamanti D'italia`,
    label: `Borsa Diamanti D'italia`,
    country: `Italy `,
    international: `0`,
  },
  {
    value: `Japan Jewellery Association `,
    label: `Japan Jewellery Association `,
    country: `Japan `,
    international: `0`,
  },
  {
    value: `Japan Jewellery Designers Association `,
    label: `Japan Jewellery Designers Association `,
    country: `Japan `,
    international: `0`,
  },
  {
    value: `Tokyo Diamond Exchange Inc.`,
    label: `Tokyo Diamond Exchange Inc.`,
    country: `Japan `,
    international: `0`,
  },
  {
    value: `Federation of Goldsmiths and Jewellers Associations of Malaysia `,
    label: `Federation of Goldsmiths and Jewellers Associations of Malaysia `,
    country: `Malaysia `,
    international: `0`,
  },
  {
    value: `Vereniging Beurs Voor Den Diamanthandel`,
    label: `Vereniging Beurs Voor Den Diamanthandel`,
    country: `Netherlands`,
    international: `0`,
  },
  {
    value: `Federation Gold & Silver `,
    label: `Federation Gold & Silver `,
    country: `Netherlands `,
    international: `0`,
  },
  {
    value: `Jewellers & Watchmakers of New Zealand (JWNZ )`,
    label: `Jewellers & Watchmakers of New Zealand (JWNZ )`,
    country: `New Zealand `,
    international: `0`,
  },
  {
    value: `Jewellers Association of New Zealand (JANZ)`,
    label: `Jewellers Association of New Zealand (JANZ)`,
    country: `New Zealand `,
    international: `0`,
  },
  {
    value: `Jewellery Industry Registration Board of New Zealand (JIRBNZ)`,
    label: `Jewellery Industry Registration Board of New Zealand (JIRBNZ)`,
    country: `New Zealand `,
    international: `0`,
  },
  {
    value: `World Jewellery & Diamond Hub`,
    label: `World Jewellery & Diamond Hub`,
    country: `Panama`,
    international: `0`,
  },
  {
    value: `Moscow Diamond Bourse`,
    label: `Moscow Diamond Bourse`,
    country: `Russia`,
    international: `0`,
  },
  {
    value: `Singapore Jewellers Association `,
    label: `Singapore Jewellers Association `,
    country: `Singapore `,
    international: `0`,
  },
  {
    value: `Diamond Exchange of Singapore`,
    label: `Diamond Exchange of Singapore`,
    country: `Singapore `,
    international: `0`,
  },
  {
    value: `Jewellery Council of South Africa `,
    label: `Jewellery Council of South Africa `,
    country: `South Africa `,
    international: `0`,
  },
  {
    value: `Diamond Dealers Club of South Africa`,
    label: `Diamond Dealers Club of South Africa`,
    country: `South Africa `,
    international: `0`,
  },
  {
    value: `Korea Diamond Exchange Co. Ltd.`,
    label: `Korea Diamond Exchange Co. Ltd.`,
    country: `South Korea`,
    international: `0`,
  },
  {
    value: `National Gem & Jewellery Authority `,
    label: `National Gem & Jewellery Authority `,
    country: `Sri Lanka`,
    international: `0`,
  },
  {
    value: `Sri Lanka Gem & Jewellery Association `,
    label: `Sri Lanka Gem & Jewellery Association `,
    country: `Sri Lanka `,
    international: `0`,
  },
  {
    value: `Taiwan Jewelry Design & Metalsmithing Association `,
    label: `Taiwan Jewelry Design & Metalsmithing Association `,
    country: `Taiwan `,
    international: `0`,
  },
  {
    value: `Gem and Jewelry Institute of Thailand (GIT)`,
    label: `Gem and Jewelry Institute of Thailand (GIT)`,
    country: `Thailand `,
    international: `0`,
  },
  {
    value: `Thai Gem and Jewellery Traders Association `,
    label: `Thai Gem and Jewellery Traders Association `,
    country: `Thailand `,
    international: `0`,
  },
  {
    value: `Bangkok Diamonds and Precious Stones Exchange`,
    label: `Bangkok Diamonds and Precious Stones Exchange`,
    country: `Thailand `,
    international: `0`,
  },
  {
    value: `JTR Jewellery Exporters’ Association `,
    label: `JTR Jewellery Exporters’ Association `,
    country: `Turkey `,
    international: `0`,
  },
  {
    value: `Borsa Istanbul`,
    label: `Borsa Istanbul`,
    country: `Turkey `,
    international: `0`,
  },
  {
    value: `Dubai Gold & Jewellery Group `,
    label: `Dubai Gold & Jewellery Group `,
    country: `United Arab Emirates`,
    international: `0`,
  },
  {
    value: `Dubai Diamond Exchange`,
    label: `Dubai Diamond Exchange`,
    country: `United Arab Emirates`,
    international: `0`,
  },
  {
    value: `Goldsmiths’ Company `,
    label: `Goldsmiths’ Company `,
    country: `United Kingdom`,
    international: `0`,
  },
  {
    value: `National Association of Jewellers `,
    label: `National Association of Jewellers `,
    country: `United Kingdom`,
    international: `0`,
  },
  {
    value: `London Diamond Bourse`,
    label: `London Diamond Bourse`,
    country: `United Kingdom`,
    international: `0`,
  },
  {
    value: `American Gem Trade Association `,
    label: `American Gem Trade Association `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Diamond Council of America `,
    label: `Diamond Council of America `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Diamond Manufacturers and Importers Association of America `,
    label: `Diamond Manufacturers and Importers Association of America `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `IJO - Independent Jewelers Organization `,
    label: `IJO - Independent Jewelers Organization `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `International Colored Gemstone Association (ICA)`,
    label: `International Colored Gemstone Association (ICA)`,
    country: `United States`,
    international: `1`,
  },
  {
    value: `International Precious Metals Institute`,
    label: `International Precious Metals Institute`,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Italian Jewelry Center `,
    label: `Italian Jewelry Center `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Jewelers 24 Karat Club of Southern California `,
    label: `Jewelers 24 Karat Club of Southern California `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Jewelers Board of Trade `,
    label: `Jewelers Board of Trade `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Platinum Guild International `,
    label: `Platinum Guild International `,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Plumb Club `,
    label: `Plumb Club `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Responsible Jewellery Council `,
    label: `Responsible Jewellery Council `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Retail Jewelers Organization (RJO, Inc.)`,
    label: `Retail Jewelers Organization (RJO, Inc.)`,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Society of North American Goldsmiths `,
    label: `Society of North American Goldsmiths `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Women’s Jewelry Association `,
    label: `Women’s Jewelry Association `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `World Federation of Diamond Bourses `,
    label: `World Federation of Diamond Bourses `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `World Gold Council `,
    label: `World Gold Council `,
    country: `United States`,
    international: `1`,
  },
  {
    value: `Diamond Club West Coast, Inc.`,
    label: `Diamond Club West Coast, Inc.`,
    country: `United States`,
    international: `0`,
  },
  {
    value: `Diamond Dealers Club New York`,
    label: `Diamond Dealers Club New York`,
    country: `United States`,
    international: `0`,
  },
  {
    value: `24 Karat Club of New York `,
    label: `24 Karat Club of New York `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `24 Karat Club of Atlanta `,
    label: `24 Karat Club of Atlanta `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Accredited Gemologists Association `,
    label: `Accredited Gemologists Association `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `American Gem Society (AGS)`,
    label: `American Gem Society (AGS)`,
    country: `United States `,
    international: `0`,
  },
  {
    value: `CIBJO – The World Jewellery Confederation `,
    label: `CIBJO – The World Jewellery Confederation `,
    country: `United States `,
    international: `1`,
  },
  {
    value: `Jewelers of America `,
    label: `Jewelers of America `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Jewelers’ Security Alliance `,
    label: `Jewelers’ Security Alliance `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Jewelers Vigilance Committee `,
    label: `Jewelers Vigilance Committee `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Leading Jewelers Guild `,
    label: `Leading Jewelers Guild `,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Manufacturing Jewelers & Suppliers of America (MJSA)`,
    label: `Manufacturing Jewelers & Suppliers of America (MJSA)`,
    country: `United States `,
    international: `0`,
  },
  {
    value: `Natural Color Diamond Association (NCDIA)`,
    label: `Natural Color Diamond Association (NCDIA)`,
    country: `United States `,
    international: `1`,
  },
  {
    value: `Precious Metals Association of North America`,
    label: `Precious Metals Association of North America`,
    country: `United States `,
    international: `0`,
  },
];
