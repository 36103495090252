import styled from "styled-components";

export interface StyledProgressProps {
  height?: number;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  progress?: number;
}

export const StyledProgress = styled.div<StyledProgressProps>`
  width: 100%;
  height: ${(props) => (props.height || 8) + "px"};
  background-color: ${(props) => props.backgroundColor || "white"};
  overflow: hidden;
  .ui-progress__current {
    width: ${(props) => (props.progress || 1) + "%"};
    height: ${(props) => (props.height || 8) + "px"};
    background-color: ${(props) =>
      props.backgroundColor || props.theme.colors.success[200]};
    transition: width 0.3s ease-in-out;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
