import { useNotifications } from "features/notifications/queries";
import { ReactComponent as Check } from "assets/icons/notifications/Check.svg";
import { ReactComponent as Timer } from "assets/icons/notifications/Time.svg";
import { ReactComponent as Reminder } from "assets/icons/notifications/Reminder.svg";
import { ReactComponent as Tender } from "assets/icons/notifications/Tender.svg";
import { ReactComponent as Update } from "assets/icons/notifications/Update.svg";
import { ReactComponent as Lose } from "assets/icons/notifications/Lose.svg";
import { ReactComponent as DiamondPurple } from "assets/icons/notifications/Diamond.svg";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useSetAllNotificationsRead } from "features/notifications/mutations";
import { useTendersOverview } from "features/tenders/queries";
import { useAllUserRegisteredTenders } from "features/users/queries";
import { asyncCatch } from "utils";
import { useRegisterToTender } from "features/tenders/mutations";
import { toast } from "react-toastify";
import { useAnalytics } from "hooks/useAnalytics";
import { ReactComponent as Message } from "../../assets/icons/header/bell.svg";
import {
  StyledNotifications,
  StyledNotificationsMain,
} from "./Notifications.styled";
import useOnClickOutside from "hooks/useClickOutside";
import Counter from "ui/Counter";
import moment from "moment";
const notificationsData: {
  [k in NotificationType]: {
    path: string;
    icon: React.ReactNode;
  };
} = {
  "registered to tender": {
    path: "/overview",
    icon: <Check />,
  },
  "approve your diamond": {
    path: "/supplier/pending",
    icon: <Reminder />,
  },
  "did not sell": {
    path: "",
    icon: <Lose />,
  },
  "did not win": {
    path: "",
    icon: <Lose />,
  },
  "new sold diamonds": {
    path: "/supplier/orders/all",
    icon: <DiamondPurple />,
  },
  "new orders": {
    path: "/supplier/orders/all",
    icon: <DiamondPurple />,
  },
  "order update": {
    path: "/supplier/orders/all",
    icon: <Update />,
  },
  "tender finished": {
    path: "/supplier/orders/all",
    icon: <Tender />,
  },
  "tender starting in 3 days": {
    path: "/overview",
    icon: <Timer />,
  },
  "tender starting now": {
    path: "/overview",
    icon: <Reminder />,
  },
  "tender starting tommorow": {
    path: "/overview",
    icon: <Timer />,
  },
};

const Notifications = () => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  useOnClickOutside(ref, () => setVisible(false));
  const track = useAnalytics();
  const history = useHistory();
  const { data } = useNotifications();
  const { data: tendersOverview } = useTendersOverview();
  const { data: registeredTenders } = useAllUserRegisteredTenders();
  const { mutateAsync: setAllNotificationsRead } = useSetAllNotificationsRead();
  const { mutateAsync: registerToTender } = useRegisterToTender();

  const hasUnread = (data || []).filter(
    (notification) => !notification.read
  ).length;
  const onTenderEnter = async () => {
    if (
      !tendersOverview?.liveTenders ||
      !(tendersOverview.liveTenders?.length > 0)
    )
      return;
    const isRegistered = useAllUserRegisteredTenders.isRegistered(
      tendersOverview.liveTenders[0]._id,
      registeredTenders || []
    );
    if (!isRegistered) {
      const [err] = await asyncCatch(
        registerToTender(tendersOverview.liveTenders[0]._id)
      );
      if (err)
        return toast.error("Could not enter to event, please try again later");
    }
    history.push(`/tender/${tendersOverview.liveTenders[0]._id}`);
  };

  const handleNotificationClick = (notification: INotification) => {
    setVisible(false);
    track("Notifications - Message Press", notification);
    if (notification.typeTender === "tender starting now") {
      return onTenderEnter();
    }
    notificationsData[notification.typeTender] &&
      history.push(
        notificationsData[notification.typeTender].path ?? ("" as any)
      );
  };

  useEffect(() => {
    if (visible) setAllNotificationsRead();
  }, [visible, setAllNotificationsRead]);

  console.log(visible);

  return (
    <StyledNotifications
      ref={ref}
      className="icon-action"
      onClick={() => {
        setVisible((prev) => !prev);
        track("Notifications - Bar Press");
      }}
    >
      {!!hasUnread && <Counter>{hasUnread}</Counter>}
      <Message style={{ stroke: "#fff" }} className="message-icon" />
      {visible && (
        <StyledNotificationsMain className="scrollbar">
          <div className="notifications__top">
            <p className="notifications__title">Notification</p>
          </div>
          {(() => {
            if (data?.length === 0)
              return (
                <p className="notifications__empty">
                  There are no notifications at this time.
                </p>
              );
            return data?.map((notification) => (
              <div
                key={notification._id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleNotificationClick(notification);
                }}
                className="notification"
              >
                <div className="notification__circle__icon">
                  {notificationsData?.[notification.typeTender]?.icon}
                </div>
                <p className="notification__body">
                  {notification.body}
                  <span>{moment(notification.createdAt).fromNow()}</span>
                </p>
              </div>
            ));
          })()}
        </StyledNotificationsMain>
      )}
    </StyledNotifications>
  );
};

export default Notifications;
