import { routeGenerator } from "api";
import { useTenderUser } from "features/users/queries";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";
import { extractId } from "utils";

const saveTenderProduct = async (
  tenderUserId: string,
  tenderProductId: string
) => {
  const { data } = await Axios.post(
    routeGenerator(
      `/tenderUsers/${tenderUserId}/saved/products/${tenderProductId}`
    )
  );
  return data;
};

const unsaveTenderProduct = async (
  tenderUserId: string,
  tenderProductId: string
) => {
  const { data } = await Axios.delete(
    routeGenerator(
      `/tenderUsers/${tenderUserId}/saved/products/${tenderProductId}`
    )
  );
  return data;
};

export const useUnsaveTenderProduct = ({
  tenderProduct,
}: {
  tenderProduct: TenderProduct;
}) => {
  const queryClient = useQueryClient();
  const queryKey = ["tenderuser", extractId(tenderProduct.tender as any)];
  const {
    data: tenderUser,
    //  isLoading, isError
  } = useTenderUser(extractId(tenderProduct.tender as any));
  // unsave product mutation
  return useMutation(
    () => unsaveTenderProduct(tenderUser?._id ?? "", tenderProduct._id),
    {
      onMutate: async (data) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value
        const previous = queryClient.getQueryData<typeof tenderUser>(queryKey);

        // Optimistically update to the new value
        queryClient.setQueryData<typeof tenderUser>(queryKey, (old) =>
          old
            ? {
                ...old,
                savedTenderProducts: (old.savedTenderProducts || []).filter(
                  (id) => id !== tenderProduct._id
                ),
              }
            : (old as any)
        );

        return {
          previous,
        };
      },
      onError: (_, __, context: any) => {
        queryClient.setQueryData(queryKey, () => context.previous);
      },
    }
  );
};

export const useSaveTenderProduct = ({
  tenderProduct,
}: {
  tenderProduct: TenderProduct;
}) => {
  const queryClient = useQueryClient();
  const queryKey = ["tenderuser", extractId(tenderProduct.tender as any)];

  const {
    data: tenderUser,
    //  isLoading, isError
  } = useTenderUser(extractId(tenderProduct.tender as any));

  // save product mutation
  return useMutation(
    () => saveTenderProduct(tenderUser?._id ?? "", tenderProduct._id),
    {
      onMutate: async (data) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);

        // Snapshot the previous value
        const previous = queryClient.getQueryData<typeof tenderUser>(queryKey);

        // Optimistically update to the new value
        queryClient.setQueryData<typeof tenderUser>(queryKey, (old) =>
          old
            ? {
                ...old,
                savedTenderProducts: [
                  ...(old.savedTenderProducts || []),
                  tenderProduct._id,
                ],
              }
            : (old as any)
        );

        return {
          previous,
        };
      },
      onError: (_, __, context: any) => {
        queryClient.setQueryData(queryKey, () => context.previous);
      },
    }
  );
};
