import { routeGenerator } from "api";
import { axios as Axios } from "lib/axios";
import { useMutation, useQueryClient } from "react-query";

export const setAllNotificationsRead = async () => {
  const { data } = await Axios.put(routeGenerator(`/notifications/setAllRead`));
  return data;
};

export const useSetAllNotificationsRead = () => {
  const queryClient = useQueryClient();
  const queryKey = ["notifications"];
  return useMutation(() => setAllNotificationsRead(), {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<INotification[] | undefined>(queryKey, (old) =>
        old
          ? old.map((notification) => ({
              ...notification,
              read: true,
            }))
          : undefined
      );

      // Return a context object with the snapshotted value
      return { previousNotifications };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        queryKey,
        (context as any).previousNotifications
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
