import styled from "styled-components";

export const StyledTable = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto min-content;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  .ui-table {
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    display: grid;
    grid-template-rows: min-content auto;
    overflow-x: auto;
    /* border: solid 1.5px rgba(192, 203, 209, 0.28); */
  }

  .ui-table__head {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    border: solid 1.5px rgba(192, 203, 209, 0.28);
    border-radius: 8px 10px 0 0;
  }

  .ui-table__body {
    position: relative;
    z-index: 1;
    overflow: auto;
    height: 100%;
    overflow-x: hidden;
  }

  .ui-table__head__row,
  .ui-table__body__row {
    display: grid;
    grid-template-columns: var(--columns);
  }

  .ui-table__head__row {
    grid-template-rows: 50px;
  }

  .ui-table__body__row {
    grid-template-rows: 60px;
    background-color: #f7f7f7;

    &:nth-of-type(odd) {
      background-color: #fff;
    }
  }

  .ui-table__body__row:hover {
    background-color: #f4f0f0;
  }

  .ui-table__body__cell,
  .ui-table__body__cell p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--archivo);
    padding: 0.5rem 0.75rem;
    font-size: 16px;
    color: #687c97;
    overflow: hidden;
    text-align: center;
  }

  .ui-table__body__cell img {
    max-height: calc(100px - 1rem) !important;
    max-width: 52px;
  }

  .ui-table__head__cell {
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    font-family: var(--archivo);
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgb(104, 124, 151);
    border-right: solid 1.5px rgb(104, 124, 151, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      border-right: none;
    }
  }

  .ui-table__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 5px 30px 0 rgba(202, 215, 227, 0.39);
    border: solid 1px #edf2f6;
    background-color: #fafafa;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: var(--sectionPadding);
    padding: 1rem;

    .ui-table__empty__icon {
      color: ${({ theme }) => theme.colors.neutral[600]};
      margin-bottom: ${({ theme }) => theme.spacing[12]};
    }

    .ui-table__empty__text {
      font-family: ${({ theme }) => theme.fonts.archivo};
      font-size: ${({ theme }) => theme.fontSize[14]};
      font-weight: 600;
      line-height: 1.43;
      text-align: center;
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
  }

  .ui-table__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;

    font-family: ${({ theme }) => theme.fonts.archivo};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  .ui-table__pagiantion__left {
    display: flex;
    align-items: center;

    .ui-select__control {
      height: 18px;
      width: 80px;
      margin-left: 10px;
    }
  }
`;
