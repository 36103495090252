import "react-tippy/dist/tippy.css";
import "react-tippy/dist/tippy.css";
import React, { useState } from "react";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputProps,
} from "./Input.styled";
import "react-popper-tooltip/dist/styles.css";
import TooltipError from "ui/TooltipError";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/eye-off.svg";
function Input(
  props: StyledInputProps &
    React.InputHTMLAttributes<HTMLInputElement> & { error?: string }
) {
  const [type, setType] = useState(props.type || "");

  return (
    <>
      <StyledInputContainer className="ui-input__container">
        <StyledInput {...props} className="ui-input" type={type} />
        {props.type === "password" &&
          !props.isError &&
          (type === "password" ? (
            <Eye className="ui-input__eye" onClick={() => setType("text")} />
          ) : (
            <EyeOff
              className="ui-input__eye"
              onClick={() => setType("password")}
            />
          ))}
        {props.isError && props.error && <TooltipError error={props.error} />}
      </StyledInputContainer>
    </>
  );
}

export default Input;
