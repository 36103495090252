import styled from "styled-components";

export const StyledMediaModal = styled.div`
  width: 90%;
  max-width: 650px;
  height: 90%;
  max-height: 530px;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 5px 30px 0 rgba(74, 85, 100, 0.14);
  background-color: #fff;
  display: flex;
  position: relative;

  .media-popup__left {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 70px);
    grid-template-columns: 70px;
    row-gap: 1rem;
    margin-right: 1.5rem;
  }

  .media-popup__right {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid rgba(74, 85, 100, 0.04);
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px transparent;
    flex: 1;
  }

  .media-popup__left__box {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid rgba(74, 85, 100, 0.04);
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px transparent;
  }

  .media-popup__left__box {
    cursor: pointer;
  }

  .media-popup__selected {
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.08);
    border: solid 2px #32dea5;
  }

  .media-popup__diamond360 {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 350px;
  }
`;
