import Modal from "..";
import Button from "ui/Button";
import {
  StyledWishlistCard,
  StyledWishlistModal,
} from "./WishlistModal.styled";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as X } from "../../../assets/icons/x.svg";
import ProductImage from "components/ProductImage";
import ElipseDetail from "components/ElipseDetail";
import Price from "ui/Price";
import Countdown from "ui/Countdown";
import useModalActions from "hooks/useModalActions";
import Shimmer from "ui/Shimmer";
import Shine from "ui/Shimmer/Shine";
import Divider from "ui/Divider";
import { useGDWishlist } from "features/gd/queries";
import { getProductPpc } from "utils";
import {
  useClearGDWishlist,
  useRemoveFromGDWishlist,
} from "features/gd/mutations";
import { GD_CLIENT_URL } from "config";
import { useWishlist } from "features/wishlist/queries";
import {
  useClearWishlist,
  useRemoveFromWishlist,
} from "features/wishlist/mutations";
import ExportCSV from "ui/ExportCSV";
// import { useUnsaveTenderProduct } from "features/products/mutations";

type WishlistItem =
  | { item: Product; type: "GET"; _id: string }
  | { item: TenderProduct; type: "TRADE"; _id: string };

const WishlistModal = (props: {}) => {
  const { openModal, closeModal } = useModalActions();
  const {
    data: tendersWishlist,
    isFetching: isFetchingTendersWishlist,
    isFetched: isFetchedTendersWishlist,
  } = useWishlist();
  const {
    data: GDWishlist,
    isFetching: isFetchingGDWishlist,
    isFetched: isFetchedGDWishlist,
  } = useGDWishlist();
  const { mutateAsync: clearGDWishlist } = useClearGDWishlist();
  const { mutateAsync: clearWishlist } = useClearWishlist();

  const handleDeleteAll = () => {
    const confirmModalProps = {
      title: "Clear Wishlist",
      subtitle: (
        <>
          Are you sure you want to <strong>clear all items?</strong>
        </>
      ),
      cancelText: "Cancel",
      submitText: (
        <>
          <Trash />
          Yes, Clear all
        </>
      ),
      onCancel: closeModal,
      onSubmit: () => {
        clearGDWishlist();
        clearWishlist();
        closeModal();
      },
    };
    openModal({
      modalType: "ConfirmModal",
      modalProps: confirmModalProps,
    });
  };

  const createWishlist = (): WishlistItem[] => {
    return [
      ...(tendersWishlist || []).map(
        (item) => ({ item, type: "TRADE", _id: item._id } as WishlistItem)
      ),
      ...(GDWishlist || []).map(
        ({ product }) =>
          ({ item: product, type: "GET", _id: product._id } as WishlistItem)
      ),
    ];
  };

  const wishlist = createWishlist();

  const getWishlistToExport = () => {
    return [
      ...(tendersWishlist || []).map((item) => item.product),
      ...(GDWishlist || []).map(({ product }) => product),
    ];
  };

  const shimmer =
    (isFetchingGDWishlist && !isFetchedGDWishlist) ||
    (isFetchingTendersWishlist && !isFetchedTendersWishlist);

  return (
    <Modal>
      <Shimmer shimmer={shimmer}>
        <StyledWishlistModal>
          <Modal.Close />
          <div className="wishlist__top">
            <h1>
              My Wishlist{" "}
              {!!wishlist.length && <span>({wishlist.length} Items)</span>}
            </h1>
            <div className="wishlist__actions">
              <ExportCSV
                filename="wishlist"
                headers={[
                  {
                    label: "Image",
                    accessor: "diamondImage",
                  },
                  {
                    label: "Image360",
                    accessor: "diamondImage360",
                  },
                  {
                    label: "Shape",
                    accessor: "model",
                  },
                  {
                    label: "Carat",
                    accessor: "carat",
                  },
                  {
                    label: "Color",
                    accessor: "color",
                  },
                  {
                    label: "Clarity",
                    accessor: "clarity",
                  },
                  {
                    label: "Cut",
                    accessor: "cut",
                  },
                  {
                    label: "Polish",
                    accessor: "polish",
                  },
                  {
                    label: "Symmetry",
                    accessor: "symmetry",
                  },
                  {
                    label: "Flour",
                    accessor: "fluorescenceIntensity",
                  },
                  {
                    label: "Lab",
                    accessor: "lab",
                  },
                  {
                    label: "Ratio",
                    accessor: "ratio",
                  },
                  {
                    label: "Depth",
                    accessor: "depth",
                  },
                  {
                    label: "Table",
                    accessor: "table",
                  },
                  {
                    label: "Measurements",
                    accessor: "measurement",
                  },
                ]}
                data={getWishlistToExport()}
              >
                Export To CSV
              </ExportCSV>
              <Button
                kind="grey"
                onClick={handleDeleteAll}
                disabled={!wishlist.length || shimmer}
              >
                <Trash />
                Clear All
              </Button>
            </div>
          </div>
          {(() => {
            if (!wishlist.length)
              return <p className="wishlist__cards--empty">No items to show</p>;
            if (!!wishlist.length)
              return (
                <div
                  className="wishlist__cards scrollbar"
                  style={shimmer ? { pointerEvents: "none" } : {}}
                >
                  {wishlist.map((item, index) => {
                    return <WishlistCard key={index} item={item} />;
                  })}
                </div>
              );
          })()}
        </StyledWishlistModal>
      </Shimmer>
    </Modal>
  );
};

const WishlistCard = ({ item }: { item: WishlistItem }) => {
  const { openModal, closeModal } = useModalActions();
  const { mutateAsync: removeFromGdWishlist } = useRemoveFromGDWishlist();
  const { mutateAsync: removeFromWishlist } = useRemoveFromWishlist();

  const { type } = item;
  const product = item.type === "GET" ? item.item : item.item.product;
  const tender = item.type === "TRADE" ? item.item.tender : null;

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const confirmModalProps = {
      title: "Clear Wishlist",
      subtitle: (
        <>
          Are you sure you want to <strong>clear this item?</strong>
        </>
      ),
      cancelText: "Cancel",
      submitText: (
        <>
          <Trash />
          Yes, Clear from list
        </>
      ),
      onCancel: closeModal,
      onSubmit: () => {
        if (type === "GET") {
          removeFromGdWishlist(product._id);
        }
        if (type === "TRADE") {
          removeFromWishlist({
            tenderId: item.item.tender._id,
            tenderProductId: item.item._id,
          });
        }
        closeModal();
      },
    };
    openModal({
      modalType: "ConfirmModal",
      modalProps: confirmModalProps,
    });
  };

  const handleClick = () => {
    if (item.type === "GET") {
      window.open(GD_CLIENT_URL + `/?diamondId=${product._id}`);
    }
  };

  const dividerProps = {
    width: 1,
    height: 35,
    color: "#f5f8fa",
    spacing: 15,
  } as const;

  return (
    <StyledWishlistCard type={type} onClick={handleClick}>
      {type === "TRADE" && <p className="tenders__tag">Tenders</p>}
      <Shine>
        <div className="product__image">
          <ProductImage src={product.diamondImage} />
        </div>
      </Shine>
      <Divider {...dividerProps} />
      {type === "GET" && (
        <p className="product__details">
          <Shine>
            <span>Diamond ID</span>
          </Shine>
          <br />
          <Shine>
            <span className="elipsis" title={product.productId}>
              {product.productId}
            </span>
          </Shine>
        </p>
      )}
      {type === "TRADE" && tender && (
        <p className="product__details">
          <Shine>
            <span>Event</span>
          </Shine>
          <br />
          <Shine>
            <span className="elipsis" title={tender.name}>
              {tender.name}
            </span>
          </Shine>
        </p>
      )}
      <Divider {...dividerProps} />
      <div className="product__properties">
        {(
          [
            "model",
            "carat",
            "color",
            "clarity",
            "cut",
            "polish",
            "symmetry",
          ] as ProductKey[]
        ).map((key, index) => {
          if (!product[key]) return null;
          return <ElipseDetail key={index}>{product[key]}</ElipseDetail>;
        })}
      </div>
      {product.lab && (
        <>
          <Divider width={1} height={35} color={"#f5f8fa"} spacing={15} />
          <div className="product__certificate">
            <ElipseDetail>
              {product.lab ?? "-"}
              {type === "GET" && <span>{product.certificateId ?? ""}</span>}
              {type === "TRADE" && <span>Certificate</span>}
            </ElipseDetail>
          </div>
        </>
      )}
      <Divider width={1} height={35} color={"#f5f8fa"} spacing={15} />
      {type === "GET" && (
        <div className="product__price">
          <Shine>
            <Price price={getProductPpc(product)} postfix="/ppc" />
          </Shine>
        </div>
      )}
      {type === "TRADE" && tender && (
        <Shine>
          <div className="product__counter divider--right">
            {(() => {
              // counting to starting date
              if (new Date(tender.startDate) > new Date()) {
                return (
                  <>
                    <p className="product__counter__text">To Tender Start</p>
                    <Countdown date={tender.startDate} withLabels={false} />
                  </>
                );
              }
              return (
                <>
                  <p className="product__counter__text">To Tender End</p>
                  <Countdown date={tender.endDate} withLabels={false} />
                </>
              );
            })()}
          </div>
        </Shine>
      )}
      <Divider width={1} height={35} color={"#f5f8fa"} spacing={15} />

      <Shine>
        <button className="remove__card" onClick={(e) => handleDelete(e)}>
          <X />
        </button>
      </Shine>
    </StyledWishlistCard>
  );
};

export default WishlistModal;
