import styled from "styled-components";
import Page from "ui/Page";

export const StyledSupplier = styled(Page)<{ tab: string }>`
  display: grid;
  grid-template-rows: min-content min-content auto min-content;

  .ui-table__container {
    width: calc(100% - var(--sectionPadding) * 2);
    margin: 0 auto;
    --columns: ${(props) =>
      (props.tab === "pending" &&
        `minmax(30px,0.3fr) 
         minmax(80px,0.7fr)
         minmax(120px,0.5fr)
         repeat(5,minmax(70px,0.5fr))
         minmax(70px,0.5fr)
         repeat(4,minmax(150px,1.1fr))`) ||
      "repeat(8, minmax(120px,0.6fr)) repeat(3, minmax(180px,1fr))"};

    .ui-table__body__cell {
      text-transform: uppercase;
    }

    .p-supplier__diamonds-table__image {
      margin: 0 12px;
    }

    .p-supplier__diamonds-table__lab {
      font-family: var(--archivo);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.75px;
      color: #f5a000;
      text-transform: uppercase;
    }

    .p-supplier__diamonds-table .countdown__box--days,
    .p-supplier__diamonds-table .countdown__box--days-seperator,
    .p-supplier__diamonds-table .countdown__label {
      display: none;
    }

    .p-supplier__diamonds-table__countdown {
      height: 33px;
      border-radius: 3px;
      border: solid 0.5px #eaeef2;
      background-color: #687c97;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .p-supplier__diamonds-table__countdown .countdown__value,
    .p-supplier
      .p-supplier__diamonds-table__countdown
      .countdown__seperator::after {
      font-family: var(--archivo);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.75px;
      text-align: center;
      color: #fff;
    }

    .p-supplier__diamonds-table__countdown .countdown {
      width: 100%;
      padding: 0 12px;
    }

    .p-supplier__diamonds-table__approve {
      width: 90%;
      height: 38px;
    }

    .p-supplier__diamonds-table__approve > * {
      color: #fff;
    }

    .p-supplier__diamonds-table__image {
      margin: 0 12px;
    }

    .p-supplier__diamonds-table__auction {
      text-align: center;
      display: grid;
      row-gap: 4px;
    }

    .p-supplier__diamonds-table__auction > p {
      padding: 0;
    }

    .p-supplier__diamonds-table__tender {
      text-align: center;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      font-family: var(--archivo);
      color: #687c97;
    }

    .p-supplier__diamonds-table__timeleft .countdown__value,
    .p-supplier__diamonds-table__timeleft .countdown__seperator::after {
      font-family: var(--archivo);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.75px;
      text-align: center;
      padding: 0.5rem 0.5rem;
    }

    .p-supplier__diamonds-table__timeleft .countdown {
      width: 100%;
      padding: 0 12px;
    }
  }

  .p-supplier__actions {
    display: flex;
    align-items: center;
  }

  .select-all {
    position: relative;
    left: -2px;
  }

  .p-supplier__approve-all {
    height: 33px;
    margin-left: 12px;
  }

  .p-supplier__diamonds-table__lotid {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ui-countdown__value {
    padding: 0 4px !important;
  }

  .ui-countdown__seperator::after {
    font-size: 16px;
  }

  @media (max-height: 740px) {
    grid-template-rows: min-content min-content minmax(450px, auto) min-content;
  }
`;
