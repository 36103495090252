import styled from "styled-components";
import background from "../../assets/images/register-bg.jpg";

export const StyledLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - var(--headerHeight));
  width: 100%;
  background-color: #f9fbfd;
  background-image: url(${background});
  background-size: 100% 13.375rem;
  background-repeat: no-repeat;
  background-position: bottom;
  background-origin: border-box;
  position: relative;
  .login__inner {
    width: 460px;
    position: relative;
    /* top: -40px; */
  }

  .login__title {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize[32]};
    color: ${({ theme }) => theme.colors.neutral[700]};
    text-align: center;
    margin: 0 auto;
  }

  .login__subtitle {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[16]};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: ${({ theme }) => theme.spacing[20]} 0;
  }

  .login__signwith {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[16]};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: ${({ theme }) => theme.spacing[20]} 0;
    opacity: 0
  }

  .ui-field {
    margin-bottom: ${({ theme }) => theme.spacing[20]};
  }

  .login__submit {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[20]};
    margin-top: ${({ theme }) => theme.spacing[24]};
  }

  .login__register,
  .login__forgot {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.archivo};
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing[32]};
    a {
      text-decoration: underline;
      padding-left: ${({ theme }) => theme.spacing[8]};
      color: ${({ theme }) => theme.colors.neutral[600]};
      transition: all 0.3s ease-in-out;
    }

    a:hover {
      color: ${({ theme }) => theme.colors.neutral[700]};
    }
  }

  .login__forgot {
    justify-content: flex-start;
    margin-top: ${({ theme }) => "-" + theme.spacing[8]};
  }

  .ui-goback {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }

  .login__input__error {
    font-family: ${({ theme }) => theme.fonts.archivo};
    font-size: ${({ theme }) => theme.fontSize[14]};
    color: #ef0000;
    position: relative;
  }

  .login__get-register {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  .login__get-register a {
    margin-left: 6px;
    font-weight: bold;
    color: #687c97;
    text-decoration: underline;
  }

  form {
    opacity: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-size: 100% 6.375rem;
    overflow-y: scroll;
    padding: var(--sectionPadding) var(--sectionPadding);
    /* height: 100%; */
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;

    .login__title {
      font-size: 27px;
      max-width: 20ch;
      text-align: center;
      overflow: auto;
    }

    .login__subtitle,
    .login__signwith {
      margin: 1rem 0;
    }

    .login__inner {
      width: 100%;
      margin: auto auto;
    }

    .ui-goback {
      display: flex;
      position: unset;
      left: unset;
      top: unset;
      bottom: unset;
      text-align: left;
      width: 100%;
      margin-left: 0;
      margin-top: 1rem;
    }

    .login__register {
      margin-top: 1.5rem;
    }

    .get-login__btn,
    input,
    .ui-button {
      height: 45px;
    }
  }
`;
