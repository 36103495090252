export const theme = {
  colors: {
    white: "#ffffff",
    neutral: {
      50: "#FAFAFA",
      100: "#F5F7F9",
      200: "#EAEEF2",
      300: "#D1D7E0",
      400: "#A2A7B4",
      500: "#9AA7B9",
      600: "#687C97",
      700: "#222B45",
      800: "#edf2f6",
    },
    primary: {
      50: "#FAFAFA",
      100: "#A895F7",
      200: "#492CBD",
      300: "#2B177A",
      400: "#160750",
    },
    secondary: {
      50: "#FAB738",
      100: "#32DEA5",
      200: "#A79572",
      300: "#FA922A",
      400: "#DDE6FF",
    },
    success: {
      50: "#CFF8EA",
      100: "#AFFFE4",
      200: "#32DEA5",
      300: "#04BE80",
      400: "#04895D",
    },
    warning: {
      50: "#FFE7BA",
      100: "#FFCF75",
      200: "#FAB738",
      300: "#F5A000",
      400: "#D68C00",
    },
    error: {
      50: "#FF8080",
      100: "#FF4747",
      200: "#EF0000",
      300: "#D90000",
      400: "#BD0000",
    },
  },
  fonts: {
    archivo: ` "Archivo", sans-serif`,
    roboto: ` "Roboto", sans-serif`,
    poppins: ` "Poppins", sans-serif`,
  },
  spacing: {
    "4": "0.25rem",
    "8": "0.5rem",
    "10": "0.625rem",
    "12": "0.75rem",
    "16": "1rem",
    "20": "1.25rem",
    "24": "1.5rem",
    "32": "2rem",
    "36": "2.25rem",
    "40": "2.5rem",
    "48": "3rem",
    "56": "3.5rem",
    "64": "4rem",
  },
  fontSize: {
    xs: "0.5rem",
    sm: "0.625rem",
    tiny: "0.75rem",
    md: "0.875rem",
    base: "1rem",
    lg: "1.25rem",
    xl: "1.5625rem",
    "2xl": "2rem",
    "3xl": "2.5rem",
    "4xl": "3rem",
    "5xl": "3.75rem",
    "6xl": "5rem",
    "7xl": "5rem",
    8: "0.5rem",
    10: "0.625rem",
    12: "0.75rem",
    14: "0.875rem",
    16: "1rem",
    18: "1.125rem",
    20: "1.25rem",
    25: "1.5625rem",
    "32": "2rem",
    "40": "2.5rem",
    "48": "3rem",
    "60": "3.75rem",
    "80": "5rem",
  },
  borderRadius: {
    6: "6px",
    10: "10px",
    30: "30px",
    full: "50%",
  },
  shadows: {
    light: "0px 5px 30px rgba(202, 215, 227, 0.15);",
    md: "0px 5px 30px rgba(202, 215, 227, 0.40);",
  },
  headerHeight: "var(--headerHeight)",
  drawerWidth: "var(--drawerWidth)",
  breakpoints: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
} as const;

export type Theme = typeof theme;
