import ProductTable from "components/ProductTable";
import { StyledTenderProductTable } from "./TenderProductTable.styled";
interface Props {
  tenderProduct: TenderProduct;
}

const keys: { label: string; key: ProductKey }[] = [
  {
    label: "Shape",
    key: "model",
  },
  {
    label: "Carat",
    key: "carat",
  },
  {
    label: "Lab(Cert.)",
    key: "lab",
  },
  {
    label: "Color",
    key: "color",
  },
  {
    label: "C. Intensity",
    key: "fluorescenceIntensity",
  },
  {
    label: "Clarity",
    key: "clarity",
  },
  {
    label: "Cut",
    key: "cut",
  },
  {
    label: "Polish",
    key: "polish",
  },
  {
    label: "Symmetry",
    key: "symmetry",
  },
  {
    label: "Flour.",
    key: "fluorescenceColor",
  },
  {
    label: "Location",
    key: "country",
  },
];

const parameters: { label: string; key: ProductKey }[] = [
  { key: "depth", label: "Depth" },
  { key: "table", label: "Table" },
  { key: "measurement", label: "Measure." },
  { key: "pavilionDepth", label: "Pavilion Depth" },
  { key: "pavilionAngle", label: "Pavilion Angle" },
  { key: "crownHeight", label: "Crown Height" },
  { key: "crownAngle", label: "Crown Angle" },
  { key: "centerInclusions", label: "Center Inclussions" },
  { key: "openInclusions", label: "Open Inclusions" },
  { key: "whiteInclusions", label: "White Inclusions" },
  { key: "girdleThin", label: "Girdle Thin" },
  { key: "girdleThick", label: "Girdle Thick" },
  { key: "girdleCondition", label: "Girdle Condition" },
  { key: "culetSize", label: "Culet Size" },
  { key: "culetCondition", label: "Culet Condition" },
  { key: "ratio", label: "Ratio" },
  { key: "shade", label: "Shade" },
  { key: "milkyInclusions", label: "Milky Inclusions" },
  { key: "eyeClean", label: "Eye Clean" },
];

const TenderProductTable = ({ tenderProduct }: Props) => {
  return (
    <StyledTenderProductTable className="tender-product__tables">
      <ProductTable
        product={tenderProduct.product}
        keys={keys}
        title="Main Parameters"
        className="tender-product__table"
      />
      <ProductTable
        product={tenderProduct.product}
        keys={parameters}
        title="More Parameters"
        className="tender-product__table tender-product__table__two"
      />
    </StyledTenderProductTable>
  );
};

export default TenderProductTable;
